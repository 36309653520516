import axiosInstance from '../utils/axiosInstance';
const useUpsertPartnerAccountSso = (requestParams = null) => {
  const promise = axiosInstance
    .post(
      `/api/users/upsertPartnerAccountSso/${requestParams?.data?.emailId}`,
      requestParams,
      {}
    )
    .then((response) => ({ data: response?.data }));
  return promise;
};

export default useUpsertPartnerAccountSso;
