import { useContext } from 'react';
import { KnivesContext } from '../../contexts/KnivesContext';

const useKnives = () => {
  const knivesState = useContext(KnivesContext);
  if (!knivesState) {
    throw new Error('Undefined knives state!');
  }
  const {
    knifeInfo,
    knives,
    newShipAddress,
    purchasedTxns,
    addresses,
    balancePoints,
  } = knivesState;

  return {
    newShipAddress,
    knifeInfo,
    knives,
    addresses,
    purchasedTxns,
    balancePoints,
  };
};

export default useKnives;
