// import { CancelToken } from 'axios'
import axiosInstance from '../utils/axiosInstance';
const useMoneyTransferTxns = (requestParams = null) => {
  const promise = axiosInstance
    .post(
      '/api/transactions',
      { data: requestParams },
      {
        // 'cancelToken': source.token,
      }
    )
    .then((response) => ({ data: response?.data }));
  // promise.cancel = () => {
  //   source.cancel('Query was cancelled by React Query')
  // }
  return promise;
};
export const useMoneyTransferTxnsV2 = (requestParams = null) => {
  const promise = axiosInstance
    .post(
      '/api/v2/transactions',
      { data: requestParams },
      {
        // 'cancelToken': source.token,
      }
    )
    .then((response) => ({ data: response?.data }));
  // promise.cancel = () => {
  //   source.cancel('Query was cancelled by React Query')
  // }
  return promise;
};

export default useMoneyTransferTxns;
