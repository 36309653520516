import { useMutation } from '@tanstack/react-query';
import initUpsertPartnerAccountSso from '../services/useUpsertPartnerAccountSso';
import { UN_AUTH_TOKEN_KEY } from '../utils/constants';

export default function useUpsertPartnerAccountSso() {
  return useMutation({
    mutationKey: ['upsertPartnerAccountSSO'],
    mutationFn: initUpsertPartnerAccountSso,
    onSuccess: () => {
      console.log(
        'useUpsertPartnerAccountSso onSuccess removeItem: UN_AUTH_TOKEN_KEY'
      );
      sessionStorage.removeItem(UN_AUTH_TOKEN_KEY);
    },
  });
}
