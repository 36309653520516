import { AuthContext } from '@rewards/contexts';
import { FEATURES_FLAGS } from '../../util/constants';
import { GenericCarouselItemContent } from './CarouselBanner';
const { useAuth } = AuthContext;

export interface DailySurpriseItem {
  imageComingSoon: string;
}

export function DailySurpriseCarouselItemContent({
  item,
  isDesktop,
  isLandingPage,
  handleClick,
}: {
  item: DailySurpriseItem;
  isDesktop: boolean;
  isLandingPage: boolean;
  handleClick: () => void;
}) {
  const { featureFlags } = useAuth();
  const module = featureFlags.find(
    (flag: any) => flag.name === FEATURES_FLAGS.ENABLE_DAILY_SURPRISES
  );

  if (!module?.otherDetails?.commingSoon) {
    return (
      <GenericCarouselItemContent
        isDailyGameItem={true}
        item={item}
        isDesktop={isDesktop}
        isLandingPage={isLandingPage}
        handleClick={handleClick}
      />
    );
  }

  return <img src={item.imageComingSoon} alt={'Coming Soon'} />;
}
