import axios from 'axios';

const BASE_URL = '/';

export function serviceCall(requestConfig) {
  const instance = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
  });
  instance.interceptors.request.use(
    function (config) {
      delete instance.defaults.headers.common['Authorization'];
      const getAccessToken =
        sessionStorage.getItem('accessToken') ||
        sessionStorage.getItem('unAuthAccessToken');
      config.headers.Authorization = `Bearer ${getAccessToken}`;
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
  instance.defaults.headers.post['Content-Type'] = 'application/json';
  instance.interceptors.response.use(
    function (response) {
      return response.data;
    },
    function (error) {
      return Promise.reject(error?.status?.desc);
    }
  );

  return instance(requestConfig)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (!error.response && error.request) {
        error.message = 'Failed To Perform Action';
      }
      return Promise.reject(error.response);
    });
}

export function setHeaders(requestConfig) {
  requestConfig['headers'] = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  return requestConfig;
}

export const httpConfig = () => ({
  baseURL: BASE_URL,
  ...setHeaders({}),
});

export function get(url, params) {
  return serviceCall({
    ...httpConfig(),
    method: 'GET',
    url,
    params,
  });
}

export function post(url, params) {
  return serviceCall({
    ...httpConfig(),
    method: 'POST',
    url,
    data: params,
  });
}

export function filePost(url, formData) {
  return serviceCall({
    ...httpConfig(),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
    url,
    data: formData,
  });
}

export function patch(url, data) {
  return serviceCall({
    ...httpConfig(),
    method: 'PATCH',
    url,
    data,
  });
}

export function del(url, data) {
  return serviceCall({
    ...httpConfig(),
    method: 'DELETE',
    url,
    data,
  });
}
