import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Modal } from 'react-bootstrap';
import { Button } from '@rewards/ui';
import { ACTIONS, AuthContext, AppContext } from '@rewards/contexts';
import { useUserInfo, useMoneyTransferTxns } from '@rewards/data-provider';

import { SchoolContext } from '../../../../contexts/SchoolContexts';
import ToolsForSchoolsContext from '../../../../contexts/ToolsForSchoolsContext';
import { useResponsive, useSchool } from '../../../../util/hooks';
import { getSelectedSchoolDashboardPath } from '../../../../util/paths';
import { EVENT_LOCATION, EVENTS, PAGES } from '../../../../analytics/events';
import { TRANSFER_MONEY_TXNS_PARAMS } from '../../../../util/constants';

import Icon from '../../../../assets/svg';
import ModalCloseBtn from '../../../SelectSchoolModals/ModalCloseBtn';

import './ConfirmDonationModal.scss';

const { useAuth } = AuthContext;

const ConfirmDonationModal = ({
  showAdvantPointsTooltip,
  showTransactionErrorTooltip,
  resetPoints,
  advantPoints,
  currentAdvantPointsDonation,
}) => {
  const { checkUserSession } = useContext(AuthContext.AuthContext);
  const { track, source } = useContext(AppContext.AppContext);
  const { selectedSchool, currencyExchange } = useContext(SchoolContext);
  const { getSchoolForUser } = useContext(ToolsForSchoolsContext);

  const history = useHistory();
  const { isSessionExpired, checkValidToken } = useAuth();
  const { data: userInfo, isFetching: isUserInfoFetching } = useUserInfo();
  const { data: user } = userInfo || {};
  const { mutateAsync: handleMoneyTransferTxns, status: moneyTransferStatus } =
    useMoneyTransferTxns();
  const { school } = useSchool();
  const { isDesktop } = useResponsive();

  const [show, setShow] = useState(false);
  const [userName, setUserName] = useState();
  const [schoolName, setSchoolName] = useState();

  useEffect(() => {
    if (user) {
      setUserName(user?.partnerAccounts[0]?.profile?.name);
    }
  }, [user]);

  useEffect(() => {
    if (school) {
      setSchoolName(school?.name);
    }
  }, [school]);

  useEffect(() => {
    if (isSessionExpired) {
      setShow(false);
    }
  }, [isSessionExpired]);

  useEffect(() => {
    if (
      moneyTransferStatus === ACTIONS.QUERY_STATUS.ERROR &&
      !isUserInfoFetching
    ) {
      getSchoolForUser();
      const isRewardPointsInsufficient =
        advantPoints > user?.partnerAccounts?.[0]?.accountBalance;
      showTransactionErrorTooltip(isRewardPointsInsufficient);
    }
  }, [isUserInfoFetching, moneyTransferStatus]);

  useEffect(() => {
    if (moneyTransferStatus === ACTIONS.QUERY_STATUS.SUCCESS) {
      getSchoolForUser();
      setShow(true);
      source(PAGES.TOOLS_FOR_SCHOOLS_DONATE_CONFIRM_MODAL, {
        ui_version: import.meta.env.APP_VERSION,
      });
    }
  }, [moneyTransferStatus]);

  const transferMoney = (advantPoints) => {
    const params = {
      fromAccount: {
        fromUserId: user?._id,
        fromAccountId: user?.partnerAccounts?.[0]?.accountId,
        fromAccountValue: advantPoints,
        fromCurrencyId: currencyExchange?.sourceCurrencyId,
        fromAccountType: TRANSFER_MONEY_TXNS_PARAMS.FROM_ACCOUNT_TYPE,
      },
      toAccount: {
        toAccountValue: advantPoints,
        toAccountType: TRANSFER_MONEY_TXNS_PARAMS.TO_ACCOUNT_TYPE,
        toUserId: user?._id,
        toAccountId: selectedSchool?.accountId,
      },
    };
    return checkValidToken()
      .then(() => {
        return handleMoneyTransferTxns(params);
      })
      .catch(() => {
        throw new Error('Transaction has failed');
      });
  };

  const handleCloseModal = () => {
    resetPoints();
    setShow(false);
    history.push(getSelectedSchoolDashboardPath(selectedSchool?.schoolId));
  };

  const handleClose = () => {
    track(EVENTS.TOOLS_FOR_SCHOOLS_CLOSE_CLICKED, {
      location: EVENT_LOCATION.TOOLS_FOR_SCHOOLS_DONATE_CONFIRM_MODAL,
      ui_version: import.meta.env.APP_VERSION,
    });
    handleCloseModal();
  };

  const handleDone = () => {
    track(EVENTS.TOOLS_FOR_SCHOOLS_DONE_CLICKED, {
      location: EVENT_LOCATION.TOOLS_FOR_SCHOOLS_DONATE_CONFIRM_MODAL,
      ui_version: import.meta.env.APP_VERSION,
    });
    handleCloseModal();
  };

  const handleDonate = () => {
    if (advantPoints === 0) {
      const scrollTopEle = document.getElementById('scrollToPosition');
      document.documentElement.scrollTop = scrollTopEle.offsetTop;
      showAdvantPointsTooltip();
    } else {
      track(EVENTS.TOOLS_FOR_SCHOOLS_DONATE_CLICKED, {
        id: school._id,
        name: school.name,
        points_selected: advantPoints,
        location: EVENT_LOCATION.TOOLS_FOR_SCHOOLS_DONATE_DASHBOARD,
        ui_version: import.meta.env.APP_VERSION,
      });

      checkUserSession()
        .then(() => {
          return transferMoney(advantPoints);
        })
        .catch((err) => console.error(err.message));
    }
  };

  const SchoolInfo = () => (
    <>
      <Col>🙏 This will go to</Col>
      <Col className="tfs-confirm-donate-school-name">{schoolName}</Col>
    </>
  );

  const ShareLinks = () => <></>;

  return (
    <>
      <Button
        // className='tfs-confirm-modal-donate-btn p-3'
        onClick={handleDonate}
        block
        disabled={
          moneyTransferStatus === ACTIONS.QUERY_STATUS.LOADING ||
          isUserInfoFetching
        }
      >
        {moneyTransferStatus === ACTIONS.QUERY_STATUS.LOADING ||
        isUserInfoFetching
          ? 'LOADING...'
          : 'DONATE'}
      </Button>

      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        centered={true}
        dialogClassName={`tfs-confirm-donate-modal rounded' ${
          isDesktop ? '' : 'tfs-mobile-dialog mx-auto'
        }`}
        contentClassName={`border-0 tfs-confirm-donate-content-modal ${
          isDesktop ? 'text-center' : ''
        }`}
      >
        <Modal.Header className="border-0 flex flex-column">
          <ModalCloseBtn handleClose={handleClose} />
          <Col className="p-0 tfs-confirm-donate-modal-title">
            👋 Thank you, {userName}!
          </Col>
          <Col className="p-0 my-1 tfs-confirm-donate-sub-info">
            You have completed donating your points.
          </Col>
          <Col className="p-0 my-1 tfs-confirm-donate-sub-info">
            Here are the details:
          </Col>
          <div
            className={`flex flex-row justify-content-between tfs-confirm-donate-badge rounded-2 my-3 ${
              isDesktop ? 'w-75 mx-auto' : 'w-100'
            }`}
          >
            <div>Points used:</div>
            <div>
              <Icon icon="bolt" width="15" height="21" />
              <span className="tfs-confirm-donate-points-text">
                {advantPoints}
              </span>
            </div>
          </div>
          <Col className="p-0 align-items-center flex justify-content-center tfs-confirm-donate-modal-my-donation">
            <span className="mr-3">My Donation:</span>
            {/* <Icon icon='pencil' width='24' height='24' color={styleConsts.cobalt} /> */}
            <span className="tfs-confirm-donate-donation ml-1">
              {' '}
              {currentAdvantPointsDonation}
            </span>
          </Col>
        </Modal.Header>
        <Modal.Body className="tfs-confirm-donate-modal-body">
          {isDesktop ? (
            <Col className="text-center tfs-confirm-donate-school-info flex flex-column py-2 rounded w-100">
              <SchoolInfo />
              <ShareLinks />
            </Col>
          ) : (
            <Row className="text-center tfs-confirm-donate-school-info flex flex-column py-2">
              <SchoolInfo />
              <ShareLinks />
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer className="bg-white justify-content-center">
          <Button
            width="w75"
            // className={`tfs-confirm-modal-done-btn rounded p-2 ${isDesktop ? 'w-75' : ''}`}
            onClick={handleDone}
            block={!isDesktop}
          >
            DONE
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmDonationModal;
