//@ts-nocheck
export const BalancePointIcon = ({
  className = 'fill-green-apple',
  width = '30px',
  height = '30px',
}) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 33 33"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle opacity="0.2" cx="16.5" cy="16.5" r="16.5" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.1715 13H18.3337V10C18.3337 9.09 17.7704 8.3 16.9669 8.07C16.81 8.02 16.653 8 16.496 8C15.8495 8 15.24 8.37 14.9076 9.01L11.2413 16.01C10.9181 16.63 10.9181 17.39 11.2505 18.01C11.583 18.63 12.1832 19.01 12.8389 19.01H14.6767V22.01C14.6767 22.92 15.24 23.71 16.0435 23.94C16.182 23.98 16.339 24 16.496 24C17.1424 24 17.7519 23.63 18.0844 22.99L21.7137 16.06C21.8892 15.75 22 15.39 22 14.99C22 13.9 21.1873 13 20.1715 13ZM16.496 22V17H12.8297L16.496 10V15H20.1622L16.496 22Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.1715 13H18.3337V10C18.3337 9.09 17.7704 8.3 16.9669 8.07C16.81 8.02 16.653 8 16.496 8C15.8495 8 15.24 8.37 14.9076 9.01L11.2413 16.01C10.9181 16.63 10.9181 17.39 11.2505 18.01C11.583 18.63 12.1832 19.01 12.8389 19.01H14.6767V22.01C14.6767 22.92 15.24 23.71 16.0435 23.94C16.182 23.98 16.339 24 16.496 24C17.1424 24 17.7519 23.63 18.0844 22.99L21.7137 16.06C21.8892 15.75 22 15.39 22 14.99C22 13.9 21.1873 13 20.1715 13ZM16.496 22V17H12.8297L16.496 10V15H20.1622L16.496 22Z"
    />
  </svg>
);
