const styleConst = {
  appleGreen: '#61BB46',
  baliHai: '#80929B',
  black: '#000',
  blue: '0000FF',
  blueWhale: '#0B324F',
  deepBlue: '#0e5ca8',
  brightOrange: '#F4BA23',
  ceruleanShade: '#F78E6B',
  cobalt: '#005BAA',
  darkTangerine: '#ffa70f',
  deepSapphire: '#0B3D6B',
  denimShade: '#082D4E',
  doveGrey: '#72716F',
  electricVioLet: '#9004FE',
  fireBrick: '#266cb2',
  gainsboro: '#E1E1E1',
  ghostWhite: '#F4F4FB',
  gray97: '#F7F7F7',
  grayDark: '#C0C0C0',
  green: '#67b548',
  greenBlue: '#0E6EC0',
  grey: '#828282',
  grey91: '#E8E8E8',
  hawkesBlue: '#D7DCEA',
  lightGrey1: '#CCCCCC',
  lightGrey: '#F9F9F9',
  lynch: '#697780',
  lynxWhite: '#F7F7F7',
  moonYellow: '#FFBF1B',
  panache: '#F6FBF4',
  pattensBlue: '#CDDFEC',
  persimmonShade: '#F9AA8F',
  schooner: '#898785',
  silver: '#C4C4C4',
  softPeach: '#D7EAD9',
  snow: '#FAFAFA',
  sulu: '#96ED7C',
  tomato: '#ff5757',
  tomatoShade1: '#ec2039',
  tangerineYellow: '#FFC700',
  veryLightGrey: '#FEFEFE',
  white: '#FFF',
  whiteSmoke: '#f5f5f5',
  whiteSmoke1: '#f4f4f4',

  'space-20': '1.25rem',
  styleValueToStyleName: (color = '#0000FF') => color,
};

export default styleConst;
