export const PAGES = {
  TOOLS_FOR_SCHOOLS_DETAILS_DASHBOARD: 'Tools For Schools Details Dashboard',
  TOOLS_FOR_SCHOOLS_CONNECT_SCHOOL_MODAL:
    'Tools For Schools Connect School Modal',
  TOOLS_FOR_SCHOOLS_CONFIRM_SCHOOL_MODAL:
    'Tools For Schools Confirm School Modal',
  TOOLS_FOR_SCHOOLS_SCHOOL_NOT_FOUND_MODAL:
    'Tools For Schools School Not Found Modal',
  TOOLS_FOR_SCHOOLS_SELECTED_SCHOOL_DASHBOARD:
    'Tools For Schools Selected School Dashboard',
  TOOLS_FOR_SCHOOLS_EARN_STARS_MODAL: 'Tools For Schools Earn Stars Modal',
  TOOLS_FOR_SCHOOLS_INVITE_FRIENDS_MODAL:
    'Tools For Schools Invite Friends Modal',
  TOOLS_FOR_SCHOOLS_DONATE_DASHBOARD: 'Tools For Schools Donate Dashboard',
  TOOLS_FOR_SCHOOLS_DONATE_CONFIRM_MODAL:
    'Tools For Schools Donate Confirm Modal',
  VIEWED_TOOLS_FOR_SCHOOLS_SIGNUP_PAGE: 'Viewed Tools for Schools Signup Page',
  VIEWED_TOOLS_FOR_SCHOOLS_SUCCESS_MODAL:
    'Viewed Tools for Schools Success Modal',
};

export const EVENTS = {
  TOOLS_FOR_SCHOOLS_BACK_CLICKED:
    'Redemption - Tools For Schools - Back Button Clicked',
  TOOLS_FOR_SCHOOLS_CLOSE_CLICKED: 'Tools For Schools Close Clicked',
  TOOLS_FOR_SCHOOLS_SELECT_YOUR_SCHOOL_CLICKED:
    'Tools For Schools Select Your School Clicked',
  TOOLS_FOR_SCHOOLS_SEARCH_SCHOOLS_CLICKED:
    'Tools For Schools Search Schools Clicked',
  TOOLS_FOR_SCHOOLS_VIEW_ALL_SCHOOLS_CLICKED:
    'Tools For Schools View All Schools Clicked',
  TOOLS_FOR_SCHOOLS_CONFIRM_SCHOOL_CLICKED:
    'Tools For Schools Confirm School Clicked',
  TOOLS_FOR_SCHOOLS_DONT_SEE_YOUR_SCHOOL_CLICKED:
    "Tools For Schools Don't See Your School Clicked",
  TOOLS_FOR_SCHOOLS_NOT_YOUR_SCHOOL_CLICKED:
    'Tools For Schools Not Your School Clicked',
  TOOLS_FOR_SCHOOLS_EARN_STARS_CLICKED: 'Tools For Schools Earn Stars Clicked',
  TOOLS_FOR_SCHOOLS_DONATE_POINTS_CLICKED:
    'Tools For Schools Donate Points Clicked',
  TOOLS_FOR_SCHOOLS_STARS_EARNED_ONLINE_INFO_CLICKED:
    'Tools For Schools Stars Earned Online Info Clicked',
  TOOLS_FOR_SCHOOLS_SHARE_ON_SOCIAL_CLICKED:
    'Tools For Schools Share On Social Clicked',
  TOOLS_FOR_SCHOOLS_INVITE_FRIENDS_CLICKED:
    'Tools For Schools Invite Friends Clicked',
  TOOLS_FOR_SCHOOLS_SHARE_ON_FACEBOOK_CLICKED:
    'Tools For Schools Share On Facebook Clicked',
  TOOLS_FOR_SCHOOLS_SHARED_ON_FACEBOOK: 'Tools For Schools Shared On Facebook',
  TOOLS_FOR_SCHOOLS_GO_BACK_CLICKED: 'Tools For Schools Go Back Clicked',
  TOOLS_FOR_SCHOOLS_COPY_LINK_CLICKED: 'Tools For Schools Copy Link Clicked',
  TOOLS_FOR_SCHOOLS_SHARE_LINK_CLICKED: 'Tools For Schools Share Link Clicked',
  TOOLS_FOR_SCHOOLS_DONATE_CLICKED: 'Tools For Schools Donate Clicked',
  TOOLS_FOR_SCHOOLS_DONE_CLICKED: 'Tools For Schools Done Clicked',
  TOOLS_FOR_SCHOOLS_MAIL_LINK_CLICKED: 'Tools For Schools Mail Link Clicked',
  T4S_SIGNUP_SELECT_SCHOOLS_CLICKED: 'T4S Signup - Select School Clicked',
  T4S_SUCCESS_CREATE_ACCOUNT_CLICKED: 'T4S Success - Create Account Clicked',
  T4S_SUCCESS_LINK_ANOTHER_CARD_CLICKED:
    'T4S Success - Link Another Card Clicked',
};

export const EVENT_LOCATION = {
  TOOLS_FOR_SCHOOLS_DETAILS_DASHBOARD:
    PAGES.TOOLS_FOR_SCHOOLS_DETAILS_DASHBOARD,
  TOOLS_FOR_SCHOOLS_CONNECT_SCHOOL_MODAL:
    PAGES.TOOLS_FOR_SCHOOLS_CONNECT_SCHOOL_MODAL,
  TOOLS_FOR_SCHOOLS_CONFIRM_SCHOOL_MODAL:
    PAGES.TOOLS_FOR_SCHOOLS_CONFIRM_SCHOOL_MODAL,
  TOOLS_FOR_SCHOOLS_SCHOOL_NOT_FOUND_MODAL:
    PAGES.TOOLS_FOR_SCHOOLS_SCHOOL_NOT_FOUND_MODAL,
  TOOLS_FOR_SCHOOLS_SELECTED_SCHOOL_DASHBOARD:
    PAGES.TOOLS_FOR_SCHOOLS_SELECTED_SCHOOL_DASHBOARD,
  TOOLS_FOR_SCHOOLS_DONATE_DASHBOARD: PAGES.TOOLS_FOR_SCHOOLS_DONATE_DASHBOARD,
  TOOLS_FOR_SCHOOLS_EARN_STARS_MODAL: PAGES.TOOLS_FOR_SCHOOLS_EARN_STARS_MODAL,
  TOOLS_FOR_SCHOOLS_INVITE_FRIENDS_MODAL:
    PAGES.TOOLS_FOR_SCHOOLS_INVITE_FRIENDS_MODAL,
  TOOLS_FOR_SCHOOLS_DONATE_CONFIRM_MODAL:
    PAGES.TOOLS_FOR_SCHOOLS_DONATE_CONFIRM_MODAL,
};
