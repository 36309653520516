export const config = {
  accountInfo: {
    clientId: 'loyalty_connect',
    ssoClientId: 'dd322bb6-8ced-4083-a80a-13a544ceb94c',
    knownAuthorities: ['pricechopperdevsso.b2clogin.com'],
    tenant: 'pricechopperdevsso',
    policies: {
      signUpPolicy: 'B2C_1A_SIGNUP',
      signInPolicy: 'B2C_1A_signup_signin',
    },
  },
};

export const BREAKPOINTS = {
  MOBILE: 768,
  TABLET: 992,
};
