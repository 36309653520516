import { useContext, useState, useEffect } from 'react';
import { ModuleLayout, BackNav, LoginButton, Modal, Button } from '@rewards/ui';
import { FEATURES_FLAGS } from '../util/constants';
import { useModule } from '../util/hooks/useModule';
import { AuthContext, ACTIONS } from '@rewards/contexts';
import DailySurpriseOfficialRules from '../components/DailySurpriseOfficialRules';
import { useUserInfo } from '@rewards/data-provider';
import { PrizePopup } from '../components/PrizePopup';
import { useCallback } from 'react';

import { useGameWin } from '../api/useGameWin';
import { useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';

export const DailySurpriseLanding = () => {
  const [open, onOpenChange] = useState(false);
  const history = useHistory();
  const { envInfo } = useContext(AuthContext.AuthContext);
  const { unAuthToken, tokenStatus, token } = AuthContext.useAuth();
  const { data: userInfo } = useUserInfo(!!token) || {};
  const queryClient = useQueryClient();
  const featureFlag = useModule(FEATURES_FLAGS.ENABLE_DAILY_SURPRISES);
  const {
    mutate: handleGameWin,
    data: transaction,
    error,
  } = useGameWin({
    onSettled: () => {
      onOpenChange(true);
      queryClient.invalidateQueries({
        queryKey: ['user'],
      });
    },
  });
  // console.log(
  //   '🚀 ~ DailySurpriseLanding ~ transaction, error:',
  //   transaction,
  //   error
  // );

  const [scratcherEvent, setEvent] = useState({
    prize: {
      name: '',
    },
    win: false,
  });

  const scratcherListener = useCallback(
    (e) => {
      if (
        e.origin === 'https://game.scratcher.io' &&
        e.data?.e === 'scratcher_fi'
      ) {
        console.log('🚀 ~ iFrameEventListener ~ e:', e.data?.p);
        setEvent(e.data?.p);
        // console.log(e.data.p.prize);
        // console.log(e.data);
        if (e.data?.p?.win) {
          handleGameWin(e.data?.p.prize);
        }
        // onOpenChange(e.data?.p?.win);
      }
    },
    [setEvent]
  );
  const campaignUrl = featureFlag?.otherDetails?.url;

  const dailySurprisePath = `${campaignUrl}${
    campaignUrl.includes('?') ? '&' : '?'
  }customer_id=${userInfo?.data?.extUserId}`;

  // Capture Events

  useEffect(() => {
    window.addEventListener('message', scratcherListener);

    return () => {
      window.removeEventListener('message', scratcherListener);
    };
  }, []);
  // Add some logic to make the transaction
  //

  return (
    <BackNav module="Daily SurPRIZE &trade;">
      <ModuleLayout>
        {/* <Modal.Root type="cta" open={isModalOpen} onOpenChange={setIsModalOpen}> */}
        {unAuthToken?.status === ACTIONS.STATUS.RESOLVED && (
          <>
            {/* <Col className='daily-title mt-4'>Daily SurPRIZE!</Col>
                        <Col className='lc-gc-un-auth-sub-title mt-2'>Spin for a chance to win up to 25,000 AdvantEdge Rewards points per day! </Col> */}
            <div className="mt-3 mb-3  daily-surprise-text-green flex justify-center items-center">
              <img
                className="welcome-daily-surprise-img"
                src={`https://cdn.spinwheel.io/images/daily-surprises/welcome_daily_surprise-01-${envInfo.NODE_ENV}.png`}
                alt="welcome"
              />
            </div>
          </>
        )}

        {tokenStatus === ACTIONS.STATUS.RESOLVED && (
          <div className=" flex flex-column items-center w-full">
            {/* {isLoading && isLoadingTransactions && isLoadingTransactions && (
              <div className="text-center spinner">
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            )} */}

            {dailySurprisePath && userInfo?.data?.extUserId && (
              <iframe
                src={dailySurprisePath}
                title=""
                className="p-0 w-full h-[80dvh] lg:h-[900px] border-0"
                // ref={iframeRef}
                // style={{ height: iframeHeight }}
              ></iframe>
            )}

            <div className="flex flex-col items-center text-center pb-2 mt-2">
              {/* <Modal.Trigger asChild>
                <Button intent="primary">Claim your Prize!</Button>
              </Modal.Trigger> */}
              <div className="mb-4 text-cobalt">
                {featureFlag?.otherDetails?.showEndDate && (
                  <>
                    <br />
                    <strong>{featureFlag?.otherDetails?.endText}</strong>
                    <br />
                  </>
                )}
              </div>
              <DailySurpriseOfficialRules />
            </div>
          </div>
        )}

        {unAuthToken?.status === ACTIONS.STATUS.RESOLVED && (
          <>
            {/* {!isDesktop && <div className="py-5"></div>} */}
            <div
              className={`flex flex-col items-center lg:flex-row lg:!p-0 lg:!mt-5 lg:mx-auto fixed lg:relative bottom-0 left-0 right-0 bg-white !p-3  `}
            >
              <LoginButton>Log In / Register to Play</LoginButton>
            </div>
            <div className="flex  flex-col items-center text-center official-rule pb-2 mt-2">
              <div className="mb-4 text-cobalt">
                {featureFlag?.otherDetails?.showEndDate && (
                  <>
                    <br />
                    <strong className="text-cobalt">
                      {featureFlag?.otherDetails?.endText}
                    </strong>
                    <br />
                  </>
                )}
              </div>
              <DailySurpriseOfficialRules />
            </div>
          </>
        )}
        <PrizePopup
          open={open}
          onOpenChange={onOpenChange}
          txnStatus={transaction?.data.txnStatus}
          prize={scratcherEvent.prize || { name: '' }}
        />
      </ModuleLayout>
    </BackNav>
  );
};
