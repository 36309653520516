import PropTypes from 'prop-types';
import React from 'react';

import styleConsts from '../util/styleConsts';

export default class Icon extends React.Component {
  render() {
    const { width, height, stroke, className, ...rest } = this.props;
    let classNameString = `${className} svg-${this.props.icon}`;
    const colorStyleName = styleConsts.styleValueToStyleName(this.props.color);
    if (colorStyleName) {
      classNameString += ` ${this.props.colorClassName}-${colorStyleName}`;
    }
    const colorAuxStyleName = styleConsts.styleValueToStyleName(
      this.props.colorAux
    );
    if (colorAuxStyleName) {
      classNameString += ` ${this.props.colorAuxClassName}-${colorAuxStyleName}`;
    }
    const colorBorderStyleName = styleConsts.styleValueToStyleName(
      this.props.colorBorder
    );
    if (colorBorderStyleName) {
      classNameString += ` ${this.props.colorBorderClassName}-${colorBorderStyleName}`;
    }
    if (this.props.iconType) {
      classNameString += ` svg-icon-type-${this.props.iconType}`;
    }

    switch (this.props.icon) {
      case 'angle-left': {
        return (
          <svg
            className={classNameString}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 9 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.365 8.28255L8.555 1.89435C8.935 1.49636 8.935 0.853459 8.555 0.455473C8.175 0.0574857 7.545 0.0574857 7.165 0.455473L0.285 7.55801C-0.095 7.95599 -0.095 8.5989 0.285 8.99688L7.165 16.0994C7.355 16.2933 7.605 16.3954 7.855 16.3954C8.105 16.3954 8.355 16.2933 8.545 16.0994C8.925 15.7014 8.925 15.0585 8.545 14.6605L2.365 8.28255Z"
              fill={this.props.color}
            />
          </svg>
        );
      }
      case 'fuel': {
        return (
          <svg
            height="45"
            width="45"
            viewBox="0 0 45 45"
            fill={styleConsts.fireBrick}
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="22.5" cy="22.5" r="22.5" fill={styleConsts.fireBrick} />
            <svg
              width="25"
              height="25"
              viewBox="0 0 32 32"
              x="10"
              y="10"
              fill="none"
            >
              <path
                d="M23.0395 30.6176H6.57228C6.47004 30.6176 6.36882 30.5974 6.27444 30.5581C6.18007 30.5188 6.09441 30.4611 6.02242 30.3886C5.95043 30.316 5.89352 30.2298 5.85498 30.1351C5.81644 30.0404 5.79703 29.9391 5.79788 29.8368V3.35363C5.79788 2.73349 6.04378 2.13866 6.48169 1.69956C6.91959 1.26046 7.51374 1.01292 8.13388 1.01123H21.4587C22.0788 1.01292 22.673 1.26046 23.1109 1.69956C23.5488 2.13866 23.7947 2.73349 23.7947 3.35363V29.8368C23.7964 30.04 23.7182 30.2358 23.5769 30.3818C23.4357 30.5279 23.2426 30.6126 23.0395 30.6176V30.6176ZM7.35308 29.0624H22.2395V3.35363C22.2412 3.25002 22.2222 3.14711 22.1838 3.0509C22.1453 2.95469 22.088 2.86709 22.0154 2.79323C21.9427 2.71936 21.856 2.6607 21.7605 2.62067C21.6649 2.58063 21.5623 2.56002 21.4587 2.56003H8.13388C7.9268 2.56003 7.7282 2.64229 7.58177 2.78872C7.43534 2.93515 7.35308 3.13375 7.35308 3.34083V29.0624Z"
                fill={styleConsts.white}
              />
              <path
                d="M19.7245 12.2432H9.86847C9.71466 12.2432 9.56236 12.2129 9.42027 12.154C9.27817 12.0952 9.14906 12.0089 9.0403 11.9002C8.93155 11.7914 8.84528 11.6623 8.78642 11.5202C8.72756 11.3781 8.69727 11.2258 8.69727 11.072V5.0816C8.69727 4.77098 8.82066 4.47308 9.0403 4.25344C9.25994 4.03379 9.55784 3.9104 9.86847 3.9104H19.7245C20.0351 3.9104 20.333 4.03379 20.5526 4.25344C20.7723 4.47308 20.8957 4.77098 20.8957 5.0816V11.072C20.8957 11.3826 20.7723 11.6805 20.5526 11.9002C20.333 12.1198 20.0351 12.2432 19.7245 12.2432V12.2432ZM10.2397 10.6816H19.3341V5.472H10.2397V10.6816Z"
                fill={styleConsts.white}
              />
              <path
                d="M23.9871 30.6177H5.6063C5.49826 30.6275 5.38935 30.6147 5.28653 30.5801C5.18372 30.5455 5.08924 30.4898 5.00913 30.4167C4.92902 30.3435 4.86504 30.2544 4.82127 30.1552C4.77749 30.0559 4.75488 29.9486 4.75488 29.8401C4.75488 29.7316 4.77749 29.6243 4.82127 29.5251C4.86504 29.4258 4.92902 29.3368 5.00913 29.2636C5.08924 29.1904 5.18372 29.1348 5.28653 29.1001C5.38935 29.0655 5.49826 29.0527 5.6063 29.0625H23.9871C24.0951 29.0527 24.204 29.0655 24.3069 29.1001C24.4097 29.1348 24.5042 29.1904 24.5843 29.2636C24.6644 29.3368 24.7284 29.4258 24.7721 29.5251C24.8159 29.6243 24.8385 29.7316 24.8385 29.8401C24.8385 29.9486 24.8159 30.0559 24.7721 30.1552C24.7284 30.2544 24.6644 30.3435 24.5843 30.4167C24.5042 30.4898 24.4097 30.5455 24.3069 30.5801C24.204 30.6147 24.0951 30.6275 23.9871 30.6177Z"
                fill={styleConsts.white}
              />
              <path
                d="M26.4066 25.7857C25.8142 25.7857 25.2461 25.5504 24.8272 25.1315C24.4084 24.7126 24.173 24.1445 24.173 23.5521V18.2337C24.173 17.9282 24.0517 17.6352 23.8356 17.4191C23.6196 17.2031 23.3266 17.0817 23.021 17.0817C22.814 17.0817 22.6154 16.9994 22.4689 16.853C22.3225 16.7066 22.2402 16.508 22.2402 16.3009C22.2402 16.0938 22.3225 15.8952 22.4689 15.7488C22.6154 15.6024 22.814 15.5201 23.021 15.5201C23.7402 15.5218 24.4294 15.8082 24.938 16.3168C25.4465 16.8253 25.7329 17.5145 25.7346 18.2337V23.5521C25.7346 23.7295 25.8051 23.8996 25.9305 24.025C26.0559 24.1504 26.2261 24.2209 26.4034 24.2209C26.5808 24.2209 26.7509 24.1504 26.8763 24.025C27.0018 23.8996 27.0722 23.7295 27.0722 23.5521V13.3953C27.0722 13.1882 27.1545 12.9896 27.3009 12.8432C27.4474 12.6968 27.646 12.6145 27.853 12.6145C28.0601 12.6145 28.2587 12.6968 28.4051 12.8432C28.5516 12.9896 28.6338 13.1882 28.6338 13.3953V23.5521C28.6338 24.1434 28.3994 24.7105 27.9819 25.1292C27.5644 25.5479 26.9979 25.784 26.4066 25.7857V25.7857Z"
                fill={styleConsts.white}
              />
              <path
                d="M27.8535 14.176C27.7365 14.1706 27.6225 14.1378 27.5207 14.08L25.6007 13.1136C25.4716 13.0484 25.3632 12.9486 25.2875 12.8255C25.2118 12.7023 25.1718 12.5605 25.1719 12.416V9.52956C25.176 9.40051 25.2121 9.27449 25.2769 9.1628C25.3416 9.05111 25.4331 8.95723 25.5431 8.88956C25.6585 8.8251 25.7885 8.79126 25.9207 8.79126C26.0529 8.79126 26.1829 8.8251 26.2983 8.88956L28.2183 9.85596C28.3469 9.92171 28.4549 10.0216 28.5305 10.1446C28.6061 10.2677 28.6465 10.4091 28.6471 10.5536V13.4528C28.6435 13.5819 28.6077 13.7082 28.5428 13.82C28.478 13.9317 28.3862 14.0255 28.2759 14.0928C28.146 14.1605 27.9993 14.1894 27.8535 14.176V14.176ZM26.7015 11.9488L27.0727 12.1344V10.976L26.7015 10.7904V11.9488Z"
                fill={styleConsts.white}
              />
              <path
                d="M25.9199 10.3104C25.7128 10.3104 25.5142 10.2281 25.3678 10.0817C25.2214 9.93524 25.1391 9.73664 25.1391 9.52956V8.95996C25.14 8.62949 25.0754 8.30211 24.9491 7.99674C24.8228 7.69136 24.6372 7.41403 24.4031 7.18076C24.261 7.03381 24.1822 6.83699 24.1836 6.63257C24.185 6.42815 24.2666 6.23244 24.4107 6.08747C24.5548 5.9425 24.7501 5.85984 24.9545 5.85724C25.1589 5.85464 25.3561 5.93231 25.5039 6.07356C25.8845 6.45149 26.1863 6.90124 26.3918 7.39673C26.5972 7.89222 26.7022 8.42357 26.7007 8.95996V9.52956C26.7007 9.73664 26.6184 9.93524 26.472 10.0817C26.3256 10.2281 26.127 10.3104 25.9199 10.3104V10.3104Z"
                fill={styleConsts.white}
              />
            </svg>
          </svg>
        );
      }
      default: {
        return (
          <svg
            className="svg-default"
            xmlns="http://www.w3.org/2000/svg"
            width="200"
            height="100"
          >
            <text fontSize="25">
              <tspan x="0" dy="1.2em">
                ICON
              </tspan>
              <tspan x="0" dy="1.2em">
                FAILURE
              </tspan>
            </text>
          </svg>
        );
      }
    }
  }
}

Icon.defaultProps = {
  children: null,
  classificationHeight: '30px',
  classificationId: 'default',
  classificationWidth: '30px',
  color: styleConsts.white,
  colorClassName: 'svg-color',
  colorAux: styleConsts.grayDark,
  colorAuxClassName: 'svg-colorAux',
  colorBorder: styleConsts.blue,
  colorBorderClassName: 'svg-colorBorder',
  height: '30px',
  iconType: '',
  includeSolutionBorder: undefined,
  standardSolutionName: '',
  style: null,
  time: '',
  width: '30px',
  x: '0',
  y: '0',
  className: 'inline',
};
Icon.propTypes = {
  children: PropTypes.element,
  classificationHeight: PropTypes.string,
  classificationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  classificationWidth: PropTypes.string,
  color: PropTypes.string,
  colorClassName: PropTypes.string,
  colorAux: PropTypes.string,
  colorAuxClassName: PropTypes.string,
  colorBorder: PropTypes.string,
  colorBorderClassName: PropTypes.string,
  height: PropTypes.string,
  icon: PropTypes.string.isRequired,
  iconType: PropTypes.string,
  includeSolutionBorder: PropTypes.bool,
  standardSolutionName: PropTypes.string,
  style: PropTypes.object,
  time: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  width: PropTypes.string,
  x: PropTypes.string,
  y: PropTypes.string,
  className: PropTypes.string,
};
