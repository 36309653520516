import { POST_MESSAGE_EVENT } from './constants';

export const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const sendPostMessageToParent = (
  postMessageToParent,
  triggerType = 'error',
  eventDetails = {},
  metaData = {}
) => {
  postMessageToParent &&
    postMessageToParent(triggerType, {
      eventName:
        eventDetails.EVENT_NAME || POST_MESSAGE_EVENT.DIM_ERROR.EVENT_NAME,
      message:
        eventDetails.EVENT_MESSAGE ||
        POST_MESSAGE_EVENT.DIM_ERROR.EVENT_MESSAGE,
      ...(metaData || {}),
    });
};
