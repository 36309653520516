// import { CancelToken } from 'axios'
import axiosInstance from '../utils/axiosInstance';

const useDIM = (requestParams = null) => {
  // const source = CancelToken.source()

  const promise = axiosInstance
    .post('/api/dim', requestParams, {
      // 'cancelToken': source.token,
    })
    .then((response) => ({ data: response?.data }));

  return promise;
};

export default useDIM;
