import React, { useEffect } from 'react';
import PartnerContext from './PartnerContext';

import { STATUS } from '../utility/actionTypes';
import { useAsync } from '../utility';
import { useAuth } from '../AuthContext';
import { fetchPartnerData } from '../../services/ComponentRegistryService';
import { PARTNER_TYPES } from '../../util/constants';

const PartnerContextState = ({ children, ...props }) => {
  const { checkValidToken, unAuthToken, tokenStatus } = useAuth();

  const asyncPartnerData = useAsync({
    status: STATUS.IDLE,
    data: { partner: null },
  });

  const { run: runasyncPartnerData, status: partnerStatus } = asyncPartnerData;

  useEffect(() => {
    if (
      (tokenStatus === STATUS.RESOLVED ||
        unAuthToken?.status === STATUS.RESOLVED) &&
      partnerStatus === STATUS.IDLE
    ) {
      checkValidToken().then(() => {
        runasyncPartnerData(
          fetchPartnerData().then((data) => {
            const partnerData = data?.accountTypes?.find(
              ({ name }) => name === PARTNER_TYPES.LOYALTY
            );
            return {
              partner: {
                ...partnerData,
                freeEntriesLimitPerDay:
                  data?.configuration?.sweepstakeConfig?.freeEntriesLimitPerDay,
                configuration: data?.configuration,
              },
            };
          })
        );
      });
    }
  }, [tokenStatus, unAuthToken?.status]);

  return (
    <PartnerContext.Provider
      value={{ ...asyncPartnerData, partnerStatus }}
      {...props}
    >
      {children}
    </PartnerContext.Provider>
  );
};

export default PartnerContextState;
