import { AuthContext } from '@rewards/contexts';

export const NotificationBanner = () => {
  const { featureFlags } = AuthContext.useAuth();
  //@ts-ignore
  const flag = featureFlags.find((ff) => ff.name === 'PC_NOTIFICATION_BANNER');

  if (flag && flag.otherDetails?.text) {
    return (
      <div className="w-full bg-green-apple text-white text-center !p-5 ">
        {flag.otherDetails?.text}
      </div>
    );
  }
  return null;
};
