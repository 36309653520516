import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAsync, ACTIONS, AppContext, AuthContext } from '@rewards/contexts';
import { useUserInfo } from '@rewards/data-provider';

import LoyaltyConnectContext from './LoyaltyConnectContext';
import useResponsive from '../../util/hooks/useResponsive';
import useLocalStorage from '../../util/useLocalStorage';
import { getClaimBonusPagePath } from '../../util/paths';
import {
  OFFER_CAMPAIGNS_TYPES,
  OFFER_CAMPAIGNS_CONFIG_TYPES,
} from '../../util/constants';
import { fetchSweepstakesCampaigns } from '../../services/componentRegistryService';

const { useAuth } = AuthContext;
const LoyaltyConnectContextState = (
  {
    fetchMobileSideNavSecure,
    fetchMobileSideNav,
    fetchRegistry,
    fetchOfferCampaigns,
    fetchOfferCampaignsIsUserValid,
    fetchOfferCampaignsIssueRewards,
    fetchUserInfoViaCardNumber,
    fetchUserInfoViaEmail,
    setUserDetails,
    children,
  },
  ...props
) => {
  const history = useHistory();
  const { isDesktop } = useResponsive();
  const [isIdentifyLoaded, setIsIdentifyLoaded] = useLocalStorage(
    'isIdentifyLoaded',
    false
  );
  const { isUnAuthorizsedFlow, envInfo } = useContext(AuthContext.AuthContext);
  const { identifyUserForAnalytics } = useContext(AppContext.AppContext);

  const [balancePointsOffer, setBalancePointsOffer] = useState();
  const [isRedeemBalancePage, setIsRedeemBalancePage] = useState(false);

  const {
    token,
    code,
    loanServicerId,
    isToolsForSchoolsInviteFriends,
    checkValidToken,
    featureFlags,
  } = useAuth();
  const { data: userInfo } = useUserInfo() || {};
  const user = userInfo?.data;

  const asyncTopNavData = useAsync({
    status: ACTIONS.STATUS.IDLE,
    data: { sidenav: null },
  });
  const asyncTopNavDataSecure = useAsync({
    status: ACTIONS.STATUS.IDLE,
    data: { sidenavSecure: null },
  });
  const asyncRegistryData = useAsync({
    status: ACTIONS.STATUS.IDLE,
    data: { registry: null },
  });
  const asyncOfferCampaigns = useAsync({
    status: ACTIONS.STATUS.IDLE,
    data: { offerCampaigns: null },
  });
  const asyncOfferCampaignsIsUserValid = useAsync({
    status: ACTIONS.STATUS.IDLE,
    data: { offerCampaignsIsUserValid: null },
  });
  const asyncOfferCampaignsIssueRewards = useAsync({
    status: ACTIONS.STATUS.IDLE,
    data: { offerCampaignsIssueRewards: null },
  });
  const asyncUserInfoCardNumber = useAsync({
    status: ACTIONS.STATUS.IDLE,
    data: { userInfoCardNumber: null },
  });
  const asyncUserInfoByEmail = useAsync({
    status: ACTIONS.STATUS.IDLE,
    data: { userInfoByEmail: null },
  });

  const asyncSweepstakesCampaings = useAsync({
    status: ACTIONS.STATUS.IDLE,
    data: { campaign: null },
  });

  const { data: sidenavData, run, status } = asyncTopNavData;
  const {
    data: sideNavSecureData,
    run: runAsyncSideNavSecure,
    status: sideNavSecureStatus,
  } = asyncTopNavDataSecure;
  const { run: runAsyncRegistryData, status: registryStatus } =
    asyncRegistryData;
  const { data: offerCampaignsData, run: runAsyncOfferCampaigns } =
    asyncOfferCampaigns;
  const {
    data: offerCampaignsIsUserValidData,
    run: runAsyncOfferCampaignsIsUserValid,
    status: offerCampaignsIsUserValidStatus,
  } = asyncOfferCampaignsIsUserValid;
  const {
    run: runAsyncOfferCampaignsIssueRewards,
    status: offerCampaignsIssueRewardsStatus,
  } = asyncOfferCampaignsIssueRewards;
  const {
    data: userInfoCardNumberData,
    run: runAsyncUserInfoCardNumber,
    status: userInfoCardNumberStatus,
  } = asyncUserInfoCardNumber;
  const {
    data: userInfoByEmailData,
    run: runAsyncUserInfoByEmail,
    status: userInfoByEmailStatus,
  } = asyncUserInfoByEmail;

  // const {
  //   data: sweepstakeCampaignsData,
  //   run: runAsyncSweepstakeCampaings,
  //   status: sweepstakeCampaignsStatus
  // } = asyncSweepstakesCampaings

  const updateIsRedeemBalancePage = () =>
    setIsRedeemBalancePage(!isRedeemBalancePage);

  const getOfferCampaignsIsUserValid = ({
    balancePointsOffer: balancePointsOffer,
  }) => {
    checkValidToken().then(() => {
      runAsyncOfferCampaignsIsUserValid(
        fetchOfferCampaignsIsUserValid({
          userId: user._id,
          accountId: user.partnerAccounts[0].accountId,
          offerCampaignId: balancePointsOffer._id,
        }).then((data) => ({ offerCampaignsIsUserValid: data }))
      );
    });
  };

  const getUserInfoByCardNumber = ({ cardNumber }) => {
    runAsyncUserInfoCardNumber(
      fetchUserInfoViaCardNumber({ cardNumber }).then((data) => ({
        userInfoCardNumber: data,
      }))
    );
  };

  const getUserInfoByEmail = ({ email }) => {
    runAsyncUserInfoByEmail(
      fetchUserInfoViaEmail({ email }).then((data) => ({
        userInfoByEmail: data,
      }))
    );
  };

  // const getCampaings = () => {
  //   runAsyncSweepstakeCampaings(fetchSweepstakesCampaigns()
  //   .then(data => {
  //     console.log(data)
  //     return ({campaign: data})
  //   }))
  // }

  useEffect(() => {
    setIsIdentifyLoaded(false);
    if (status === ACTIONS.STATUS.IDLE) {
      run(
        fetchMobileSideNav().then((data) => ({
          sidenav: data,
        }))
      );
    }
    if (sideNavSecureStatus === ACTIONS.STATUS.IDLE) {
      runAsyncSideNavSecure(
        fetchMobileSideNavSecure().then((data) => ({
          sidenavSecure: data,
        }))
      );
    }
  }, []);

  useEffect(() => {
    if ((code || loanServicerId) && !isToolsForSchoolsInviteFriends) {
      runAsyncRegistryData(
        fetchRegistry({ isCodeLoaded: true, featureFlags, envInfo }).then(
          (data) => ({ registry: data })
        )
      );
    }
  }, [code, loanServicerId, isToolsForSchoolsInviteFriends]);

  useEffect(() => {
    if (registryStatus === ACTIONS.STATUS.IDLE && !isUnAuthorizsedFlow) {
      runAsyncRegistryData(
        fetchRegistry({ featureFlags, envInfo }).then((data) => {
          return { registry: data };
        })
      );
    }
  }, []);
  // useEffect(()=> {
  //   if(registryStatus === ACTIONS.STATUS.)
  // },[registryStatus])

  useEffect(() => {
    if (token && user && !isIdentifyLoaded) {
      setIsIdentifyLoaded(true);
      identifyUserForAnalytics(
        {
          email: user?.partnerAccounts?.[0]?.profile.email,
          name: user?.partnerAccounts?.[0]?.profile.name,
          account_balance: user?.partnerAccounts?.[0]?.accountBalance,
        },
        user?._id
      );
    }
  }, [token, user]);

  useEffect(() => {
    if (user) {
      setUserDetails(user);
    }
  }, [user]);

  useEffect(() => {
    if (token) {
      checkValidToken().then(() => {
        runAsyncOfferCampaigns(
          fetchOfferCampaigns().then((data) => ({ offerCampaigns: data }))
        );
      });
    }
  }, [token]);

  useEffect(() => {
    if (
      offerCampaignsIsUserValidStatus === ACTIONS.STATUS.IDLE &&
      offerCampaignsData?.offerCampaigns &&
      user
    ) {
      const filteredBalancePointsOffer =
        offerCampaignsData?.offerCampaigns.filter(
          ({ isActive, type, configuration: { type: configType } }) =>
            isActive &&
            type === OFFER_CAMPAIGNS_TYPES.BALANCE &&
            configType === OFFER_CAMPAIGNS_CONFIG_TYPES.POINTS
        );

      if (filteredBalancePointsOffer?.length) {
        setBalancePointsOffer(filteredBalancePointsOffer?.[0]);
        const { startTime, endTime } = filteredBalancePointsOffer?.[0];
        if (startTime <= new Date().getTime() <= endTime) {
          getOfferCampaignsIsUserValid({
            balancePointsOffer: filteredBalancePointsOffer?.[0],
          });
        }
      }
    }
  }, [offerCampaignsData?.offerCampaigns, user]);

  const getOfferCampaignsIssueRewards = () => {
    return checkValidToken()
      .then(() => {
        return runAsyncOfferCampaignsIssueRewards(
          fetchOfferCampaignsIssueRewards({
            userId: user._id,
            accountId: user.partnerAccounts[0].accountId,
            offerCampaignId: balancePointsOffer._id,
          }).then((data) => ({ offerCampaignsIssueRewards: data }))
        );
      })
      .then(() => {
        getOfferCampaignsIsUserValid({ balancePointsOffer });
        return;
      })
      .then(() => {
        setIsRedeemBalancePage(!isRedeemBalancePage);
        history.push(getClaimBonusPagePath());
      });
  };

  return (
    <LoyaltyConnectContext.Provider
      value={{
        user,
        isDesktop,
        sidenavData,
        sideNavSecureData,
        offerCampaignsIsUserValidData,
        offerCampaignsIssueRewardsStatus,
        isRedeemBalancePage,
        userInfoByCardNumber: {
          data: userInfoCardNumberData?.userInfoCardNumber,
          status: userInfoCardNumberStatus,
        },
        userInfoByEmail: {
          data: userInfoByEmailData?.userInfoByEmail,
          status: userInfoByEmailStatus,
        },
        updateIsRedeemBalancePage,
        ...asyncRegistryData,
        getOfferCampaignsIssueRewards,
        getUserInfoByCardNumber,
        getUserInfoByEmail,
      }}
      {...props}
    >
      {children}
    </LoyaltyConnectContext.Provider>
  );
};
export default LoyaltyConnectContextState;
