const styleConst = {
  white: '#FFF',
  grayDark: '#C0C0C0',
  blue: '0000FF',
  cobalt: '#005BAA',
  appleGreen: '#61BB46',
  fireBrick: '#266cb2',
  schooner: '#ffbf1b',
  lightGrey: '#F9F9F9',
  lightGrey2: '#CECECE',
  gray77: '#C4C4C4',
  gray97: '#F7F7F7',
  california: '#E6893B',

  styleValueToStyleName: (color = '#0000FF') => color,
};

export default styleConst;
