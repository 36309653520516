import { useQuery } from '@tanstack/react-query';
import getPartner from '../services/usePartner';

export default function usePartner(enabled = false) {
  return useQuery({
    queryKey: ['partner'],
    queryFn: getPartner,
    staleTime: 1000,
    enabled,
    retry: false,
  });
}
