import { useQuery } from '@tanstack/react-query';

import getCampaigns from '../services/useCampaigns';

export default function useCampaigns(enabled = false) {
  return useQuery({
    queryKey: ['campaigns'],
    queryFn: getCampaigns,
    staleTime: 1000,
    enabled,
    retry: false,
  });
}
