export const TOKEN_KEY = 'accessToken';
export const UN_AUTH_TOKEN_KEY = 'unAuthAccessToken';

export const TOKEN_TYPE = {
  NON_SIGNIN: 'NON_SIGNIN',
};

export const PARTNER_TYPES = {
  LOYALTY: 'LOYALTY',
  BANK: 'BANK',
};

export const LIABILITIES = {
  STUDENT_LOAN: 'STUDENT_LOAN',
  CREDIT_CARD: 'CREDIT_CARD',
};

export const ERROR_CODES = {
  400: 400,
  500: 500,
};
