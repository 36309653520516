import React, { useState, useEffect } from 'react';

import { useSchool, useToolsForSchools } from '../../util/hooks';
import { ACTIONS, AuthContext } from '@rewards/contexts';

import FindSchoolModal from './FindSchoolModal';
import ConfirmSchoolModal from './ConfirmSchoolModal';
import DontSeeSchoolModal from './DontSeeSchoolModal';

const { useAuth } = AuthContext;
const SelectSchoolModals = ({
  disabled,
  isLoading = false,
  handleSelectSchool,
  showSignupFlowModal,
  setShowSignupFlowModal,
  handleClearSignUpInputs,
  showSignUpFlowFindSchoolModal,
  handleResetSignUpFlow,
}) => {
  const { tokenStatus } = useAuth();
  const { schools } = useToolsForSchools();
  const { connectSchoolStatus } = useSchool();

  const [schoolName, setSchoolName] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [showFindSchoolModal, setShowFindSchoolModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showDontSeeSchoolModal, setShowDontSeeSchoolModal] = useState(false);
  const [schoolsList, setSchoolsList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [zipCodeList, setZipCodeList] = useState([]);
  const [filteredSchools, setFilteredSchools] = useState([]);
  const [schoolSearchId, setSchoolSearchId] = useState('');
  const [relativeFilteredSchoolList, setRelativeFilteredSchoolList] = useState(
    []
  );
  const [relativeFilteredZipCodeList, setRelativeFilteredZipCodeList] =
    useState([]);

  useEffect(() => {
    const schoolItems = schools
      ? schools.map((school) => ({
          id: school._id,
          name: school.name,
          city: school.address.city,
          state: school.address.state,
          zipCode: school.address.zipCode,
        }))
      : [];
    const zipCodeItems = schools
      ? schools.map((school) => ({
          id: school._id,
          name: school.address.zipCode,
          schoolName: school.name,
        }))
      : [];
    const schoolZipCodeList = schools
      ? schools.map((school) => ({
          id: school._id,
          zipCode: school.address.zipCode,
          name: school.name,
          city: school.address.city,
          state: school.address.state,
        }))
      : [];
    setSchoolList(schoolItems);
    setZipCodeList(zipCodeItems);
    setSchoolsList(schoolZipCodeList);
  }, [schools]);

  useEffect(() => {
    setRelativeFilteredSchoolList(schoolList);
    setRelativeFilteredZipCodeList(zipCodeList);
  }, [schoolList, zipCodeList]);

  const handleSchoolSearchId = (id) => {
    setSchoolSearchId(id);
  };
  const handleSchoolName = (e) => setSchoolName(e.target.value);
  const handleZipCode = (e) => setZipCode(e.target.value);

  const ChangeSchoolName = (item) => {
    setSchoolName(item ? item.name : '');
  };
  const ChangeZipCode = (item) => {
    setZipCode(item ? item.name : '');
  };
  useEffect(() => {
    if (
      showSignUpFlowFindSchoolModal &&
      tokenStatus === ACTIONS.STATUS.RESOLVED
    ) {
      setShowFindSchoolModal(true);
    }
  }, [showSignUpFlowFindSchoolModal, tokenStatus]);

  const resetSchoolDetails = () => {
    setSchoolName('');
    setZipCode('');
  };

  const handleFindSchoolModal = () => {
    if (handleSelectSchool && showSignupFlowModal) {
      new Promise((resolve) => {
        return handleSelectSchool().then((response) => {
          resolve(setShowSignupFlowModal(false));
        });
      });
    } else {
      setShowFindSchoolModal(true);
    }
  };

  const handleCloseFindSchoolModal = (shouldReset = false) => {
    handleResetSignUpFlow?.(shouldReset);
    setShowFindSchoolModal(false);
    if (shouldReset) {
      resetSchoolDetails();
    }
  };
  const handleConfirmModal = () => setShowConfirmModal(!showConfirmModal);

  const handleConfirmBackClick = () => {
    handleConfirmModal();
    handleFindSchoolModal();
  };

  const handleCloseConfirmModal = (shouldReset = false) => {
    if (connectSchoolStatus === ACTIONS.STATUS.PENDING) {
      return;
    }
    handleConfirmModal();
    if (shouldReset) {
      resetSchoolDetails();
    }
  };

  const handleDontSeeSchoolModal = () =>
    setShowDontSeeSchoolModal(!showDontSeeSchoolModal);
  const handleCloseDontSeeSchoolModal = () => {
    handleDontSeeSchoolModal();
    resetSchoolDetails();
  };
  const handleDontSeeSchoolBackClick = ({ shouldReset = false }) => {
    handleDontSeeSchoolModal();
    handleConfirmModal();
    shouldReset && resetSchoolDetails();
  };

  return (
    <>
      <FindSchoolModal
        show={showFindSchoolModal}
        schoolName={schoolName}
        zipCode={zipCode}
        handleSchoolName={handleSchoolName}
        handleZipCode={handleZipCode}
        handleFindSchoolModal={handleFindSchoolModal}
        handleClose={handleCloseFindSchoolModal}
        handleConfirmModal={handleConfirmModal}
        handleDontSeeSchoolModal={handleDontSeeSchoolModal}
        ChangeSchoolName={ChangeSchoolName}
        ChangeZipCode={ChangeZipCode}
        schoolList={schoolList}
        zipCodeList={zipCodeList}
        schoolsList={schoolsList}
        relativeFilteredSchoolList={relativeFilteredSchoolList}
        relativeFilteredZipCodeList={relativeFilteredZipCodeList}
        setRelativeFilteredSchoolList={setRelativeFilteredSchoolList}
        setRelativeFilteredZipCodeList={setRelativeFilteredZipCodeList}
        //Confirm Modal props
        showConfirmModal={showConfirmModal}
        handleCloseConfirmModal={handleCloseConfirmModal}
        disabled={disabled}
        isLoading={isLoading}
        setFilteredSchools={setFilteredSchools}
        filteredSchools={filteredSchools}
        handleSchoolSearchId={handleSchoolSearchId}
      />
      <ConfirmSchoolModal
        show={showConfirmModal}
        handleClose={handleCloseConfirmModal}
        handleDontSeeSchoolModal={handleDontSeeSchoolModal}
        handleBackClick={handleConfirmBackClick}
        filteredSchools={filteredSchools}
        schoolSearchId={schoolSearchId}
        handleClearSignUpInputs={handleClearSignUpInputs}
      />
      <DontSeeSchoolModal
        show={showDontSeeSchoolModal}
        handleClose={handleCloseDontSeeSchoolModal}
        handleBackClick={handleDontSeeSchoolBackClick}
        handleResetSignUpFlow={handleResetSignUpFlow}
      />
    </>
  );
};

export default SelectSchoolModals;
