import React from 'react';
import { INVALID_TOKEN, LOG_MESSAGES } from '../util/constants';
import '../styles/with-error-boundary.scss';
import TokenExpired from './TokenExpired';

export default function ErrorFallback({ error, ...rest }) {
  let showTokenExpiredPage = error?.name == INVALID_TOKEN;
  showTokenExpiredPage =
    showTokenExpiredPage &&
    Boolean(Number(rest?.configDataProps?.showTokenExpiredPage || 1));

  return (
    <>
      {showTokenExpiredPage ? (
        <TokenExpired {...rest} />
      ) : (
        <div className="error-boundary-container">
          <h3>Uh oh. Something went wrong.</h3>
          <small>
            We&apos;ve reported this issue and we&apos;ll fix it soon.
          </small>
          <details>
            <div>{LOG_MESSAGES.APPLICATION_CRASHED}</div>
            {error && error.toString()}
          </details>
        </div>
      )}
    </>
  );
}
