export const COMMUNITY_SOLAR_MODULE = 'community_solar';

export const config = {
  accountInfo: {
    clientId: 'loyalty_connect',
    ssoClientId: 'dd322bb6-8ced-4083-a80a-13a544ceb94c',
    knownAuthorities: ['pricechopperdevsso.b2clogin.com'],
    tenant: 'pricechopperdevsso',
    policies: {
      signUpPolicy: 'B2C_1A_SIGNUP',
      signInPolicy: 'B2C_1A_signup_signin',
    },
    greenMountainPath: 'https://www.greenmountainenergy.com/market32',
    greenMountainSecondPath: 'https://www.greenmountainenergy.com/market32',
    communitySolarNYPath:
      'https://www.arcadia.com/community-solar/lp-market32?utm_source=nrg&utm_medium=website&promo=gme-ny-m32-web',
    communitySolarMAPath:
      'https://greenmountainenergy.powermarket.io/overlook.html',
  },
};

export const BREAKPOINTS = {
  MOBILE: 768,
  TABLET: 992,
};

export const QUERY_STATUS = {
  LOADING: 'loading',
  ERROR: 'error',
  SUCCESS: 'success',
  IDLE: 'idle',
};

export const FEATURES_FLAGS = {
  ENABLE_COMING_SOON_UTILITY_OFFERS: 'ENABLE_COMING_SOON_UTILITY_OFFERS',
};

export const LOYALTY_CONNECT_MODULES_LABEL_BUTTONS = {
  COMMUNITY_SOLAR: {
    label: 'NY Residents',
    secondLabel: 'MA Residents',
  },
};
