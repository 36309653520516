import React, { useEffect, useState } from 'react';
import { Col, Nav, Media } from 'react-bootstrap';

import { useResponsive } from '../../util/hooks';
import { dateString, toTitleCase, sortOnKey } from '../../util/formatters';
import {
  WEEKLY_PRIZE_TYPE,
  WINNER_SELECTION_INFO_STATUS,
  WINNER_SELECTION_INFO_TYPE,
} from '../../util/constants';

import './WinnersList.scss';

const WinnersList = ({ campaign }) => {
  const { isDesktop } = useResponsive();
  const [completedInfo, setCompletedInfo] = useState();

  useEffect(() => {
    if (campaign) {
      const [grandPrizeWinners, weeklyPrizeWinners] = campaign.drawings.reduce(
        (result, info) => {
          if (info.status === WINNER_SELECTION_INFO_STATUS.COMPLETED_SUCCESS) {
            if (info.type === WINNER_SELECTION_INFO_TYPE.INTERMEDIATE) {
              result[1].push(info);
            } else if (info.type === WINNER_SELECTION_INFO_TYPE.GRAND) {
              result[0].push(info);
            }
          }
          return result;
        },
        [[], []]
      );

      const sortedGrandPrizeWinners = sortOnKey(
        grandPrizeWinners,
        'selectionTime',
        false
      );
      const sortedWeeklyPrizeWinners = sortOnKey(
        weeklyPrizeWinners,
        'selectionTime',
        false
      );
      setCompletedInfo(
        sortedGrandPrizeWinners.concat(sortedWeeklyPrizeWinners)
      );
    }
  }, [campaign]);

  if (!completedInfo || completedInfo?.length === 0) {
    return null;
  }

  return completedInfo?.map((info, index) =>
    info.winners.map((winner, winnerIndex) => (
      <Col
        className="winners p-0 card borderless mt-2 rounded-2"
        key={`${winner?.userId}${index}${winnerIndex}`}
      >
        <Nav className="flex-column w-100">
          <MediaObject
            userInfo={winner}
            isDesktop={isDesktop}
            prizeType={info.type}
          />
        </Nav>
      </Col>
    ))
  );
};

export default WinnersList;

function MediaObject({ userInfo, isDesktop, prizeType }) {
  return (
    <Media className="px-3 media-object rounded bg-white">
      <Media.Body className="text-left my-auto">
        <Col className="p-0 user-name">{toTitleCase(userInfo.name)}</Col>
        <Col className="p-0 font-weight-normal store-name">
          {userInfo?.storeName}
        </Col>
      </Media.Body>
      <div className="p-0 flex flex-column text-right my-auto ml-2">
        <Col className="p-0 prize-type">{WEEKLY_PRIZE_TYPE[prizeType]}</Col>
        <Col className="p-0 selection-time">
          {dateString(new Date(+userInfo?.selectionTime), !isDesktop)}
        </Col>
      </div>
    </Media>
  );
}
