import React from 'react';
import { Modal, ButtonGroup } from 'react-bootstrap';
import { Button } from '@rewards/ui';
import CloseButton from 'react-bootstrap/CloseButton';
import { useResponsive } from '../../../util/hooks';

import './ConfirmModal.scss';

const ConfirmModal = ({
  name,
  show,
  handleClose,
  isProcessingTxn = false,
  isStartingTxn = false,
}) => {
  const { isDesktop } = useResponsive();

  const handleCloseOverloaded = () => {
    handleClose();
  };

  return (
    <Modal
      show={show}
      onHide={handleCloseOverloaded}
      backdrop="static"
      keyboard={false}
      centered={true}
      className={isDesktop ? 'ms-modal text-center px-5 py-1' : 'ms-modal'}
      dialogClassName={`modal-90w rounded' ${
        isDesktop ? '' : 'ms-mobile-dialog mx-auto'
      }`}
      contentClassName="text-center"
    >
      <Modal.Header className="flex flex-column w-100 mt-3 p-0">
        <CloseButton
          className="position-absolute"
          style={{
            right: '20px',
          }}
          onClick={handleCloseOverloaded}
        />
        <div className="mb-5 mt-3 p-0 w-100">
          <div className="text-left survey-header-text py-0 px-4">
            {name ? `👋 Thank you, ${name}!` : ''}
          </div>
        </div>
        <div className="survey-confirm-details-text py-0 px-4 w-100 mb-4">
          {isStartingTxn || isProcessingTxn ? (
            isStartingTxn ? (
              <p>
                Transaction is being processed. Your points will be awarded
                shortly
              </p>
            ) : (
              isProcessingTxn && (
                <p>
                  Transaction is being processed. Your points will be awarded
                  shortly
                </p>
              )
            )
          ) : (
            <p>
              Looks like you’ve already participated this month.
              <br />
              <br />
              Come back next month for another chance to earn points!
            </p>
          )}
        </div>
      </Modal.Header>
      <Modal.Body className="py-0"></Modal.Body>
      <Modal.Footer className="border-0 bg-white">
        <ButtonGroup className="flex justify-center button-group-footer mb-3">
          <Button
            // className='dd-cancel-btn close-button mt-3 mr-1 py-3 border-0'
            onClick={() => {
              handleCloseOverloaded();
            }}
          >
            CLOSE
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
