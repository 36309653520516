import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { AuthContext, ACTIONS, DailySurpriseContext } from '@rewards/contexts';
import { FEATURES_FLAGS } from '../../util/constants';

const { DailySurpriseContext: Context } = DailySurpriseContext;
const { useAuth } = AuthContext;
const DailySurprisesContextState = ({ children }, ...props) => {
  const { unAuthToken, tokenStatus } = useAuth();
  const [dailySurpriseEvent, setDailySurpriseEvent] = useState({});
  const [data, setData] = useState();
  const [refreshBalancePoint, setRefreshBalancePoint] = useState({
    pointsEarned: 0,
    status: false,
  });

  if (
    unAuthToken?.status !== ACTIONS.STATUS.RESOLVED &&
    tokenStatus !== ACTIONS.STATUS.RESOLVED
  ) {
    return (
      <div id="spinner-icon">
        <div className="text-center spinner">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      </div>
    );
  }
  return (
    <Context.Provider
      value={{
        data,
        setData,
        dailySurpriseEvent,
        setDailySurpriseEvent,
        refreshBalancePoint,
        setRefreshBalancePoint,
      }}
      {...props}
    >
      {children}
    </Context.Provider>
  );
};
export default DailySurprisesContextState;
