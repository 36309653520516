import type { IconProps } from './IconProps';
import { tailwindMerge } from '../../util';

export const AngleRightIcon = (props: IconProps) => {
  const className = tailwindMerge(props.className, 'fill-schooner');
  return (
    <svg
      className={className}
      width={props.width}
      height={props.height}
      viewBox="0 0 7 13"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.18829 5.99678L0.461758 10.7183C0.1716 11.0125 0.1716 11.4877 0.461758 11.7818C0.751916 12.076 1.23297 12.076 1.52313 11.7818L6.77652 6.5323C7.06668 6.23814 7.06668 5.76297 6.77652 5.46881L1.52313 0.219284C1.37805 0.0759778 1.18716 0.000553577 0.996262 0.00055356C0.805368 0.000553544 0.614474 0.0759777 0.469395 0.219283C0.179236 0.513438 0.179236 0.988611 0.469395 1.28277L5.18829 5.99678Z"
      />
    </svg>
  );
};
