import { authAxios } from '@rewards/contexts';

export const fetchEMagazines = async (userId) => {
  return authAxios.get(`/api/emagazines/${userId}`).then((response) => {
    if (response.status === 200) {
      const { data } = response.data;
      return data;
    } else {
      return response;
    }
  });
};

export const fetchEMagazinesUnAuthorized = async () => {
  return authAxios.get('/api/emagazines').then((response) => {
    if (response.status === 200) {
      const { data } = response.data;
      return data;
    } else {
      return response;
    }
  });
};
