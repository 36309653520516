import React from 'react';

import App from './App';
import Icon from './assets/svg';
import { DailySurprisesContextState } from './contexts/DailySurprisesContext';
import SocialShareModal from './components/SocialShareModal';
import ConfirmationShareModal from './components/ConfirmationShareModal';

const register = (props: any) => {
  return {
    parent: 'earn',
    label: 'Daily Surprise',
    alterLabel: 'Daily SurPRIZE',
    tmLabel: true,
    icon: <Icon icon="daily-surprises" width="32" height="32" />,
    content: function Content() {
      return <App {...props} />;
    },
  };
};

export {
  DailySurprisesContextState,
  register,
  SocialShareModal,
  ConfirmationShareModal,
};
export default { register };
