import React, { useEffect, useState, useContext } from 'react';
import { Container, Col, Nav } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ACTIONS, AppContext } from '@rewards/contexts';

import BackNav from '../../../BackNav';
import KnifeConfirmation from '../../../KnifeConfirmation';

import Icon from '../../../../assets/svg';

import {
  getKnifeInfoPath,
  getAddressFormPath,
  getUserAddressPath,
} from '../../../../util/paths';
import { useKnives, useResponsive } from '../../../../util/hooks';
import { KNIFE_INFO } from '../../../../util/constants';
import { KnivesContext } from '../../../../contexts/KnivesContext';
import { PAGES, EVENTS, EVENT_LOCATION } from '../../../../analytics/events';

import './AddressList.scss';
const AddressList = () => {
  const { source, track } = useContext(AppContext.AppContext);
  const { toShippingAddressId, updateToShippingAddressId } =
    useContext(KnivesContext);

  const history = useHistory();
  const { addresses } = useKnives();
  const { isDesktop } = useResponsive();

  const [shouldCallNext, setShouldCallNext] = useState(false);

  useEffect(() => {
    source(PAGES.VIEWED_SHIPPING_ADDRESS_LIST_PAGE, {
      ui_version: import.meta.env.APP_VERSION,
    });
    AddressList.LOCATION = EVENT_LOCATION.SHIPPING_ADDRESS_LIST_PAGE;
    AddressList.showBackBtn = true;
    const knifeId = localStorage.getItem(KNIFE_INFO);

    knifeId && (AddressList.landingPath = getKnifeInfoPath(knifeId));
  }, []);

  useEffect(() => {
    if (addresses?.status === ACTIONS.STATUS.IDLE) {
      history.push(getUserAddressPath());
    }
  }, [addresses?.status]);

  const handleNextAddress = () => {
    if (toShippingAddressId) {
      const address = addresses?.data?.find(
        ({ _id }) => _id === toShippingAddressId
      );
      const { fullName, phone, state, city, email, street, zipCode } = address;
      track(EVENTS.SHIPPING_NEXT_BUTTON_CLICKED, {
        location: EVENT_LOCATION.SHIPPING_ADDRESS_LIST_PAGE,
        fullName,
        phone,
        state,
        city,
        email,
        street,
        zipCode,
        to_shipping_address_id: toShippingAddressId,
        ui_version: import.meta.env.APP_VERSION,
      });
      setShouldCallNext(true);
    }
  };

  const handleSelect = (id) => {
    updateToShippingAddressId(id);
  };

  const handleAddNewAddress = () => {
    track(EVENTS.SHIP_TO_HOME_ADD_NEW_ADDRESS_CLICKED, {
      ui_version: import.meta.env.APP_VERSION,
    });
    history.push(getAddressFormPath());
  };

  return (
    <Container className="text-center">
      <Col className="lc-add-list-title my-2">
        Please select your contact information and shipping address
      </Col>
      <Nav className="flex-column" onSelect={handleSelect}>
        {addresses?.data?.map((address) => (
          <Nav.Item
            key={address?._id}
            className={`rounded lc-add-user my-2 ${
              isDesktop ? 'border' : 'bg-white'
            } ${toShippingAddressId === address?._id && 'active'}`}
          >
            <Nav.Link eventKey={address?._id} className="flex-column">
              <Col className="lc-add-name my-1">{address?.fullName}</Col>
              <Col className="lc-add-info my-1">{address?.street}</Col>
              <Col className="lc-add-info">
                {address?.city}, {address?.state} {address?.zipCode}
              </Col>
              <Col className="lc-add-info">{address?.email}</Col>
              <Col className="lc-add-info">{address?.phone}</Col>
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
      <Col
        className={`flex flex-row lc-add-new-add justify-content-center my-2 py-2 hover ${
          isDesktop ? 'border' : 'bg-white'
        }`}
        onClick={handleAddNewAddress}
      >
        <div>
          <Icon icon="plus-o" width="41" height="41" />
        </div>
        <div className="lc-add-new my-auto mx-2">
          Add new shipping information
        </div>
      </Col>
      {!isDesktop && <Col className="py-5"></Col>}
      <Col
        lg={9}
        className={
          isDesktop
            ? 'p-0 mt-5 mx-auto'
            : 'lc-add-bottom fixed-bottom bg-white p-3'
        }
      >
        {/* <Button className='lc-add-next p-3' block disabled={!(addressIndex > -1)}>Next</Button> */}
        <KnifeConfirmation
          handleNextAddress={handleNextAddress}
          isDisabled={!toShippingAddressId}
          shouldCallNext={shouldCallNext}
        />
      </Col>
    </Container>
  );
};

export default BackNav(AddressList);
