import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Nav, Badge, Container, Col, Row, Spinner } from 'react-bootstrap';
import { Button } from '@rewards/ui';
import { useMsal } from '@azure/msal-react';
import { AuthContext, AppContext, ACTIONS } from '@rewards/contexts';

import { formatPath, toTitleCase } from '../../util/formatters';
import { events, findEvents, PAGE_EVENT, EVENTS } from '../../analytics/events';
import { LoyaltyConnectContext } from '../../contexts/LoyaltyConnectContext';
import Icon from '../../assets/svg';
import { getLandingPath, getCategoryPath } from '../../util/paths';
import {
  APP_TABS,
  LOYALTY_CONNECT_MODULES,
  LOYALTY_CONNECT_MODULES_DETAILS,
} from '../../util/constants';
import { useLoyaltyConnect } from '../../util/hooks';

import FAQs from '../FAQs';
import TermsAndConditons from '../TermsAndConditons';
import { RedeemBonusPointsInfo } from '../BonusPoints';
import { FEATURES_FLAGS } from '../../util/constants';

import './DropInModulesNavLinks.scss';
import CarouselBanner from '../CarouselBanner';
import CarouselModules from '../CarouselModules';
import { emitGVariables } from '@rewards/events';
import { BonusPointsBanner, useBonusPointsModule } from '@rewards/bonus-points';

const { useAuth } = AuthContext;
export default function DropInModulesNavLinks({
  registryContent,
  mainTab,
  isLandingPage = true,
}) {
  const history = useHistory();
  const { instance } = useMsal();
  const { isSSOEnabled, featureFlags, unAuthToken } = useAuth();
  const { offerCampaignsIsUserValid } = useLoyaltyConnect();

  const [splitPathName, setsplitPathName] = useState('');

  const { handleShowUnAuthenticatedUserModal } = useContext(
    AuthContext.AuthContext
  );
  const { source, track } = useContext(AppContext.AppContext);
  const { isDesktop } = useContext(LoyaltyConnectContext);
  const [openBanner, setOpenBanner] = useState(false);
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  const [enableComingSoonProducts, setEnableComingSoonProducts] =
    useState(false);
  const [enableComingSoonUtilityOffers, setEnableComingSoonUtilityOffers] =
    useState(false);
  const [isMAsubmoduleActive, setisMAsubmoduleActive] = useState(false);
  const [enableToolsForSchool, setEnableToolsForSchoolActive] = useState(false);
  const productsModules = ['In Store Products', 'Ship To Home'];
  const pathname = history.location.pathname;
  const today = new Date().valueOf();
  const bonusPointsModule = useBonusPointsModule();

  const handleLoginRegister = () => {
    setIsLoadingLogin(true);
    track(EVENTS.LOG_IN_REGISTER_CLICKED, {
      ui_version: import.meta.env.APP_VERSION,
    });
    instance.config.auth.authority = instance.config.auth.loginAuthority;
    instance.loginRedirect({
      ...instance.config.auth.loginRequest,
      extraQueryParameters: {
        anonymousId: window?.analytics?.user?.()?.anonymousId?.(),
      },
    });
  };

  useEffect(() => {
    if (mainTab) {
      source(PAGE_EVENT(mainTab), { ui_version: import.meta.env.APP_VERSION });
    }
  }, [mainTab]);

  useEffect(() => {
    if (
      featureFlags.find(
        (ff) => ff.name === FEATURES_FLAGS.ENABLE_DAILY_SURPRISES && ff.isActive
      )
    ) {
      setOpenBanner(true);
    }

    if (
      featureFlags.find(
        (ff) =>
          ff.name === FEATURES_FLAGS.ENABLE_COMING_SOON_PRODUCTS && !ff.isActive
      )
    ) {
      setEnableComingSoonProducts(true);
    }

    if (
      featureFlags.find(
        (ff) =>
          ff.name === FEATURES_FLAGS.ENABLE_COMING_SOON_UTILITY_OFFERS &&
          !ff.isActive
      )
    ) {
      setEnableComingSoonUtilityOffers(true);
    }

    if (
      featureFlags.find(
        (ff) =>
          ff.name === FEATURES_FLAGS.ENABLE_COMMUNITY_SOLAR_MA && ff.isActive
      )
    ) {
      setisMAsubmoduleActive(true);
    }

    if (
      featureFlags.find(
        (ff) =>
          ff.name === FEATURES_FLAGS.ENABLE_TOOLS_FOR_SCHOOLS && ff.isActive
      )
    ) {
      setEnableToolsForSchoolActive(true);
    }
  }, [featureFlags]);

  useEffect(
    () => {
      let splitPathName = '';
      switch (mainTab) {
        case APP_TABS.EARN: {
          splitPathName = pathname.split('/')?.[2];
          break;
        }
        default: {
          splitPathName = pathname.split('/')?.[1];
        }
      }
      setsplitPathName(splitPathName);
    },
    [pathname],
    mainTab
  );

  function GetTitle(props) {
    const { isLandingPage, isDesktop } = props;
    return (
      <div
        className={`flex ${
          isLandingPage ? 'flex-column h-100' : 'align-items-center'
        }`}
      >
        {/* <GetBadge {...props} /> */}
        <div
          className={`text-center ${
            isLandingPage ? `w-100 ${isDesktop ? 'm-auto' : ''}` : 'ml-1 mt-1'
          }`}
        ></div>
        <div className={`${isLandingPage ? 'h-25 block' : 'flex '}`}>
          <div
            className={`nav-item-text w-100 droping-module-title-text ${
              isLandingPage
                ? `mt-3 text-center ${isDesktop ? 'm-auto' : ''}`
                : 'ml-3 text-left'
            }`}
          >
            {props.alterTitle ? props.alterTitle : props.title}
            {props.tmLabel ? <span>&trade;</span> : ''}
          </div>
          {/* {isDesktop && <GetBadge {...props} />} */}
        </div>
      </div>
    );
  }

  const GetBadge = (props) => (
    <div
      className={`w-full relative ${
        props.isLandingPage ? ` ${props.isDesktop ? 'mt-1' : ''}` : ''
      } mt-1 h-25`}
    >
      {(props.isNew || props.overlayText) && (
        <div
          className={`isNew-badge w-max text-center py-1 px-2 absolute inset-x-1/2 -translate-y-1/2 -translate-x-1/2 rounded ${
            props.isLandingPage ? '' : 'small'
          }`}
        >
          {props.overlayText || 'New!'}
        </div>
      )}
    </div>
  );

  const handleSelect = (k) => {
    const { key, path, havePath, name } = JSON.parse(k);
    track(findEvents(events(key, mainTab)), {
      tab: mainTab,
      module: key,
      external: havePath || false,
      link: path,
      viewMode: isDesktop ? 'Desktop' : 'Mobile',
      ui_version: import.meta.env.APP_VERSION,
    });

    switch (mainTab) {
      case APP_TABS.REDEEM: {
        emitGVariables({ redeem_option: name });
        history.push(getLandingPath(key));
        break;
      }
      default: {
        emitGVariables({ earn_option: name });

        if (!havePath || key === 'survey') {
          history.push(getCategoryPath(key, mainTab));
        } else if (key === 'question-of-the-month') {
          history.push(getCategoryPath('survey', mainTab));
        } else {
          window.open(path, '_blank').focus();
        }
      }
    }
  };
  const handleClick = (category, tab) => {
    const location = toTitleCase(category);
  };

  const handlePlayNowButtonRedirect = () => {
    track(EVENTS.BANNER_CLICKED, { ui_version: import.meta.env.APP_VERSION });
    history.push('/earn/daily-surprise');
  };

  const goTo = (link) => window.open(link, '_blank').focus();

  // console.log(registryContent, featureFlags)

  return (
    <Container fluid className="redeem-container pt-0">
      {isDesktop && (
        <nav className="navbar navbar-expand-lg navbar-light bg-light navbar-bottom-line">
          <div className="navbar-collapse flex justify-content-center">
            <Nav onSelect={handleSelect} className="navbar-nav">
              {registryContent.tabs.map(
                (category, index) =>
                  category?.name !== 'Community Solar' && (
                    <Nav.Item
                      key={index}
                      className={`m-2 nav-item`}
                      onClick={() => handleClick(category?.name, mainTab)}
                    >
                      <Nav.Link
                        eventKey={JSON.stringify({
                          key: formatPath(category.label),
                          path: category?.path,
                          name: category?.name,
                          havePath:
                            category?.havePath && !category?.navPathInternal,
                        })}
                        className={`p-0 h-100 ${isLandingPage ? '' : 'flex'} ${
                          splitPathName === formatPath(category.label)
                            ? 'menu-active'
                            : ''
                        }`}
                      >
                        {category.alterLabel
                          ? category.alterLabel
                          : category.name}
                      </Nav.Link>
                    </Nav.Item>
                  )
              )}
            </Nav>
          </div>
        </nav>
      )}

      {offerCampaignsIsUserValid &&
        isLandingPage &&
        mainTab === APP_TABS.REDEEM && <RedeemBonusPointsInfo />}
      {isLandingPage && (
        <BonusPointsBanner
          className={`mx-auto mb-2 ${isDesktop ? 'w-75' : ''} text-center`}
        />
      )}

      {isLandingPage && (
        <>
          {isDesktop &&
            unAuthToken?.status === ACTIONS.STATUS.RESOLVED &&
            mainTab === APP_TABS.REDEEM &&
            !bonusPointsModule?.isActive && (
              <Col
                className="mx-auto p-0 text-center m-5 m"
                lg={isDesktop && isLandingPage ? '9' : '12'}
              >
                <h1 className="text-h1 text-cobalt font-medium m-2">
                  Sign up or log in and start redeeming points today!
                </h1>
                <span className="landing-sub-text-para">
                  Use your card in-store or online to earn points
                  <br />
                  when you buy groceries at Price Chopper, Market 32, and Market
                  Bistro.
                </span>
                <Row className="m-3 flex justify-center">
                  <div className="w-50 flex items-center justify-end">
                    {' '}
                    <Button
                      onClick={() => handleLoginRegister()}
                      // className={`btn-button-custom-green btn-rounded ${(isDesktop) ? 'lg-link' : ''}`}
                    >
                      {isLoadingLogin && (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="secondary"
                            className="align-middle"
                          />{' '}
                        </>
                      )}
                      Log In / Register
                    </Button>
                  </div>
                  <div className="w-50 flex items-center justify-start">
                    <Button
                      as="a"
                      target="_blanck"
                      href="https://www.pricechopper.com/rewards/"
                      // onClick={() => goTo('https://www.pricechopper.com/rewards/')}
                      // className={`btn-button-custom-green btn-rounded ${(isDesktop) ? 'lg-link' : ''}`}
                    >
                      Learn More
                    </Button>
                  </div>
                </Row>
              </Col>
            )}

          <Col
            className="m-auto p-0 droping-modules-bg-gray m"
            lg={isDesktop && isLandingPage ? '9' : '12'}
          >
            <CarouselBanner
              section={mainTab}
              isDesktop={isDesktop}
              isLandingPage={isLandingPage}
              mainTab={mainTab}
            />

            {mainTab === APP_TABS.REDEEM && (
              <Col
                className="text-center m-auto new-ways-to-redeem py-4"
                lg={9}
              >
                <span className={`title-${isDesktop ? 'size-30' : 'size-20'}`}>
                  Check out all the ways to redeem your points!
                </span>
              </Col>
            )}

            {mainTab === APP_TABS.EARN && (
              <Col
                className="text-center m-auto new-ways-to-redeem py-4"
                lg={9}
              >
                <span className={`title-${isDesktop ? 'size-30' : 'size-20'}`}>
                  Check out these ways to earn more AdvantEdge Rewards points!
                </span>
              </Col>
            )}

            <CarouselModules
              isDesktop={isDesktop}
              desktopShow={4}
              mobileShow={2}
            >
              {registryContent.tabs
                .filter((category, i) => {
                  if (
                    category.name === 'Tools For Schools' &&
                    !enableToolsForSchool
                  ) {
                    return false;
                  }
                  return true;
                })
                .map((category, i) => {
                  const ff = featureFlags.find(
                    ({ name }) => name === category.FF
                  );
                  let hasStarted =
                    ff?.otherDetails?.campaignActive != undefined
                      ? ff?.otherDetails?.campaignActive
                      : true;
                  const comingSoon = ff?.otherDetails?.commingSoon;
                  const showEndDate = ff?.otherDetails?.showEndDate;
                  const endText = ff?.otherDetails?.endText;
                  if (category.name === 'Question of the Month') {
                    hasStarted =
                      ff?.timeFlag?.find(
                        (survey) =>
                          survey.startTs <= today && survey.endTs >= today
                      ) && !ff?.otherDetails?.campaignEnded;
                  }

                  return (
                    <Col
                      key={i}
                      className="nav-item-wrap p-0"
                      xs={!isLandingPage ? null : 6}
                      sm={!isLandingPage ? null : 3}
                      md={!isLandingPage ? 3 : null}
                      lg={!isLandingPage ? null : 3}
                      xl={!isLandingPage ? 2 : null}
                    >
                      <Nav.Item
                        className={`p-${isDesktop ? 3 : 2}`}
                        onClick={() => handleClick(category?.name, mainTab)}
                      >
                        <div
                          className={` ${
                            isDesktop && isLandingPage
                              ? 'nav-desktop-item-menu'
                              : 'nav-desktop-item-menu'
                          } m-auto overflow-hidden ${
                            !isLandingPage ? 'small' : ''
                          } ${
                            splitPathName === formatPath(category.label)
                              ? 'active'
                              : ''
                          }`}
                        >
                          <div className="droping-module-header flex !justify-center items-center flex-col pt-4 pb-4">
                            <Icon
                              icon={category.label.toLowerCase()}
                              width={isDesktop && isLandingPage ? '130' : '120'}
                              height={
                                isDesktop && isLandingPage ? '130' : '120'
                              }
                              stroke={
                                splitPathName === formatPath(category.label)
                                  ? 'white'
                                  : null
                              }
                            />
                            {category.name === 'Community Solar' && (
                              <Col
                                md={12}
                                className="Community-solar-card-module-name text-center !pt-3"
                              >
                                Community Solar
                              </Col>
                            )}

                            <GetBadge {...category} />
                          </div>
                          <div
                            className={`droping-module-body ${
                              isDesktop ? 'droping-module-body-space' : ''
                            } text-center p-${isDesktop ? 3 : 2}`}
                          >
                            <span className="droping-module-name pt-2 pb-2">
                              <GetTitle
                                title={category.name}
                                alterTitle={category.alterLabel}
                                tmLabel={category.tmLabel}
                                isNew={category.isNew}
                                isLandingPage={isLandingPage}
                                overlayText={category.overlayText}
                                isDesktop={isDesktop}
                              />
                            </span>
                            {isDesktop && (
                              <div className="text-center droping-module-name-separator mt-1"></div>
                            )}
                            {isDesktop &&
                              (enableComingSoonProducts &&
                              productsModules.includes(category.name) ? (
                                <div
                                  className="text-cobalt p-2"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      LOYALTY_CONNECT_MODULES_DETAILS[
                                        category.name
                                      ].description_coming_soon,
                                  }}
                                ></div>
                              ) : (
                                <>
                                  <div
                                    className="text-cobalt p-2"
                                    dangerouslySetInnerHTML={{
                                      __html: hasStarted
                                        ? LOYALTY_CONNECT_MODULES_DETAILS[
                                            category.name
                                          ].description
                                        : LOYALTY_CONNECT_MODULES_DETAILS[
                                            category.name
                                          ].description_ended,
                                    }}
                                  ></div>
                                  {hasStarted && showEndDate && endText && (
                                    <div className="text-cobalt p-2">
                                      {endText}
                                    </div>
                                  )}
                                </>
                              ))}
                          </div>
                          <div className="droping-module-footer flex text-center pb-4 pt-4 pl-2 pr-2">
                            {enableComingSoonProducts &&
                            productsModules.includes(category.name) ? (
                              <div
                                className="text-white p-2"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    LOYALTY_CONNECT_MODULES_DETAILS[
                                      category.name
                                    ].rule_coming_soon,
                                }}
                              />
                            ) : (
                              !(
                                category.name === 'Community Solar' &&
                                isMAsubmoduleActive
                              ) &&
                              LOYALTY_CONNECT_MODULES_DETAILS[category.name]
                                .rule &&
                              LOYALTY_CONNECT_MODULES_DETAILS[category.name]
                                .rule.length > 0 && (
                                <div
                                  className="text-white p-2"
                                  dangerouslySetInnerHTML={{
                                    __html: hasStarted
                                      ? LOYALTY_CONNECT_MODULES_DETAILS[
                                          category.name
                                        ].rule
                                      : LOYALTY_CONNECT_MODULES_DETAILS[
                                          category.name
                                        ].rule_ended,
                                  }}
                                />
                              )
                            )}

                            {(category.name === 'Community Solar' &&
                              isMAsubmoduleActive &&
                              category.secondPath) ||
                            (category.secondPath &&
                              category.name !== 'Community Solar') ? (
                              <Nav.Link
                                eventKey={JSON.stringify({
                                  key: formatPath(category.label),
                                  path: category?.path,
                                  name: category?.name,
                                  havePath: category?.havePath,
                                })}
                                className={`p-0 pt-2 text-dark h-100 droping-module-two-buttons ${
                                  isLandingPage ? '' : 'flex'
                                }`}
                              >
                                <div className="h-50">
                                  {category.labelButton && (
                                    <div className="mb-2 text-white">
                                      {category.labelButton}
                                    </div>
                                  )}
                                  <Button
                                    width="full"
                                    className="!m-0"
                                    onClick={() =>
                                      handleSelect(
                                        JSON.stringify({
                                          key: formatPath(category.label),
                                          path: category?.path,
                                          name: category?.name,
                                          havePath: category?.havePath,
                                        })
                                      )
                                    }
                                    // className={`btn-button-custom-green btn-rounded h-50 w-75 mb-4 ${(isDesktop) ? '' : ''}`}
                                  >
                                    {mainTab === APP_TABS.REDEEM
                                      ? 'Redeem'
                                      : formatPath(category.label) ===
                                        'student-loan-refinance'
                                      ? 'Shop Now'
                                      : 'Earn'}
                                  </Button>
                                </div>

                                <div className="h-50 droping-module-second-button-align">
                                  {category.secondLabelButton && (
                                    <div className="mb-2 text-white">
                                      {category.secondLabelButton}
                                    </div>
                                  )}
                                  <Button
                                    width="full"
                                    className="!m-0"
                                    onClick={() =>
                                      handleSelect(
                                        JSON.stringify({
                                          key: formatPath(category.label),
                                          path: category?.secondPath,
                                          name: category?.name,
                                          havePath: category?.havePath,
                                        })
                                      )
                                    }
                                    // className={`btn-button-custom-green btn-rounded h-50 w-75 ${(isDesktop) ? '' : ''}`}
                                  >
                                    {mainTab === APP_TABS.REDEEM
                                      ? 'Redeem'
                                      : formatPath(category.label) ===
                                        'student-loan-refinance'
                                      ? 'Shop Now'
                                      : 'Earn'}
                                  </Button>
                                </div>
                              </Nav.Link>
                            ) : (
                              <Nav.Link
                                eventKey={JSON.stringify({
                                  key: formatPath(category.label),
                                  path: category?.path,
                                  name: category?.name,
                                  havePath: category?.havePath,
                                })}
                                className={`p-0 text-dark h-100 link-to-droping-module ${
                                  isLandingPage ? '' : 'flex'
                                }`}
                              >
                                <Button
                                  width="full"
                                  className="!m-0"
                                  onClick={() =>
                                    handleSelect(
                                      JSON.stringify({
                                        key: formatPath(category.label),
                                        path: category?.path,
                                        name: category?.name,
                                        havePath: category?.havePath,
                                      })
                                    )
                                  }
                                  // className={`btn-button-custom-green btn-rounded w-75 ${(isDesktop) ? '' : ''}`}
                                >
                                  {mainTab === APP_TABS.REDEEM
                                    ? 'Redeem'
                                    : formatPath(category.label) ===
                                      'student-loan-refinance'
                                    ? 'Shop Now'
                                    : 'Earn'}
                                </Button>
                              </Nav.Link>
                            )}
                          </div>
                        </div>
                      </Nav.Item>
                    </Col>
                  );
                })}
            </CarouselModules>
          </Col>
        </>
      )}
      {isLandingPage && (
        <div className="mt-3">
          <Col className="p-0 text-center m-auto flex justify-center" lg={9}>
            <FAQs />
          </Col>
          <Col className="p-0 text-center m-auto flex justify-center" lg={9}>
            <TermsAndConditons />
          </Col>
        </div>
      )}
    </Container>
  );
}
