import React from 'react';
import Carousel from 'react-multi-carousel';

import 'react-multi-carousel/lib/styles.css';
import useResponsive from '../../util/hooks/useResponsive';
import Icon from '../../assets/svg';

import './MultiItemCarousel.scss';

function CarouselCards({ children, numberOfItems }) {
  const { isDesktop } = useResponsive();
  const ButtonGroup = ({ next, previous, ...rest }) => {
    const {
      carouselState: { currentSlide, totalItems, slidesToShow },
    } = rest;
    if (totalItems < slidesToShow) {
      return null;
    }
    if (!(totalItems !== currentSlide + slidesToShow) && currentSlide === 0) {
      return null;
    }
    return (
      <div className="carousel-button-group">
        {currentSlide !== 0 && (
          <div className="custom-left-arrow hover" onClick={() => previous()}>
            <Icon icon="carouselArrow" width="25" height="48" />
          </div>
        )}
        {totalItems !== currentSlide + slidesToShow && (
          <div onClick={() => next()} className="custom-right-arrow hover">
            <Icon icon="carouselArrow" width="25" height="48" />
          </div>
        )}
      </div>
    );
  };
  return (
    <div className="position-relative">
      <Carousel
        partialVisible={true}
        additionalTransfrom={0}
        autoPlaySpeed={3000}
        centerMode={false}
        className={`${
          numberOfItems > 1 ? '' : 'lte-single-item-carosal w-100'
        }`}
        containerClass="container-with-dots react-multi-carousel-list"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite={false}
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={true}
        renderDotsOutside={false}
        arrows={false}
        customButtonGroup={isDesktop ? <ButtonGroup /> : ''}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: 1,
            partialVisibilityGutter: 30,
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0,
            },
            items: 1,
            partialVisibilityGutter: 30,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464,
            },
            items: 1,
            partialVisibilityGutter: 30,
          },
        }}
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {children}
      </Carousel>
    </div>
  );
}

export default CarouselCards;
