import React, { useState, useEffect, useRef, useContext } from 'react';
import { Col, Container, Card } from 'react-bootstrap';
import { AppContext, ACTIONS } from '@rewards/contexts';
import { useUserInfo } from '@rewards/data-provider';

import BackNav from '../BackNav';
import DonateSlider from './components/DonateSlider';
import ConfirmDonationModal from './components/ConfirmDonationModal';
import OverLayToolTip from '../OverLayToolTip';

import { SchoolContext } from '../../contexts/SchoolContexts';
import Icon from '../../assets/svg';
import { useResponsive, useSchool } from '../../util/hooks';
import { PAGES, EVENT_LOCATION } from '../../analytics/events';
import { numberWithCommas, numToWords } from '../../util/formatters';

import './DonateDashboard.scss';
import styleConst from '../../util/styleConsts';

const DonateDashboard = () => {
  const { source } = useContext(AppContext.AppContext);

  const { isDesktop } = useResponsive();

  useEffect(() => {
    DonateDashboard.showBackBtn = true;
    DonateDashboard.LOCATION =
      EVENT_LOCATION.TOOLS_FOR_SCHOOLS_DONATE_DASHBOARD;
    source(PAGES.TOOLS_FOR_SCHOOLS_DONATE_DASHBOARD, {
      ui_version: import.meta.env.APP_VERSION,
    });
  }, []);

  return (
    <Container>
      {isDesktop ? (
        <DonateDashboardDesktopView isDesktop={isDesktop} />
      ) : (
        <DonateDashboardMobileView />
      )}
    </Container>
  );
};

const DonateDashboardDesktopView = ({ isDesktop = false }) => {
  return (
    <div className="text-center flex justify-content-between">
      <Col md="7" className="p-0">
        <Col className="pt-3 tfs-selected-school-title text-left">
          Donate to Tools for Schools
        </Col>
        <DonateDashboardMobileView isDesktop={isDesktop} />
      </Col>
      <Col md="4" className="p-0">
        <DonateDashboardActions isDesktop={isDesktop} />
      </Col>
    </div>
  );
};

const DonateDashboardMobileView = ({ isDesktop }) => {
  const { school } = useSchool();

  const SelectedSchoolInfo = () => (
    <>
      <Card.Title
        className={`tfs-${isDesktop ? 'desktop' : 'mob'}-donate-card-title m-0`}
      >
        You are currently supporting:
      </Card.Title>
      <Card.Title
        className={`tfs-${
          isDesktop ? 'desktop' : 'mob'
        }-donate-card-title-school-name m-0`}
      >
        {school?.name}
      </Card.Title>
    </>
  );

  return (
    <div className="text-center">
      {!isDesktop && <RewardsPointsInfo />}
      <Card
        className={`rounded-2 borderless tfs-donate-card my-3 ${
          isDesktop ? 'text-center' : 'text-left'
        }`}
      >
        <Card.Body>
          <Card.Img
            variant="bottom"
            className="my-3"
            src="https://cdn.spinwheel.io/images/tcc/tools-for-school.png"
          />
          <SelectedSchoolInfo />
        </Card.Body>
      </Card>
      {isDesktop ? <DonationInfo /> : <DonateDashboardActions />}
    </div>
  );
};

const DonateDashboardActions = ({ isDesktop = false }) => {
  const target = useRef(null);

  const { currencyExchange, advantPointsMax, selectedSchool } =
    useContext(SchoolContext);

  const { school } = useSchool();
  const [showTooltip, setTooltip] = useState(false);
  const [showTxnErrorTooltip, setTxnErrorTooltip] = useState(false);
  const [txnErrorTooltipMessage, setTxnErrorTooltipMessage] = useState(
    ACTIONS.TXN_ERROR.INTERNAL
  );
  const [advantPoints, setAdvantPoints] = useState(0);
  const [currentAdvantPointsDonation, setCurrentAdvantPointsDonation] =
    useState(0);

  const resetPoints = () => {
    setAdvantPoints(0);
    setCurrentAdvantPointsDonation(0);
  };

  useEffect(() => {
    if (showTooltip) {
      const timer = setTimeout(() => {
        setTooltip(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showTooltip]);

  useEffect(() => {
    if (showTxnErrorTooltip) {
      const timer = setTimeout(() => {
        setTxnErrorTooltip(false);
        resetPoints();
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showTxnErrorTooltip]);

  const handleSliderAdvantPoints = (value) => {
    setAdvantPoints(value);
    setCurrentAdvantPointsDonation(
      value / currencyExchange?.sourceDebitIncrements || 0
    );
  };

  const handlePoints = (shouldDecrease = false) => {
    const { sourceDebitIncrements } = currencyExchange;
    if (!shouldDecrease && advantPointsMax < sourceDebitIncrements) {
      setTooltip(true);
      return;
    }
    let calculatedPoints = 0;
    if (shouldDecrease) {
      calculatedPoints = advantPoints - sourceDebitIncrements;
    } else {
      calculatedPoints = advantPoints + sourceDebitIncrements;
    }
    if (calculatedPoints < 0) {
      setAdvantPoints(0);
      setCurrentAdvantPointsDonation(0);
    } else if (calculatedPoints <= advantPointsMax) {
      setAdvantPoints(calculatedPoints);
      setCurrentAdvantPointsDonation(calculatedPoints / sourceDebitIncrements);
    }
  };

  const showAdvantPointsTooltip = (isTrue = true) => setTooltip(isTrue);

  const showTransactionErrorTooltip = (isRewardPointsInsufficient) => {
    isRewardPointsInsufficient &&
      setTxnErrorTooltipMessage(ACTIONS.TXN_ERROR.POINTS);
    setTxnErrorTooltip(true);
  };

  return (
    <div className={isDesktop ? 'bg-white rounded' : ''}>
      {isDesktop && <RewardsPointsInfo />}
      <Col
        className={`flex justify-content-between text-center ${
          isDesktop ? '' : 'p-0'
        }`}
      >
        <Col className="p-3 tfs-landing-points-donated bg-white text-center rounded-20 w-50 mr-n2">
          <Col className="p-0 points-donated mt-4">
            {selectedSchool?.pointsDonated || 0}
          </Col>
          <Col className="p-0 mt-2 points-info">
            Points you’ve donated to your school
          </Col>
        </Col>
        <div className="my-auto p-2 card-icon-overlap">
          <div>
            <Icon
              icon="dollar-o"
              color={styleConst.cobalt}
              width="42"
              height="42"
            />
          </div>
        </div>
        <Col className="p-3 tfs-landing-points-donated text-center bg-white rounded-20 w-50 ml-n2">
          <Col className="p-0 points-donated mt-4">
            {school?.pointBalance || 0}
          </Col>
          <Col className="p-0 mt-2 points-info">
            Total points donated to your school
          </Col>
        </Col>
      </Col>
      {!isDesktop && <DonationInfo />}
      <OverLayToolTip
        tooltipText={`Minimum points should be ${currencyExchange?.sourceDebitIncrements}`}
        target={target}
        showTooltip={showTooltip}
      />
      <OverLayToolTip
        tooltipText={txnErrorTooltipMessage}
        target={target}
        showTooltip={showTxnErrorTooltip}
      />
      <div className="flex justify-content-center align-items-center">
        <div onClick={() => handlePoints(true)} className="mx-n0">
          <Icon icon="minus" width="71" height="71" />
        </div>
        <div className="tfs-donate-dashboard-points my-auto px-1" ref={target}>
          {' '}
          <Icon className="inline" icon="balance-bolt" width="43" height="43" />
          <span className="pl-1 mt-1">{advantPoints}</span>
        </div>
        <div onClick={() => handlePoints(false)} className="mx-n0">
          <Icon icon="plus" width="71" height="71" />
        </div>
      </div>
      <Col className="p-0 tfs-donate-dashboard-current-donation">
        ${numberWithCommas(currentAdvantPointsDonation)}{' '}
        {/* {currentAdvantPointsDonation > 1 ? ' dollars' : 'dollar'} donation */}
        value donation
      </Col>
      <DonateSlider
        handleSliderAdvantPoints={handleSliderAdvantPoints}
        advantPoints={advantPoints}
        currentAdvantPointsDonation={currentAdvantPointsDonation}
      />

      <div className="text-center mt-16">
        Price Chopper Market 32 matches all donations
      </div>
      <Col
        className={`p-3 tfs-donate-dashboard-confirm flex justify-center ${
          isDesktop ? '' : 'fixed-bottom bg-white'
        }`}
      >
        <ConfirmDonationModal
          showAdvantPointsTooltip={showAdvantPointsTooltip}
          showTransactionErrorTooltip={showTransactionErrorTooltip}
          resetPoints={resetPoints}
          advantPoints={advantPoints}
          currentAdvantPointsDonation={currentAdvantPointsDonation}
        />
      </Col>
    </div>
  );
};

const RewardsPointsInfo = () => {
  const { data: userInfo } = useUserInfo();
  const { data: user } = userInfo || {};
  return (
    <>
      <Col className="tfs-donate-dashboard-rewards-points pt-3">
        <span>{user?.partnerAccounts?.[0]?.accountBalance || 0} Rewards</span>{' '}
        Points
      </Col>
      <Col className="tfs-donate-dashboard-points-text">
        will make an impact in our community.
      </Col>
      <Col className="divider my-3"></Col>
    </>
  );
};

const DonationInfo = () => (
  <Col
    className="p-0 tfs-donate-dashboard-price-chopper my-4"
    id="scrollToPosition"
  >
    <Icon icon="donate-text" width="100%" height="66" />
    <div className="tfs-donate-dashboard-price-chopper-text position-absolute text-white px-5 text-center w-100">
      100 points = $1 donation
    </div>
  </Col>
);

export default DonateDashboard;
