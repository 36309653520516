import React, { useEffect, useContext } from 'react';
import { Container, Card, Image, Col } from 'react-bootstrap';
import { AppContext } from '@rewards/contexts';

import BackNav from './components/BackNav';

import { useResponsive } from './util/hooks';
import { PAGE } from './analytics/events';

// import 'bootstrap/dist/css/bootstrap.min.css'
import './App.scss';
import './styles.css';

const App = () => {
  const { isDesktop } = useResponsive();
  const { source } = useContext(AppContext.AppContext);
  useEffect(() => {
    source(PAGE, { ui_version: process?.env.APP_VERSION });
  }, []);

  return (
    <Container className={isDesktop ? 'flex justify-content-between' : 'mt-3'}>
      <Col className="p-0 mb-3" md={isDesktop ? 7 : 12}>
        <Card className="border-0 f-card">
          <Card.Img
            variant="top"
            src="https://cdn.spinwheel.io/images/tcc/advantedge-fuel-savings.png "
          />
          <Card.Body>
            <Card.Title className="f-card-title">Fuel Savings</Card.Title>
            <Card.Text className="f-card-text">
              <span className="f-card-imp-info">
                Every 100 points = $0.05 off{' '}
              </span>
              per gallon of gas (up to 20 gallons per fill-up) at hundreds of
              participating Sunoco locations near you.
            </Card.Text>
            <Card.Text className="f-card-text">
              Simply swipe your wallet-size{' '}
              <span className="f-card-imp-info">AdvantEdge Card</span> at the
              pump or present it while paying inside. Follow the prompts and
              watch the price of gasoline drop!
            </Card.Text>
            <Card.Text className="f-card-text">
              Click here to find participating stations:{' '}
              <a
                href="https://www.pricechopper.com/rewards/participating-fuel-locations/"
                target="_blank"
                rel="noreferrer"
              >
                https://www.pricechopper.com/rewards/participating-fuel-locations/
              </a>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col
        className={`p-0 my-auto ${isDesktop ? 'mr-n5' : ''}`}
        md={isDesktop ? 5 : 12}
      >
        <Image
          src="https://cdn.spinwheel.io/images/tcc/fuel/sunoco_logo.png"
          fluid
          className="block mx-auto"
        />
      </Col>
    </Container>
  );
};

export default BackNav(App);
