const dev = {
  accountInfo: {
    clientId: 'loyalty_connect',
    redirectUri: 'http://rewards.pricechopper.com:8080',
    authBaseUrl: 'https://dev-dim-api.spinwheel.io',
    NODE_ENV: 'dev',
    appId: '164477602254704',
    ssoClientId: 'dd322bb6-8ced-4083-a80a-13a544ceb94c',
    tenant: 'pricechopperdevsso',
    knownAuthorities: ['pricechopperdevsso.b2clogin.com'],
    surveyMonkeyLink: 'https://www.surveymonkey.com/r/C8FRSW7',
    policies: {
      signUpPolicy: 'B2C_1A_SIGNUP',
      signInPolicy: 'B2C_1A_signup_signin',
      resetPasswordPolicy: 'B2C_1A_PASSWORDRESET',
    },
    REACT_APP_SITE_KEY: '6Le4UZEbAAAAAGqJRhbsP827j_gztWghm5Xm7nmL',
    REACT_APP_SECRET_KEY: '6Le4UZEbAAAAADFcLrrvc8W_Z2p6TD_jKUKPMY3a',
    magazinesPath:
      'https://pricechopper.magazinegoldcard.com/login/memberlogin.aspx',
    allMagazineListPath: 'https://pricechopper.magazinegoldcard.com/browse/',
    giftCardsPath: 'http://shopgiftcards.pricechopper.com/',
    pricechopperCoupons: 'https://uat-pch-platform-master-web.unataops.com',
    dailySurprisePath:
      'https://gmteurope4.gametation.com/p/pc2dev/?customerToken=',
    greenMountainPath: 'https://www.greenmountainenergy.com/market32',
    communitySolarNYPath:
      'https://www.arcadia.com/community-solar/lp-market32?utm_source=nrg&utm_medium=website&promo=gme-ny-m32-web',
    communitySolarMAPath:
      'https://greenmountainenergy.powermarket.io/overlook.html',
    travelsPath:
      'https://claimrewardmiles.mileageplus.com/united/claim.html#claim_codes',
    allTravelListPath: 'https://pricechopper.travelgoldcard.com/browse/',
  },
};

const qa = {
  accountInfo: {
    clientId: 'loyalty_connect',
    redirectUri: 'https://rewards.pricechopper.com',
    authBaseUrl: 'https://dev-dim-api.spinwheel.io',
    NODE_ENV: 'qa',
    appId: '164477602254704',
    ssoClientId: 'dd322bb6-8ced-4083-a80a-13a544ceb94c',
    tenant: 'pricechopperdevsso',
    knownAuthorities: ['pricechopperdevsso.b2clogin.com'],
    surveyMonkeyLink: 'https://www.surveymonkey.com/r/C8FRSW7',
    policies: {
      signUpPolicy: 'B2C_1A_SIGNUP',
      signInPolicy: 'B2C_1A_signup_signin',
    },
    REACT_APP_SITE_KEY: '6Le4UZEbAAAAAGqJRhbsP827j_gztWghm5Xm7nmL',
    REACT_APP_SECRET_KEY: '6Le4UZEbAAAAADFcLrrvc8W_Z2p6TD_jKUKPMY3a',
    magazinesPath:
      'https://pricechopper.magazinegoldcard.com/login/memberlogin.aspx',
    allMagazineListPath: 'https://pricechopper.magazinegoldcard.com/browse/',
    giftCardsPath: 'http://shopgiftcards.pricechopper.com/',
    pricechopperCoupons: 'https://uat-pch-platform-master-web.unataops.com',
    dailySurprisePath:
      'https://gmteurope4.gametation.com/p/pc2dev/?customerToken=',
    greenMountainPath: 'https://www.greenmountainenergy.com/market32',
    communitySolarNYPath:
      'https://www.arcadia.com/community-solar/lp-market32?utm_source=nrg&utm_medium=website&promo=gme-ny-m32-web',
    communitySolarMAPath:
      'https://greenmountainenergy.powermarket.io/overlook.html',
    travelsPath:
      'https://claimrewardmiles.mileageplus.com/united/claim.html#claim_codes',
    allTravelListPath: 'https://pricechopper.travelgoldcard.com/browse/',
  },
};
const sandbox = {
  // ORIGINAL qa2
  accountInfo: {
    clientId: 'loyalty_connect',
    redirectUri: 'https://rewards.pricechopper.com',
    authBaseUrl: 'https://dev-dim-api.spinwheel.io',
    NODE_ENV: 'sandbox',
    appId: '164477602254704',
    ssoClientId: '8b13073d-975e-4cdf-9fa2-979a95909c64',
    tenant: 'developersso',
    knownAuthorities: ['developersso.b2clogin.com'],
    surveyMonkeyLink: 'https://www.surveymonkey.com/r/C957CWQ',
    policies: {
      signUpPolicy: 'B2C_1A_SIGNUP_SIGNIN',
      signInPolicy: 'B2C_1A_signup_signin',
    },
    REACT_APP_SITE_KEY: '6Le4UZEbAAAAAGqJRhbsP827j_gztWghm5Xm7nmL',
    REACT_APP_SECRET_KEY: '6Le4UZEbAAAAADFcLrrvc8W_Z2p6TD_jKUKPMY3a',
    magazinesPath:
      'https://pricechopper.magazinegoldcard.com/login/memberlogin.aspx',
    allMagazineListPath: 'https://pricechopper.magazinegoldcard.com/browse/',
    giftCardsPath: 'http://shopgiftcards.pricechopper.com/',
    pricechopperCoupons: 'https://uat-pch-platform-master-web.unataops.com',
    dailySurprisePath:
      'https://gmteurope4.gametation.com/p/pc2dev/?customerToken=',
    greenMountainPath: 'https://www.greenmountainenergy.com/market32',
    communitySolarNYPath:
      'https://www.arcadia.com/community-solar/lp-market32?utm_source=nrg&utm_medium=website&promo=gme-ny-m32-web',
    communitySolarMAPath:
      'https://greenmountainenergy.powermarket.io/overlook.html',
    travelsPath:
      'https://claimrewardmiles.mileageplus.com/united/claim.html#claim_codes',
    allTravelListPath: 'https://pricechopper.travelgoldcard.com/browse/',
  },
};

const sandbox_ = {
  // REMOVE THE "_" AT THE END
  accountInfo: {
    clientId: 'loyalty_connect',
    redirectUri: 'https://rewards.pricechopper.com:8080',
    authBaseUrl: 'https://sandbox-dim-api.spinwheel.io',
    NODE_ENV: 'sandbox',
    appId: '164477602254704',
    ssoClientId: '6a08c47d-f749-4326-8b1c-cabfde79417d',
    tenant: 'pricechopperdevsso',
    knownAuthorities: ['pricechopperdevsso.b2clogin.com'],
    surveyMonkeyLink: 'https://www.surveymonkey.com/r/C957CWQ',
    policies: {
      signUpPolicy: 'B2C_1A_SIGNUP',
      signInPolicy: 'B2C_1A_signup_signin',
    },
    REACT_APP_SITE_KEY: '6Le4UZEbAAAAAGqJRhbsP827j_gztWghm5Xm7nmL',
    REACT_APP_SECRET_KEY: '6Le4UZEbAAAAADFcLrrvc8W_Z2p6TD_jKUKPMY3a',
    magazinesPath:
      'https://pricechopper.magazinegoldcard.com/login/memberlogin.aspx',
    allMagazineListPath: 'https://pricechopper.magazinegoldcard.com/browse/',
    giftCardsPath: 'http://shopgiftcards.pricechopper.com/',
    pricechopperCoupons: 'https://uat-pch-platform-master-web.unataops.com',
    dailySurprisePath:
      'https://gmteurope4.gametation.com/p/pc2dev/?customerToken=',
    greenMountainPath: 'https://www.greenmountainenergy.com/market32',
    communitySolarNYPath:
      'https://greenmountainenergy.powermarket.io/heuvelton.html',
    communitySolarMAPath:
      'https://greenmountainenergy.powermarket.io/overlook.html',
    travelsPath:
      'https://claimrewardmiles.mileageplus.com/united/claim.html#claim_codes',
    allTravelListPath: 'https://pricechopper.travelgoldcard.com/browse/',
  },
};

const sandbox2 = {
  accountInfo: {
    clientId: 'loyalty_connect',
    redirectUri: 'https://rewards.pricechopper.com',
    authBaseUrl: 'https://sandbox-dim-api.spinwheel.io',
    NODE_ENV: 'sandbox2',
    appId: '164477602254704',
    ssoClientId: 'f499baeb-b011-4344-baa8-65572607c1ad',
    tenant: 'developersso',
    knownAuthorities: ['developersso.b2clogin.com'],
    surveyMonkeyLink: 'https://www.surveymonkey.com/r/C957CWQ',
    policies: {
      signUpPolicy: 'B2C_1A_SIGNUP',
      signInPolicy: 'B2C_1A_signup_signin',
      resetPasswordPolicy: 'B2C_1A_PASSWORDRESET',
    },
    REACT_APP_SITE_KEY: '6Le4UZEbAAAAAGqJRhbsP827j_gztWghm5Xm7nmL',
    REACT_APP_SECRET_KEY: '6Le4UZEbAAAAADFcLrrvc8W_Z2p6TD_jKUKPMY3a',
    magazinesPath:
      'https://pricechopper.magazinegoldcard.com/login/memberlogin.aspx',
    allMagazineListPath: 'https://pricechopper.magazinegoldcard.com/browse/',
    giftCardsPath: 'http://shopgiftcards.pricechopper.com/',
    pricechopperCoupons: 'https://uat-pch-platform-master-web.unataops.com',
    dailySurprisePath:
      'https://gmteurope4.gametation.com/p/pc2dev/?customerToken=',
    greenMountainPath: 'https://www.greenmountainenergy.com/market32',
    communitySolarNYPath:
      'https://greenmountainenergy.powermarket.io/heuvelton.html',
    communitySolarMAPath:
      'https://greenmountainenergy.powermarket.io/overlook.html',
    travelsPath:
      'https://claimrewardmiles.mileageplus.com/united/claim.html#claim_codes',
    allTravelListPath: 'https://pricechopper.travelgoldcard.com/browse/',
  },
};

const prod = {
  accountInfo: {
    clientId: 'loyalty_connect',
    redirectUri: 'https://rewards.pricechopper.com',
    authBaseUrl: 'https://dim-api.spinwheel.io',
    NODE_ENV: 'prod',
    appId: '164477602254704',
    ssoClientId: 'cdbb0892-872d-45a1-beed-e6741603eeae',
    tenant: 'pricechopperrewards',
    knownAuthorities: ['pricechopperrewards.b2clogin.com'],
    surveyMonkeyLink: 'https://www.surveymonkey.com/r/YVPGZ3Y',
    policies: {
      signUpPolicy: 'B2C_1A_SIGNUP',
      signInPolicy: 'B2C_1A_signup_signin',
    },
    REACT_APP_SITE_KEY: '6Le4UZEbAAAAAGqJRhbsP827j_gztWghm5Xm7nmL',
    REACT_APP_SECRET_KEY: '6Le4UZEbAAAAADFcLrrvc8W_Z2p6TD_jKUKPMY3a',
    magazinesPath:
      'https://pricechopper.magazinegoldcard.com/login/memberlogin.aspx',
    allMagazineListPath: 'https://pricechopper.magazinegoldcard.com/browse/',
    giftCardsPath: 'http://shopgiftcards.pricechopper.com/',
    pricechopperCoupons: 'https://shop.pricechopper.com',
    dailySurprisePath:
      'https://gmteurope4.gametation.com/p/pricechop2prod/?customerToken=',
    greenMountainPath: 'https://www.greenmountainenergy.com/market32',
    communitySolarNYPath:
      'https://www.arcadia.com/community-solar/lp-market32?utm_source=nrg&utm_medium=website&promo=gme-ny-m32-web',
    communitySolarMAPath:
      'https://greenmountainenergy.powermarket.io/overlook.html',
    travelsPath:
      'https://claimrewardmiles.mileageplus.com/united/claim.html#claim_codes',
    allTravelListPath: 'https://pricechopper.travelgoldcard.com/browse/',
  },
};

const sandbox3 = {
  accountInfo: {
    clientId: 'loyalty_connect',
    redirectUri: 'https://sandbox3-pricechopper.spinwheel.io',
    authBaseUrl: 'https://dim-api.spinwheel.io',
    NODE_ENV: 'prod',
    appId: '164477602254704',
    ssoClientId: '54d24323-aa97-4a19-8390-609d9ef23355',
    tenant: 'pricechopperrewards',
    knownAuthorities: ['pricechopperrewards.b2clogin.com'],
    surveyMonkeyLink: 'https://www.surveymonkey.com/r/YVPGZ3Y',
    policies: {
      signUpPolicy: 'B2C_1A_SIGNUP',
      signInPolicy: 'B2C_1A_signup_signin',
    },
    REACT_APP_SITE_KEY: '6Le4UZEbAAAAAGqJRhbsP827j_gztWghm5Xm7nmL',
    REACT_APP_SECRET_KEY: '6Le4UZEbAAAAADFcLrrvc8W_Z2p6TD_jKUKPMY3a',
    magazinesPath:
      'https://pricechopper.magazinegoldcard.com/login/memberlogin.aspx',
    allMagazineListPath: 'https://pricechopper.magazinegoldcard.com/browse/',
    giftCardsPath: 'http://shopgiftcards.pricechopper.com/',
    pricechopperCoupons: 'https://uat-pch-platform-master-web.unataops.com',
    dailySurprisePath:
      'https://gmteurope3.gametation.com/p/pchopper/?customerToken=',
    greenMountainPath: 'https://www.greenmountainenergy.com/market32',
    communitySolarNYPath:
      'https://greenmountainenergy.powermarket.io/heuvelton.html',
    communitySolarMAPath:
      'https://greenmountainenergy.powermarket.io/overlook.html',
    travelsPath:
      'https://claimrewardmiles.mileageplus.com/united/claim.html#claim_codes',
    allTravelListPath: 'https://pricechopper.travelgoldcard.com/browse/',
  },
};

let config = import.meta.env.MODE === 'production' ? prod : dev;

if (import.meta.env.MODE === 'production') {
  config = prod;
} else if (import.meta.env.MODE === 'qa') {
  config = qa;
} else if (import.meta.env.MODE === 'qa2') {
  config = qa2;
} else if (import.meta.env.MODE === 'sandbox') {
  config = sandbox;
} else if (import.meta.env.MODE === 'sandbox2') {
  config = sandbox2;
} else if (import.meta.env.MODE === 'sandbox3') {
  config = sandbox3;
} else {
  config = dev;
}

export default {
  ...config,
};
