export const config = {
  accountInfo: {
    clientId: 'loyalty_connect',
    appId: '295256691978389',
    ssoClientId: 'dd322bb6-8ced-4083-a80a-13a544ceb94c',
    knownAuthorities: ['pricechopperdevsso.b2clogin.com'],
    tenant: 'pricechopperdevsso',
    surveryMonkeyLink: 'https://www.surveymonkey.com/r/C8FRSW7',
  },
};

export const BREAKPOINTS = {
  MOBILE: 768,
  TABLET: 992,
};
