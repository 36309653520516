import PropTypes from 'prop-types';
import React from 'react';

import styleConsts from '../util/styleConsts';

export default class Icon extends React.Component {
  render() {
    const { width, height, stroke, className, ...rest } = this.props;
    let classNameString = `${className} svg-${this.props.icon}`;
    const colorStyleName = styleConsts.styleValueToStyleName(this.props.color);
    if (colorStyleName) {
      classNameString += ` ${this.props.colorClassName}-${colorStyleName}`;
    }
    const colorAuxStyleName = styleConsts.styleValueToStyleName(
      this.props.colorAux
    );
    if (colorAuxStyleName) {
      classNameString += ` ${this.props.colorAuxClassName}-${colorAuxStyleName}`;
    }
    const colorBorderStyleName = styleConsts.styleValueToStyleName(
      this.props.colorBorder
    );
    if (colorBorderStyleName) {
      classNameString += ` ${this.props.colorBorderClassName}-${colorBorderStyleName}`;
    }
    if (this.props.iconType) {
      classNameString += ` svg-icon-type-${this.props.iconType}`;
    }

    switch (this.props.icon) {
      case 'angle-left': {
        return (
          <svg
            className={classNameString}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 9 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.365 8.28255L8.555 1.89435C8.935 1.49636 8.935 0.853459 8.555 0.455473C8.175 0.0574857 7.545 0.0574857 7.165 0.455473L0.285 7.55801C-0.095 7.95599 -0.095 8.5989 0.285 8.99688L7.165 16.0994C7.355 16.2933 7.605 16.3954 7.855 16.3954C8.105 16.3954 8.355 16.2933 8.545 16.0994C8.925 15.7014 8.925 15.0585 8.545 14.6605L2.365 8.28255Z"
              fill={this.props.color}
            />
          </svg>
        );
      }
      case 'balance-bolt': {
        return (
          <svg
            className={classNameString}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 33 33"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <ellipse
              opacity="0.2"
              cx="16.5"
              cy="17.1"
              rx="16"
              ry="16"
              fill={styleConsts.white}
            />
            {/* <circle
              opacity='0.2'
              cx='16.5'
              cy='16.5'
              r='16.5'
              fill={styleConsts.white}
            /> */}
            <path
              className={this.props.colorClassName}
              fillRule="evenodd"
              clipRule="evenodd"
              fill={styleConsts.white}
              d="M20.1715 13H18.3337V10C18.3337 9.09 17.7704 8.3 16.9669 8.07C16.81 8.02 16.653 8 16.496 8C15.8495 8 15.24 8.37 14.9076 9.01L11.2413 16.01C10.9181 16.63 10.9181 17.39 11.2505 18.01C11.583 18.63 12.1832 19.01 12.8389 19.01H14.6767V22.01C14.6767 22.92 15.24 23.71 16.0435 23.94C16.182 23.98 16.339 24 16.496 24C17.1424 24 17.7519 23.63 18.0844 22.99L21.7137 16.06C21.8892 15.75 22 15.39 22 14.99C22 13.9 21.1873 13 20.1715 13ZM16.496 22V17H12.8297L16.496 10V15H20.1622L16.496 22Z"
            />
            <path
              className={this.props.colorClassName}
              fill={styleConsts.white}
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.1715 13H18.3337V10C18.3337 9.09 17.7704 8.3 16.9669 8.07C16.81 8.02 16.653 8 16.496 8C15.8495 8 15.24 8.37 14.9076 9.01L11.2413 16.01C10.9181 16.63 10.9181 17.39 11.2505 18.01C11.583 18.63 12.1832 19.01 12.8389 19.01H14.6767V22.01C14.6767 22.92 15.24 23.71 16.0435 23.94C16.182 23.98 16.339 24 16.496 24C17.1424 24 17.7519 23.63 18.0844 22.99L21.7137 16.06C21.8892 15.75 22 15.39 22 14.99C22 13.9 21.1873 13 20.1715 13ZM16.496 22V17H12.8297L16.496 10V15H20.1622L16.496 22Z"
            />
          </svg>
        );
      }
      case 'bolt': {
        return (
          <svg
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 15 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill={this.props.color}
              d="M12.1731 6.5625H9.73385V2.625C9.73385 1.43062 8.98615 0.39375 7.91977 0.091875C7.71139 0.02625 7.50302 0 7.29465 0C6.43663 0 5.62765 0.485625 5.18639 1.32562L0.320238 10.5131C-0.108767 11.3269 -0.108767 12.3244 0.332496 13.1381C0.773759 13.9519 1.57048 14.4506 2.44075 14.4506H4.87996V18.3881C4.87996 19.5825 5.62765 20.6194 6.69404 20.9212C6.8779 20.9737 7.08627 21 7.29465 21C8.15266 21 8.96164 20.5144 9.4029 19.6744L14.22 10.5788C14.4529 10.1719 14.6 9.69938 14.6 9.17437C14.6 7.74375 13.5214 6.5625 12.1731 6.5625ZM7.29465 18.375V11.8125H2.42849L7.29465 2.625V9.1875H12.1608L7.29465 18.375Z"
            />
          </svg>
        );
      }
      case 'close-o': {
        return (
          <svg
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 51 51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={this.props.className}
          >
            <circle cx="25.5" cy="25.5" r="25.5" fill={styleConsts.silver} />
            <circle cx="25.5" cy="25.5" r="20.5" fill={styleConsts.cobalt} />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19.7929 29.2214C19.4024 29.612 19.4024 30.2451 19.7929 30.6357C20.1834 31.0262 20.8166 31.0262 21.2071 30.6357L25.2143 26.6285L29.2214 30.6357C29.612 31.0262 30.2451 31.0262 30.6357 30.6357C31.0262 30.2451 31.0262 29.612 30.6357 29.2214L26.6285 25.2143L30.6357 21.2071C31.0262 20.8166 31.0262 20.1834 30.6357 19.7929C30.2451 19.4024 29.612 19.4024 29.2214 19.7929L25.2143 23.8001L21.2071 19.7929C20.8166 19.4024 20.1834 19.4024 19.7929 19.7929C19.4024 20.1834 19.4024 20.8166 19.7929 21.2071L23.8001 25.2143L19.7929 29.2214Z"
              fill={styleConsts.lightGrey}
            />
          </svg>
        );
      }
      case 'daily surprise': {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={this.props.width}
            height={this.props.height}
            stroke={stroke || styleConsts.white}
            fill={stroke || styleConsts.white}
            strokeWidth="1"
            viewBox="0 0 50 50"
          >
            <path
              className="st0"
              d="M4.9,26.6c-0.4,0.5-0.5,1.2-0.4,1.9c0.1,0.7,0.5,1.2,1,1.6l3.8,2.7c0.4,0.3,1,0.5,1.5,0.5c0.8,0,1.5-0.4,2-1
              l0.9-1.2v11.3c0,1.4,1.1,2.5,2.5,2.5h8.5c0.1,0,14.6,0,14.6,0c1.4,0,2.5-1.1,2.5-2.5V24.5c0-1.4-1.1-2.5-2.5-2.5h-8.7h-5.6h-4.6
              l8-11c0.8-1.1,0.6-2.7-0.6-3.5l-3.8-2.7c-1.1-0.8-2.7-0.6-3.5,0.6c0,0,0,0,0,0l-1.1,1.5c-0.3-0.8-0.9-1.4-1.6-1.9
              C16.6,4.1,15.3,3.8,14,4c-1.3,0.2-2.4,0.9-3.2,2c-1,1.4-1.2,3.1-0.6,4.7l1.3,4.6l-4.8,0.2c-1.7-0.1-3.3,0.7-4.2,2
              c-0.8,1.1-1.1,2.4-0.9,3.7c0.2,1.3,0.9,2.4,2,3.2c0.7,0.5,1.5,0.8,2.3,0.9L4.9,26.6z M39.1,24.5v17.9H16V27.7l2.3-3.1H39.1z
              M12.8,7.5c0.5-0.7,1.2-1,2-1c0.5,0,1,0.1,1.4,0.5c0.5,0.4,0.9,0.9,1,1.6c0.1,0.6,0,1.3-0.4,1.8c0,0,0,0,0,0c0,0,0,0,0,0l-2.8,3.9
              l-1.3-4.3c0,0,0-0.1,0-0.1C12.2,9,12.3,8.1,12.8,7.5z M14.4,17.8C14.4,17.8,14.4,17.8,14.4,17.8L14.4,17.8l0.4-0.5l0.1-0.1
              c0,0,0,0,0,0l3.9-5.4c0,0,0,0,0,0c0,0,0,0,0,0l3.6-4.9l-1-0.7l4.8,3.5L10.7,30.8l-3.8-2.7l3.6-4.9c0,0,0,0,0,0c0,0,0,0,0,0
              L14.4,17.8z M5.1,22.2c-0.5-0.4-0.9-0.9-1-1.6C4,20,4.2,19.4,4.5,18.9c0.5-0.6,1.2-1,2-1c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0l4.5-0.1
              l-2.8,3.9c0,0,0,0,0,0c0,0,0,0,0,0c-0.4,0.5-0.9,0.9-1.6,1C6.2,22.8,5.6,22.6,5.1,22.2z"
            />
            <path
              className="st0"
              d="M38.3,16.3c0.1,0,0.2,0.1,0.3,0.1c0.6,0.1,1.2-0.3,1.4-0.9l1.5-4.5c0.2-0.7-0.1-1.5-0.9-1.7
              C40.1,9,39.3,9.4,39,10.1l-1.5,4.5C37.2,15.3,37.6,16.1,38.3,16.3z"
            />
            <path
              className="st0"
              d="M44,20.7c0.2,0,0.5-0.1,0.7-0.3l2.2-1.7c0.6-0.5,0.7-1.3,0.3-1.9c-0.5-0.6-1.3-0.7-1.9-0.3l-2.2,1.7
              c-0.6,0.5-0.7,1.3-0.3,1.9C43.1,20.5,43.6,20.7,44,20.7z"
            />
            <path
              className="st0"
              d="M31,16.2c0.2,0.5,0.5,0.8,1,0.9c0.2,0.1,0.5,0,0.7,0c0.7-0.2,1.1-1,0.9-1.7l-0.9-2.6c-0.2-0.7-1-1.1-1.7-0.9
              c-0.7,0.2-1.1,1-0.9,1.7L31,16.2z"
            />
            <path
              className="st4"
              d="M23.7,29.6c0.5-2,2-3.1,4-3.1c1.9,0,3.7,1.2,3.7,3.4c0,1.8-2.8,3.8-2.8,5.4v1h-2.2v-1.9c0-0.9,2.5-3.5,2.5-4.6
              c0-0.7-0.6-1.1-1.2-1.1c-0.8,0-1.6,0.9-1.7,1.7L23.7,29.6z M26.2,40.5V38h2.5v2.5H26.2z"
            />
          </svg>
        );
      }
      case 'daily-surprises': {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 45 45"
          >
            <g id="cart" transform="translate(-34 -26)">
              <g id="Group_2" data-name="Group 2">
                <circle
                  id="Ellipse_1"
                  data-name="Ellipse 1"
                  cx="22.5"
                  cy="22.5"
                  r="22.5"
                  transform="translate(34 26)"
                  fill="#266cb2"
                ></circle>
              </g>
              <svg
                width="30"
                height="30"
                x="42"
                y="33"
                fill={stroke || styleConsts.white}
                stroke={stroke || styleConsts.white}
                strokeWidth="1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 50 50"
              >
                <path
                  className="st0"
                  d="M4.9,26.6c-0.4,0.5-0.5,1.2-0.4,1.9c0.1,0.7,0.5,1.2,1,1.6l3.8,2.7c0.4,0.3,1,0.5,1.5,0.5c0.8,0,1.5-0.4,2-1
                  l0.9-1.2v11.3c0,1.4,1.1,2.5,2.5,2.5h8.5c0.1,0,14.6,0,14.6,0c1.4,0,2.5-1.1,2.5-2.5V24.5c0-1.4-1.1-2.5-2.5-2.5h-8.7h-5.6h-4.6
                  l8-11c0.8-1.1,0.6-2.7-0.6-3.5l-3.8-2.7c-1.1-0.8-2.7-0.6-3.5,0.6c0,0,0,0,0,0l-1.1,1.5c-0.3-0.8-0.9-1.4-1.6-1.9
                  C16.6,4.1,15.3,3.8,14,4c-1.3,0.2-2.4,0.9-3.2,2c-1,1.4-1.2,3.1-0.6,4.7l1.3,4.6l-4.8,0.2c-1.7-0.1-3.3,0.7-4.2,2
                  c-0.8,1.1-1.1,2.4-0.9,3.7c0.2,1.3,0.9,2.4,2,3.2c0.7,0.5,1.5,0.8,2.3,0.9L4.9,26.6z M39.1,24.5v17.9H16V27.7l2.3-3.1H39.1z
                  M12.8,7.5c0.5-0.7,1.2-1,2-1c0.5,0,1,0.1,1.4,0.5c0.5,0.4,0.9,0.9,1,1.6c0.1,0.6,0,1.3-0.4,1.8c0,0,0,0,0,0c0,0,0,0,0,0l-2.8,3.9
                  l-1.3-4.3c0,0,0-0.1,0-0.1C12.2,9,12.3,8.1,12.8,7.5z M14.4,17.8C14.4,17.8,14.4,17.8,14.4,17.8L14.4,17.8l0.4-0.5l0.1-0.1
                  c0,0,0,0,0,0l3.9-5.4c0,0,0,0,0,0c0,0,0,0,0,0l3.6-4.9l-1-0.7l4.8,3.5L10.7,30.8l-3.8-2.7l3.6-4.9c0,0,0,0,0,0c0,0,0,0,0,0
                  L14.4,17.8z M5.1,22.2c-0.5-0.4-0.9-0.9-1-1.6C4,20,4.2,19.4,4.5,18.9c0.5-0.6,1.2-1,2-1c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0l4.5-0.1
                  l-2.8,3.9c0,0,0,0,0,0c0,0,0,0,0,0c-0.4,0.5-0.9,0.9-1.6,1C6.2,22.8,5.6,22.6,5.1,22.2z"
                />
                <path
                  className="st0"
                  d="M38.3,16.3c0.1,0,0.2,0.1,0.3,0.1c0.6,0.1,1.2-0.3,1.4-0.9l1.5-4.5c0.2-0.7-0.1-1.5-0.9-1.7
                  C40.1,9,39.3,9.4,39,10.1l-1.5,4.5C37.2,15.3,37.6,16.1,38.3,16.3z"
                />
                <path
                  className="st0"
                  d="M44,20.7c0.2,0,0.5-0.1,0.7-0.3l2.2-1.7c0.6-0.5,0.7-1.3,0.3-1.9c-0.5-0.6-1.3-0.7-1.9-0.3l-2.2,1.7
                  c-0.6,0.5-0.7,1.3-0.3,1.9C43.1,20.5,43.6,20.7,44,20.7z"
                />
                <path
                  className="st0"
                  d="M31,16.2c0.2,0.5,0.5,0.8,1,0.9c0.2,0.1,0.5,0,0.7,0c0.7-0.2,1.1-1,0.9-1.7l-0.9-2.6c-0.2-0.7-1-1.1-1.7-0.9
                  c-0.7,0.2-1.1,1-0.9,1.7L31,16.2z"
                />
                <path
                  className="st4"
                  d="M23.7,29.6c0.5-2,2-3.1,4-3.1c1.9,0,3.7,1.2,3.7,3.4c0,1.8-2.8,3.8-2.8,5.4v1h-2.2v-1.9c0-0.9,2.5-3.5,2.5-4.6
                  c0-0.7-0.6-1.1-1.2-1.1c-0.8,0-1.6,0.9-1.7,1.7L23.7,29.6z M26.2,40.5V38h2.5v2.5H26.2z"
                />
              </svg>
            </g>
          </svg>
        );
      }
      case 'daily-surprises-trophy': {
        return (
          <svg
            width={this.props.width}
            height={this.props.height}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 45 45"
          >
            <g data-name="Layer 2">
              <g data-name="Layer 1">
                <g opacity="0.1">
                  <circle
                    fil={styleConsts.white}
                    cx="22.5"
                    cy="22.5"
                    r="22.5"
                  />
                </g>
                <svg
                  width="16"
                  height="16"
                  x="14"
                  y="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15 2H13C13 0.9 12.1 0 11 0H5C3.9 0 3 0.9 3 2H1C0.45 2 0 2.45 0 3V6C0 6.97 0.75 8.71 3.4 8.95C4.04 10.44 5.38 11.57 7 11.9V14H5C4.45 14 4 14.45 4 15C4 15.55 4.45 16 5 16H11C11.55 16 12 15.55 12 15C12 14.45 11.55 14 11 14H9V11.9C10.63 11.57 11.97 10.45 12.6 8.95C15.25 8.71 16 6.97 16 6V3C16 2.45 15.55 2 15 2ZM3 6.88C2.11 6.64 2.01 6.1 2 5.97V4H3V6.88ZM11 7C11 8.66 9.66 10 8 10C6.34 10 5 8.66 5 7V2H11V7ZM13 6.88V4H14V5.97C13.99 6.1 13.89 6.64 13 6.88Z"
                    fill="white"
                  />
                </svg>
              </g>
            </g>
          </svg>
        );
      }
      default: {
        return (
          <svg
            className="svg-default"
            xmlns="http://www.w3.org/2000/svg"
            width="200"
            height="100"
          >
            <text fontSize="25">
              <tspan x="0" dy="1.2em">
                ICON
              </tspan>
              <tspan x="0" dy="1.2em">
                FAILURE
              </tspan>
            </text>
          </svg>
        );
      }
    }
  }
}

Icon.defaultProps = {
  children: null,
  classificationHeight: '30px',
  classificationId: 'default',
  classificationWidth: '30px',
  color: styleConsts.white,
  colorClassName: 'svg-color',
  colorAux: styleConsts.grayDark,
  colorAuxClassName: 'svg-colorAux',
  colorBorder: styleConsts.blue,
  colorBorderClassName: 'svg-colorBorder',
  height: '30px',
  iconType: '',
  includeSolutionBorder: undefined,
  standardSolutionName: '',
  style: null,
  time: '',
  width: '30px',
  x: '0',
  y: '0',
  className: 'inline',
};
Icon.propTypes = {
  children: PropTypes.element,
  classificationHeight: PropTypes.string,
  classificationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  classificationWidth: PropTypes.string,
  color: PropTypes.string,
  colorClassName: PropTypes.string,
  colorAux: PropTypes.string,
  colorAuxClassName: PropTypes.string,
  colorBorder: PropTypes.string,
  colorBorderClassName: PropTypes.string,
  height: PropTypes.string,
  icon: PropTypes.string.isRequired,
  iconType: PropTypes.string,
  includeSolutionBorder: PropTypes.bool,
  standardSolutionName: PropTypes.string,
  style: PropTypes.object,
  time: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  width: PropTypes.string,
  x: PropTypes.string,
  y: PropTypes.string,
  className: PropTypes.string,
};
