import DataProvider, { queryClient } from './App';
import providers from './providers';
import instance, {
  setAccessToken,
  getAccessToken,
} from './utils/axiosInstance';

export const {
  useUserInfo,
  useMoneyTransferTxns,
  useMoneyTransferTxnSLP,
  usePartnerConfiguration,
  useExtraImpact,
  useAuth,
  useFeatureFlags,
  useDIM,
  useDisconnectStudentLoan,
  usePartner,
  useCampaigns,
  useUnAuthToken,
  useUpsertPartnerAccountSso,
  useCurrencyExchanges,
  useGenerateTokenPublic,
  usePartnerConfig,
  useGenerateToken,
  useUser,
  useDigitalDiscounts,
  useSweepstakeCampaigns,
  useTransaction,
  useReservation,
} = providers;

export {
  DataProvider,
  queryClient,
  setAccessToken,
  getAccessToken,
  instance as loyaltyAxios,
};
