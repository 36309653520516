import jwtDecode from 'jwt-decode';
import { TOKEN_KEY, UN_AUTH_TOKEN_KEY, TOKEN_TYPE } from './constants';
import { loadedInIframe } from './dropinUtils';

export const setToken = (token) => {
  if (!loadedInIframe()) {
    const decoded = token && jwtDecode(token);
    if (decoded?.tokenType === TOKEN_TYPE.NON_SIGNIN) {
      window.sessionStorage.setItem(UN_AUTH_TOKEN_KEY, token);
    } else {
      window.sessionStorage.setItem(TOKEN_KEY, token);
    }
  }
};
export const getToken = () =>
  !loadedInIframe() &&
  (window.sessionStorage.getItem(TOKEN_KEY) ||
    window.sessionStorage.getItem(UN_AUTH_TOKEN_KEY));
