import axiosInstance from '../utils/axiosInstance';

const getCampaigns = ({ signal }) => {
  const promise = axiosInstance
    .get('/api/sweepstakeCampaigns', {
      signal,
    })
    .then((response) => {
      return {
        data: response?.data?.data,
      };
    });

  return promise;
};

export default getCampaigns;
