import { useContext, useState, useEffect } from 'react';
import { CampaignsContext } from '../../contexts/CampaignsContext';

import {
  CAMPAIGN_STATUS,
  WINNER_SELECTION_INFO_TYPE,
  WINNER_SELECTION_INFO_STATUS,
} from '../constants';
import { formatDate, dateString, numToWords } from '../formatters';

const useCampaigns = () => {
  const campaignsState = useContext(CampaignsContext);
  if (!campaignsState) {
    throw new Error('Undefined campaigns state!');
  }
  const {
    campaignsData,
    calculateExchangeData,
    bonusPointsTxnsStatus,
    campaignsStatus,
    bonusPointsTxn,
    bonusPointsError,
    bonusSweepstakeMutation,
  } = campaignsState;
  const activeCampaigns = campaignsData.active;
  // console.log("🚀 ~ useCampaigns ~ campaignsData:", campaignsData)
  const upComingCampaigns = campaignsData.pending;
  const completedCampaigns = campaignsData.completed;

  const [officialDocInfo, setOfficialDocInfo] = useState();

  useEffect(() => {
    if (activeCampaigns) {
      let weeklyPrizeAmount = 0;
      let description;
      let numOfWinners = 0;
      let isWeeklyWinners = false;
      const weeklyPrizesDates = new Set();

      activeCampaigns?.map(({ drawings }) => {
        drawings.map(
          ({ status, type, prizes, numberOfWinners, selectionTime }) => {
            if (type === WINNER_SELECTION_INFO_TYPE.INTERMEDIATE) {
              description = prizes?.[0]?.description;
              numOfWinners += numberOfWinners;
              isWeeklyWinners = true;
              weeklyPrizeAmount = prizes?.[0]?.amount || 0;
              if (status === WINNER_SELECTION_INFO_STATUS.PENDING) {
                weeklyPrizesDates.add(selectionTime);
              }
            }
          }
        );
      });

      const startDate = formatDate(new Date(activeCampaigns?.[0]?.startTime));
      const startDateString = dateString(activeCampaigns?.[0]?.startTime);
      const endDate = dateString(activeCampaigns?.[0]?.endTime);
      const endDateString = dateString(activeCampaigns?.[0]?.endTime);
      const minimumAgeActiveCampaign = activeCampaigns?.[0]?.details?.ageLimit;
      const minimumAgeInNumbersActiveCampaign = numToWords(
        minimumAgeActiveCampaign
      );
      const winnerListSubmissionDate = dateString(
        activeCampaigns?.[0]?.details?.winnerListSubmissionTime
      );
      setOfficialDocInfo({
        description,
        numOfWinners,
        isWeeklyWinners,
        startDate,
        startDateString,
        endDate,
        endDateString,
        weeklyPrizeAmount,
        weeklyPrizesDates: Array.from(weeklyPrizesDates),
        minimumAgeActiveCampaign,
        minimumAgeInNumbersActiveCampaign,
        winnerListSubmissionDate,
      });
    }
  }, [activeCampaigns]);

  const getCampaign = (campaignId) => {
    if (activeCampaigns.length > 0) {
      return activeCampaigns.find(({ _id }) => _id === campaignId);
    } else {
      return completedCampaigns.find(({ _id }) => _id === campaignId);
    }
  };

  return {
    officialDocInfo,
    activeCampaigns,
    upComingCampaigns,
    completedCampaigns,
    bonusPointsTxnsStatus,
    campaignsStatus,
    getCampaign,
    calculateExchange: calculateExchangeData?.calculateExchange,
    bonusPointsTxn,
    bonusPointsError,
    bonusSweepstakeMutation,
  };
};

export default useCampaigns;
