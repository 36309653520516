import React from 'react';
import { Button } from 'react-bootstrap';

import {
  POST_MESSAGE_EVENT,
  TOKEN_EXPIRED_PAGE_EXIT_BUTTON_LABEL,
  TOKEN_EXPIRED_PAGE_SUB_TITLE,
  TOKEN_EXPIRED_PAGE_TITLE,
} from '../util/constants';
import { parseJwt } from '../util/utils';

import './TokenExpired.scss';

export default function TokenExpired(props) {
  const configDataProps = props?.configDataProps || {};
  const tokenExpiredPageTitle =
    configDataProps?.tokenExpiredPageTitle || TOKEN_EXPIRED_PAGE_TITLE;
  const tokenExpiredPageSubTitle =
    configDataProps?.tokenExpiredPageSubTitle || TOKEN_EXPIRED_PAGE_SUB_TITLE;
  const tokenExpiredPageExitBtnLabel =
    configDataProps?.tokenExpiredPageExitBtnLabel ||
    TOKEN_EXPIRED_PAGE_EXIT_BUTTON_LABEL;
  const isPartnerConfigLoaded = configDataProps?.partner?.name;
  const tokenExpiredPageIcon =
    configDataProps?.tokenExpiredPageIcon ||
    'https://cdn.spinwheel.io/images/icons/session_expired_icon.svg';

  function exitDIMInstance() {
    const decoded = configDataProps?.accessToken
      ? parseJwt(configDataProps?.accessToken)
      : {};
    const redirectUri = decoded.redirectUri;
    configDataProps?.postMessageToParent &&
      configDataProps.postMessageToParent('exit', {
        eventName: POST_MESSAGE_EVENT.DROPIN_EXIT.EVENT_NAME,
        message: POST_MESSAGE_EVENT.DROPIN_EXIT.EVENT_MESSAGE,
        redirectUri,
      });
  }

  return (
    <div
      className={`timeout-alert ${
        isPartnerConfigLoaded ? 'timeout-alert-fallback' : ''
      }`}
    >
      {tokenExpiredPageIcon && (
        <img src={tokenExpiredPageIcon} alt="session_expired" />
      )}

      <h3>{tokenExpiredPageTitle}</h3>
      <hr />
      <p>{tokenExpiredPageSubTitle}</p>
      <Button className="btn mt-3" onClick={exitDIMInstance}>
        {tokenExpiredPageExitBtnLabel}
      </Button>
    </div>
  );
}
