import React, { useEffect, useContext, useState } from 'react';
import { Container, Col, Button, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { AppContext } from '@rewards/contexts';
import Icon from '../../assets/svg';
import { useResponsive } from '../../util/customHooks';
import { EVENTS } from '../../analytics/events';

import styleConsts from '../../util/styleConsts';
import './BackNav.scss';

export default (WrappedComponent) => {
  const BackNav = ({ ...props }) => {
    const history = useHistory();
    const { isDesktop } = useResponsive();
    const { track } = useContext(AppContext.AppContext);
    const [hideTopNav, setHideTopNav] = useState(true);

    useEffect(() => {
      setHideTopNav(WrappedComponent?.hideTopNav);
    }, [WrappedComponent?.hideTopNav]);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    const handleBackPropagation = () => {
      track(EVENTS.DONATE_BACK_CLICKED, {
        location: WrappedComponent.EVENT_LOCATION,
        ui_version: import.meta.env.APP_VERSION,
      });
      history.push(WrappedComponent.landingPath);
    };

    return (
      <>
        {isDesktop ? (
          <div className="flex justify-center">
            <Col md={hideTopNav ? 12 : 10} className="mt-4 p-0 mb-2">
              <WrappedComponent {...props} />
            </Col>
          </div>
        ) : (
          <>
            <Container fluid className="back-nav">
              <Row>
                <Col xs="1" sm="1" md="1" className="back-nav-heading">
                  <span onClick={handleBackPropagation} className="hover">
                    <Icon icon="angle-left" width="9" height="17" />
                  </span>
                </Col>
                <Col
                  xs="10"
                  sm="10"
                  md="10"
                  className="back-nav-heading text-center"
                >
                  <span className="">Donate</span>
                </Col>
              </Row>
            </Container>
            <WrappedComponent {...props} />
          </>
        )}
      </>
    );
  };

  return BackNav;
};
