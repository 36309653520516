import { APP_URLS } from '../util/constants';

export default function logError(message, context = {}) {
  return fetch(APP_URLS.LOGGING, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      data: {
        logInfo: {
          ...context,
          message,
        },
      },
    }),
  });
}
