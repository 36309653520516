export const GiftCardIcon = ({
  width = '30px',
  height = '30px',
  className = 'stroke-cobalt',
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 309 199.8"
      fill="none"
      strokeWidth="1"
    >
      <path
        d="M372.3,308.9H93.8a15.13,15.13,0,0,1-15.2-15.2V124.3a15.19,15.19,0,0,1,15.2-15.2H372.3a15.19,15.19,0,0,1,15.2,15.2V293.7A15.26,15.26,0,0,1,372.3,308.9Z"
        transform="translate(-78.5 -109.1)"
        fill="#13569b"
      />
      <polygon
        points="309 65.6 92 65.6 92 0 72.5 0 72.5 65.6 0 65.6 0 85.1 72.5 85.1 72.5 199.8 92 199.8 92 85.1 309 85.1 309 65.6"
        fill="#61a247"
      />
      <path
        d="M192.9,192.1l-20.6-2.4a11.84,11.84,0,0,0-14.8-12.1l-6-20.3c-3.6-12-19.3-13-28.7-1.8l-9.4,11.2c-9.4,11.2-5.7,26.5,6.8,27.9l22.1,2.6a11.92,11.92,0,0,0,4.2,8.7,12,12,0,0,0,9,2.6l6.2,20.9c3.6,12,19.3,13,28.7,1.8l9.4-11.2C209.1,208.9,205.4,193.6,192.9,192.1Z"
        transform="translate(-78.5 -109.1)"
        fill="#002c52"
      />
      <path
        d="M192.9,192.1l-20.6-2.4a11.84,11.84,0,0,0-14.8-12.1l-6-20.3c-.1-.4-.3-.7-.4-1.1v18.4H109c-2.7,8.5.7,17.1,8.5,19.5h33.6v14.1a12.34,12.34,0,0,0,4.3.3l6.2,20.9a12.77,12.77,0,0,0,9,9V194h28.2A17.79,17.79,0,0,0,192.9,192.1Z"
        transform="translate(-78.5 -109.1)"
        fill="#124b27"
      />
      <path
        d="M116.7,158.3l9.4-11.2c9.4-11.2,25.1-10.2,28.7,1.8l12.6,42.4-44-5.1C111,184.8,107.3,169.5,116.7,158.3Z"
        transform="translate(-78.5 -109.1)"
        fill="#61a247"
      />
      <path
        d="M193.7,222.9l9.4-11.2c9.4-11.2,5.7-26.5-6.8-27.9l-44-5.1,12.6,42.4C168.6,233.1,184.3,234.1,193.7,222.9Z"
        transform="translate(-78.5 -109.1)"
        fill="#61a247"
      />
      <path
        d="M148.3,156.8l19.2,34.6h0L154.9,149c-3.6-12-19.3-13-28.7-1.8l-2,2.4C129.6,143,142.8,147,148.3,156.8Z"
        transform="translate(-78.5 -109.1)"
        fill="#7bae66"
      />
      <path
        d="M130.1,178.4c-10.6-3.7-16.8-16-11.4-22.5l-2,2.4c-9.4,11.2-5.7,26.5,6.8,27.9l44,5.1Z"
        transform="translate(-78.5 -109.1)"
        fill="#288540"
      />
      <path
        d="M171.6,213.2l-19.2-34.6L165,221c3.6,12,19.3,13,28.7,1.8l2-2.4C190.2,227,177,223,171.6,213.2Z"
        transform="translate(-78.5 -109.1)"
        fill="#288540"
      />
      <path
        d="M196.3,183.7l-44-5.1h0l37.4,12.9c10.6,3.7,16.8,16,11.4,22.5l2-2.4C212.5,200.4,208.8,185.1,196.3,183.7Z"
        transform="translate(-78.5 -109.1)"
        fill="#7bae66"
      />
      <path
        d="M171.2,193.4c-6,7.2-15.6,9-21.4,4.1s-5.7-14.6.3-21.8,15.6-9,21.4-4.1S177.2,186.3,171.2,193.4Z"
        transform="translate(-78.5 -109.1)"
        fill="#61a247"
      />
      <path
        d="M166.2,178c5.8,4.9,8,11.8,5,15.5,6-7.2,6.2-16.9.3-21.8s-15.4-3-21.4,4.1C153.2,172.1,160.4,173.1,166.2,178Z"
        transform="translate(-78.5 -109.1)"
        fill="#7bae66"
      />
      <path
        d="M155.1,191.2c-5.8-4.9-8-11.8-5-15.5-6,7.2-6.2,16.9-.3,21.8s15.4,3,21.4-4.1C168.1,197.1,160.9,196.1,155.1,191.2Z"
        transform="translate(-78.5 -109.1)"
        fill="#288540"
      />
    </svg>
  );
};
