import React from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';

import './OverLayToolTip.scss';

const OverLayToolTip = ({
  placement = 'top',
  tooltipText,
  target,
  showTooltip,
}) => {
  return (
    <Overlay
      target={target.current}
      show={showTooltip}
      placement={placement}
      delay={{ show: 250, hide: 400 }}
      trigger="focus"
    >
      {(props) => (
        <Tooltip className="points-tooltip" {...props}>
          {tooltipText}
        </Tooltip>
      )}
    </Overlay>
  );
};

export default OverLayToolTip;
