import axios from 'axios';
import authAxios from './authAxios';

export const fetchCurrencyExchanges = async () => {
  return authAxios.get('/api/currencyExchanges').then((response) => {
    if (response?.status === 200) {
      const { data } = response.data;
      return data;
    }
  });
};

export const fetchPartnerData = async () => {
  return authAxios.get(`/api/partners`).then((response) => {
    if (response?.status === 200) {
      const { data } = response.data;
      return data;
    }
  });
};

export const fetchUserProfile = async () => {
  return axios.get('/api/v2/user/profile').then((response) => {
    if (response?.status === 200) {
      const { data } = response;
      return data;
    }
  });
};

export const upsertPartnerAccount = async (requestBody) => {
  return axios
    .post(`/api/users/upsertPartnerAccount/${requestBody.id}`, {
      data: requestBody,
    })
    .then((response) => {
      if (response?.status === 200) {
        const { data } = response;
        return data?.status?.data?.token;
      }
    });
};

export const fetchUserSessions = async () => {
  const data = {
    binary: '',
    binary_version: '',
    is_retina: false,
    os_version: navigator.platform,
    pixel_density: devicePixelRatio,
    push_token: '',
    screen_height: screen.height,
    screen_width: screen.width,
  };
  return axios.post('/api/v2/user_sessions', data).then((response) => {
    if (response?.status === 201) {
      const { data } = response;
      return data?.session_token;
    }
  });
};

export const fetchLoyaltyCardsInfo = async () => {
  return axios.get('api/v2/loyalty_cards');
};

export const validateAndGenerateToken = async (userId, code, extUserId) => {
  return axios
    .post('/api/users/validateAndGenerateToken', {
      data: {
        userId,
        extUserId,
        code,
      },
    })
    .then((response) => {
      if (response?.status === 200) {
        const { data } = response;
        return data?.data?.token;
      }
    });
};

export const fetchFeatureFlag = async () => {
  return axios.get('/api/featureflags').then((response) => {
    if (response?.status === 200) {
      const { data } = response?.data;
      return data;
    }
  });
};

export const upsertPartnerAccountSso = async ({
  extension_CustomerId,
  email,
  name,
  cardNumber,
  isRegistered,
  registeredType,
  identityProvider,
  isMigrated,
}) => {
  const customerIdentifier = isMigrated ? extension_CustomerId : email;
  return axios
    .post(`/api/users/upsertPartnerAccountSso/${customerIdentifier}`, {
      data: {
        name,
        email,
        cardNumber,
        isRegistered,
        registeredType,
        identityProvider,
      },
    })
    .then((response) => {
      if (response?.status === 200) {
        const { data } = response?.data;
        return data?.token;
      }
    })
    .catch((error) => {
      throw error.response.data;
    });
};

export const unauthorizedToken = async () => {
  return axios
    .post(`/api/auth/generateToken`, { data: { tokenType: 'NON_SIGNIN' } })
    .then((response) => {
      if (response?.status === 200) {
        const { data } = response?.data;
        return data.token;
      }
    })
    .catch((error) => {
      throw error.response.data;
    });
};
