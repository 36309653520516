import React from 'react';
import dataReducer from './dataReducer';
import { ACTION_TYPES } from './actionTypes';

const useAsync = (initialState) => {
  const [state, dispatch] = React.useReducer(dataReducer, initialState);

  const mounted = React.useRef();
  const safeDispatch = React.useCallback(
    (data) => {
      return mounted.current && dispatch(data);
    },
    [mounted.current, dispatch]
  );
  React.useEffect(() => {
    mounted.current = true;
    return () => (mounted.current = false);
  }, []);

  const run = React.useCallback(
    (promise) => {
      if (!promise) {
        return;
      }
      safeDispatch({ type: ACTION_TYPES.PENDING });
      return promise.then(
        (data) => {
          safeDispatch({ type: ACTION_TYPES.RESOLVED, data });
        },
        (error) => {
          safeDispatch({ type: ACTION_TYPES.REJECTED, error });
        },
        []
      );
    },
    [safeDispatch]
  );
  const reset = React.useCallback(() => {
    safeDispatch({ type: ACTION_TYPES.RESET, data: null });
  }, [safeDispatch]);
  return { ...state, run, reset };
};

export default useAsync;
