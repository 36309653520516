import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Row,
  Col,
  Badge,
  Container,
  Overlay,
  Tooltip,
  Alert,
  Modal,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import {
  AppContext,
  PartnerContext,
  DailySurpriseContext,
  ACTIONS,
  AuthContext,
} from '@rewards/contexts';
import { useUserInfo, useReservation } from '@rewards/data-provider';

const { useApp } = AppContext;
const { usePartner } = PartnerContext;

const { useDailySurprise } = DailySurpriseContext;

import { useCampaigns } from '../../util/hooks';
import { CampaignContext } from '../../contexts';
import { CampaignsContext } from '../../contexts/CampaignsContext';

import BackNav from '../BackNav';
import { GrandPrizesCarousal } from '../PrizesModals';
import ConfirmModal from '../ConfirmModal';
import WeeklyPrize from './WeeklyPrize';
import Slider from './Slider';
import SweepstakeOfficialRules from '../SweepstakeOfficialRules';

import Icon from '../../assets/svg';
import {
  dateString,
  daysRemaining,
  getNewYorkDateString,
  sortOnKey,
} from '../../util/formatters';
import useResponsive from '../../util/hooks/useResponsive';
import {
  WINNER_SELECTION_INFO_STATUS,
  WINNER_SELECTION_INFO_TYPE,
  CAMPAIGN_STATUS,
} from '../../util/constants';
import { getSweepstakesPath } from '../../util/paths';
import { EVENTS, PAGES, EVENT_LOCATION } from '../../analytics/events';
import { useUserEntries } from '../../api/useUserEntries';
import './Campaign.scss';
import DailySupriseConfirmation from '../DailySupriseConfirmation/DailySurpriseConfirmation';

const { useAuth } = AuthContext;

const Campaign = () => {
  const { unAuthToken } = useAuth();
  const [isEntriesClosed, setIsEntriesClosed] = useState(false);
  const [entriesHoldData, setEntriesHoldData] = useState();
  const [advantPoints, setAdvantPoints] = useState(0);
  const [currentAdvantPointsEntries, setCurrentAdvantPointsEntries] =
    useState(0);

  const { dailySurpriseEvent } = useDailySurprise(
    DailySurpriseContext.DailySurpriseContext
  );
  const [advantPointsMax, setAdvantPointsMax] = useState(0);
  const [userSweepStakeCampaign, setUserSweepStakeCampaign] = useState({});
  const [currencyExchange, setCurrencyExchange] = useState(null);
  const [completedInfo, setCompletedInfo] = useState([]);
  const [pendingInfo, setPendingInfo] = useState([]);
  const [activeCampaign, setActiveCampaign] = useState();
  const [isActiveCampaign, setIsActiveCampaign] = useState(false);
  const [showTooltip, setTooltip] = useState(false);
  const [showMaxLimitToolTip, setMaxLimitToolTip] = useState(false);
  const [showMaxLimitToolTipAlt, setMaxLimitToolTipAlt] = useState(false);
  const [showTxnErrorToolTip, setTxnErrorToolTip] = useState(false);
  const [txnErrorTooltipMessage, setTxnErrorTooltipMessage] = useState(
    ACTIONS.TXN_ERROR.INTERNAL
  );
  const [isDailySurprise, setIsDailySurprise] = useState(false);
  const [voucherCode, setVoucherCode] = useState(0);
  const [realTotalEntries, setRealTotalEntries] = useState(0);

  const params = useParams();
  const target = useRef(null);

  const queryString = new URL(document.location).searchParams;
  const reservationId = queryString.get('reservationId');
  const { data: reservationInfo, ...rest } = useReservation(reservationId);
  const { isDesktop } = useResponsive();
  const { currencyExchanges } = useApp();
  const { data: userInfo } = useUserInfo();
  const { data: userEntries, status: entriesStatus } = useUserEntries(
    params.id
  );

  const { data: user } = userInfo || {};
  const { activeCampaigns, completedCampaigns, getCampaign } = useCampaigns();
  const { partner } = usePartner();
  const entriesLimit =
    partner?.configuration?.sweepstakeConfig?.paidEntriesLimitPerDay;

  const { getCalculateExchange } = useContext(CampaignsContext);
  const { track, source, landingPath } = useContext(AppContext.AppContext);

  useEffect(() => {
    Campaign.landingPath = getSweepstakesPath();
    Campaign.showBackBtn = true;
  }, []);

  useEffect(() => {
    if (
      currencyExchange &&
      reservationInfo?.data &&
      reservationInfo.data.redeem === false &&
      Object.keys(dailySurpriseEvent).length > 0
    ) {
      // OPEN THE MODAL
      const { sourceDebitIncrements } = currencyExchange;

      if (sourceDebitIncrements) {
        setAdvantPoints(dailySurpriseEvent.ammount * sourceDebitIncrements);
        setCurrentAdvantPointsEntries(dailySurpriseEvent.ammount);
        setVoucherCode(dailySurpriseEvent.code);
        setIsDailySurprise(true);
      }
    }
  }, [reservationInfo, currencyExchange]);

  useEffect(() => {
    if (activeCampaigns?.length === 1 && !completedCampaigns?.length) {
      Campaign.landingPath = landingPath;
    }
  }, [activeCampaigns?.length, completedCampaigns?.length]);

  useEffect(() => {
    if (partner && activeCampaign && user && currencyExchanges) {
      const filteredCurrencyExchange = currencyExchanges.filter(
        (ce) =>
          ce.sourceCurrencyId === partner?.currencyId &&
          ce.destCurrencyId === activeCampaign?.currencyId
      );

      setCurrencyExchange(filteredCurrencyExchange[0]);
      setAdvantPointsMax(
        Math.min(
          filteredCurrencyExchange[0]?.sourceDebitMax,
          user?.partnerAccounts[0]?.accountBalance
        )
      );
      setCurrentAdvantPointsEntries(0);
      getCalculateExchange(filteredCurrencyExchange[0]);
    }
  }, [partner, activeCampaign, user, currencyExchanges]);

  useEffect(() => {
    if (activeCampaign) {
      const today = new Date();
      if (activeCampaign?.entriesCloseTime <= today) {
        setIsEntriesClosed(true);
      }

      const entriesHoldData = activeCampaign?.entriesHoldTime?.find(
        ({ startTime, endTime }) => {
          return startTime <= today && today <= endTime;
        }
      );
      if (entriesHoldData) {
        setEntriesHoldData(entriesHoldData);
      }

      Campaign.track = () => {
        // Back Click track
        track(EVENTS.SWEEPSTAKES_BACK_CLICKED, {
          id: activeCampaign?._id,
          name: activeCampaign?.name,
          location: EVENT_LOCATION.SWEEPSTAKES_LANDING_PAGE,
          ui_version: import.meta.env.APP_VERSION,
        });
      };
    }
  }, [activeCampaign]);

  useEffect(() => {
    if (activeCampaigns && completedCampaigns) {
      source(PAGES.SWEEPSTAKES, { ui_version: import.meta.env.APP_VERSION });
      const campaign = getCampaign(params.id);

      if (campaign) {
        track(EVENTS.SWEEPSTAKES_VIEWED, {
          id: campaign?._id,
          name: campaign?.name,
          ui_version: import.meta.env.APP_VERSION,
        });
        setActiveCampaign(campaign);

        const [completedInfo, pendingInfo] = campaign.drawings.reduce(
          (result, info) => {
            if (
              info.status === WINNER_SELECTION_INFO_STATUS.COMPLETED_SUCCESS
            ) {
              result[0].push(info);
            } else if (info.status === WINNER_SELECTION_INFO_STATUS.PENDING) {
              result[1].push(info);
            }
            return result;
          },
          [[], []]
        );

        const sortedPendingInfo = sortOnKey(pendingInfo, 'selectionTime');
        const sortedCompletedInfo = sortOnKey(completedInfo, 'selectionTime');
        setCompletedInfo(sortedCompletedInfo);
        setPendingInfo(sortedPendingInfo);

        const isActiveCampaign = campaign?.status === CAMPAIGN_STATUS.ACTIVE;
        setIsActiveCampaign(isActiveCampaign);
        if (!isActiveCampaign) {
          const pendingInfo = campaign.drawings.filter(
            ({ type }) => type === WINNER_SELECTION_INFO_TYPE.INTERMEDIATE
          );
          const sortedPendingInfo = sortOnKey(pendingInfo, 'selectionTime');
          setPendingInfo(sortedPendingInfo);
        }
      }
    }
  }, [activeCampaigns, completedCampaigns]);

  useEffect(() => {
    if (showTooltip) {
      const timer = setTimeout(() => {
        setTooltip(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showTooltip]);

  const resetValue = () => {
    setAdvantPoints(0);
    setCurrentAdvantPointsEntries(0);
    setAdvantPointsMax(
      Math.min(
        currencyExchange?.sourceDebitMax,
        user?.partnerAccounts[0]?.accountBalance
      )
    );
  };

  useEffect(() => {
    if (showTxnErrorToolTip) {
      const timer = setTimeout(() => {
        resetValue();
        setTxnErrorToolTip(false);
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [showTxnErrorToolTip]);

  useEffect(() => {
    if (showMaxLimitToolTip) {
      const timer = setTimeout(() => {
        setMaxLimitToolTip(false);
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [showMaxLimitToolTip]);
  useEffect(() => {
    if (showMaxLimitToolTipAlt) {
      const timer = setTimeout(() => {
        setMaxLimitToolTipAlt(false);
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [showMaxLimitToolTipAlt]);

  useEffect(() => {
    if (activeCampaign && user) {
      const sweepstakeCampaignsAccount = user.sweepstakeCampaigns.filter(
        ({ campaignId }) => campaignId === activeCampaign?._id
      );
      if (sweepstakeCampaignsAccount.length) {
        setUserSweepStakeCampaign(sweepstakeCampaignsAccount[0]);
      }
    }
  }, [activeCampaign, user]);

  const handleSliderAdvantPoints = (value) => {
    const limitPerDay = entriesLimit - userEntries.todayEntries;
    //  console.log(realTotalEntries + 1 > limitPerDay);
    const entries = value / currencyExchange?.sourceDebitIncrements;
    if (entries > limitPerDay) {
      setMaxLimitToolTip(true);
      const points = limitPerDay * currencyExchange?.sourceDebitIncrements;
      setAdvantPoints(points);
      setCurrentAdvantPointsEntries(limitPerDay);
      // setRealTotalEntries(limitPerDay);
      return;
    }
    setAdvantPoints(value);
    setCurrentAdvantPointsEntries(
      value / currencyExchange?.sourceDebitIncrements || 0
    );
    // setRealTotalEntries(value / currencyExchange?.sourceDebitIncrements || 0);
  };

  const handlePoints = (shouldDecrease = false) => {
    const { sourceDebitIncrements } = currencyExchange;
    const limitPerDay = entriesLimit - userEntries?.todayEntries;
    let currentAdvantPoints = advantPoints;
    let calculatedPoints = 0;
    if (shouldDecrease) {
      calculatedPoints = currentAdvantPoints - sourceDebitIncrements;
    } else {
      calculatedPoints = currentAdvantPoints + sourceDebitIncrements;
    }
    const calculatedEntries = calculatedPoints / sourceDebitIncrements;

    if (!shouldDecrease && calculatedEntries > limitPerDay) {
      setMaxLimitToolTip(true);
      return;
    }
    if (!shouldDecrease && advantPointsMax < sourceDebitIncrements) {
      setTooltip(true);
      return;
    }

    if (!shouldDecrease && limitPerDay < currentAdvantPointsEntries) {
      currentAdvantPoints = advantPoints - sourceDebitIncrements;
      setMaxLimitToolTipAlt(true);
    }

    if (calculatedPoints < 0) {
      setAdvantPoints(0);
      setCurrentAdvantPointsEntries(0);
    } else if (calculatedPoints <= advantPointsMax) {
      setAdvantPoints(calculatedPoints);
      setCurrentAdvantPointsEntries(calculatedPoints / sourceDebitIncrements);
    }
  };
  function renderSweepstakeActions() {
    return (
      <Row className={`${isDesktop ? 'bg-white pt-3 pb-4' : ''}`}>
        <Col md={12} sm={12} lg={8} xl={8}>
          {isDesktop && (
            <div className="grand-prize-card desktop-content-desc-campaign">
              <div className="grand-prize-desktop-title mb-1 text-center">
                {activeCampaign?.drawings[0]?.prizes[0]?.title
                  .split('\\n')
                  .map((val, index, arr) =>
                    index !== arr.length - 1 ? (
                      <>
                        {val} <br />{' '}
                      </>
                    ) : (
                      val
                    )
                  )}
              </div>
              <div className="font-weight-normal grand-prize-desktop-description mt-2 mb-0 text-center">
                {activeCampaign?.drawings[0]?.prizes[0]?.description}
                <br />
                {activeCampaign?.drawings[0]?.campaign?.text ? (
                  <a
                    href={activeCampaign?.drawings[0]?.campaign?.url}
                    target="_blank"
                  >
                    {activeCampaign?.drawings[0]?.campaign?.text || ''}
                  </a>
                ) : null}
              </div>
              <Col className="flex justify-center text-center official-rule pb-2 mt-2">
                <SweepstakeOfficialRules />
              </Col>
            </div>
          )}
        </Col>
        <Col
          md={12}
          sm={12}
          lg={4}
          xl={4}
          className={`${isDesktop ? 'rounded' : 'campaign-actions'}`}
        >
          <div className="flex justify-content-center align-items-center plus-minus-points">
            <div onClick={() => handlePoints(true)} className="mx-n4">
              <Icon icon="minus-updated" width="61" height="61" />
            </div>
            <div className="points my-auto px-1" ref={target}>
              {' '}
              <Icon icon="balance-bolt" width="40" height="40" />
              <span className="pl-1 mt-1">{advantPoints}</span>
            </div>
            <div onClick={() => handlePoints(false)} className="mx-n4">
              <Icon icon="plus-updated" width="61" height="61" />
            </div>
          </div>
          <Col className="more-prize-info mt-2 mb-3">
            <span className="end-title">
              {isActiveCampaign ? 'Ends On:' : 'Ended On:'}
            </span>
            <span className="end-text ml-2 mb-0">
              {getNewYorkDateString(activeCampaign?.endTime, 'MMMM dd, yyyy')}
            </span>
            <p className="end-text">
              {daysRemaining(activeCampaign?.endTime)} remaining
            </p>
          </Col>
          <Overlay
            target={target.current}
            show={showTooltip}
            placement="top"
            delay={{ show: 250, hide: 400 }}
          >
            {(props) => (
              <Tooltip className="points-tooltip" {...props}>
                Minimum points should be{' '}
                {currencyExchange?.sourceDebitIncrements}
              </Tooltip>
            )}
          </Overlay>
          <Overlay
            target={target.current}
            show={showTxnErrorToolTip}
            placement="top"
            delay={{ show: 250, hide: 400 }}
          >
            {(props) => (
              <Tooltip className="points-tooltip" {...props}>
                {txnErrorTooltipMessage}
              </Tooltip>
            )}
          </Overlay>
          <Overlay
            target={target.current}
            show={showMaxLimitToolTip}
            placement="top"
            delay={{ show: 250, hide: 400 }}
          >
            {(props) => (
              <Tooltip className="points-tooltip" {...props}>
                You’ve reached the daily limit of {entriesLimit} entries! Please
                come back tomorrow to enter again.
              </Tooltip>
            )}
          </Overlay>
          <Overlay
            target={target.current}
            show={showMaxLimitToolTipAlt}
            placement="top"
            delay={{ show: 250, hide: 400 }}
          >
            {(props) => (
              <Tooltip className="points-tooltip" {...props}>
                You’ve reached the limit of {entriesLimit} entries!
              </Tooltip>
            )}
          </Overlay>
          {currentAdvantPointsEntries > 0 && (
            <Col className="mb-4 mt-2">
              <h6 className="m-0">
                {currentAdvantPointsEntries} more{' '}
                {currentAdvantPointsEntries > 1 ? 'entries' : 'entry'}!
              </h6>
            </Col>
          )}
          <Slider handleSliderAdvantPoints={handleSliderAdvantPoints} />
          <Col
            className="text-center end-text pt-4 pb-4"
            sm={12}
            md={12}
            xs={12}
            id="totalPoints"
          >
            You currently have:{' '}
            <span className="text-green">
              {userEntries?.totalEntries || 0} entries
            </span>
          </Col>

          {isDesktop && (
            <div className={`${isDesktop ? '' : 'py-4'}`}>
              <Col
                className={`w-100 flex justify-center ${
                  isDesktop ? '' : 'my-4'
                } p-0`}
              >
                <ConfirmModal />
              </Col>
            </div>
          )}
        </Col>
      </Row>
    );
  }

  function renderMobileView() {
    return (
      <>
        <CampaignAlert
          isEntriesClosed={isEntriesClosed}
          entriesHoldData={entriesHoldData}
        />
        <Row className="text-center flex campaign campaing-blue-box">
          {/* {!isDesktop && unAuthToken?.status === ACTIONS.STATUS.RESOLVED &&
          <Col className='signup-unauth-text py-3'>Link your AdvantEdge Card to start redeeming your points towards sweepstakes!</Col>
        } */}
          <Col
            md={12}
            sm={12}
            lg={8}
            xl={8}
            className={`campaing-blue-box text-white ${
              isDesktop
                ? 'campaign-desktop-name my-auto'
                : 'campaign-name pt-4 pb-4'
            }`}
          >
            <div className="">
              {/* {activeCampaign?.name.split('\\n').map((attribute, index, arr) => {
            const isLast = arr.length === (index + 1);
            return !isLast ? <>{attribute}<br /></> : attribute;
          })} */}
              {activeCampaign?.drawings[0]?.type ===
              WINNER_SELECTION_INFO_TYPE.GRAND
                ? 'Grand Prizes'
                : 'Weekly Prizes'}
              <p className="campaign-subtext pt-3 mb-1">
                Use your points to enter for a chance to win
              </p>
              <p className="campaign-subtext mb-subtext mb-1">
                100 points = 1 entry
              </p>
            </div>
          </Col>

          <Col md={12} sm={12} lg={4} xl={4} className={'p-0 bg-white'}>
            <GrandPrizesCarousal
              campaign={activeCampaign}
              isActiveCampaign={isActiveCampaign}
            />
            {activeCampaign?.sponsor?.title && (
              <Col
                className={`prize-info my-3 py-4 p-0 ${isDesktop ? '' : ''}`}
                id="scrollToPosition"
              >
                <Col
                  className={`campaign-sponsor px-0  ${
                    activeCampaign.sponsor.logo ? '' : 'justify-content-center'
                  }`}
                  sm={12}
                  md={12}
                  xs={12}
                >
                  <span
                    className={`campaign-sponsor-title ${
                      isDesktop ? 'campaign-sponsor-title-desktop' : ''
                    } campaign-sponsor px-0  ${
                      activeCampaign.sponsor.logo ? '' : 'mx-0'
                    }`}
                  >
                    {activeCampaign.sponsor.title}{' '}
                  </span>
                  {activeCampaign.sponsor.logo && (
                    <img
                      src={`${activeCampaign.sponsor.logo}`}
                      height="99"
                      width="99"
                      alt="sponsor"
                    />
                  )}
                </Col>
              </Col>
            )}
            {!isDesktop && renderSweepstakeActions()}
            {(pendingInfo.length > 0 || completedInfo.length > 0) && (
              <WeeklyPrize />
            )}

            {!isDesktop && (
              <Col className="text-center flex justify-center official-rule pb-2 mt-2">
                <SweepstakeOfficialRules />
              </Col>
            )}

            {!isDesktop && (
              <>
                <Col className="slot-margin"></Col>
                <Col className="enter-sweepstake fixed-bottom flex justify-center">
                  <ConfirmModal />
                </Col>
              </>
            )}
          </Col>
        </Row>
      </>
    );
  }

  function renderDesktopView() {
    return (
      <div className="campaign text-center d-flex justify-content-between">
        <Col md="12" className="p-0">
          {renderMobileView()}
          {renderSweepstakeActions()}
        </Col>
      </div>
    );
  }

  return (
    <Container className="campaign-container">
      <div className="p-3">
        <CampaignContext.Provider
          value={{
            isActiveCampaign,
            activeCampaign,
            advantPoints,
            currentAdvantPointsEntries,
            advantPointsMax,
            currencyExchange,
            campaignId: activeCampaign?._id,
            userSweepStakeCampaign,
            pendingInfo,
            completedInfo,
            resetValue,
            setTooltip,
            setMaxLimitToolTip,
            setTxnErrorToolTip,
            setTxnErrorTooltipMessage,
            isEntriesClosed,
            entriesHoldData,
          }}
        >
          {isDesktop ? renderDesktopView() : renderMobileView()}
          <DailySupriseConfirmation
            isDailySurprise={isDailySurprise}
            voucherCode={voucherCode}
            redeemed={() => setIsDailySurprise(false)}
            dailySurpriseEvent={dailySurpriseEvent}
          />
        </CampaignContext.Provider>
      </div>
    </Container>
  );
};

const CampaignAlert = ({ isEntriesClosed, entriesHoldData }) => {
  const [showAlert, setshowAlert] = useState(false);
  const handleCloseAlert = (event) => {
    event.stopPropagation();
    setshowAlert(false);
  };

  useEffect(() => {
    if (isEntriesClosed || entriesHoldData) {
      setshowAlert(isEntriesClosed);
    }
  }, [isEntriesClosed, entriesHoldData]);
  return (
    <Alert
      show={showAlert}
      className="p-2 rounded hover my-1 completed-campaign-alert"
    >
      {isEntriesClosed && <>Sweepstakes entries closed</>}
      {!isEntriesClosed && entriesHoldData && (
        <>
          Entries for sweepstake has been hold till {entriesHoldData?.endTime}
        </>
      )}

      <span
        className="position-absolute alert-close hover"
        onClick={handleCloseAlert}
      >
        <Icon icon="close-o" width="20" height="20" />
      </span>
    </Alert>
  );
};

Campaign.shouldLandOnLandingPath = true;
export default BackNav(Campaign, { landingPath: '/' });
