import { useContext } from 'react';
import { LoyaltyConnectContext } from '../../contexts/LoyaltyConnectContext';

const useLoyaltyConnect = () => {
  const loyaltyConnectState = useContext(LoyaltyConnectContext);
  if (!loyaltyConnectState) throw new Error('Undefined loyaltyConnect state!');
  const {
    sidenavData,
    sideNavSecureData,
    data: registryData,
    status,
    offerCampaignsIsUserValidData,
    offerCampaignsIssueRewardsStatus,
    userInfoByCardNumber,
    userInfoByEmail,
  } = loyaltyConnectState;

  return {
    sidenav: sidenavData?.sidenav,
    sidenavSecure: sideNavSecureData?.sidenavSecure,
    registry: registryData?.registry,
    registryStatus: status,
    userInfoByCardNumber,
    userInfoByEmail,
    offerCampaignsIsUserValid:
      offerCampaignsIsUserValidData?.offerCampaignsIsUserValid,
    offerCampaignsIssueRewardsStatus,
  };
};

export default useLoyaltyConnect;
