import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useUserInfo } from '@rewards/data-provider';
import { useAsync, ACTIONS, AuthContext } from '@rewards/contexts';

import TravelsContext from './TravelsContext';

const { useAuth } = AuthContext;
const TravelsContextState = (
  { children, fetchTravels, fetchTravelsUnAuthorized },
  ...props
) => {
  const { checkValidToken, unAuthToken } = useAuth();
  const { data: user } = useUserInfo();

  const asyncETravels = useAsync({
    status: ACTIONS.STATUS.IDLE,
    data: { eTravels: null },
  });

  const {
    data: eTravelsData,
    run: runAsyncETravels,
    status: travelsStatus,
  } = asyncETravels;

  useEffect(() => {
    if (user) {
      checkValidToken().then(() => {
        runAsyncETravels(
          fetchTravels(user?.data?._id).then((data) => ({
            eTravels: data,
          }))
        );
      });
    }
  }, [user]);

  useEffect(() => {
    if (unAuthToken?.status === ACTIONS.STATUS.RESOLVED) {
      checkValidToken().then(() => {
        runAsyncETravels(
          fetchTravelsUnAuthorized().then((data) => ({
            eTravels: data,
          }))
        );
      });
    }
  }, [unAuthToken?.status]);

  if (travelsStatus === ACTIONS.STATUS.IDLE) {
    return (
      <div className="text-center spinner">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }
  return (
    <TravelsContext.Provider
      value={{
        eTravelsData,
      }}
      {...props}
    >
      {children}
    </TravelsContext.Provider>
  );
};
export default TravelsContextState;
