import React, { useEffect, useContext } from 'react';
import { Modal, Col } from 'react-bootstrap';
import { AppContext } from '@rewards/contexts';

import ModalCloseBtn from '../../SelectSchoolModals/ModalCloseBtn';
import { EarnPointsNavLinks, EarnPointsInfo } from '../components';

import { useResponsive } from '../../../util/hooks';
import { PAGES, EVENT_LOCATION, EVENTS } from '../../../analytics/events';
import './EarnModal.scss';

const EarnModal = ({ show, handleClose, handleInviteFriends }) => {
  const { track, source } = useContext(AppContext.AppContext);

  const { isDesktop } = useResponsive();
  useEffect(() => {
    if (show) {
      source(PAGES.TOOLS_FOR_SCHOOLS_EARN_STARS_MODAL, {
        ui_version: import.meta.env.APP_VERSION,
      });
    }
  }, [show]);

  const handleCloseModal = () => {
    track(EVENTS.TOOLS_FOR_SCHOOLS_CLOSE_CLICKED, {
      location: EVENT_LOCATION.TOOLS_FOR_SCHOOLS_EARN_STARS_MODAL,
      ui_version: import.meta.env.APP_VERSION,
    });
    handleClose();
  };
  return (
    <Modal
      show={show}
      centered
      backdrop="static"
      keyboard={false}
      contentClassName="tfs-earn-modal-content"
      dialogClassName={`${isDesktop ? '' : 'tfs-mobile-dialog mx-auto'}`}
    >
      <Modal.Header className={`border-0 ${isDesktop ? 'w-75 mx-auto' : ''}`}>
        <Col className="tfs-earn-modal-title">
          Check out more ways to earn stars!
        </Col>
        <ModalCloseBtn handleClose={handleCloseModal} />
      </Modal.Header>
      {/*TODO:  Make Smaller !Not Urgent*/}
      <Modal.Body
        className={`${isDesktop && 'w-75 mx-auto'}`}
        style={{ overflowY: 'auto' }}
      >
        <EarnPointsNavLinks handleInviteFriends={handleInviteFriends} />
      </Modal.Body>
      <Modal.Footer className={`border-0 mb-5 ${isDesktop && 'w-75 mx-auto'}`}>
        <EarnPointsInfo />
      </Modal.Footer>
    </Modal>
  );
};

export default EarnModal;
