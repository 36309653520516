import { useMsal } from '@azure/msal-react';
import Button from '../../lib/Button';
import { HTMLProps } from 'react';

interface ILoginButtonProps {
  className?: HTMLProps<HTMLElement>['className'];
  style?: HTMLProps<HTMLElement>['style'];
  onClick?: () => void;
}

export const LoginButton = ({
  className,
  style,
  onClick,
  children,
}: React.PropsWithChildren<ILoginButtonProps>) => {
  const { instance } = useMsal();
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    //@ts-ignore
    instance.config.auth.authority = instance.config.auth.loginAuthority;
    instance.loginRedirect({
      //@ts-ignore
      ...instance.config.auth.loginRequest,
      extraQueryParameters: {
        //@ts-ignore
        anonymousId: window?.analytics?.user?.()?.anonymousId?.(),
      },
    });
  };

  return (
    <Button className={className} style={style} onClick={handleClick}>
      {children || 'Log in/Register'}
    </Button>
  );
};

export default LoginButton;
