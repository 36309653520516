import { ACTION_TYPES, STATUS } from './actionTypes';

const dataReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.PENDING: {
      return { status: STATUS.PENDING, data: action?.data, error: null };
    }
    case ACTION_TYPES.RESET: {
      return { status: STATUS.IDLE, data: null, error: null };
    }
    case ACTION_TYPES.RESOLVED: {
      return { status: STATUS.RESOLVED, data: action.data, error: null };
    }
    case ACTION_TYPES.REJECTED: {
      return { status: STATUS.REJECTED, data: null, error: action.error };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export default dataReducer;
