import React, { useContext, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { AuthContext } from '@rewards/contexts';
import { BackNav } from '@rewards/ui';

import LandingDashboard from './components/LandingDashboard';
import DonateDashboard from './components/DonateDashboard';
import SelectedSchoolDasboard from './components/SelectedSchoolDasboard';
import EarnDashboard from './components/EarnPoints/EarnDashboard';
import SignUp from './components/SignUp';
import ComingSoonDashboard from './components/ComingSoonDashboard';

import { ToolsForSchoolsContextState } from './contexts';
import { SchoolContextState } from './contexts/SchoolContexts';

import {
  getToolsForSchoolsPath,
  getSelectedSchoolDashboardPath,
  getDonateDashboardPath,
  getEarnDashboardPath,
  getSignUpPath,
  getComingSoonPath,
} from './util/paths';
import {
  fetchToolsForSchools,
  fetchSchoolForUser,
  fetchSchools,
  fetchSchoolDetails,
  updateConnectSchool,
  fetchStarsViaSocialShare,
  fetchLinkReferralUser,
  validateCardNumber,
} from './services/toolsForSchoolsService';

// import 'bootstrap/dist/css/bootstrap.min.css'
import './App.scss';
import './styles.css';

const App = () => {
  const { envInfo } = useContext(AuthContext.AuthContext);

  useEffect(() => {
    window?.FB?.init?.({
      appId: envInfo?.appId,
      autoLogAppEvents: true,
      xfbml: true,
      version: 'v10.0',
    });
  }, []);

  return (
    <ToolsForSchoolsContextState
      fetchToolsForSchools={fetchToolsForSchools}
      fetchSchoolForUser={fetchSchoolForUser}
      fetchSchools={fetchSchools}
      validateCardNumber={validateCardNumber}
    >
      <SchoolContextState
        updateConnectSchool={updateConnectSchool}
        fetchSchoolDetails={fetchSchoolDetails}
        fetchStarsViaSocialShare={fetchStarsViaSocialShare}
        fetchLinkReferralUser={fetchLinkReferralUser}
      >
        <BackNav
          to="/"
          module="Tools for Schools"
          event="Back Nav Click - Tools for Schools"
        >
          <Switch>
            <Route
              exact
              path={getToolsForSchoolsPath()}
              component={LandingDashboard}
            />
            <Route
              exact
              path={getComingSoonPath()}
              component={ComingSoonDashboard}
            />
            <Route exact path={getSignUpPath()} component={SignUp} />
            <Route
              exact
              path={getSelectedSchoolDashboardPath(':id')}
              component={SelectedSchoolDasboard}
            />
            <Route
              exact
              path={getEarnDashboardPath(':id')}
              component={EarnDashboard}
            />
            <Route
              exact
              path={getDonateDashboardPath(':id')}
              component={DonateDashboard}
            />
            <Route
              path="*"
              render={() => <Redirect to={getToolsForSchoolsPath()} />}
            />
          </Switch>
        </BackNav>
      </SchoolContextState>
    </ToolsForSchoolsContextState>
  );
};

export default App;
