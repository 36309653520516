import React, { useState } from 'react';

import DailySurpriseContext from './DailySurpriseContext';

const DailySurpriseContextState = ({ children }, ...props) => {
  const [dailySurpriseEvent, setDailySurpriseEvent] = useState({});
  const [data, setData] = useState();

  return (
    <DailySurpriseContext.Provider
      value={{
        data,
        setData,
        dailySurpriseEvent,
        setDailySurpriseEvent,
      }}
      {...props}
    >
      {children}
    </DailySurpriseContext.Provider>
  );
};
export default DailySurpriseContextState;
