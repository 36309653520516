// import { CancelToken } from 'axios'
import axiosInstance from '../utils/axiosInstance';
const useTransaction = (requestParams = null) => {
  const promise = axiosInstance
    .post('/v1/transaction', requestParams)
    .then((response) => ({ data: response?.data }));

  return promise;
};

export default useTransaction;
