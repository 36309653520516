import { LOYALTY_CONNECT_MODULES } from '../util/constants';
import { constructModuleContainer } from './moduleContainerFactory';

export const constructComponentRegistry = ({
  response,
  isCodeLoaded,
  ...props
}) => {
  const moduleContainer = constructModuleContainer(props);
  const componentList = response.features.map((feature) => {
    let component = moduleContainer.get(feature.component);

    if (typeof component.register === 'object')
      return {
        ...component.register,
        ...feature,
        havePath: true,
      };

    return {
      ...moduleContainer.get(feature.component).register({
        isCodeLoaded,
      }),
      ...feature,
      isNew: feature.isNew,
    };
  });
  const componentRegistry = componentList.reduce((red, acc, index) => {
    if (acc.path) {
      componentList[index].havePath = true;
    }

    if (!red[acc.parent]) {
      red[acc.parent] = [];
      red[acc.parent].tabs = [];
      red[acc.parent].primaryTab = acc.label;
    }
    red[acc.parent].tabs = [...(red[acc.parent].tabs || []), acc];
    return red;
  }, {});

  return componentRegistry;
};
