import React from 'react';
import { ACTIONS, AuthContext } from '@rewards/contexts';
import BannerContent from './banner-content';
import useModule from '../../utils/hooks/useModule';
import useUserBonusPoints from '../../utils/hooks/useUserBonusPoints';

const BonusPointsBanner = ({ className, style, small }) => {
  const { tokenStatus } = AuthContext.useAuth();
  const module = useModule();
  const isAuthenticated = tokenStatus === ACTIONS.STATUS.RESOLVED;
  // const userInfo = useUserInfo(  isAuthenticated)
  // // console.log(userInfo.data)
  // const cardNumber = userInfo?.data?.data?.partnerAccounts[0].cardNumber
  // const {data} = useBonusPointsByCard({
  //     cardNumber,
  //     enabled: !!cardNumber
  // })
  const { data } = useUserBonusPoints(isAuthenticated);
  if (!module?.isActive) return null;
  return (
    <BannerContent
      style={style}
      small={small}
      className={className}
      isAuthenticated={isAuthenticated}
      isActive={!!data?.timestamp}
      userClass={data?.class}
    />
  );
};

export default BonusPointsBanner;
