export const getLandingPath = () => '';
export const getCommunityPath = () => `${getLandingPath()}/donate`;
export const getCardPath = () => `${getCommunityPath()}/card`;

export const getStudentDebtPath = () => '/student-debt';
export const getProductsPath = () => '/products';
export const getSweepstakesPath = () => '/enter-sweepstakes';
export const getCharitiesPath = () => '/donate';
export const getCharityPath = (id) => `${getCharitiesPath()}/${id}`;

export const getRedirectingPath = (navItem = '') => {
  let path;
  switch (navItem) {
    case 'Sweepstakes':
      path = getSweepstakesPath();
      break;
    case 'Student Debt':
      path = getStudentDebtPath();
      break;
    default:
      path = getProductsPath();
  }
  return path;
};
