import {
  BackNav,
  LOYALTY_CONNECT_MODULES,
  LoginButton,
  ModuleLayout,
  Toast,
} from '@rewards/ui';
import {
  fetchEventSource,
  EventStreamContentType,
} from '@microsoft/fetch-event-source';

import { useUserEntries } from '../api/useUserEntries';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { useSetAtom } from 'jotai';
import { loyaltyAxios, useUserInfo } from '@rewards/data-provider';
import { AuthContext } from '@rewards/contexts';
import { getAccessToken } from '@rewards/data-provider';
import { useQueryClient } from '@tanstack/react-query';
import { useCampaigns } from '../util/hooks';

class RetriableError extends Error {}
class FatalError extends Error {}
type ServeSideEventsBase = {
  customerId: string;
  eventType: 'SWEEPSTAKE_FREE_ENTRY' | 'CONNECTED';
  data: Record<string, any>;
};

const FORM_URL =
  'https://fm.addxt.com/form/?vf=1FAIpQLSeNJ2b0v3rNWklZZ9ESHaiTO-80BST06nINv_Kb1gI-vGPMUw&entry.2056812726=CUSTOMER_ID&entry.416841201=CARD_NUMBER&entry.1728358069=SWEEPSTAKE_ID';
const CARD_NUMBER_PATTERN = 'CARD_NUMBER';
const CUSTOMER_ID_PATTERN = 'CUSTOMER_ID';
const SWEEPSTAKE_ID_PATTERN = 'SWEEPSTAKE_ID';
const defaultUserData: any = {};
export const FreeSurvey = () => {
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { token } = AuthContext.useAuth();
  const { activeCampaigns, completedCampaigns } = useCampaigns();

  const { data: userEntries, status: entriesStatus } = useUserEntries(
    params.id
  );
  const { data: userData, status } = useUserInfo(!!token);
  const userInfo = userData?.data ?? defaultUserData;
  const setToastMessage = useSetAtom(Toast.setToastMessageAtom);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const { url } = useRouteMatch();

  useEffect(() => {
    if (activeCampaigns && completedCampaigns) {
      const isCurrentCampaign = activeCampaigns.find(
        ({ _id }: { _id: string }) => _id === params.id
      );
      if (!isCurrentCampaign) {
        const campaign = completedCampaigns.find(
          ({ _id }: { _id: string }) => _id === params.id
        );
        setToastMessage(
          `The survey for sweepstake ${campaign?.name} is now closed. You'll be redirected to the current sweepstake where you can find the new survey.`
        );
        // we need to go back 2 back
        const path = url.split('/');
        path.pop();
        path.pop();
        setTimeout(() => {
          history.push(path.join('/'));
        }, 3000);
      }
    }
  }, [activeCampaigns, params]);
  useEffect(() => {
    if (userEntries && userEntries.todayEntries >= 5) {
      setToastMessage(`Entries Limit reached. Try again Tomorrow`);
      const path = url.split('/');
      path.pop();
      setTimeout(() => {
        history.push(path.join('/'));
      }, 3000);
    }
  }, [userEntries, url, setToastMessage]);

  useEffect(() => {
    const abortSignal = new AbortController();
    const path = url.split('/');
    path.pop();
    if (userInfo.extUserId && iframeRef.current) {
      // Setup URL
      const userCardNumber = userInfo?.partnerAccounts[0].cardNumber;
      const url = FORM_URL.replace(CUSTOMER_ID_PATTERN, userInfo?.extUserId)
        .replace(CARD_NUMBER_PATTERN, userCardNumber)
        .replace(SWEEPSTAKE_ID_PATTERN, params.id);
      iframeRef.current.src = url;
      // Event Source Handler w/ Access Token for Sec.
      const eventSource = fetchEventSource(
        `/api/v2/sweepstakes/${params.id}/events/`,
        {
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
            Accept: 'text/event-stream',
          },
          signal: abortSignal.signal,

          async onopen(response) {
            if (
              response.ok &&
              response.headers.get('content-type') === EventStreamContentType
            ) {
              return; // everything's good
            } else if (
              (response.status >= 400 &&
                response.status < 500 &&
                response.status !== 429) ||
              response.headers.get('content-type') !== EventStreamContentType
            ) {
              // client-side errors are usually non-retriable:
              throw new FatalError();
            } else {
              throw new RetriableError();
            }
          },
          onmessage(event) {
            const parsedData = JSON.parse(event.data) as ServeSideEventsBase;
            if (parsedData.eventType !== 'CONNECTED') {
              queryClient.invalidateQueries({
                queryKey: ['sweepstake-entries', params.id],
              });
              if (parsedData.data.data.txnStatus === 'COMPLETE') {
                setToastMessage(
                  'Your free survey entry was submitted successfully.'
                );
                setTimeout(() => {
                  history.push(path.join('/'));
                }, 3000);
              } else if (
                parsedData.data.data.txnStatus === 'FREEENTRY_LIMIT_REACHED'
              ) {
                setToastMessage(`Entries Limit reached. Try again Tomorrow`);

                setTimeout(() => {
                  history.push(path.join('/'));
                }, 3000);
              } else {
                setToastMessage(
                  <>
                    Your entry is being processed.
                    <br />
                    <div className="text-small font-normal">
                      Free entries may not yet be reflected in your entry
                      totals, but will be processed prior to the drawing.
                      Limited entries per day.
                    </div>
                  </>
                );

                setTimeout(() => {
                  history.push(path.join('/'));
                }, 3000);
              }
            }
          },
          onerror(err) {
            if (err instanceof FatalError) {
              throw err; // rethrow to stop the operation
            } else {
              // do nothing to automatically retry. You can also
              // return a specific retry interval here.
            }
          },
        }
      );
    }

    return () => {
      // Close Event Source
      abortSignal.abort();
    };
  }, [userInfo, params.id, url]);

  return (
    <BackNav module={LOYALTY_CONNECT_MODULES.SWEEPSTAKES} to="/redeem">
      <ModuleLayout>
        {}
        {!token ? (
          <>
            <div>Login before submitting your Free Entry!</div>
            <LoginButton></LoginButton>
          </>
        ) : (
          <iframe ref={iframeRef} className=" h-dvh "></iframe>
        )}
      </ModuleLayout>
    </BackNav>
  );
};
