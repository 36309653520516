import { Modal } from '@rewards/ui';
import { STAGES } from './constants';
import { PriceAnimation } from '../PriceAnimation';
import styles from './animation.module.css';
const PopupWrapper = ({ open, onOpenChange, children, background, stage }) => {
  return (
    <Modal.Root open={open} onOpenChange={onOpenChange}>
      <Modal.Content
        className={`bg-${
          background || 'white'
        } opacity-100 max-w-[360px] flex flex-col  items-center relative justify-center  p-2  w-[90%] z-[2] `}
      >
        <Modal.CloseButton color={background} />
        {children}
      </Modal.Content>
      {open && <PriceAnimation start={stage === STAGES.ACTIVATED} />}
    </Modal.Root>
  );
};

export default PopupWrapper;
