import React, { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import ErrorFallbackDefault from './ErrorFallback';
import errorHandlerDefault from '../util/errorHandler';
import {
  INVALID_MODULE_NAME,
  INVALID_TOKEN,
  POST_MESSAGE_EVENT,
} from '../util/constants';
import { sendPostMessageToParent } from '../util/utils';

const WithCustomErrorBoundary = ({
  children,
  appName = 'This app',
  ...props
}) => {
  const [userDetails, setUserDetails] = useState({});
  const errorContext = {
    appName,
    userDetails,
  };

  const onErrorHandle = (error, info) => {
    if (props?.errorHandler && error?.name == INVALID_TOKEN) {
      props.errorHandler(error);
    } else if (props?.errorHandler && error?.name == INVALID_MODULE_NAME) {
      sendPostMessageToParent(
        props?.configDataProps?.postMessageToParent,
        'error',
        POST_MESSAGE_EVENT.INVALID_MODULE_NAME,
        { ...(props?.configDataProps?.widget ? { widget: true } : {}) }
      );
    } else {
      sendPostMessageToParent(props?.configDataProps?.postMessageToParent);
    }
    errorHandlerDefault(error, info, errorContext);

    if (props?.segment) {
      // track UI error on segment
      const { track, EVENT } = props?.segment;
      track(EVENT, { ...errorContext, ...error, ...info });
    }
  };

  return (
    <>
      <ErrorBoundary
        FallbackComponent={({ error }) => (
          <ErrorFallbackDefault
            error={error}
            configDataProps={props.configDataProps}
          />
        )}
        onError={onErrorHandle}
        {...props}
      >
        {React.Children.map(children, (child) =>
          React.cloneElement(child, {
            setUserDetails,
          })
        )}
      </ErrorBoundary>
    </>
  );
};
export default WithCustomErrorBoundary;
