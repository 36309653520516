import { useContext } from 'react';
import { SchoolContext } from '../../contexts/SchoolContexts';
import { Container, Col, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Button } from '@rewards/ui';
import { useResponsive } from '../../util/hooks';
import Icon from '../../assets/svg';

export const StarsEarnedInfo = () => {
  const { isDesktop } = useResponsive();

  const { selectedSchool } = useContext(SchoolContext);
  const today = new Date();
  return (
    <div
      className={`flex justify-content-between text-center ${
        isDesktop ? 'my-3' : 'p-0'
      }`}
    >
      <Col className="tfs-landing-stars-earned bg-white rounded-20 w-50 p-2 mr-n2">
        <Col className="p-0 stars-number mt-4">
          {selectedSchool?.starsEarnedOnline || 0}
        </Col>
        <Col className="p-0 text-right">
          <OverlayTrigger
            delay={{ show: 250, hide: 400 }}
            overlay={(props) => (
              <Tooltip
                id="button-tooltip"
                {...props}
                className="question-tooltip"
              >
                Every star earned goes towards purchasing equipment and
                resources for your school.
              </Tooltip>
            )}
          >
            <Button
              intent="transparent"
              size="wrapper"
              // as='span'
              //  className='p-0 border-0 tfs-selected-school-dash-tooltip'
            >
              <Icon icon="question-o" width="13" height="13" />
            </Button>
          </OverlayTrigger>
        </Col>
        {/* TODO: FIX ALIGNMENT WITH THE OTHER SIDE */}
        <Col className="p-0 stars-info">Stars earned online </Col>
      </Col>
      <div className="my-auto p-2 card-icon-overlap">
        <div>
          <Icon icon="star-o" width="9" height="17" />
        </div>
      </div>
      <Col className="tfs-landing-stars-earned text-center bg-white rounded-20 w-50 p-2 ml-n2">
        <Col className="p-0 stars-number mt-4">
          {selectedSchool?.starsEarnedOffline || 0}
        </Col>
        <Col className="p-0 stars-info">
          <Col className="p-0">Stars earned in store</Col>
          <Col className="p-0 stars-sub-info">
            as of {today.toLocaleDateString()}
          </Col>
        </Col>
      </Col>
    </div>
  );
};
