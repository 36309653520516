import { useContext } from 'react';

import PartnerContext from './PartnerContext';

const usePartner = () => {
  const partnerState = useContext(PartnerContext);
  if (!partnerState) {
    throw new Error('Undefined partner state!');
  }
  const { data, partnerStatus } = partnerState;
  return { partner: data?.partner, partnerStatus };
};

export default usePartner;
