import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Campaigns from './components/Campaigns';
import Campaign from './components/Campaign';
import BonusPoints from './components/BonusPoints';

import { CampaignsContextState } from './contexts/CampaignsContext';
import {
  fetchCampaigns,
  fetchCalculateExchange,
  fetchBonusPointsTxns,
} from './services/campaignsService';

import { getSweepstakesPath, getSweepstakePath } from './util/paths';

// // import 'bootstrap/dist/css/bootstrap.min.css'
import './App.scss';
import { FreeSurvey } from './routes/free-entry';

const App = () => {
  return (
    <CampaignsContextState
      fetchCalculateExchange={fetchCalculateExchange}
      fetchBonusPointsTxns={fetchBonusPointsTxns}
    >
      <Switch>
        <Route exact path={getSweepstakesPath()} component={Campaigns} />
        <Route
          path={`${getSweepstakePath(':id')}/free-entry`}
          exact
          component={FreeSurvey}
        />
        <Route path={getSweepstakePath(':id')} exact component={Campaign} />
        <Route path="*" render={() => <Redirect to={getSweepstakePath()} />} />
      </Switch>
    </CampaignsContextState>
  );
};

export default App;
