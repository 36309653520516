import PropTypes from 'prop-types';
import React from 'react';

import styleConsts from '../util/styleConsts';

export default class Icon extends React.Component {
  render() {
    const { width, height, stroke, className, ...rest } = this.props;
    let classNameString = `${className} svg-${this.props.icon}`;
    const colorStyleName = styleConsts.styleValueToStyleName(this.props.color);
    if (colorStyleName) {
      classNameString += ` ${this.props.colorClassName}-${colorStyleName}`;
    }
    const colorAuxStyleName = styleConsts.styleValueToStyleName(
      this.props.colorAux
    );
    if (colorAuxStyleName) {
      classNameString += ` ${this.props.colorAuxClassName}-${colorAuxStyleName}`;
    }
    const colorBorderStyleName = styleConsts.styleValueToStyleName(
      this.props.colorBorder
    );
    if (colorBorderStyleName) {
      classNameString += ` ${this.props.colorBorderClassName}-${colorBorderStyleName}`;
    }
    if (this.props.iconType) {
      classNameString += ` svg-icon-type-${this.props.iconType}`;
    }

    switch (this.props.icon) {
      case 'angle-left': {
        return (
          <svg
            className={classNameString}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 9 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.365 8.28255L8.555 1.89435C8.935 1.49636 8.935 0.853459 8.555 0.455473C8.175 0.0574857 7.545 0.0574857 7.165 0.455473L0.285 7.55801C-0.095 7.95599 -0.095 8.5989 0.285 8.99688L7.165 16.0994C7.355 16.2933 7.605 16.3954 7.855 16.3954C8.105 16.3954 8.355 16.2933 8.545 16.0994C8.925 15.7014 8.925 15.0585 8.545 14.6605L2.365 8.28255Z"
              fill={this.props.color}
            />
          </svg>
        );
      }
      case 'angle-right': {
        return (
          <svg
            className={classNameString}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 7 13"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.18829 5.99678L0.461758 10.7183C0.1716 11.0125 0.1716 11.4877 0.461758 11.7818C0.751916 12.076 1.23297 12.076 1.52313 11.7818L6.77652 6.5323C7.06668 6.23814 7.06668 5.76297 6.77652 5.46881L1.52313 0.219284C1.37805 0.0759778 1.18716 0.000553577 0.996262 0.00055356C0.805368 0.000553544 0.614474 0.0759777 0.469395 0.219283C0.179236 0.513438 0.179236 0.988611 0.469395 1.28277L5.18829 5.99678Z"
              fill={styleConsts.schooner}
            />
          </svg>
        );
      }
      case 'balance-bolt': {
        return (
          <svg
            className={classNameString}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 33 33"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              opacity="0.2"
              cx="16.5"
              cy="16.5"
              r="16.5"
              fill={styleConsts.appleGreen}
            />
            <path
              className={this.props.colorClassName}
              fillRule="evenodd"
              clipRule="evenodd"
              fill={styleConsts.appleGreen}
              d="M20.1715 13H18.3337V10C18.3337 9.09 17.7704 8.3 16.9669 8.07C16.81 8.02 16.653 8 16.496 8C15.8495 8 15.24 8.37 14.9076 9.01L11.2413 16.01C10.9181 16.63 10.9181 17.39 11.2505 18.01C11.583 18.63 12.1832 19.01 12.8389 19.01H14.6767V22.01C14.6767 22.92 15.24 23.71 16.0435 23.94C16.182 23.98 16.339 24 16.496 24C17.1424 24 17.7519 23.63 18.0844 22.99L21.7137 16.06C21.8892 15.75 22 15.39 22 14.99C22 13.9 21.1873 13 20.1715 13ZM16.496 22V17H12.8297L16.496 10V15H20.1622L16.496 22Z"
            />
            <path
              className={this.props.colorClassName}
              fill={styleConsts.appleGreen}
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.1715 13H18.3337V10C18.3337 9.09 17.7704 8.3 16.9669 8.07C16.81 8.02 16.653 8 16.496 8C15.8495 8 15.24 8.37 14.9076 9.01L11.2413 16.01C10.9181 16.63 10.9181 17.39 11.2505 18.01C11.583 18.63 12.1832 19.01 12.8389 19.01H14.6767V22.01C14.6767 22.92 15.24 23.71 16.0435 23.94C16.182 23.98 16.339 24 16.496 24C17.1424 24 17.7519 23.63 18.0844 22.99L21.7137 16.06C21.8892 15.75 22 15.39 22 14.99C22 13.9 21.1873 13 20.1715 13ZM16.496 22V17H12.8297L16.496 10V15H20.1622L16.496 22Z"
            />
          </svg>
        );
      }
      case 'bolt': {
        return (
          <svg
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 15 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill={this.props.color}
              d="M12.1731 6.5625H9.73385V2.625C9.73385 1.43062 8.98615 0.39375 7.91977 0.091875C7.71139 0.02625 7.50302 0 7.29465 0C6.43663 0 5.62765 0.485625 5.18639 1.32562L0.320238 10.5131C-0.108767 11.3269 -0.108767 12.3244 0.332496 13.1381C0.773759 13.9519 1.57048 14.4506 2.44075 14.4506H4.87996V18.3881C4.87996 19.5825 5.62765 20.6194 6.69404 20.9212C6.8779 20.9737 7.08627 21 7.29465 21C8.15266 21 8.96164 20.5144 9.4029 19.6744L14.22 10.5788C14.4529 10.1719 14.6 9.69938 14.6 9.17437C14.6 7.74375 13.5214 6.5625 12.1731 6.5625ZM7.29465 18.375V11.8125H2.42849L7.29465 2.625V9.1875H12.1608L7.29465 18.375Z"
            />
          </svg>
        );
      }
      case 'close-o': {
        return (
          <svg
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 51 51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={this.props.className}
          >
            <circle cx="25.5" cy="25.5" r="25.5" fill={styleConsts.silver} />
            <circle cx="25.5" cy="25.5" r="20.5" fill={styleConsts.cobalt} />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19.7929 29.2214C19.4024 29.612 19.4024 30.2451 19.7929 30.6357C20.1834 31.0262 20.8166 31.0262 21.2071 30.6357L25.2143 26.6285L29.2214 30.6357C29.612 31.0262 30.2451 31.0262 30.6357 30.6357C31.0262 30.2451 31.0262 29.612 30.6357 29.2214L26.6285 25.2143L30.6357 21.2071C31.0262 20.8166 31.0262 20.1834 30.6357 19.7929C30.2451 19.4024 29.612 19.4024 29.2214 19.7929L25.2143 23.8001L21.2071 19.7929C20.8166 19.4024 20.1834 19.4024 19.7929 19.7929C19.4024 20.1834 19.4024 20.8166 19.7929 21.2071L23.8001 25.2143L19.7929 29.2214Z"
              fill={styleConsts.lightGrey}
            />
          </svg>
        );
      }
      case 'subscription-star': {
        return (
          <svg
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 33 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              opacity="0.5"
              cx="16.5"
              cy="16.5"
              r="16.5"
              fill={styleConsts.cobalt}
              fillOpacity="0.99"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.1715 13H18.3337V10C18.3337 9.09 17.7704 8.3 16.9669 8.07C16.81 8.02 16.653 8 16.496 8C15.8495 8 15.24 8.37 14.9076 9.01L11.2413 16.01C10.9181 16.63 10.9181 17.39 11.2505 18.01C11.583 18.63 12.1832 19.01 12.8389 19.01H14.6767V22.01C14.6767 22.92 15.24 23.71 16.0435 23.94C16.182 23.98 16.339 24 16.496 24C17.1424 24 17.7519 23.63 18.0844 22.99L21.7137 16.06C21.8892 15.75 22 15.39 22 14.99C22 13.9 21.1873 13 20.1715 13ZM16.496 22V17H12.8297L16.496 10V15H20.1622L16.496 22Z"
              fill={styleConsts.white}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.1715 13H18.3337V10C18.3337 9.09 17.7704 8.3 16.9669 8.07C16.81 8.02 16.653 8 16.496 8C15.8495 8 15.24 8.37 14.9076 9.01L11.2413 16.01C10.9181 16.63 10.9181 17.39 11.2505 18.01C11.583 18.63 12.1832 19.01 12.8389 19.01H14.6767V22.01C14.6767 22.92 15.24 23.71 16.0435 23.94C16.182 23.98 16.339 24 16.496 24C17.1424 24 17.7519 23.63 18.0844 22.99L21.7137 16.06C21.8892 15.75 22 15.39 22 14.99C22 13.9 21.1873 13 20.1715 13ZM16.496 22V17H12.8297L16.496 10V15H20.1622L16.496 22Z"
              fill={styleConsts.white}
            />
          </svg>
        );
      }
      case 'travel': {
        return (
          <svg
            width={this.props.width}
            height={this.props.height}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 400 400"
            fill="none"
            stroke={''}
            strokeWidth="1"
          >
            <path
              d="M219.2,18.17H180.6c-19.16,0-34.87,16.21-34.87,36.32V80.16a7.5,7.5,0,1,0,15,0V54.49c0-11.4,8.78-20.56,19.73-20.56h38.61c10.95,0,19.74,9.16,19.74,20.56V80.16a7.5,7.5,0,1,0,15,0V54.49C254.07,34.38,238.36,18.17,219.2,18.17Z"
              fill="#81c96a"
            />
            <path
              d="M70.82,346.87H58.43a9.17,9.17,0,0,0-8.93,9.3v5.55A9.17,9.17,0,0,0,58.43,371H70.82a9.17,9.17,0,0,0,8.93-9.31v-5.55C79.61,350.92,75.72,346.87,70.82,346.87Z"
              fill="#00447e"
            />
            <path
              d="M341.51,346.87H329.12a9.16,9.16,0,0,0-8.93,9.3v5.55a9.17,9.17,0,0,0,8.93,9.31h12.39a9.17,9.17,0,0,0,8.93-9.31v-5.55C350.3,350.92,346.41,346.87,341.51,346.87Z"
              fill="#00447e"
            />
            <path
              d="M375.8,69.8H24C10.75,69.8-.06,81.06-.06,94.87V335.31c0,13.81,10.81,25.06,24.06,25.06H375.8c13.25,0,24.06-11.25,24.06-25.06V94.87C400,81.06,389.2,69.8,375.8,69.8Z"
              fill="#61bb45"
            />
            <path
              d="M375.8,69.8H351.31a42.31,42.31,0,0,0-.43,6.15c0,23.42,18.15,42.33,40.62,42.33a39.94,39.94,0,0,0,8.5-.9V95C400,81.06,389.2,69.8,375.8,69.8Z"
              fill="#005aa8"
            />
            <path
              d="M350.88,354.37a42.31,42.31,0,0,0,.43,6.15H375.8c13.25,0,24.06-11.25,24.06-25.06V313.1a39.31,39.31,0,0,0-8.5-.91C369,312,350.88,331,350.88,354.37Z"
              fill="#005aa8"
            />
            <path
              d="M24,69.8C10.75,69.8-.06,81.06-.06,94.87v22.36a39.94,39.94,0,0,0,8.5.9c22.48,0,40.63-18.91,40.63-42.33a42.31,42.31,0,0,0-.43-6.15H24Z"
              fill="#005aa8"
            />
            <path
              d="M8.3,312A39.31,39.31,0,0,0-.2,313v22.36c0,13.81,10.81,25.06,24.06,25.06H48.35a42.31,42.31,0,0,0,.43-6.15C48.93,331,30.77,312,8.3,312Z"
              fill="#005aa8"
            />
            <rect
              x="69.67"
              y="69.8"
              width="35.15"
              height="290.72"
              fill="#81c96a"
            />
            <rect
              x="294.98"
              y="69.8"
              width="35.15"
              height="290.72"
              fill="#81c96a"
            />
          </svg>
        );
      }
      case 'newspaperClipping': {
        return (
          <svg
            className={this.props.className}
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.125 14.3438V3.71875C2.125 3.57785 2.18097 3.44273 2.2806 3.3431C2.38023 3.24347 2.51535 3.1875 2.65625 3.1875H14.3438C14.4846 3.1875 14.6198 3.24347 14.7194 3.3431C14.819 3.44273 14.875 3.57785 14.875 3.71875V14.3438L12.75 13.2812L10.625 14.3438L8.5 13.2812L6.375 14.3438L4.25 13.2812L2.125 14.3438Z"
              stroke={styleConsts.white}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.5625 7.4375H12.75"
              stroke={styleConsts.white}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.5625 9.5625H12.75"
              stroke={styleConsts.white}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.4375 6.375H4.25V10.625H7.4375V6.375Z"
              stroke={styleConsts.white}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case 'minus-updated': {
        return (
          <svg
            className={classNameString}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 61 61"
          >
            <g id="Group_2" data-name="Group 2">
              <path
                id="Path_9-2"
                data-name="Path 9-2"
                d="M30.8,6.72a22,22,0,1,1-22,22A22,22,0,0,1,30.8,6.72Z"
                fill={styleConsts.white}
              />
            </g>
            <g id="Group_3" data-name="Group 3">
              <path
                id="Path_8"
                data-name="Path 8"
                d="M30.59,8.69A20.1,20.1,0,1,1,14,17.26,20.12,20.12,0,0,1,30.59,8.69Z"
                fill={styleConsts.cobalt}
              />
            </g>
            <rect
              x="17.77"
              y="26.05"
              width="24.84"
              height="6.24"
              transform="translate(60.38 58.34) rotate(-180)"
              fill={styleConsts.white}
            />
          </svg>
        );
      }
      case 'plus-updated': {
        return (
          <svg
            width={this.props.width}
            height={this.props.width}
            viewBox="0 0 61 61"
          >
            <g id="Group_2" data-name="Group 2">
              <path
                id="Path_9-2"
                data-name="Path 9-2"
                d="M30.8,6.72a22,22,0,1,1-22,22A22,22,0,0,1,30.8,6.72Z"
                fill={styleConsts.white}
              />
            </g>
            <g id="Group_3" data-name="Group 3">
              <path
                id="Path_8"
                data-name="Path 8"
                d="M30.59,8.69A20.1,20.1,0,1,1,14,17.26,20.12,20.12,0,0,1,30.59,8.69Z"
                fill={styleConsts.cobalt}
              />
            </g>
            <rect
              x="16.98"
              y="26.24"
              width="26.43"
              height="5.87"
              transform="translate(59.36 -1.02) rotate(90)"
              fill={styleConsts.white}
            />
            <rect
              x="17.77"
              y="26.05"
              width="24.84"
              height="6.24"
              transform="translate(60.38 58.34) rotate(-180)"
              fill={styleConsts.white}
            />
          </svg>
        );
      }
      case 'link': {
        return (
          <svg
            width={this.props.width}
            height={this.props.width}
            viewBox="0 0 16 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_857_393)">
              <g clipPath="url(#clip1_857_393)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.26992 7.57548L6.26992 8.91526C5.29992 9.56798 3.79992 9.54049 2.77992 8.8603C2.28992 8.5305 2.00992 8.11139 1.99992 7.67167C1.97992 7.23881 2.22992 6.82657 2.69992 6.51739L4.69991 5.17761C5.08991 4.91652 5.08991 4.49054 4.69991 4.22945C4.30991 3.96837 3.67992 3.96837 3.28992 4.22945L1.28992 5.56924C0.429915 6.1395 -0.0300847 6.90215 -8.46998e-05 7.70602C0.0299153 8.48928 0.509915 9.23818 1.36992 9.80845C2.27992 10.4199 3.44992 10.7291 4.59992 10.7291C5.71992 10.7291 6.81991 10.4406 7.67991 9.86342L9.67992 8.52363C10.0699 8.26255 10.0699 7.83656 9.67992 7.57548C9.28992 7.31439 8.65992 7.31439 8.26992 7.57548ZM4.27992 7.85717C4.46992 7.98772 4.72991 8.05642 4.98991 8.05642C5.24991 8.05642 5.49991 7.98772 5.69991 7.85717L11.6899 3.83782C12.0799 3.57674 12.0799 3.15075 11.6899 2.88967C11.2999 2.62858 10.6699 2.62858 10.2799 2.88967L4.28992 6.90902C3.88992 7.17011 3.88992 7.59609 4.27992 7.85717ZM14.5799 0.931521C12.7799 -0.27772 10.0099 -0.298332 8.26992 0.876556L6.26992 2.21634C5.87992 2.47743 5.87992 2.90341 6.26992 3.16449C6.65992 3.42558 7.28991 3.42558 7.67991 3.16449L9.67992 1.82471C10.6499 1.172 12.1499 1.19948 13.1699 1.87968C13.6599 2.20947 13.9399 2.62858 13.9499 3.0683C13.9699 3.50116 13.7199 3.9134 13.2499 4.22258L11.2499 5.56236C10.8599 5.82345 10.8599 6.24943 11.2499 6.51052C11.4399 6.64106 11.6999 6.70977 11.9599 6.70977C12.2199 6.70977 12.4699 6.64106 12.6699 6.51052L14.6699 5.17074C15.5299 4.5936 15.9799 3.83782 15.9499 3.03395C15.9199 2.25069 15.4299 1.50866 14.5799 0.931521Z"
                  fill="#898785"
                />
              </g>
            </g>
            <defs>
              <clipPath id="clip0_857_393">
                <rect
                  width="15.91"
                  height="10.9931"
                  fill="white"
                  transform="translate(0 0.0107422)"
                />
              </clipPath>
              <clipPath id="clip1_857_393">
                <rect
                  width="15.95"
                  height="10.7183"
                  fill="white"
                  transform="translate(0 0.0107422)"
                />
              </clipPath>
            </defs>
          </svg>
        );
      }
      default: {
        return (
          <svg
            className="svg-default"
            xmlns="http://www.w3.org/2000/svg"
            width="200"
            height="100"
          >
            <text fontSize="25">
              <tspan x="0" dy="1.2em">
                ICON
              </tspan>
              <tspan x="0" dy="1.2em">
                FAILURE
              </tspan>
            </text>
          </svg>
        );
      }
    }
  }
}

Icon.defaultProps = {
  children: null,
  classificationHeight: '30px',
  classificationId: 'default',
  classificationWidth: '30px',
  color: styleConsts.white,
  colorClassName: 'svg-color',
  colorAux: styleConsts.grayDark,
  colorAuxClassName: 'svg-colorAux',
  colorBorder: styleConsts.blue,
  colorBorderClassName: 'svg-colorBorder',
  height: '30px',
  iconType: '',
  includeSolutionBorder: undefined,
  standardSolutionName: '',
  style: null,
  time: '',
  width: '30px',
  x: '0',
  y: '0',
  className: 'inline',
};
Icon.propTypes = {
  children: PropTypes.element,
  classificationHeight: PropTypes.string,
  classificationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  classificationWidth: PropTypes.string,
  color: PropTypes.string,
  colorClassName: PropTypes.string,
  colorAux: PropTypes.string,
  colorAuxClassName: PropTypes.string,
  colorBorder: PropTypes.string,
  colorBorderClassName: PropTypes.string,
  height: PropTypes.string,
  icon: PropTypes.string.isRequired,
  iconType: PropTypes.string,
  includeSolutionBorder: PropTypes.bool,
  standardSolutionName: PropTypes.string,
  style: PropTypes.object,
  time: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  width: PropTypes.string,
  x: PropTypes.string,
  y: PropTypes.string,
  className: PropTypes.string,
};
