export const EVENTS = {
  UTILITY_OFFERS_BACK_CLICKED: 'Earning - Utility Offers - Back Button Clicked',
  LOG_IN_REGISTER_CLICKED: 'Earning - Utility Offers - Log In / button clicked',
  UTILITY_OFFERS_APPLY_BUTTON_CLICKED:
    'Earning - Utility Offers - APPLY Button Clicked',
};

export const PAGES = {
  VIEWED_UTILITY_OFFERS_PAGE: 'Earning - Utility Offers - Landing Page Viewed',
  VIEWED_UTILITY_OFFERS_COMMING_SOON:
    'Earning - Utility Offers - Comming Soon Page Viewed',
};

export const EVENT_LOCATION = {};
