import { useQuery } from '@tanstack/react-query';
import getExtraImpact from '../services/extraImpact';

export default function useExtraImpact(
  enabled = false,
  requestParams = null,
  projectionsHit = false
) {
  return useQuery({
    queryKey: [
      `${projectionsHit ? 'projections-impact' : 'impact'}`,
      requestParams,
      projectionsHit,
    ],

    queryFn: getExtraImpact,
    staleTime: 1000,
    enabled,
    retry: false,
  });
}
