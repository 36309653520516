import { format, utcToZonedTime } from 'date-fns-tz';
const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const shortMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const getNewYorkDateString = (timestamp, formatString) => {
  const timezone = 'America/New_York';
  try {
    return format(utcToZonedTime(timestamp, timezone), formatString, {
      timeZone: timezone,
    });
  } catch (e) {
    return '';
  }
};

export const dateString = (
  date = new Date(),
  shouldShowShortMonths = false
) => {
  const d = new Date(date);
  const months = shouldShowShortMonths ? shortMonths : monthNames;
  return `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
};

export const firstLetterOfString = (str = '') => {
  const matches = str.match(/\b(\w)/g);
  return matches.join('');
};

export const daysRemaining = (mstime = null) => {
  if (!mstime) {
    return '';
  }
  const timeleft = mstime - new Date().getTime();
  const days = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
  return `${days} ${days > 1 ? 'days' : 'day'}`;
};

export const formatDate = (date = new Date()) => {
  return `${date.getMonth() + 1}/${date.getDate()}/${date
    .getFullYear()
    .toString()
    .substr(-2)}`;
};

export const toTitleCase = (str = '') => {
  const splittedStr = str.split('-').join(' ');
  return splittedStr.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const sortOnKey = (data = [], key, asc = true) => {
  if (asc) {
    return data.sort((a, b) => a[key] - b[key]);
  }
  return data.sort((a, b) => b[key] - a[key]);
};

const a = [
  '',
  'one ',
  'two ',
  'three ',
  'four ',
  'five ',
  'six ',
  'seven ',
  'eight ',
  'nine ',
  'ten ',
  'eleven ',
  'twelve ',
  'thirteen ',
  'fourteen ',
  'fifteen ',
  'sixteen ',
  'seventeen ',
  'eighteen ',
  'nineteen ',
];
const b = [
  '',
  '',
  'twenty',
  'thirty',
  'forty',
  'fifty',
  'sixty',
  'seventy',
  'eighty',
  'ninety',
];

export const numToWords = (num = 0) => {
  if ((num = num.toString()).length > 9) {
    return 'overflow';
  }
  const n = ('000000000' + num)
    .substr(-9)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) {
    return;
  }
  let str = '';
  str +=
    n[1] != 0
      ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore '
      : '';
  str +=
    n[2] != 0
      ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh '
      : '';
  str +=
    n[3] != 0
      ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand '
      : '';
  str +=
    n[4] != 0
      ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred '
      : '';
  str += n[5] != 0 ? a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]] : '';
  return str;
};

export const numberWithCommas = (x = 0) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
