import { useHistory } from 'react-router-dom';
import { LOYALTY_CONNECT_MODULES } from '../../constants';
import { AngleLeftIcon } from '../../lib/icons';
import Button from '../../lib/Button';
import { track } from '@rewards/events';

interface IBackNav {
  module: LOYALTY_CONNECT_MODULES;
  to?: string;
  event?: string;
}
export const BackNav = ({
  children,
  module,
  to,
  event,
}: React.PropsWithChildren<IBackNav>) => {
  const history = useHistory();

  const onBackClick = () => {
    if (event) {
      track(event, {
        location: history.location,
        //@ts-ignore
        ui_version: import.meta.env.APP_VERSION,
      });
    }
    if (to) {
      history.push(to);
    } else {
      history.goBack();
    }
  };

  return (
    <div className="flex flex-col">
      <div className="grid grid-cols-7 bg-green-apple text-white !p-5  text-center lg:hidden">
        <Button onClick={onBackClick} intent="transparent" size="wrapper">
          <AngleLeftIcon width="9" height="17" />
        </Button>
        <div className="col-span-5 font-medium">{module}</div>
      </div>
      {children}
    </div>
  );
};
