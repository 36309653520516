import React from 'react';
import { Container, Col } from 'react-bootstrap';

import BackNav from '../../BackNav';
import { EarnPointsNavLinks, EarnPointsInfo } from '../components';
import { useResponsive } from '../../../util/hooks';

import './EarnDashboard.scss';

const EarnDashboard = () => {
  const { isDesktop } = useResponsive();
  return (
    <Container className="text-center">
      <EarnPointsNavLinks isDesktop={isDesktop} />
      <Col className="tfs-donate-dashboard-slot p-0"></Col>
      <Col className="fixed-bottom  p-3 tfs-earn-dashboard-info">
        <EarnPointsInfo isDesktop={isDesktop} />
      </Col>
    </Container>
  );
};

export default EarnDashboard;
