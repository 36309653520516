import React, { useEffect, useState } from 'react';
// import { usePartnerConfig } from 'loyalty-data-provider'

import Icon from '../../assets/svg';
import styleConst from '../../util/styleConsts';

import './ModalCloseBtn.scss';

const ModalCloseBtn = ({ handleClose }) => {
  // const { data: partnerConfig, isSuccess: isPartnerConfigSuccess } = usePartnerConfig()

  const [iconColor, setIconColor] = useState(styleConst.cobalt);

  // useEffect(() => {
  //   if (isPartnerConfigSuccess) {
  //     const { configuration } = partnerConfig?.data?.[0]
  //     const styles = configuration?.find(({ category }) => category === PARTNER_CONFIGURATIONS.STYLES)

  //     setIconColor(styles?.details?.primaryColor1)
  //   }
  // }, [isPartnerConfigSuccess]);

  return (
    <span
      className="position-absolute alert-close hover rounded-circle"
      onClick={handleClose}
    >
      <Icon icon="close-o" width="35" height="35" color={iconColor} />
    </span>
  );
};

export default ModalCloseBtn;
