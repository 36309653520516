import { type Config, fromTheme } from 'tailwind-merge';
// console.log(fromTheme('fontSize'));
const twMergeConfig: Partial<Config> = {
  theme: {
    colors: [
      'cobalt',
      'green-apple',
      'dark-brown',
      'light-brown',
      'grey',
      'dark-grey',
    ],
    fontSize: ['h1', 'h2', 'h3', 'h4', 'h5', 'navbar', 'small'],
  },
  classGroups: {
    'font-size': [{ text: [fromTheme('fontSize')] }],
  },
};

export default twMergeConfig;
