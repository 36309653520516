import axios from 'axios';

const instance = axios.create();

instance.interceptors.request.use(function (config) {
  const getAccessToken = sessionStorage.getItem('accessToken');
  config.headers.Authorization = `Bearer ${getAccessToken}`;
  return config;
});
instance.defaults.headers.post['Content-Type'] = 'application/json';

export default instance;
