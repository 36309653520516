import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import jwtDecode from 'jwt-decode';
import { MsalProvider } from '@azure/msal-react';
import {
  PublicClientApplication,
  BrowserAuthError,
  InteractionRequiredAuthError,
  EventType,
} from '@azure/msal-browser';

import AuthContext from './AuthContext';

import {
  fetchUserProfile,
  upsertPartnerAccount,
  fetchLoyaltyCardsInfo,
  validateAndGenerateToken,
  fetchUserSessions,
  fetchFeatureFlag,
  upsertPartnerAccountSso,
  unauthorizedToken,
} from '../../services/ComponentRegistryService';

import { identifyUserForAnalytics } from '../../analytics';
import { STATUS } from '../utility/actionTypes';
import { ACTIONS, useAsync } from '../utility';
import { msalConfig } from '../../authConfig';
import {
  FEATURES_FLAGS,
  ERROR_STATUS_CODES,
  MSAL_SERVER_ERROR,
} from '../../util/constants';
import {
  getToolsForSchoolsPath,
  getStudentDebtInvitePath,
} from '../../util/paths';

import UpsertFailSafeToast from '../../components/UpsertFailSafeToast';
import { useAtom } from 'jotai';
import {
  unAuthenticatedModalDisplayAtom,
  isToolsForSchoolInviteFriendsAtom,
} from '../atoms';

const AuthContextState = (props) => {
  const history = useHistory();
  const [code, setCode] = useState();
  const [sourceUserName, setSourceUserName] = useState();
  const [isChampionsBorrower, setIsChampionsBorrower] = useState();
  const [userId, setUserId] = useState();
  const [loanServicerId, setLoanServicerId] = useState();
  const [isSSOEnabled, setIsSSOEnabled] = useState(false);
  const [isToolsForSchoolsInviteFriends, setIsToolsForSchoolsInviteFriends] =
    useAtom(isToolsForSchoolInviteFriendsAtom);
  const [show, setShow] = useAtom(unAuthenticatedModalDisplayAtom);
  const [isUnAuthorizsedFlow, setIsUnAuthorizsedFlow] = useState(false);
  const [msalInstance, setMsalInstance] = useState();
  const [upsertFailSafeToast, setUpsertFailSafeToast] = useState();
  const [switchSignUpSso, setSwitchSignUpSso] = useState(false);
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);

  const asyncFeatureFlags = useAsync({
    status: STATUS.IDLE,
    data: { featureFlags: null },
  });
  const asyncTokenData = useAsync({
    status: STATUS.IDLE,
    data: { token: null },
  });
  const asyncUnAuthToken = useAsync({
    status: STATUS.IDLE,
    data: { unAuthToken: null },
  });
  const asyncProfileData = useAsync({
    status: STATUS.IDLE,
    data: { profile: null },
  });
  const asyncLoyaltyCardsData = useAsync({
    status: STATUS.IDLE,
    data: { loyaltyCards: null },
  });
  const asyncUserSession = useAsync({
    status: ACTIONS.STATUS.IDLE,
    data: { isSessionExpired: null },
  });

  const {
    data: featureFlagsData,
    run: runAsyncFeatureFlags,
    status: featureFlagsStatus,
  } = asyncFeatureFlags;
  const {
    data: tokenData,
    status: tokenStatus,
    run: runAsyncTokenData,
    reset: resetAsyncToken,
  } = asyncTokenData;
  const {
    data: unAuthTokenData,
    status: unAuthTokenStatus,
    run: runAsyncUnAuthToken,
  } = asyncUnAuthToken;
  const {
    data: profileData,
    run: runAsyncProfileData,
    status: profileStatus,
  } = asyncProfileData;
  const { run: runAsyncLoyaltyCardsData } = asyncLoyaltyCardsData;
  const { data: userSessionData, run: runAsyncUserSession } = asyncUserSession;

  const isMigrated = props.ssoMigrated;

  const hasFeatureFlagAndActive = (FF = '') => {
    const { featureFlags } = featureFlagsData;
    return (
      featureFlags.findIndex(({ name, isActive }) => name === FF && isActive) >
      -1
    );
  };

  const getSSOToken = ({
    emailId,
    name,
    cardNumber,
    extension_CustomerId,
    isRegistered = true,
    registeredType = null,
    identityProvider = null,
  }) => {
    return runAsyncTokenData(
      upsertPartnerAccountSso({
        extension_CustomerId,
        email: emailId,
        name,
        cardNumber,
        isRegistered,
        registeredType,
        identityProvider,
        isMigrated,
      })
        .then((data) => ({ token: data }))
        .catch((error) => {
          if (error?.status?.code === ERROR_STATUS_CODES.ERROR_400) {
            setUpsertFailSafeToast(error?.status?.desc);
            throw error;
          }
        })
    );
  };

  const resetSSOToken = () => resetAsyncToken();

  const handleClose = () => setShow(false);
  const handleShowUnAuthenticatedUserModal = () => {
    setShow(true);
  };
  const handleSwitchSignupSso = () => {
    setSwitchSignUpSso(true);
  };
  const checkUserSession = () => {
    if (isSSOEnabled) {
      const accounts = msalInstance.getAllAccounts();
      if (accounts?.length) {
        setShow(false);
        return Promise.resolve();
      } else {
        setShow(true);
        return Promise.reject();
      }
    } else {
      return runAsyncUserSession(
        fetchUserSessions().then((data) => {
          const decoded = jwtDecode(data);
          if (decoded?.anonymous) {
            sessionStorage.removeItem('accessToken');
            return { isSessionExpired: true };
          }
          return { isSessionExpired: false };
        })
      );
    }
  };

  useEffect(() => {
    runAsyncFeatureFlags(
      fetchFeatureFlag().then((data) => ({ featureFlags: data }))
    );
  }, []);

  const fetchUnAuthToken = () => {
    runAsyncUnAuthToken(
      unauthorizedToken().then((data) => ({
        unAuthToken: data,
      }))
    );
  };

  useEffect(() => {
    if (featureFlagsData?.featureFlags) {
      const isSSOEnabled = hasFeatureFlagAndActive(
        FEATURES_FLAGS.PRICECHOPPPER_SSO_ENABLE
      );
      setIsSSOEnabled(isSSOEnabled);

      const {
        envInfo: { ssoClientId, knownAuthorities, tenant, policies },
      } = props;
      msalConfig.auth.clientId = ssoClientId;
      msalConfig.auth.loginAuthority = `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/${policies?.signInPolicy}`;
      msalConfig.auth.signUpAuthority = `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/${policies?.signUpPolicy}`;
      msalConfig.auth.resetPasswordAuthority = `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/${policies?.resetPasswordPolicy}`;
      msalConfig.auth.knownAuthorities = knownAuthorities;
      msalConfig.auth.loginRequest.scopes.unshift(ssoClientId);
      const instance = new PublicClientApplication(msalConfig);

      const querySubArray = location?.href.split('?');
      const searchParams = new URLSearchParams(querySubArray?.[1]);
      const code = searchParams.get('code');
      const sourceUserName = searchParams.get('sourceUserName');
      const isChampionsBorrower = searchParams.get('isChampionsBorrower');
      const userId = searchParams.get('userId');
      const loanServicerId = searchParams.get('loanServicerId');

      const pathName = location.pathname;
      let isUnAuthorizsedUserFlow = false;
      if ((code || loanServicerId) && sourceUserName) {
        setCode(code);
        setSourceUserName(sourceUserName);
        setIsChampionsBorrower(isChampionsBorrower);
        setUserId(userId);
        setLoanServicerId(loanServicerId);
        if (pathName === getToolsForSchoolsPath()) {
          setIsToolsForSchoolsInviteFriends(true);
        } else if (pathName === getStudentDebtInvitePath()) {
          history.replace(getStudentDebtInvitePath());
          isUnAuthorizsedUserFlow = false;
        } else {
          isUnAuthorizsedUserFlow = true;
          identifyUserForAnalytics({ champion_name: sourceUserName, code });
        }
        history.replace(pathName);
      } else if (!isSSOEnabled) {
        runAsyncLoyaltyCardsData(fetchLoyaltyCardsInfo()).then(() => {
          return runAsyncProfileData(
            fetchUserProfile().then((data) => {
              return { profile: data };
            })
          );
        });
        checkUserSession();
        const unlisten = history.listen(checkUserSession);
        return () => unlisten();
      }

      if (isSSOEnabled) {
        setMsalInstance(instance);
        instance.addEventCallback(
          (event) => {
            const accounts = instance.getAllAccounts();
            switch (event?.eventType) {
              case EventType.ACQUIRE_TOKEN_FAILURE: {
                if (
                  event?.error instanceof InteractionRequiredAuthError &&
                  accounts?.length
                ) {
                  const index = accounts.findIndex(
                    ({ idTokenClaims }) =>
                      idTokenClaims?.acr ===
                      props?.envInfo?.policies?.signUpPolicy
                  );
                  instance.logoutRedirect({
                    account: accounts?.[index],
                  });
                }
                break;
              }
              case EventType.LOGIN_FAILURE: {
                if (
                  event?.error instanceof BrowserAuthError &&
                  !accounts.length
                ) {
                  document.cookie =
                    'msal.interaction.status' +
                    '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'; //Delete interaction status cookie
                  instance.loginRedirect({
                    ...instance.config.auth.loginRequest,
                    extraQueryParameters: {
                      anonymousId: window?.analytics?.user?.()?.anonymousId?.(),
                    },
                  });
                }
                break;
              }
              default: {
                if (
                  event?.eventType === EventType.LOGIN_SUCCESS ||
                  event?.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
                ) {
                  setIsUserAuthenticated(true);
                  setIsUnAuthorizsedFlow(false);
                }
              }
            }
          },
          (error) => {
            console.log('addEventCallback failure', error);
          }
        );
        instance
          .handleRedirectPromise()
          .then((tokenResponse) => {
            if (tokenResponse) {
              instance.config.auth.authority =
                instance.config.auth.loginAuthority;
              instance.setActiveAccount(tokenResponse?.account);
              return tokenResponse;
            }
            const accounts = instance.getAllAccounts();
            const index = accounts.findIndex(
              ({ idTokenClaims }) =>
                idTokenClaims?.acr === 'b2c_1a_signup_signin'
            );
            if (accounts?.length) {
              if (!tokenResponse) {
                instance.config.auth.authority =
                  instance.config.auth.loginAuthority;

                return instance.acquireTokenSilent({
                  ...instance.config.auth.loginRequest,
                  account: accounts[index > -1 ? index : 0],
                  loginHint:
                    accounts[index > -1 ? index : 0].idTokenClaims.email,
                });
              }
            } else {
              fetchUnAuthToken();
            }
          })
          .then((response) => {
            if (response) {
              handleClose();
              const {
                idTokenClaims: {
                  extension_AdvantEdgeCard: cardNumber,
                  email: emailId,
                  given_name,
                  family_name,
                  idp,
                  extension_CustomerId,
                },
              } = response?.account;
              getSSOToken({
                name: `${given_name} ${family_name}`,
                emailId,
                cardNumber,
                identityProvider: idp || 'emailAddress',
                extension_CustomerId,
              });
            }
          })
          .catch((error) => {
            const { errorCode, name } = error;
            if (
              name === MSAL_SERVER_ERROR?.name &&
              errorCode === MSAL_SERVER_ERROR?.errorCode
            ) {
              fetchUnAuthToken();
            }
          });
      }
      setIsUnAuthorizsedFlow(isUnAuthorizsedUserFlow);
    }
  }, [featureFlagsData?.featureFlags]);

  useEffect(() => {
    if (
      profileData?.profile &&
      profileStatus === STATUS.RESOLVED &&
      (profileData?.profile?.loyalty_enabled || profileData?.profile?.ext_id)
    ) {
      runAsyncTokenData(
        upsertPartnerAccount(profileData?.profile).then((data) => ({
          token: data,
        }))
      );
    }
  }, [profileData?.profile]);
  useEffect(() => {
    if (unAuthTokenData?.unAuthToken) {
      sessionStorage.setItem('unAuthAccessToken', unAuthTokenData?.unAuthToken);
      sessionStorage.removeItem('accessToken');
    }
  }, [unAuthTokenData?.unAuthToken]);

  useEffect(() => {
    if (tokenData?.token) {
      sessionStorage.setItem('accessToken', tokenData?.token);
      sessionStorage.removeItem('unAuthAccessToken');
      const decodeAuthTokens = jwtDecode(tokenData?.token);
      window?.analytics?.alias(decodeAuthTokens?.userId);
    }
  }, [tokenData?.token]);

  const getvalidateAndGenerateToken = (userId, extUserId) => {
    runAsyncTokenData(
      validateAndGenerateToken(userId, code, extUserId).then((data) => ({
        token: data,
      }))
    );
  };

  const handleCloseToast = () => setUpsertFailSafeToast();

  const resetToolsForSchoolsInviteFriendsFlow = () => {
    setCode();
    setSourceUserName();
    setIsToolsForSchoolsInviteFriends(false);
  };

  if (featureFlagsStatus !== ACTIONS.STATUS.RESOLVED || !msalInstance) {
    return (
      <div className="text-center spinner">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (msalInstance) {
    return (
      <MsalProvider instance={msalInstance}>
        <AuthContext.Provider
          value={{
            isMigrated,
            userSessionData,
            ...asyncTokenData,
            unAuthToken: {
              data: unAuthTokenData?.unAuthToken,
              status: unAuthTokenStatus,
            },
            tokenStatus,
            unAuthTokenStatus,
            code,
            sourceUserName,
            isChampionsBorrower,
            userId,
            loanServicerId,
            isSSOEnabled,
            isToolsForSchoolsInviteFriends,
            switchSignUpSso,
            handleSwitchSignupSso,
            isUnAuthorizsedFlow,
            featureFlagsData,
            getvalidateAndGenerateToken,
            getSSOToken,
            resetSSOToken: () => {},
            checkUserSession,
            handleShowUnAuthenticatedUserModal,
            handleClose,
            isUserAuthenticated,
            resetToolsForSchoolsInviteFriendsFlow,
            ...props,
          }}
        >
          {props.children}

          {!!upsertFailSafeToast && (
            <UpsertFailSafeToast
              upsertFailSafeToast={upsertFailSafeToast}
              handleCloseToast={handleCloseToast}
            />
          )}
        </AuthContext.Provider>
      </MsalProvider>
    );
  } else {
    return (
      <AuthContext.Provider
        value={{
          isMigrated,
          userSessionData,
          ...asyncTokenData,
          tokenStatus,
          code,
          sourceUserName,
          isChampionsBorrower,
          userId,
          loanServicerId,
          isSSOEnabled,
          isToolsForSchoolsInviteFriends,
          switchSignUpSso,
          handleSwitchSignupSso,
          isUnAuthorizsedFlow,
          featureFlagsData,
          isUserAuthenticated,
          // resetSSOToken,
          getvalidateAndGenerateToken,
          getSSOToken,
          checkUserSession,
          handleShowUnAuthenticatedUserModal,
          handleClose,
          resetToolsForSchoolsInviteFriendsFlow,
          ...props,
        }}
      >
        {props.children}
      </AuthContext.Provider>
    );
  }
};

export default AuthContextState;
