import axiosInstance from '../utils/axiosInstance';
const useGenerateTokenPublic = (requestParams = null) => {
  const updatedReqParams = { ...requestParams, tokenType: 'NON_SIGNIN' };

  const promise = axiosInstance
    .post('/v1/auth/generateToken/public', updatedReqParams, {})
    .then((response) => ({ data: response?.data }));

  return promise;
};

export default useGenerateTokenPublic;
