export const EVENTS = {
  SHIP_TO_HOME_BACK_CLICKED: 'Redemption - Ship to Home - Back Clicked',
  SHIP_TO_HOME_CANCEL_CLICKED:
    'Redemption - Ship to Home - Cancel button Clicked',
  SHIP_TO_HOME_NEXT_CLICKED: 'Redemption - Ship To Home - Next Button Clicked',
  SHIPPING_NEXT_BUTTON_CLICKED:
    'Redemption - Ship To Home - Shipping Next Button Clicked',
  SHIP_TO_HOME_CONFIRM_CLICKED:
    'Redemption - Ship to Home - Confirm button Clicked',
  KNIFE_SUCCESS_DONE_CLICKED: 'Redemption - Ship To Home - Done button Clicked',
  SHIP_TO_HOME_ADD_NEW_ADDRESS_CLICKED:
    'Redemption - Ship To Home - Add new Address',
  LOG_IN_REGISTER_CLICKED: 'Log In / Register Clicked',
  CLICKED_LARGE_SANTOKU: 'Redemption - Ship To Home - Clicked Large Santoku',
};

export const PAGES = {
  VIEWED_SHIP_TO_HOME: 'Viewed Ship To Home',
  VIEWED_SHIPPING_INFO_PAGE: 'Viewed Shipping Info Page',
  VIEWED_SHIPPING_ADDRESS_LIST_PAGE: 'Viewed Shipping Address List Page',
  VIEWED_KNIFE_CONFIRMATION_MODAL: 'Viewed Knife Confirmation Modal',
  VIEWED_KNIFE_SUCCESS_MODAL: 'Viewed Knife Success Modal',
};

export const EVENT_LOCATION = {
  SHIP_TO_HOME_LANDING_PAGE: 'Ship To Home Landing Page',
  SHIPPING_INFO_PAGE: 'Shipping Info Page',
  SHIPPING_ADDRESS_LIST_PAGE: 'Shipping Address List Page',
  KNIFE_CONFIRMATION_MODAL: 'Knife Confirmation Modal',
  KNIFE_SUCCESS_MODAL: 'Knife Success Modal',
};

export const clickedEvent = (eventName = '') =>
  `Redemption - Ship To Home - Clicked ${eventName}`;
export const viewedEvent = (eventName = '') => `Viewed ${eventName} Details`;
export const locationEvent = (eventName = '') => `${eventName} Details Page`;

export const findEvents = (event) => {
  const EventUpper = event.toUpperCase().split(' ').join('_');
  return EVENTS[EventUpper] ?? event;
};
