import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  useAsync,
  ACTIONS,
  AuthContext,
  AppContext,
  PartnerContext,
} from '@rewards/contexts';
import { useUserInfo } from '@rewards/data-provider';
import { fetchInviteFriendsLink } from '../../services/toolsForSchoolsService';
import SchoolContext from './SchoolContext';
import ToolsForSchoolsContext from '../ToolsForSchoolsContext';

import { useToolsForSchools } from '../../util/hooks';
import {
  getSelectedSchoolDashboardPath,
  getToolsForSchoolsPath,
  getSignUpPath,
} from '../../util/paths';
import { SHARE_ACTIVITY_TYPE } from '../../util/constants';

const { useAuth } = AuthContext;
const { useApp } = AppContext;
const { usePartner } = PartnerContext;

const SchoolContextState = ({
  children,
  updateConnectSchool,
  fetchSchoolDetails,
  fetchStarsViaSocialShare,
  fetchLinkReferralUser,
}) => {
  const history = useHistory();
  const { checkValidToken, code } = useAuth();
  const { currencyExchanges } = useApp();
  const { data: userInfo } = useUserInfo();
  const { data: user } = userInfo || {};
  const { partner } = usePartner();
  const { getToolsForSchools, getSchoolForUser, isSignUpFlow } = useContext(
    ToolsForSchoolsContext
  );
  const { schoolForUser, toolsForSchools } = useToolsForSchools();

  const [selectedSchool, setSelectedSchool] = useState();
  const [advantPointsMax, setAdvantPointsMax] = useState(0);
  const [currencyExchange, setCurrencyExchange] = useState();

  const asyncInviteFriendsLink = useAsync({
    status: ACTIONS.STATUS.IDLE,
    data: { inviteFriendsLink: null },
  });
  const asyncConnectSchool = useAsync({
    status: ACTIONS.STATUS.IDLE,
    data: { connectSchool: null },
  });
  const asyncSchool = useAsync({
    status: ACTIONS.STATUS.IDLE,
    data: { school: null },
  });
  const asyncSocialShare = useAsync({
    status: ACTIONS.STATUS.IDLE,
    data: { socialShare: null },
  });
  const asyncInviteFriends = useAsync({
    status: ACTIONS.STATUS.IDLE,
    data: { inviteFriends: null },
  });
  const asyncLinkReferralUser = useAsync({
    status: ACTIONS.STATUS.IDLE,
    data: { linkReferralUser: null },
  });

  const {
    data: inviteFriendsLinkData,
    run: runAsyncInviteFriendsLink,
    status: inviteFriendsLinkStatus,
  } = asyncInviteFriendsLink;
  const {
    data: connectSchoolData,
    run: runAsyncConnectSchool,
    status: connectSchoolStatus,
  } = asyncConnectSchool;
  const {
    data: schoolData,
    run: runAsyncSchool,
    status: schoolStatus,
  } = asyncSchool;
  const { run: runAsyncSocialShare } = asyncSocialShare;
  const { run: runAsyncInviteFriends, status: inviteFriendsStatus } =
    asyncInviteFriends;
  const { run: runAsyncLinkReferralUser, status: linkReferralUserStatus } =
    asyncLinkReferralUser;

  const getInviteFriendsLink = () => {
    const params = {
      userId: user._id,
      type: 'REFERRAL',
      location: getToolsForSchoolsPath(),
    };
    runAsyncInviteFriendsLink(
      fetchInviteFriendsLink(params).then((data) => ({
        inviteFriendsLink: data,
      }))
    );
  };

  const updateSelectedSchool = (schoolId) => {
    // return new Promise((resolve) => {
    return checkValidToken().then(() => {
      return runAsyncConnectSchool(
        updateConnectSchool(schoolId).then((data) => {
          // return resolve({ connectSchool: data })
          return { connectSchool: data };
        })
      );
    });
    // })
  };

  const getSchoolDetails = (schoolId) => {
    checkValidToken().then(() => {
      runAsyncSchool(
        fetchSchoolDetails(schoolId).then((data) => ({ school: data }))
      );
    });
  };

  const updateActiveSchoolDetails = (schoolAccounts) => {
    const index = schoolAccounts?.findIndex(({ isActive }) => isActive);
    const activeSchoolAccount = schoolAccounts.splice(index, 1)?.[0];

    schoolAccounts.forEach((schoolAccount) => {
      if (schoolAccount?.schoolId === activeSchoolAccount?.schoolId) {
        activeSchoolAccount.pointsDonated += schoolAccount?.pointsDonated;
        activeSchoolAccount.starsEarnedOffline +=
          schoolAccount?.starsEarnedOffline;
        activeSchoolAccount.starsEarnedOnline +=
          schoolAccount?.starsEarnedOnline;
      }
    });
    if (
      activeSchoolAccount?.schoolId !== selectedSchool?.schoolId &&
      history?.location?.pathname !== getSignUpPath()
    ) {
      history.push(
        getSelectedSchoolDashboardPath(activeSchoolAccount?.schoolId)
      );
    }
    setSelectedSchool(activeSchoolAccount);
  };

  const getStarsViaSocialShare = () => {
    checkValidToken().then(() => {
      runAsyncSocialShare(
        fetchStarsViaSocialShare({
          activityType: SHARE_ACTIVITY_TYPE.SHARE_FACEBOOK,
        })
          .then((data) => ({ socialShare: data }))
          .then(() => {
            getSchoolForUser();
          })
      );
    });
  };

  useEffect(() => {
    if (code && user) {
      if (linkReferralUserStatus === ACTIONS.STATUS.IDLE) {
        checkValidToken().then(() => {
          return runAsyncLinkReferralUser(
            fetchLinkReferralUser({ code, userId: user?._id }).then((data) => ({
              linkReferralUser: data,
            }))
          );
        });
      }
      if (inviteFriendsStatus === ACTIONS.STATUS.IDLE) {
        checkValidToken().then(() => {
          return runAsyncInviteFriends(
            fetchStarsViaSocialShare({
              code,
              activityType: SHARE_ACTIVITY_TYPE.INVITE_FRIENDS,
            }).then((data) => ({ linkReferralUser: data }))
          );
        });
      }
    }
  }, [code, user]);

  useEffect(() => {
    if (user && partner && currencyExchanges && toolsForSchools) {
      const filteredCurrencyExchange = currencyExchanges.filter(
        (ce) =>
          ce.sourceCurrencyId === partner.currencyId &&
          ce.destCurrencyId === toolsForSchools?.currencyId
      );
      setCurrencyExchange(filteredCurrencyExchange?.[0]);
      setAdvantPointsMax(
        Math.min(
          filteredCurrencyExchange[0]?.sourceDebitMax,
          user?.partnerAccounts[0]?.accountBalance
        )
      );
      inviteFriendsLinkStatus === ACTIONS.STATUS.IDLE && getInviteFriendsLink();
    }
  }, [user, partner, currencyExchanges, toolsForSchools]);

  useEffect(() => {
    if (selectedSchool && toolsForSchools) {
      getSchoolDetails(selectedSchool?.schoolId);
    }
  }, [selectedSchool, toolsForSchools]);

  useEffect(() => {
    if (schoolForUser) {
      updateActiveSchoolDetails(schoolForUser?.schoolAccounts);
    }
  }, [schoolForUser]);

  useEffect(() => {
    if (connectSchoolData?.connectSchool && !isSignUpFlow) {
      const {
        connectSchool: { toolsForSchoolsId, schoolAccounts },
      } = connectSchoolData;

      updateActiveSchoolDetails(schoolAccounts);
      getToolsForSchools(toolsForSchoolsId);
    }
  }, [connectSchoolData?.connectSchool, isSignUpFlow]);

  return (
    <SchoolContext.Provider
      value={{
        schoolData,
        selectedSchool,
        schoolStatus,
        connectSchoolStatus,
        currencyExchange,
        advantPointsMax,
        inviteFriendsLinkData,
        updateSelectedSchool,
        getStarsViaSocialShare,
      }}
    >
      {children}
    </SchoolContext.Provider>
  );
};

export default SchoolContextState;
