import { useQuery } from '@tanstack/react-query';
import getCurrencyExchanges from '../services/useCurrencyExchanges';

export default function useCurrencyExchanges(enabled = false) {
  return useQuery({
    queryKey: ['currencyExchanges'],
    queryFn: getCurrencyExchanges,
    staleTime: 1000,
    enabled,
    retry: false,
  });
}
