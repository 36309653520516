import React, { useContext, useState } from 'react';
import { Modal, Col } from 'react-bootstrap';
import { Button } from '@rewards/ui';
import Icon from '../../assets/svg';
import styleConsts from '../../util/styleConsts';
import { useResponsive } from '../../util/hooks';

import './FAQs.scss';
import { ToolsForSchoolsContext } from '../../contexts';

const FAQs = () => {
  const [show, setShow] = useState(false);
  const { featureFlag } = useContext(ToolsForSchoolsContext);

  const { isDesktop } = useResponsive();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Col
        className={`p-0 flex justify-center ${
          featureFlag?.otherDetails?.commingSoon ? 'hidden' : ''
        }`}
      >
        <Button intent="link" onClick={handleShow}>
          <Icon
            icon="link-0"
            width="15"
            height="16"
            color={styleConsts.cobalt}
          />
          <span className="ml-2">FAQs</span>
        </Button>
      </Col>

      <Modal
        size="lg"
        backdrop="static"
        keyboard={false}
        className="tfs-FAQs-modal"
        dialogClassName="modal-90w"
        scrollable
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title
            className={`tfs-FAQs-title w-100 ${isDesktop && 'text-center'}`}
          >
            Tools for Schools - FAQs
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-justify">
          <Col className="px-0 pb-2 tfs-FAQs-heading">
            What is Tools for Schools?
          </Col>
          <Col className="px-0 tfs-FAQs-subheading">
            Tools for Schools is a program at Price Chopper / Market 32 that
            allows customers to shop with their AdvantEdge Card and earn Stars
            for their local schools. It's easy to participate and at the end of
            the program, schools can choose from a catalog of educational
            equipment. This year's promotion runs through February 15, 2025.
          </Col>

          <Col className="px-0 pb-2 mt-3 tfs-FAQs-heading">
            I may have signed up for Tools for Schools in a previous year. Do I
            need to enroll again?
          </Col>
          <Col className="px-0 tfs-FAQs-subheading">
            If you were enrolled in last year's program you do not need to
            register, but you can still confirm your school.
          </Col>

          <Col className="px-0 pb-2 mt-3 tfs-FAQs-heading">
            How do I enroll my AdvantEdge Card in Tools for Schools?{' '}
          </Col>
          <Col className="px-0 tfs-FAQs-subheading">
            Tools for Schools is a page within{' '}
            <a
              href={window.location.origin}
              target="_blank"
              className="tfs-link"
              rel="noreferrer"
            >
              rewards.pricechopper.com
            </a>
            <ol className="pl-2 tfs-FAQs-ol">
              <li className="tfs-FAQs-ol-li">
                Enter Tools for Schools by clicking on the icon
              </li>
              <li className="tfs-FAQs-ol-li">
                Search for your school by name or by zip code
              </li>
              <li className="tfs-FAQs-ol-li">
                Choose who you'd like to support from the list of schools
              </li>
            </ol>
          </Col>

          <Col className="px-0 pb-2 mt-3 tfs-FAQs-heading">
            My school isn't listed. What should I do?
          </Col>
          <Col className="px-0 tfs-FAQs-subheading">
            Contact school officials and tell them about the program. If school
            administration supports the effort, they can contact a Tools for
            Schools representative at{' '}
            <a href="tel:(800) 352-4658" className="tfs-num">
              (800) 352-4658
            </a>
            .
          </Col>

          <Col className="px-0 pb-2 mt-3 tfs-FAQs-heading">
            How does enrolling my AdvantEdge Card help the school of my choice?
          </Col>
          <Col className="px-0 pb-2 tfs-FAQs-subheading">
            The Tools for Schools platform enables your shopping at Price
            Chopper / Market 32 to earn Stars for your registered school. One
            (1) AdvantEdge Rewards point earned = One (1) Star earned. Stars are
            credited weekly and can be seen on the Tools for Schools page at
            Schools page at{' '}
            <a
              href={window.location.origin}
              target="_blank"
              className="tfs-link"
              rel="noreferrer"
            >
              rewards.pricechopper.com
            </a>
            .
          </Col>
          <Col className="px-0 pb-2 tfs-FAQs-subheading">
            At the conclusion of the promotion, each participating school will
            get their share of over $200,000 in educational equipment. That
            share is based on their proportion of total Stars and given to
            schools in the form of Catalog Points for schools to use to acquire
            educational equipment.
          </Col>

          <Col className="px-0 pb-2 mt-3 tfs-FAQs-heading">
            Does everything I buy at Price Chopper Market 32 count toward Tools
            for Schools?
          </Col>
          <Col className="px-0 pb-2 tfs-FAQs-subheading">
            Almost everything you buy with your enrolled AdvantEdge Card will
            accumulate AdvantEdge Rewards points and therefore add Stars to your
            school’s total. Promotion excludes purchases of Price Chopper,
            Market 32 and Market Bistro gift cards, alcohol, tobacco, money
            orders, Western Union, lottery tickets, postage stamps, tickets,
            bottle deposits, copy/fax services, taxes and other items prohibited
            by law, including prescription co-pays in NY. Points are calculated
            after all of the aforementioned items, all store discounts and all
            coupons (including manufacturers’ coupons) have been subtracted from
            the order. Visit{' '}
            <a
              href="https://www.pricechopper.com/rewards/"
              target="_blank"
              className="tfs-link"
              rel="noreferrer"
            >
              pricechopper.com/rewards
            </a>{' '}
            for full details on earning AdvantEdge Rewards points.
          </Col>

          <Col className="px-0 pb-2 mt-3 tfs-FAQs-heading">
            How else can I help my school earn more educational equipment?
          </Col>
          <Col className="px-0 pb-2 tfs-FAQs-subheading">
            First, you may follow the share links within the Tools for Schools
            page on{' '}
            <a
              href={window.location.origin}
              target="_blank"
              className="tfs-link"
              rel="noreferrer"
            >
              rewards.pricechopper.com
            </a>{' '}
            to tell your family and friends about the program. Each share earns
            your school more Stars.
          </Col>
          <Col className="px-0 pb-2 tfs-FAQs-subheading">
            Second, you may donate your AdvantEdge Rewards points directly to
            your school at{' '}
            <a
              href={window.location.origin}
              target="_blank"
              className="tfs-link"
              rel="noreferrer"
            >
              rewards.pricechopper.com
            </a>
            . Donated Rewards points are redeemed on the Digital Rewards
            platform and converted to Tools for Schools Catalog Points. 100
            Rewards points donated = 100 Catalog Points. Plus, Price Chopper
            will match the value of donated Rewards points up to $35,000!
          </Col>

          <Col className="px-0 pb-2 mt-3 tfs-FAQs-heading">
            Can I change my designated school?
          </Col>
          <Col className="px-0 tfs-FAQs-subheading">
            Yes. You may change your designation on the Tools for Schools page
            in the Digital Rewards platform at any time during the promotion.
            Your designated school at the end of each week is who will be
            credited with Stars for shopping within that week.
          </Col>

          <Col className="px-0 pb-2 mt-3 tfs-FAQs-heading">
            How much can my school earn in educational equipment?
          </Col>
          <Col className="px-0 tfs-FAQs-subheading">
            Price Chopper has committed a retail value of over $200,000 to
            support schools in the communities we serve. Your school will earn
            its share of this total based on their percent of total Stars earned
            across all schools and converted to Tools for Schools Catalog
            Points. Additionally, the value of donated points to each school
            will be added to their Catalog Points.
          </Col>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FAQs;
