import React, { useEffect, useState, useContext } from 'react';
import { Spinner, Alert, Col, Container, Image, Row } from 'react-bootstrap';
import { Button } from '@rewards/ui';
import { useHistory } from 'react-router-dom';
import { useUserInfo } from '@rewards/data-provider';
import { AppContext } from '@rewards/contexts';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { AuthContext, ACTIONS } from '@rewards/contexts';

import BackNav from '../BackNav';

import { getLandingPath, getEarnPath } from '../../util/paths';
import { FEATURES_FLAGS } from '../../util/constants';
import { PAGES, events, EVENT_LOCATION, EVENTS } from '../../analytics/events';

import './UserProfile.scss';
import { checkIsDesktop } from '../../util/hooks/useResponsive';
import { BonusPointsBanner } from '@rewards/bonus-points';

const { useAuth } = AuthContext;

const UserProfile = () => {
  const {
    featureFlags,
    isUserAuthenticated,
    unAuthToken,
    isSSOEnabled,
    isMigrated,
  } = useAuth();
  const { source, track } = useContext(AppContext.AppContext);
  const { data: userInfo } = useUserInfo() || {};
  const user = userInfo?.data;
  const [userName, setUserName] = useState('');
  const { instance, accounts } = useMsal();

  const history = useHistory();

  useEffect(() => {
    source(PAGES.LOYALTY_CONNECT_USER_PROFILE, {
      ui_version: import.meta.env.APP_VERSION,
    });
  }, []);

  useEffect(() => {
    if (
      !(
        isUserAuthenticated &&
        featureFlags.find(
          (ff) => ff.name === FEATURES_FLAGS.ENABLE_USER_PROFILE && ff.isActive
        )
      ) &&
      unAuthToken?.status === ACTIONS.STATUS.RESOLVED
    ) {
      history.push(getLandingPath());
    }
  }, [isUserAuthenticated, featureFlags, unAuthToken]);

  const handleRedeemPointsClick = () => {
    track(EVENTS.LOYALTY_CONNECT_REWARDS_PROFILE_REDEEM_CLICKED, {
      ui_version: import.meta.env.APP_VERSION,
    });
    history.push(getLandingPath());
  };

  const handleEarnClick = () => {
    track(EVENTS.LOYALTY_CONNECT_REWARDS_PROFILE_EARN_CLICKED, {
      ui_version: import.meta.env.APP_VERSION,
    });
    history.push(getEarnPath());
  };

  useEffect(() => {
    if (isSSOEnabled && accounts?.length) {
      const currentAccount = accounts?.[0];
      const {
        idTokenClaims: { given_name, family_name },
      } = currentAccount;
      if (isMigrated) {
        setUserName(user?.partnerAccounts?.[0]?.profile?.name);
      } else {
        setUserName(`${given_name} ${family_name}`);
      }
    }
  }, [accounts, isSSOEnabled]);

  return (
    <Container className="flex flex-col">
      {!user ? (
        <div id="spinner-icon">
          <div className="text-center spinner">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        </div>
      ) : (
        <>
          <BonusPointsBanner className="mt-1" />
          {checkIsDesktop() && (
            <div className="py-4 text-center lc-profile-rewards-profile-header">
              My Rewards Profile
            </div>
          )}
          <div
            className={`${
              checkIsDesktop() ? '' : 'py-4'
            } pb-4 text-center lc-profile-rewards-profile-header-name`}
          >
            {userName}
          </div>
          <div className="flex justify-center">
            <Image
              fluid
              src="https://cdn.spinwheel.io/images/tcc/profile-image.png"
            />
          </div>
          <div className="text-center py-2 lc-profile-rewards-profile-card-number">
            Card #: {user?.partnerAccounts?.[0]?.cardNumber}
          </div>
          <div className="py-2 text-center bg-white mx-auto my-4 lc-profile-rewards-profile-rewards-points-card">
            <div className="lc-profile-rewards-profile-rewards-points-card-header">
              You currently have:{' '}
            </div>

            <div className="lc-profile-rewards-profile-rewards-points-card-points">
              {user?.partnerAccounts?.[0]?.accountBalance}
            </div>

            <div className="lc-profile-rewards-profile-rewards-points-card-subtext">
              points
            </div>
          </div>
          <Row>
            <Col md="12">
              <div className="align-self-center lc-profile-rewards-points-button-box">
                <Row>
                  <Col
                    md="6"
                    sm="6"
                    xs="6"
                    className="text-center flex align-items-stretch pt-2"
                  >
                    <Button
                      width="full"
                      // className='mx-auto py-2 lc-profile-rewards-profile-rewards-points-redeem-button border-0'
                      onClick={handleRedeemPointsClick}
                    >
                      Redeem Points
                    </Button>
                  </Col>
                  <Col
                    md="6"
                    sm="6"
                    xs="6"
                    className="text-center flex align-items-stretch pt-2"
                  >
                    <Button
                      width="full"
                      // className='mx-auto py-2 lc-profile-rewards-profile-rewards-points-redeem-button border-0'
                      onClick={handleEarnClick}
                    >
                      Earn Points
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default BackNav(UserProfile);
