import axiosInstance from '../utils/axiosInstance';
const useDisconnectStudentLoan = (requestParams = null) => {
  const promise = axiosInstance
    .post('/api/users/disconnectStudentLoanAccount', requestParams, {})
    .then((response) => ({ data: response?.data }));

  return promise;
};

export default useDisconnectStudentLoan;
