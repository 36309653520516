import { toTitleCase } from '../util/formatters';

export const events = (events = '', tab = '') => {
  let location = events;
  let event = '';

  switch (events) {
    case ('earn', 'redeem'):
      location = 'Top Nav';
      break;
    default:
      location = events;
  }
  event = `${tab === 'earn' ? 'Earning' : 'Redemption'} - ${toTitleCase(
    location
  )} - ${toTitleCase(events)} Button Clicked`;

  return [`${events} CLICKED`, event];
};

export const findEvents = (event) => {
  const EventUpper = event[0].toUpperCase().split(' ').join('_');
  return EVENTS[EventUpper] ?? event[1];
};

export const PAGE_EVENT = (page_name = '') => {
  return `${toTitleCase(page_name)}`;
};

export const EVENTS = {
  LOG_IN_REGISTER_CLICKED: 'Header - Log In / Register Clicked',
  LOG_OUT_CLICKED: 'Header - Username - Log Out Clicked',
  RESET_PASSWORD_CLICKED: 'Header - Username - Reset Password Clicked',
  LOYALTY_CONNECT_UI_ERROR: 'Page - UI Error',
  LOYALTY_CONNECT_REDEEM_BONUS_POINTS_INFO_CLICKED:
    'Redeem Bonus Points - Info Clicked',
  LOYALTY_CONNECT_BONUS_POINTS_REDEEMED:
    'Redeem Bonus Points - Bonus Points Redeemed',
  LOYALTY_CONNECT_CLOSE_CLICKED: 'Popup - Close Clicked',
  LOYALTY_CONNECT_TERMS_AND_CONDITIONS_CLICKED:
    'Redemption - Terms And Conditions Link Clicked',
  LOYALTY_CONNECT_FAQS_CLICKED: 'Redemption - FAQs Link Clicked',
  LOYALTY_CONNECT_PROFILE_BACK_CLICKED:
    'Profile - My Rewards Profile Back Button Clicked',
  LOYALTY_CONNECT_DROP_DOWN_MENU_CLICKED:
    'Header - Profile - Drop Down Clicked',
  LOYALTY_CONNECT_MY_REWARDS_SHOP_ONLINE_CLICKED:
    'Header - Profile - Shop Online Clicked',
  LOYALTY_CONNECT_MY_REWARDS_PROFILE_CLICKED:
    'Header - Profile - My Rewards Profile Clicked',
  LOYALTY_CONNECT_VIEWED_MY_REWARDS_PROFILE_PAGE:
    'Profile - My Rewards Profile Viewed',
  LOYALTY_CONNECT_REWARDS_PROFILE_REDEEM_CLICKED:
    'Profile - My Rewards Profile Redeem Button Clicked',
  LOYALTY_CONNECT_REWARDS_PROFILE_EARN_CLICKED:
    'Profile - My Rewards Profile Earn Button Clicked',
  EARN_CLICKED: 'Top Nav - Earn Button Clicked',
  REDEEM_CLICKED: 'Top Nav - Redeem Button Clicked',
  DONATE_CLICKED: 'Redemption - Donate - Donate Button Clicked',
  SWEEPSTAKES_CLICKED: 'Redemption - Sweepstakes - Sweepstake Button Clicked',
  STUDENT_LOAN_REFINANCE_CLICKED:
    'Earning - Student Loan Refinance - Student Loan Refinance Button Clicked',
  PRODUCTS_CLICKED: 'Redemption - Products - Products Button Clicked',
  FOOD_CLICKED: 'Redemption - Food - Food Button Clicked',
  FUEL_CLICKED: 'Redemption - Fuel - Fuel Button Clicked',
  SHIP_TO_HOME_CLICKED: 'Redemption - Ship To Home - Ship To Home Clicked',
  MAGAZINES_CLICKED: 'Redemption - Magazines - Magazines Button Clicked',
  GIFT_CARDS_CLICKED: 'Earning - Gift Cards - Gift Cards Clicked',
  SHOP_ONLINE_CLICKED: 'Earning - Shop Online - Shop Online Button Clicked',
  SHOP_IN_STORE_CLICKED:
    'Earning - Shop In Store - Shop In Store Button Clicked',
  DAILY_SURPRISE_CLICKED:
    'Earning - Daily surPrize - Daily surPrize Button Clicked',
  BANNER_CLICKED: 'Earning - Banner - Daily surPrize Button Clicked',
  UTILITY_OFFERS_CLICKED:
    'Earning - Green Mountain Energy - Earn Button Clicked',
  POPUP_SWEEPSTAKES_CLICKED: 'Popup - Sweepstakes - Clicked',
  POPUP_DAILY_SURPRISE_CLICKED: 'Popup - Daily surPrize - Clicked',
  BANNER_BUTTON_CLICKED: 'Banner - Button clicked',
  SURVEY_SHARE_BUTTON_CLICKED: 'Earning - Survey - Share Button Clicked',
  SURVEY_COPY_LINK_BUTTON_CLICKED:
    'Earning - Survey - Copy Link Button Clicked',
  SURVEY_SHARE_LINK_BUTTON_CLICKED:
    'Earning - Survey - Share Link Button Clicked',
  SURVEY_CLOSE_BUTTON_CLICKED: 'Earning - Survey - Close Button Clicked',
  SURVEY_EARN_BUTTON_CLICKED: 'Earning - Survey - Earn Button Clicked',
  SURVEY_REDEEM_BUTTON_CLICKED: 'Earning - Survey - Redeem Button Clicked',
};

export const PAGES = {
  LOYALTY_CONNECT_BONUS_POINTS: 'Redeem Bonus Points Page',
  LOYALTY_CONNECT_TERMS_AND_CONDITIONS_MODAL: 'Popup - Terms And Conditions',
  LOYALTY_CONNECT_FAQS_MODAL: 'Popup - FAQs',
  LOYALTY_CONNECT_USER_PROFILE: 'Profile - My Rewards Profile Viewed',
  POPUP_SWEEPSTAKES_VIEWED: 'Popup - Sweepstakes - Viewed',
  POPUP_DAILY_SURPRISE_VIEWED: 'Popup - Daily surPrize - Viewed',
  LOYALTY_CONNECT_SURVEY: 'Survey - Page Viewed',
};

export const EVENT_LOCATION = {
  LOYALTY_CONNECT_BONUS_POINTS: PAGES.LOYALTY_CONNECT_BONUS_POINTS,
  LOYALTY_CONNECT_TERMS_AND_CONDITIONS_MODAL:
    PAGES.LOYALTY_CONNECT_TERMS_AND_CONDITIONS_MODAL,
  LOYALTY_CONNECT_FAQS_MODAL: PAGES.LOYALTY_CONNECT_FAQS_MODAL,
  LOYALTY_CONNECT_USER_PROFILE_PAGE: PAGES.LOYALTY_CONNECT_USER_PROFILE,
  LOYALTY_CONNECT_SURVEY_PAGE: PAGES.LOYALTY_CONNECT_SURVEY,
};
