import React, { useContext, useEffect, useState } from 'react';
import { Modal, Form, Col, Spinner } from 'react-bootstrap';
import { Button } from '@rewards/ui';
import { ACTIONS, AppContext } from '@rewards/contexts';
import Downshift from 'downshift';
import matchSorter from 'match-sorter';

import { ToolsForSchoolsContext } from '../../../contexts';
import {
  useToolsForSchools,
  useResponsive,
  useSchool,
} from '../../../util/hooks';
import { EVENTS, PAGES, EVENT_LOCATION } from '../../../analytics/events';
import ModalCloseBtn from '../ModalCloseBtn';
import { SCHOOL_SEARCH_ID } from '../../../util/constants';
import './FindSchoolModal.scss';
import styleConst from '../../../util/styleConsts';

const FindSchoolModal = ({
  show,
  handleClose,
  handleFindSchoolModal,
  handleConfirmModal,
  isLoading = false,
  handleDontSeeSchoolModal,
  ...props
}) => {
  const { source, track } = useContext(AppContext.AppContext);
  const { isSignUpFlow } = useContext(ToolsForSchoolsContext);
  const { schoolsStatus } = useToolsForSchools();
  const { school } = useSchool();
  const { isDesktop } = useResponsive();

  useEffect(() => {
    if (props.zipCode === '' && props.schoolName === '') {
      props.setRelativeFilteredSchoolList(props.schoolList);
      props.setRelativeFilteredZipCodeList(props.zipCodeList);
    } else {
      props.setRelativeFilteredSchoolList(
        matchSorter(props.schoolList, props.zipCode, { keys: ['zipCode'] })
      );
      props.setRelativeFilteredZipCodeList(
        matchSorter(props.zipCodeList, props.schoolName, {
          keys: ['schoolName'],
        })
      );
    }
  }, [props.zipCode, props.schoolName]);

  useEffect(() => {
    if (show) {
      source(PAGES.TOOLS_FOR_SCHOOLS_CONNECT_SCHOOL_MODAL, {
        ui_version: import.meta.env.APP_VERSION,
      });
    }
  }, [show]);

  const itemToString = (item) => (item ? item.name : '');

  const stateReducer = (state, changes) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownEnter:
        return {
          ...changes,
          isOpen: false,
          highlightedIndex: state.highlightedIndex,
        };
      case Downshift.stateChangeTypes.clickItem:
        return {
          ...changes,
          isOpen: false,
          highlightedIndex: state.highlightedIndex,
        };
      default:
        return changes;
    }
  };

  const handleGetSchools = (event) => {
    props.handleSchoolSearchId(event.target.id);
    if (event.target.id === SCHOOL_SEARCH_ID.GET_SCHOOL) {
      if (props.schoolName || props.zipCode) {
        props.setFilteredSchools(
          getFilteredSchoolZipCodeList(props.schoolsList)
        );
      } else if (props.schoolName === '' && props.zipCode === '') {
        props.setFilteredSchools(props.schoolsList);
      } else {
        return;
      }
    }

    handleConfirmModal && handleConfirmModal();
    handleClose();
  };

  const handleCloseModal = () => {
    track(EVENTS.TOOLS_FOR_SCHOOLS_CLOSE_CLICKED, {
      location: EVENT_LOCATION.TOOLS_FOR_SCHOOLS_CONNECT_SCHOOL_MODAL,
      ui_version: import.meta.env.APP_VERSION,
    });
    handleClose(true);
  };

  const handleSchool = () => {
    if (!isSignUpFlow) {
      if (school) {
        track(EVENTS.TOOLS_FOR_SCHOOLS_NOT_YOUR_SCHOOL_CLICKED, {
          location: EVENT_LOCATION.TOOLS_FOR_SCHOOLS_SELECTED_SCHOOL_DASHBOARD,
          ui_version: import.meta.env.APP_VERSION,
        });
      } else {
        track(EVENTS.TOOLS_FOR_SCHOOLS_SELECT_YOUR_SCHOOL_CLICKED, {
          location: EVENT_LOCATION.TOOLS_FOR_SCHOOLS_DETAILS_DASHBOARD,
          ui_version: import.meta.env.APP_VERSION,
        });
      }
    }

    handleFindSchoolModal();
  };

  const getFilteredSchoolZipCodeList = (schoolsList) => {
    const sortedSchoolName = matchSorter(schoolsList, props.schoolName, {
      keys: [{ threshold: matchSorter.rankings.CONTAINS, key: 'name' }],
    });
    return matchSorter(sortedSchoolName, props.zipCode, {
      keys: [{ threshold: matchSorter.rankings.CONTAINS, key: 'zipCode' }],
    });
  };

  return (
    <>
      <Button
        // className={
        //   `tfs-${school ? 'not' : 'find'}-school-modal-btn
        //   borderless
        //   rounded-pill
        //   ${school ? 'p-2' : 'p-3'}
        //   ${isDesktop && !school ? 'mt-3' : 'mt-3'}
        // `}
        onClick={handleSchool}
        block={school || isDesktop ? false : true}
        disabled={schoolsStatus === ACTIONS.STATUS.PENDING || props?.disabled}
      >
        {isLoading && (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="mx-2 align-middle"
          />
        )}
        {school ? 'NOT YOUR SCHOOL?' : 'SELECT YOUR SCHOOL'}
      </Button>

      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        centered={true}
        dialogClassName={`tfs-find-school-modal rounded' ${
          isDesktop ? '' : 'tfs-mobile-dialog mx-auto'
        }`}
        contentClassName="border-0 tfs-find-content-modal"
      >
        <Modal.Header className="border-0">
          <Modal.Title className="tfs-find-school-modal-title">
            Find your school
          </Modal.Title>
          <ModalCloseBtn handleClose={handleCloseModal} />
        </Modal.Header>
        <Modal.Body className="tfs-find-school-body">
          <Downshift
            stateReducer={stateReducer}
            onChange={(selection) => {
              props.ChangeSchoolName(selection);
            }}
            itemToString={itemToString}
          >
            {({
              getLabelProps,
              getInputProps,
              getMenuProps,
              getItemProps,
              highlightedIndex,
              isOpen,
              inputValue,
              openMenu,
            }) => {
              return (
                <div>
                  <Form.Label
                    {...getLabelProps()}
                    className="tfs-find-school-label"
                  >
                    Enter school name
                  </Form.Label>
                  <Form.Control
                    {...getInputProps({
                      onFocus: openMenu,
                      onChange: props.handleSchoolName,
                      value: props?.schoolName || '',
                    })}
                    className="tfs-find-school-input border-0 p-2"
                    type="text"
                    placeholder="School Name"
                    autoComplete="off"
                  />
                  <ul
                    {...getMenuProps({
                      style: isOpen
                        ? {
                            listStyleType: 'none',
                            paddingLeft: '15px',
                            height: 100,
                            overflowY: 'scroll',
                            backgroundColor: styleConst.white,
                          }
                        : {},
                    })}
                  >
                    {isOpen
                      ? matchSorter(
                          props.relativeFilteredSchoolList,
                          inputValue,
                          {
                            keys: [
                              {
                                threshold: matchSorter.rankings.CONTAINS,
                                key: 'name',
                              },
                            ],
                          }
                        ).map((item, index) => (
                          <li
                            key={item.id}
                            {...getItemProps({
                              item,
                              key: item.id,
                              style: {
                                backgroundColor:
                                  index === highlightedIndex
                                    ? styleConst.gainsboro
                                    : null,
                              },
                            })}
                          >
                            {item.name}
                          </li>
                        ))
                      : null}
                  </ul>
                </div>
              );
            }}
          </Downshift>

          <Col className="tfs-find-school-divider text-center mt-4 mb-3">
            OR
          </Col>

          <Downshift
            stateReducer={stateReducer}
            onChange={(selection) => {
              props.ChangeZipCode(selection);
            }}
            itemToString={itemToString}
          >
            {({
              getLabelProps,
              getInputProps,
              getMenuProps,
              getItemProps,
              highlightedIndex,
              isOpen,
              inputValue,
              openMenu,
            }) => {
              return (
                <div>
                  <Form.Label
                    {...getLabelProps()}
                    className="tfs-find-school-label"
                  >
                    Enter school ZIP code
                  </Form.Label>
                  <Form.Control
                    {...getInputProps({
                      onFocus: openMenu,
                      onChange: props.handleZipCode,
                      value: props?.zipCode || '',
                    })}
                    className="tfs-find-school-input border-0 p-2"
                    type="text"
                    placeholder="School ZIP code"
                    autoComplete="off"
                  />
                  <ul
                    {...getMenuProps({
                      style: isOpen
                        ? {
                            listStyleType: 'none',
                            paddingLeft: '15px',
                            height: 100,
                            overflowY: 'scroll',
                            backgroundColor: styleConst.white,
                          }
                        : {},
                    })}
                  >
                    {isOpen
                      ? matchSorter(
                          props.relativeFilteredZipCodeList,
                          inputValue,
                          {
                            keys: [
                              {
                                threshold: matchSorter.rankings.CONTAINS,
                                key: 'name',
                              },
                            ],
                          }
                        ).map((item, index) => (
                          <li
                            key={item.id}
                            {...getItemProps({
                              item,
                              key: item.id,
                              style: {
                                backgroundColor:
                                  index === highlightedIndex
                                    ? styleConst.gainsboro
                                    : null,
                              },
                            })}
                          >
                            {item.name}
                          </li>
                        ))
                      : null}
                  </ul>
                </div>
              );
            }}
          </Downshift>
        </Modal.Body>
        <Modal.Footer className="bg-white flex !justify-center tfs-find-footer">
          <Button
            width="full"
            id={SCHOOL_SEARCH_ID.GET_SCHOOL}
            block
            disabled={schoolsStatus === ACTIONS.STATUS.PENDING}
            onClick={handleGetSchools}
          >
            GET STARTED
          </Button>
          <Button
            intent="secondary"
            width="full"
            onClick={handleGetSchools}
            id={SCHOOL_SEARCH_ID.ALL_SCHOOL}
            block
            disabled={schoolsStatus === ACTIONS.STATUS.PENDING}
          >
            View All Schools
          </Button>
          <Button
            intent="tertiary"
            width="full"
            onClick={() => {
              handleDontSeeSchoolModal();
              handleClose();
            }}
          >
            Don't see your school?
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FindSchoolModal;
