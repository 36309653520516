declare const window: Window & { dataLayer: object[] };

const dataLayer = window.dataLayer || [];

export type GVariable = Record<string, string | number>;

export interface GEvent {
  event: string;
  data?: GVariable;
}
const gtag = (args: GEvent | GVariable) => dataLayer.push(args);

export const emitEvent = (event: string, data: GVariable) => {
  gtag({
    event,
    data,
  });
};

export const emitGVariables = (data: GVariable) => {
  gtag(data);
};
