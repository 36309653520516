import React, { useState, useContext, useEffect, Fragment } from 'react';
import { Route, Switch, useHistory, Redirect } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Spinner, Nav, Row, Col, Container } from 'react-bootstrap';
import { AppContext, ACTIONS, AuthContext } from '@rewards/contexts';
import { Link } from 'react-router-dom';

import DropInModulesNavLinks from '../DropInModulesNavLinks';
import { BonusPointsPage } from '../BonusPoints';
import AuthenticaUserSSO from '../AuthenticaUserSSO';
import SignUp from '../SignUp';
import UserProfile from '../UserProfile';

import {
  getLandingPath,
  getCategoryPath,
  getClaimBonusPagePath,
  getSignUpPath,
  getProfilePath,
  getSurveyPath,
} from '../../util/paths';
import useLocalStorage from '../../util/useLocalStorage';
import { useLoyaltyConnect } from '../../util/hooks';
import { formatPath } from '../../util/formatters';
import { FEATURES_FLAGS } from '../../util/constants';
import { LoyaltyConnectContext } from '../../contexts/LoyaltyConnectContext';
import Icon from '../../assets/svg';
import { events, findEvents } from '../../analytics/events';

import './LandingPage.scss';
import PromoteSweepstake from '../PromoteSweepstake';
import styles from '../../util/styleConsts';
import Survey from '../Survey';
import BannerWrapper from '../BannerWrapper';

const { useAuth } = AuthContext;
function LandingPage() {
  const { isUnAuthorizsedFlow } = useContext(AuthContext.AuthContext);
  const { track } = useContext(AppContext.AppContext);
  const { isDesktop, isRedeemBalancePage } = useContext(LoyaltyConnectContext);

  const history = useHistory();
  const {
    code,
    featureFlags,
    sourceUserName,
    loanServicerId,
    isToolsForSchoolsInviteFriends,
  } = useAuth();
  const { registry, registryStatus } = useLoyaltyConnect();

  const [key, setKey] = useState('');
  const [isFirstTimeSharedBorrower, setisFirstTimeSharedBorrower] =
    useState(false);

  const [isLoaded, setIsLoaded] = useLocalStorage('isLoaded', false);
  const [isStudentLoanLoaded, setIsStudentLoanLoaded] = useLocalStorage(
    'isStudentLoanLoaded',
    false
  );
  const [enableComingSoonUtilityOffers, setEnableComingSoonUtilityOffers] =
    useState(false);

  useEffect(() => {
    if (
      (code || loanServicerId) &&
      sourceUserName &&
      !isToolsForSchoolsInviteFriends &&
      isUnAuthorizsedFlow
    ) {
      setisFirstTimeSharedBorrower(true);
    } else {
      setisFirstTimeSharedBorrower(false);
    }
  }, [
    code,
    loanServicerId,
    sourceUserName,
    isToolsForSchoolsInviteFriends,
    isUnAuthorizsedFlow,
  ]);

  useEffect(() => {
    if (
      featureFlags.find(
        (ff) =>
          ff.name === FEATURES_FLAGS.ENABLE_COMING_SOON_UTILITY_OFFERS &&
          !ff.isActive
      )
    ) {
      setEnableComingSoonUtilityOffers(true);
    }
  }, [featureFlags]);

  function updateCurrentKey() {
    const {
      location: { pathname },
    } = history;
    let updateKey = pathname.split('/')[1];
    if (!registry[updateKey]) {
      updateKey = Object.keys(registry)[0];
    }
    setKey(updateKey);
  }

  useEffect(() => {
    setIsLoaded(false);
    setIsStudentLoanLoaded(false);
  }, []);

  useEffect(() => {
    if (registry) {
      updateCurrentKey();
      const unlisten = history.listen(updateCurrentKey);
      return () => unlisten();
    }
  }, [registry]);

  if (registryStatus !== ACTIONS.STATUS.RESOLVED)
    return (
      <div id="spinner-icon">
        <div className="text-center spinner">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      </div>
    );

  const getRenderComponent = (key, data = '') => {
    if (registry[key]) {
      return (
        <DropInModulesNavLinks registryContent={registry[key]} mainTab={key} />
      );
    }
    return <div>Create component</div>;
  };

  const handleSelect = async (k, e) => {
    track(findEvents(events(k, k)), {
      ui_version: import.meta.env.APP_VERSION,
    });
    setKey(k);
  };
  return (
    <Fragment>
      <Container fluid className="p-0">
        {!isFirstTimeSharedBorrower && (
          <Row className="registry-tabs align-items-center m-0">
            {isDesktop && (
              <Col md="2" className="text-left">
                <Link to={getLandingPath()}>
                  <Icon
                    icon="advantEdge-rewards"
                    height="30"
                    width="100"
                    color={styles.cobalt}
                  />
                </Link>
              </Col>
            )}
            <Col
              className={`tabs rounded-pill align-self-start ${
                isDesktop ? '' : 'mb-3'
              }`}
              md={isDesktop ? 3 : null}
            >
              <Nav
                variant="pills"
                className="justify-content-around text-center nav-fill"
                activeKey={key}
                defaultActiveKey={key}
                onSelect={handleSelect}
              >
                {Object.keys(registry).map((registryKey, index) => {
                  return (
                    <LinkContainer
                      to={getLandingPath(index === 0 ? '' : registryKey)}
                      key={index}
                    >
                      <Nav.Item
                        className="text-capitalize"
                        id={`${registryKey}-nav-item`}
                      >
                        <Nav.Link
                          href={getLandingPath(index === 0 ? '' : registryKey)}
                          eventKey={registryKey}
                          className="rounded-pill text-capitalize l-nav-link"
                        >
                          {registryKey}
                        </Nav.Link>
                      </Nav.Item>
                    </LinkContainer>
                  );
                })}
              </Nav>
            </Col>
            {isDesktop && (
              <Col className="">
                <AuthenticaUserSSO />
              </Col>
            )}
          </Row>
        )}
        {!isRedeemBalancePage &&
          !isFirstTimeSharedBorrower &&
          registry &&
          key &&
          !['/', '/earn'].some((el) => el === history.location.pathname) &&
          isDesktop && (
            <DropInModulesNavLinks
              registryContent={registry[key]}
              mainTab={key}
              isLandingPage={false}
            />
          )}
      </Container>
      <Switch>
        <Route
          path={getClaimBonusPagePath()}
          exact
          component={BonusPointsPage}
        />
        <Route path={getSignUpPath()} exact component={SignUp} />
        <Route path={getProfilePath()} exact component={UserProfile} />
        <Route path={getSurveyPath()} exact component={Survey} />
        {Object.keys(registry).map((registryKey, index) => {
          let routes = [];
          routes.push(
            <Route
              path={getLandingPath(index === 0 ? '' : registryKey)}
              exact
              render={() => getRenderComponent(registryKey)}
            />
          );
          registry[registryKey].tabs.map((cat) => {
            (!cat.havePath ||
              cat?.navPathInternal ||
              (enableComingSoonUtilityOffers &&
                cat.name === 'Community Solar')) &&
              routes.push(
                <Route
                  path={
                    index === 0
                      ? getLandingPath(formatPath(cat.label))
                      : getCategoryPath(
                          formatPath(cat.label),
                          formatPath(registryKey)
                        )
                  }
                  // @INFO For some reason, this is the only way it is loading the paths of the child modules
                  // exact={index === 0 ? false : true}
                  render={() => {
                    return cat.content();
                  }}
                />
              );
          });
          return routes;
        })}
        <Route path="*" render={() => <Redirect to={getLandingPath()} />} />
      </Switch>
      <BannerWrapper />
    </Fragment>
  );
}

export default LandingPage;
