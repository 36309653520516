import Donate from '@rewards/charity';
import Sweepstakes from '@rewards/sweepstakes';
// import Student_Debt from '@rewards/student-loans';
import Products from '@rewards/products';
import Food from '@rewards/food';
import Fuel from '@rewards/fuel';
import ToolsForSchools from '@rewards/tools-for-schools';
import Magazines from '@rewards/emagazines';
import ShipToHome from '@rewards/ship-to-home';
import ShopInStore from '@rewards/shop-in-store';
// import RefiStudentLoans from '@rewards/refinance';
import DailySurprises from '@rewards/daily-surprises';
import UtilityOffers from '@rewards/utility-offers';
import CommunitySolar from '@rewards/community-solar';
import Travel from '@rewards/travel';
import { register as SurveyModule } from '@rewards/survey';

import {
  LOYALTY_CONNECT_MODULES,
  APP_TABS,
  LOYALTY_CONNECT_MODULES_LABEL_BUTTONS,
} from '../util/constants';
import {
  getPriceChopperShopPath,
  getPriceChopperPromoPath,
  getPriceChopperFlyerPath,
  getSurveyPath,
} from '../util/paths';

const register = (props) => {
  return {
    parent: props.parent,
    label: props.label,
    path: props.path,
    alterLabel: props?.alterLabel,
    labelButton: props?.labelButton,
    secondPath: props?.secondPath,
    secondLabelButton: props?.secondLabelButton,
  };
};

export const constructModuleContainer = (props) => {
  const moduleContainer = new Map();
  moduleContainer.set(LOYALTY_CONNECT_MODULES.SWEEPSTAKES, Sweepstakes);
  moduleContainer.set(LOYALTY_CONNECT_MODULES.DONATE, Donate);
  // moduleContainer.set(LOYALTY_CONNECT_MODULES.STUDENT_DEBT, Student_Debt);
  moduleContainer.set(LOYALTY_CONNECT_MODULES.PRODUCTS, Products);
  moduleContainer.set(LOYALTY_CONNECT_MODULES.MAGAZINES, Magazines);
  moduleContainer.set(LOYALTY_CONNECT_MODULES.SHIP_TO_HOME, ShipToHome);
  moduleContainer.set(
    LOYALTY_CONNECT_MODULES.TOOLS_FOR_SCHOOLS,
    ToolsForSchools
  );
  moduleContainer.set(LOYALTY_CONNECT_MODULES.FOOD, Food);
  moduleContainer.set(LOYALTY_CONNECT_MODULES.FUEL, Fuel);
  moduleContainer.set(LOYALTY_CONNECT_MODULES.SHOP_IN_STORE, ShopInStore);
  // moduleContainer.set(
  //   LOYALTY_CONNECT_MODULES.STUDENT_LOAN_REFINANCE,
  //   RefiStudentLoans
  // );
  moduleContainer.set(LOYALTY_CONNECT_MODULES.DAILY_SURPRISES, DailySurprises);
  moduleContainer.set(LOYALTY_CONNECT_MODULES.TRAVEL, Travel);
  moduleContainer.set(LOYALTY_CONNECT_MODULES.COMMUNITY_SOLAR, CommunitySolar);
  moduleContainer.set(LOYALTY_CONNECT_MODULES.SHOP_ONLINE, {
    register: register({
      parent: APP_TABS.EARN,
      label: LOYALTY_CONNECT_MODULES.SHOP_ONLINE,
      path: getPriceChopperShopPath(),
    }),
  });
  moduleContainer.set(LOYALTY_CONNECT_MODULES.GIFT_CARDS, {
    register: register({
      parent: APP_TABS.EARN,
      label: LOYALTY_CONNECT_MODULES.GIFT_CARDS,
      path: props?.envInfo?.giftCardsPath,
    }),
  });
  moduleContainer.set(LOYALTY_CONNECT_MODULES.SHOP, {
    register: register({
      parent: APP_TABS.EARN,
      label: LOYALTY_CONNECT_MODULES.SHOP,
      path: getPriceChopperShopPath(),
    }),
  });
  moduleContainer.set(LOYALTY_CONNECT_MODULES.MULTIPLIER_PROMOTION, {
    register: register({
      parent: APP_TABS.EARN,
      label: LOYALTY_CONNECT_MODULES.MULTIPLIER_PROMOTION,
      path: getPriceChopperPromoPath(),
    }),
  });
  moduleContainer.set(LOYALTY_CONNECT_MODULES.WEEKLY_FLYER, {
    register: register({
      parent: APP_TABS.EARN,
      label: LOYALTY_CONNECT_MODULES.WEEKLY_FLYER,
      path: getPriceChopperFlyerPath(),
    }),
  });
  moduleContainer.set(LOYALTY_CONNECT_MODULES.UTILITY_OFFERS, UtilityOffers); // Temporal Fix
  //  moduleContainer.set(LOYALTY_CONNECT_MODULES.UTILITY_OFFERS, {
  //    register: register({
  //      parent: APP_TABS.EARN,
  //      label: LOYALTY_CONNECT_MODULES.UTILITY_OFFERS,
  //      path: props?.envInfo?.greenMountainPath,
  //    })
  //  })

  // moduleContainer.set(LOYALTY_CONNECT_MODULES.SURVEY, {
  //   register: register({
  //     parent: APP_TABS.EARN,
  //     label: LOYALTY_CONNECT_MODULES.SURVEY,
  //     path: getSurveyPath(),
  //   }),
  // });
  moduleContainer.set(LOYALTY_CONNECT_MODULES.SURVEY, {
    register: SurveyModule,
  });

  return moduleContainer;
};
