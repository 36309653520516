const styleConst = {
  white: '#FFF',
  grayDark: '#C0C0C0',
  blue: '0000FF',
  cobalt: '#005BAA',
  appleGreen: '#61BB46',
  schooner: '#898785',
  lightGrey: '#F9F9F9',

  styleValueToStyleName: (color = '#0000FF') => color,
};

export default styleConst;
