import { useMutation, useQueryClient } from '@tanstack/react-query';
import initTransaction from '../services/useTransaction';

export default function useTransaction() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['transaction'],
    mutationFn: initTransaction,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['userInfo'],
      });
      queryClient.invalidateQueries({
        queryKey: ['digitalDiscounts'],
      });
    },
  });
}
