import React, { useEffect, useContext, useState, useRef } from 'react';
import { Modal, Col, ButtonGroup, Overlay, Tooltip } from 'react-bootstrap';
import { Button } from '@rewards/ui';
import { useMsal } from '@azure/msal-react';
import {
  useUserInfo,
  useMoneyTransferTxns,
  queryClient,
} from '@rewards/data-provider';
import {
  AuthContext,
  AppContext,
  ACTIONS,
  DailySurpriseContext,
} from '@rewards/contexts';
import { ConfirmationShareModal } from '@rewards/daily-surprises';

import ModalCloseBtn from '../ModalCloseButton';

import { EVENT_LOCATION, EVENTS, PAGES } from '../../analytics/events';
import { useResponsive, useTravels } from '../../util/hooks';
import Icon from '../../assets/svg';
import { TRAVEL } from '../../util/constants';
import { TravelsContext } from '../../contexts/TravelsContext';

import './TravelConfirm.scss';

const { useAuth } = AuthContext;
const { QUERY_STATUS } = ACTIONS;
const { useDailySurprise } = DailySurpriseContext;

const TravelDetail = () => {
  return <></>;
};

const TravelBody = ({ travelCode, isDesktop }) => {
  return (
    <Col className="bg-white px-0 text-center lc-travels-subscription-success-voucher-card">
      <h3 className="confirm-title-text p-0 w-75 text-left mx-auto">
        👋 Prize Awarded!
      </h3>
      <div className="confirm-details-text p-0 w-100">
        You won (1) Free Travel Subscription.
      </div>

      <div
        className={`flex flex-row justify-content-between header-badge rounded my-3 ${
          isDesktop ? 'w-75 mx-auto' : 'w-100'
        }`}
      >
        <div>Subscription Earned</div>
        <div>
          <span className="header-points-text">1</span>
        </div>
      </div>
      <Col className="pt-2  lc-travels-subscription-success-voucher-code-header">
        Here is your unique voucher code:
      </Col>
      <Col className="pt-1 lc-travels-subscription-success-voucher-code">
        {travelCode}
      </Col>
      <Col className="text-center lc-travels-subscription-success-next-step-info">
        Save this code for reference.
      </Col>
      <Col className="text-center pt-5 lc-travels-subscription-success-next-step-info-try w-75 mx-auto">
        Try again tomorrow for another chance to win!
      </Col>
    </Col>
  );
};

const TravelFooter = ({ share }) => {
  const { envInfo } = useContext(AuthContext.AuthContext);

  return (
    <div className="row mb-4">
      <div className="col-md-6">
        <Button
          width="full"
          // className='select-button w-100 h-100'
          href={envInfo?.allTravelListPath}
          target="_blank"
        >
          Select Travel
        </Button>
      </div>

      <div className="col-md-6">
        <Button
          width="full"
          // className='share-button w-100 h-100'
          onClick={share}
        >
          Share
        </Button>
      </div>
    </div>
  );
};

const TravelSubscriptionConfirm = ({
  show,
  handleSubscribeConfirm,
  handleSubscribeSuccess,
  eTravels,
}) => {
  const { source, track } = useContext(AppContext.AppContext);
  const queryString = new URL(document.location).searchParams;
  const reservationId = queryString.get('reservationId');
  const { dailySurpriseEvent } = useDailySurprise(
    DailySurpriseContext.DailySurpriseContext
  );

  const { data: user, isFetching: isUserInfoFetching } = useUserInfo();
  const {
    status: moneyTransferStatus,
    error: moneyTransferTxnsError,
    mutate: handleMoneyTransferTxns,
    data: transaction,
  } = useMoneyTransferTxns();
  const { instance } = useMsal();
  const { unAuthToken } = useAuth();
  const { isDesktop } = useResponsive();
  // const { eTravels } = useTravels()
  const target = useRef(null);

  const [showTxnErrorTooltip, setTxnErrorTooltip] = useState(false);
  const [txnErrorTooltipMessage, setTxnErrorTooltipMessage] = useState(
    ACTIONS.TXN_ERROR.INTERNAL
  );
  const [failSafeToast, setFailSafeToast] = useState();
  const [showSocialConfirmation, setShowSocialConfirmation] = useState(false);
  const [showSocialShare, setShowSocialShare] = useState(false);
  const [miles, setMiles] = useState(0);

  const { advantPoints, currencyExchange } = useContext(TravelsContext) || {};

  useEffect(() => {
    return () => {
      queryClient.removeQueries('moneyTransferTxnsError');
    };
  }, []);

  useEffect(() => {
    if (moneyTransferTxnsError) {
      setFailSafeToast(moneyTransferTxnsError?.response?.data?.status);
    }
    setTimeout(() => setFailSafeToast(), 5000);
  }, [moneyTransferTxnsError]);

  useEffect(() => {
    if (show) {
      source(PAGES.VIEWED_TRAVEL_CONFIRMATION_PAGE, {
        ui_version: import.meta.env.APP_VERSION || '2.0.0',
      });
      calculateMiles();
    }
  }, [show]);

  useEffect(() => {
    if (
      moneyTransferStatus === QUERY_STATUS.ERROR &&
      !isUserInfoFetching &&
      !failSafeToast
    ) {
      user?.data?.partnerAccounts[0]?.accountBalance < advantPoints &&
        setTxnErrorTooltipMessage(ACTIONS.TXN_ERROR.POINTS);
      setTxnErrorTooltip(true);
    }
  }, [isUserInfoFetching, moneyTransferStatus]);

  useEffect(() => {
    if (moneyTransferStatus === QUERY_STATUS.SUCCESS) {
      handleSubscribeSuccess();
      handleSubscribeConfirm();
    }
  }, [moneyTransferStatus]);

  useEffect(() => {
    if (showTxnErrorTooltip) {
      const timer = setTimeout(() => {
        setTxnErrorTooltip(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showTxnErrorTooltip]);

  const handleCancelSubscription = (e) => {
    track(EVENTS.CLICKED_CANCEL_BUTTON, {
      location: EVENT_LOCATION.TRAVEL_CONFIRMATION_PAGE,
      is_daily_surprise: dailySurpriseEvent?.code || false,
      ui_version: import.meta.env.APP_VERSION || '2.0.0',
    });
    e.preventDefault();
    if (moneyTransferStatus === QUERY_STATUS.LOADING) {
      return;
    }
    handleSubscribeConfirm(moneyTransferStatus === QUERY_STATUS.ERROR);
  };

  const handleConfirmSubscription = () => {
    track(EVENTS.CLICKED_TRAVEL_CONFIRM_BUTTON, {
      location: EVENT_LOCATION.TRAVEL_CONFIRMATION_PAGE,
      is_daily_surprise: dailySurpriseEvent?.code || false,
      ui_version: import.meta.env.APP_VERSION || '2.0.0',
    });

    const { _id, partnerAccounts } = user?.data;
    const params = {
      fromAccount: {
        fromUserId: _id,
        fromAccountId: partnerAccounts[0]?.accountId,
        fromAccountValue: advantPoints,
        fromCurrencyId: currencyExchange?.sourceCurrencyId,
        fromAccountType: TRAVEL.PARTNER,
      },
      toAccount: {
        toUserId: _id,
        subAccountId: eTravels?.[0]?._id,
        // toAccountValue: Math.floor(advantPoints / currencyExchange?.sourceDebitMin),
        toAccountValue: Math.floor(miles / 100),
        toAccountType: TRAVEL.ETRAVEL,
      },
    };
    if (dailySurpriseEvent?.code) {
      params.fromAccount = {
        ...params.fromAccount,
        fromAccountId: '',
        fromAccountValue: 1,
        fromCurrencyId: dailySurpriseEvent?.code,
        fromAccountType: TRAVEL.DAILY_SURPRISES,
      };
      params.toAccount = {
        ...params.toAccount,
        toAccountValue: 1,
      };
    }

    handleMoneyTransferTxns(params);
  };

  useEffect(() => {
    if (
      reservationId &&
      dailySurpriseEvent?.code &&
      user &&
      eTravels &&
      !showSocialConfirmation
    ) {
      // console.log('Transfer Status',moneyTransferStatus)
      handleConfirmSubscription();
    }
  }, [
    reservationId,
    dailySurpriseEvent,
    user,
    eTravels,
    showSocialConfirmation,
  ]);

  useEffect(() => {
    if (transaction && reservationId) {
      setShowSocialConfirmation(true);
    }
  }, [transaction, reservationId]);

  const handleSubscribe = () => {
    track(EVENTS.CLICKED_SUBSCRIBE_BUTTON, {
      location: EVENT_LOCATION.TRAVEL_CONFIRMATION_PAGE,
      is_daily_surprise: dailySurpriseEvent?.code || false,
      ui_version: import.meta.env.APP_VERSION || '2.0.0',
    });
    handleSubscribeConfirm();
  };

  const handleLoginRegister = () => {
    track(EVENTS.LOG_IN_REGISTER_CLICKED, {
      is_daily_surprise: dailySurpriseEvent?.code || false,
    });

    instance.config.auth.authority = instance.config.auth.loginAuthority;
    instance.loginRedirect({
      ...instance.config.auth.loginRequest,
      extraQueryParameters: {
        anonymousId: window?.analytics?.user?.()?.anonymousId?.(),
      },
    });
  };

  const calculateMiles = () => {
    let miles = 0;

    if (advantPoints === 400) {
      miles = 100;
    } else if (advantPoints === 700) {
      miles = 200;
    } else if (advantPoints === 950) {
      miles = 300;
    }

    setMiles(miles);
  };

  return (
    <>
      {unAuthToken?.status !== ACTIONS.STATUS.RESOLVED && (
        <Col className="border-top my-2"></Col>
      )}
      <Button
        width="full"
        // className={`w-100 border-0 ${isDesktop ? 'py-2 lc-travels-subscription-subscribe-button' : 'py-2 lc-travels-subscription-subscribe-button-mobile'}`}
        block
        onClick={
          unAuthToken?.status === ACTIONS.STATUS.RESOLVED
            ? handleLoginRegister
            : handleSubscribe
        }
      >
        {unAuthToken?.status === ACTIONS.STATUS.RESOLVED
          ? 'LOG IN / REGISTER'
          : 'REDEEM'}
      </Button>
      <Modal
        show={failSafeToast?.code === 500}
        hide={!failSafeToast}
        className="mt-0"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header
          closeButton
          className="border-0 lc-upsert-toast bg-danger"
        >
          <Col className="p-0">{failSafeToast?.desc}</Col>
        </Modal.Header>
      </Modal>
      <Modal
        show={show && !dailySurpriseEvent?.code}
        backdrop="static"
        keyboard={false}
        centered={true}
        className="ms-modal"
        dialogClassName={`rounded' ${
          isDesktop ? '' : 'ms-mobile-dialog mx-auto'
        }`}
        contentClassName="lc-travels-modal-content"
      >
        <Modal.Header className="border-0 justify-content-center lc-travels-subscription-confirmation-header pt-3">
          Please Confirm
          <ModalCloseBtn handleClose={handleCancelSubscription} />
        </Modal.Header>
        <Modal.Body className="border-0 pt-0">
          <Col className="lc-travels-subscription-confirmation-info-card bg-white">
            <Col className="lc-travels-subscription-confirmation-info-card-header px-4 pt-3 pb-3 text-center">
              Do you want to use {advantPoints} Rewards points for {miles}{' '}
              United MileagePlus&reg; miles?
            </Col>
            <Col className="flex px-0 pt-3 pb-4 lc-travels-subscription-confirmation-info-card-icon-amount">
              <Icon
                className="inline"
                icon="balance-bolt"
                width="45"
                height="45"
              />
              <span className="lc-travels-subscription-confirmation-info-card-amount pl-2">
                {advantPoints}
              </span>
            </Col>
          </Col>
          <Col className="text-center pt-3 pb-4 mt-4 lc-travels-subscription-confirmation-next-step-info">
            <div className="pl-4 pr-4">
              The next step will provide you with a unique code to access your
              miles.
            </div>
          </Col>
        </Modal.Body>
        <Modal.Footer className="border-0 bg-white">
          <ButtonGroup className="flex flex-col-reverse w-100">
            <Button
              // className='lc-travels-subscription-cancel-button mr-1 rounded w-50 py-2'
              disabled={
                moneyTransferStatus === QUERY_STATUS.LOADING ||
                isUserInfoFetching
              }
              intent="secondary"
              onClick={handleCancelSubscription}
            >
              CANCEL
            </Button>

            <Button
              // className='lc-travels-subscription-confirm-button w-50 ml-1 py-2 rounded border-0'
              disabled={
                moneyTransferStatus === QUERY_STATUS.LOADING ||
                isUserInfoFetching ||
                failSafeToast
              }
              onClick={handleConfirmSubscription}
              ref={target}
            >
              CONFIRM
            </Button>
            <Overlay
              target={target.current}
              show={showTxnErrorTooltip}
              placement="top"
              delay={{ show: 250, hide: 400 }}
            >
              {(props) => (
                <Tooltip className="lc-travels-subscription-tooltip" {...props}>
                  {txnErrorTooltipMessage}
                </Tooltip>
              )}
            </Overlay>
          </ButtonGroup>
        </Modal.Footer>
      </Modal>
      <ConfirmationShareModal
        show={showSocialConfirmation}
        isDesktop={isDesktop}
        handleClose={() => {
          track(EVENTS.DAILY_SURPRISES_CLOSE_BUTTON_CLICKED, {
            location: EVENT_LOCATION.DAILY_SURPRISES_CONFIRM_POPUP,
          });
          setShowSocialConfirmation(false);
        }}
        detail={<TravelDetail />}
        body={
          <TravelBody
            travelCode={transaction?.data?.data?.code?.code}
            isDesktop={isDesktop}
          />
        }
        footer={
          <TravelFooter
            isDesktop={isDesktop}
            share={() => {
              track(EVENTS.DAILY_SURPRISES_SHARE_BUTTON_CLICKED, {
                location: EVENT_LOCATION.DAILY_SURPRISES_CONFIRM_POPUP,
              });
              setShowSocialShare(true);
            }}
          />
        }
        showSocialShare={showSocialShare}
      />
    </>
  );
};

export default TravelSubscriptionConfirm;
