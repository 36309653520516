import { PropsWithChildren, forwardRef } from 'react';
import { tailwindMerge, tv } from '../../util';
import { useModalContext } from '../../contexts/ModalContext';

interface IModalBodyProps {
  className?: string;
}

const styles = tv({
  base: 'text-left',
  variants: {
    type: {
      transaction: 'py-0 my-3 flex flex-col items-center',
      cta: '',
      document: 'overflow-auto ',
      notification: '',
    },
  },
  defaultVariants: {
    type: 'cta',
  },
});

export const ModalBody = forwardRef(
  ({ children, className = '' }: PropsWithChildren<IModalBodyProps>) => {
    const variantProps = useModalContext();
    const bodyStyles = styles({ ...variantProps, className });
    return (
      <section aria-label="modal-body" className={bodyStyles}>
        {children}
      </section>
    );
  }
);
