import { useQueryClient } from '@tanstack/react-query';
import { useUserInfo } from '@rewards/data-provider';
import useActivateCardNumber from '../../api/activateCardNumber';
import { CANT_SEE_POPUP } from '../constants';

const useActivateMutation = ({ onStart, onSuccess, onError }) => {
  const queryClient = useQueryClient();
  const { data } = useUserInfo(true);
  const mutation = useActivateCardNumber({
    onMutate: () => onStart && onStart(),
  });
  const onClick = () => {
    const cardNumber = data.data.partnerAccounts[0].cardNumber;
    mutation.mutate(
      { cardNumber },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: ['bonus-points-cardNumber', cardNumber],
          });
          window.localStorage.setItem(CANT_SEE_POPUP, 'true');
          onSuccess && onSuccess();
        },
        onError: () => {
          onError && onError();
        },
      }
    );
  };

  return {
    mutation,
    mutationTrigger: onClick,
  };
};

export default useActivateMutation;
