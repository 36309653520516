import React from 'react';
import { Spinner } from 'react-bootstrap';
import { AuthContext, ACTIONS } from '@rewards/contexts';

import UtilityOffersContext from './UtilityOffersContext';

const { useAuth } = AuthContext;

const UtilityOffersContextState = ({ children }, ...props) => {
  const { tokenStatus, unAuthToken } = useAuth();

  if (
    !(
      tokenStatus === ACTIONS.STATUS.RESOLVED ||
      unAuthToken?.status === ACTIONS.STATUS.RESOLVED
    )
  ) {
    return (
      <div className="text-center spinner">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <UtilityOffersContext.Provider value={{}} {...props}>
      {children}
    </UtilityOffersContext.Provider>
  );
};
export default UtilityOffersContextState;
