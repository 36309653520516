import React, { useRef, useState, useEffect, useContext } from 'react';
import { AppContext } from '@rewards/contexts';
// import { getDailySurprisesPath } from "../../util/paths"
import { Row, Col, Overlay, Tooltip } from 'react-bootstrap';
import { Button } from '@rewards/ui';
import { EVENTS, EVENT_LOCATION } from '../../analytics/events';
import { useResponsive } from '../../util/hooks';
import './SocialShareModal.scss';

const SocialShareModal = ({ handleClose, module, ammount }) => {
  const linkRef = useRef(null);
  const target = useRef(null);
  const [copiedTooltip, setCopiedTooltip] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');
  const { isDesktop } = useResponsive();
  const url = `https://${window.location.hostname}/api/priceReservation/reservation/share/${module}/${ammount}`;
  const { track } = useContext(AppContext.AppContext);

  function copyToClipboard(e) {
    track(EVENTS.DAILY_SURPRISES_COPY_LINK_BUTTON_CLICKED, {
      location: EVENT_LOCATION.DAILY_SURPRISES_SHARE_POPUP,
      ui_version: import.meta.env.APP_VERSION,
    });
    linkRef.current.select();
    document.execCommand('copy');
    e.target.focus();
    setCopySuccess('Copied!');
    setCopiedTooltip(true);
  }

  const share = (e) => {
    track(EVENTS.DAILY_SURPRISES_SHARE_LINK_BUTTON_CLICKED, {
      location: EVENT_LOCATION.DAILY_SURPRISES_SHARE_POPUP,
      ui_version: import.meta.env.APP_VERSION,
    });

    if (navigator.share) {
      navigator
        .share({
          title:
            '👋 I won playing the Price Chopper / Market 32 Daily SurPRIZE!™',
          text: 'Play every day to see what you can win! \nGrand Prize up to 50,000 Rewards points, plus exclusive eCoupons and other rewards!',
          url: url,
        })
        .then(() => setCopiedTooltip(true))
        .catch(console.error);
    } else {
      // copyToClipboard(e) PREVENT auto copyToClipboard
    }
  };

  useEffect(() => {
    document.getElementById('share-link-button').click();
  }, []);

  useEffect(() => {
    if (copiedTooltip) {
      const timer = setTimeout(() => {
        setCopiedTooltip(false);
        handleClose();
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [copiedTooltip]);

  return (
    <>
      <Row className="flex flex-column w-100">
        <div className="p-0 w-100">
          <div className="share-header-text text-center p-0">
            👋 Click to Share
          </div>
        </div>
      </Row>
      <div className="mt-2 mb-3 w-75 mx-auto">
        <Col
          className={`p-0 justify-content-around mt-4 mb-0 w-75-lg mx-auto-lg`}
        >
          <Row>
            <Overlay
              target={target.current}
              show={copiedTooltip}
              placement="top"
              delay={{ show: 250, hide: 400 }}
            >
              {(props) => (
                <Tooltip className="points-tooltip" {...props}>
                  Copied
                </Tooltip>
              )}
            </Overlay>
            <div
              className="copy-to-clipboard input-group"
              id="clipboard"
              ref={target}
            >
              <div className="input-group-prepend">
                <span
                  className="input-group-text copy-to-clipboard-icon pr-0"
                  id="cp-clipboard"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-link"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
                    <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" />
                  </svg>
                </span>
              </div>
              <input
                type="text"
                ref={linkRef}
                value={url}
                onChange={() => {}}
                className="form-control copy-to-clipboard-input pl-1"
                id="copy-to-clipboard-input"
                aria-describedby="cp-clipboard"
              />
            </div>
          </Row>
        </Col>

        <Row className="my-3 w-100 mx-auto row flex flex-row">
          <div className={`text-center copy-link-button-container`}>
            <Button
              onClick={copyToClipboard}
              block
              // className={`copy-link-button mt-1 mx-auto-lg`}
            >
              COPY LINK
            </Button>
          </div>

          <div className={`text-center share-link-button-container`}>
            <Button
              onClick={share}
              block
              // className={`share-link-button mt-1 mx-auto-lg`}
              id="share-link-button"
            >
              SHARE LINK
            </Button>
          </div>
        </Row>
      </div>
    </>
  );
};

export default SocialShareModal;
