import React, { useState, useEffect } from 'react';
import { Col, Card } from 'react-bootstrap';

import Icon from '../../assets/svg';
import { WINNER_SELECTION_INFO_TYPE } from '../../util/constants';
import { daysRemaining, dateString } from '../../util/formatters';
import useResponsive from '../../util/hooks/useResponsive';
import MultiItemCarousel from '../MultiItemCarousel';

import './GrandPrizesCarousal.scss';

const GrandPrizesCarousal = ({
  campaign,
  isActiveCampaign = false,
  showTitle = false,
}) => {
  const { isDesktop } = useResponsive();

  const [grandPrizes, setgrandPrizes] = useState();

  useEffect(() => {
    if (campaign) {
      const grandPrizesInfo = campaign.drawings.filter(
        ({ type }) => type === WINNER_SELECTION_INFO_TYPE.GRAND
      );
      setgrandPrizes(grandPrizesInfo?.[0]);
    }
  }, [campaign]);

  if (!grandPrizes) {
    return null;
  }
  // console.log(campaign.drawings)
  return (
    <>
      {showTitle && (
        <Col
          className={`p-0 my-2 ${
            isDesktop ? 'grand-prizes-desktop-text' : 'grand-prizes-text'
          }`}
        >
          <span>Grand Prizes</span>
        </Col>
      )}
      <Col className="p-0">
        <MultiItemCarousel numberOfItems={grandPrizes?.prizes?.length}>
          {grandPrizes?.prizes?.map((grandPrize, index) => (
            <Card
              className="text-left carousal-card h-100 borderless rounded-0 mw-100 "
              key={index}
            >
              <Card.Header as="span" className="p-0 borderless">
                <Card>
                  <Card.Img
                    src={grandPrize?.images?.[0]?.url}
                    alt="Card image"
                    className={
                      isDesktop ? 'wp-desktop-card-image' : 'wp-card-image'
                    }
                  />
                </Card>
              </Card.Header>

              {!isDesktop && (
                <Card.Body className="grand-prize-card" id="scrollToPosition">
                  <Card.Text className="grand-prize-title mb-1 text-center">
                    {grandPrize?.title}
                  </Card.Text>
                  <Card.Text className="font-weight-normal grand-prize-heading mb-0 text-center">
                    {grandPrize?.description}
                    <br />
                    {campaign.drawings[0]?.campaign?.text ? (
                      <a
                        href={campaign.drawings[0]?.campaign?.url}
                        target="_blank"
                      >
                        {campaign.drawings[0]?.campaign?.text || ''}
                      </a>
                    ) : null}
                  </Card.Text>
                </Card.Body>
              )}
            </Card>
          ))}
        </MultiItemCarousel>
      </Col>
    </>
  );
};

export default GrandPrizesCarousal;
