import { PropsWithChildren } from 'react';
import { useModalContext } from '../../contexts/ModalContext';
import { tv } from '../../util';
import css from './index.module.css';

const styles = tv({
  base: 'flex flex-col pt-4 ',
  variants: {
    type: {
      transaction: `${css.cta} !py-0 !mt-0 md:!py-2 flex flex-col lg:flex-row `,
      cta: `${css.cta} sm:flex-row-reverse space-y-2 sm:space-y-0 !mt-4`,
      document: 'sm:flex-row-reverse',
      notification: '',
    },
  },
  defaultVariants: {
    type: 'cta',
  },
});
export const ModalFooter = ({
  children,
  className,
}: PropsWithChildren<{
  className?: string;
}>) => {
  const variantProps = useModalContext();
  // console.log(variantProps);
  const footerStyles = styles({ ...variantProps, className });
  return (
    <section aria-label="dialogfooter" className={footerStyles}>
      {children}
    </section>
  );
};
