export const EVENTS = {
  DAILY_SURPRISES_BACK_CLICKED:
    'Earning - Daily surPrize - Back Button Clicked',
  CLICKED_SIGN_UP_BUTTON:
    'Earning - Daily surPrize - Log In / Register to Play button clicked',
  GAMETATION_REDEEM_BUTTON_CLICKED:
    'Earning - Gametation - Daily surPrize - Redeem Button Clicked',
  GAMETATION_DONATE_BUTTON_CLICKED:
    'Earning - Gametation - Daily surPrize - Donate Button Clicked',
  DAILY_SURPRISES_REDEEM_BUTTON_CLICKED:
    'Earning - Daily surPrize - Redeem Button Clicked',
  GAMETATION_DAILY_SURPRISES_USER_LOST:
    'Earning - Gametation - Daily surPrize -  User Lost',
  DAILY_SURPRISES_HOME_BUTTON_CLICKED:
    'Earning - Daily surPrize - Home Button Clicked',
  DAILY_SURPRISES_SHARE_BUTTON_CLICKED:
    'Earning - Daily surPrize - Share Button Clicked',
  DAILY_SURPRISES_COPY_LINK_BUTTON_CLICKED:
    'Earning - Daily surPrize - Copy Link Button Clicked',
  DAILY_SURPRISES_SHARE_LINK_BUTTON_CLICKED:
    'Earning - Daily surPrize - Share Link Button Clicked',
  DAILY_SURPRISES_CLOSE_BUTTON_CLICKED:
    'Earning - Daily surPrize - Close Button Clicked',
  DAILY_SURPRISES_USER_ALREADY_PLAYED:
    'Earning - Daily surPrize -  User Already Played',
  DAILY_SURPRISES_OFFICIAL_RULES_CLICKED:
    'Earning - Daily surPrize - Official Rules Clicked',
  DAILY_SURPRISES_REDEEM_CLICKED:
    'Earning - Daily surPrize - Redeem Button Clicked',
  DAILY_SURPRISES_EARN_CLICKED:
    'Earning - Daily surPrize - Earn Button Clicked',
};

export const PAGES = {
  VIEWED_DAILY_SURPRISES:
    'Earning - Daily surPrize - Daily surPrize Details Viewed',
  DAILY_SURPRISES_OFFICIAL_RULES_MODAL:
    'Earning - Daily surPrize - Daily surPrize Official Rules Viewed',
};

export const EVENT_LOCATION = {
  DAILY_SURPRISES_LANDING_PAGE: 'Daily surPrize Landing Page',
  DAILY_SURPRISES_CONFIRM_POPUP: 'Daily surPrize Confirmation Popup',
  DAILY_SURPRISES_SHARE_POPUP: 'Daily surPrize Share Popup',
  DAILY_SURPRISES_OFFICIAL_RULES_MODAL: 'Daily surPrize Official Rules Modal',
};
