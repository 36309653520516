import { AuthContext } from '@rewards/contexts';
import { LOYALTY_CONNECT_MODULES } from '@rewards/ui';
export type TimeFlag = {
  startTs: number;
  endTs: number;
  url?: string;
};
export type FeatureFlag = {
  timeFlag: TimeFlag[];
  name: string;
  isActive: true;
  otherDetails?: {
    timeFlag: TimeFlag[];
    [key: string]: string | number | TimeFlag[];
  };
};

const useModule = (module: string) => {
  const { featureFlags } = AuthContext.useAuth();
  const flag = featureFlags.find((ff: FeatureFlag) => ff.name === module);

  return flag as FeatureFlag;
};

export default useModule;
