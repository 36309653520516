export const Modules = {
  E_COUPON: 'eCoupon',
  SWEEPSTAKE: 'Sweepstakes',
  CHARITY: 'CharityDonation',
  MAGAZINE: 'MagazineSubscription',
  REWARDS_POINTS: 'RewardsPoints',
};

export const TransactionModule = {
  [Modules.E_COUPON]: 'INMAR',
  [Modules.SWEEPSTAKE]: 'SWEEPSTAKE',
  [Modules.CHARITY]: 'CHARITY',
  [Modules.MAGAZINE]: 'MAGAZINE',
  [Modules.REWARDS_POINTS]: 'PARTNER',
};

export const ModuleMessages = {
  [Modules.E_COUPON]: 'eCoupon Earned',
  [Modules.CHARITY]: 'Donated',
  [Modules.MAGAZINE]: 'Subscription Earned',
  [Modules.REWARDS_POINTS]: 'Points Earned',
  [Modules.SWEEPSTAKE]: 'Entries Earned',
};

export const getModule = (prize) => {
  if (prize.name.includes(Modules.E_COUPON)) return Modules.E_COUPON;
  if (prize.name.includes(Modules.CHARITY)) return Modules.CHARITY;
  if (prize.name.includes(Modules.SWEEPSTAKE)) return Modules.SWEEPSTAKE;
  if (prize.name.includes(Modules.REWARDS_POINTS))
    return Modules.REWARDS_POINTS;
  if (prize.name.includes(Modules.MAGAZINE)) return Modules.MAGAZINE;
  return '';
};

export const getPrizeAmountMsg = (prize) => {
  if (prize.name.includes(Modules.SWEEPSTAKE)) {
    const entries = prize.name.split('_')[1].replace('0', '');
    return `(${entries}) Entr${entries > 1 ? 'ies' : 'y'}`;
  }
  if (prize.name.includes(Modules.REWARDS_POINTS)) {
    return prize.name.split('_')[1];
  }
  if (prize.name.includes(Modules.CHARITY)) {
    return '$5';
  }
  return 1;
};
export const getPrizeAmount = (prize) => {
  if (
    prize.name.includes(Modules.REWARDS_POINTS) ||
    prize.name.includes(Modules.SWEEPSTAKE)
  ) {
    return prize.name.split('_')[1];
  }
  if (prize.name.includes(Modules.CHARITY)) {
    return '5';
  }
  return 1;
};

export const getAccountType = (prize) => {
  const module = getModule(prize);
  return TransactionModule[module];
};
