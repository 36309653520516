export const StudentLoanRefinanceIcon = ({ className = '' }) => {
  return (
    <svg
      className={className}
      strokeWidth="1"
      viewBox="0 0 322.6 232.2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Shape">
        <path
          d="M329.9,317.9c0,4-6.7,7.2-14.9,7.2s-14.9-3.2-14.9-7.2c0-15.3,6.7-32.8,14.9-32.8S329.9,302.5,329.9,317.9Z"
          transform="translate(-71.7 -92.9)"
          fill="#61a247"
        />
        <path
          d="M309.9,317.9c0-15.3,2.3-32.8,5.1-32.8-6.8,0-12.2,17.4-12.2,32.8,0,4,5.5,7.2,12.2,7.2C312.2,325.1,309.9,321.9,309.9,317.9Z"
          transform="translate(-71.7 -92.9)"
          fill="#7bae66"
        />
        <path
          d="M315,285.1c4,0,7.2,17.4,7.2,32.8a7.17,7.17,0,0,1-7.2,7.2c8.2,0,14.9-3.2,14.9-7.2C329.9,302.5,323.2,285.1,315,285.1Z"
          transform="translate(-71.7 -92.9)"
          fill="#288540"
        />
        <path
          d="M130.4,209.5c0,21.8,45.9,47.9,102.6,47.9s102.6-26.2,102.6-47.9V155.9H130.4Z"
          transform="translate(-71.7 -92.9)"
          fill="#13569b"
        />
        <polygon
          points="61.6 71.5 58.7 72.5 58.7 111.8 161.3 146.2 263.9 111.8 263.9 72.5 261 71.5 61.6 71.5"
          fill="#002c52"
        />
        <polygon
          points="0 65.8 161.3 119.9 322.6 65.8 322.6 54.1 0 54.1 0 65.8"
          fill="#0d4278"
        />
        <polygon
          points="161.3 119.9 161.3 54.1 0 54.1 0 65.8 161.3 119.9"
          fill="#0d4278"
        />
        <polygon
          points="161.3 108.1 0 54.1 161.3 0 322.6 54.1 161.3 108.1"
          fill="#13569b"
        />
        <path
          d="M315.2,287a3,3,0,0,1-3-3V175.6l-80.1-25.8a3,3,0,0,1,1.9-5.7l82.2,26.5a3,3,0,0,1,2.1,2.8V284A3.24,3.24,0,0,1,315.2,287Z"
          transform="translate(-71.7 -92.9)"
          fill="#61a247"
        />
        <path
          d="M316.1,170.6l-82.2-26.5a3.17,3.17,0,0,0-3.3,1h.1l82.2,26.5a3,3,0,0,1,2.1,2.8V284.9a3.51,3.51,0,0,1-.6,1.8,2.35,2.35,0,0,0,.8.2,3,3,0,0,0,3-3V173.4A3.26,3.26,0,0,0,316.1,170.6Z"
          transform="translate(-71.7 -92.9)"
          fill="#288540"
        />
        <circle cx="243.3" cy="192.2" r="7.2" fill="#61a247" />
        <path
          d="M310.7,287.9a7.74,7.74,0,0,1,6.6-7.6,5.58,5.58,0,0,0-2.3-.6,5.34,5.34,0,0,0-5.3,5.3,5.39,5.39,0,0,0,1,3Z"
          transform="translate(-71.7 -92.9)"
          fill="#7bae66"
        />
        <path
          d="M317.1,278.2a7,7,0,0,1,2,5,7.17,7.17,0,0,1-7.2,7.2,4.92,4.92,0,0,1-2.1-.4,7.09,7.09,0,0,0,5.2,2.2,7.17,7.17,0,0,0,7.2-7.2A7.08,7.08,0,0,0,317.1,278.2Z"
          transform="translate(-71.7 -92.9)"
          fill="#288540"
        />
        <path
          d="M242.8,145.8c0,2.9-4.4,5.3-9.8,5.3s-9.8-2.4-9.8-5.3,4.4-5.3,9.8-5.3S242.8,142.9,242.8,145.8Z"
          transform="translate(-71.7 -92.9)"
          fill="#61a247"
        />
        <path
          d="M227.2,147.9c0-2.8,3.9-5.2,8.9-5.6a14.56,14.56,0,0,0-3.1-.4c-4,0-7.2,1.8-7.2,3.9a3,3,0,0,0,1.4,2.2Z"
          transform="translate(-71.7 -92.9)"
          fill="#7bae66"
        />
        <path
          d="M235.9,140.8c1.7,1,2.7,2.3,2.7,3.7,0,2.9-4.4,5.3-9.8,5.3a13.58,13.58,0,0,1-2.9-.3,14.78,14.78,0,0,0,7,1.6c5.4,0,9.8-2.4,9.8-5.3C242.8,143.4,239.9,141.4,235.9,140.8Z"
          transform="translate(-71.7 -92.9)"
          fill="#288540"
        />
      </g>
    </svg>
  );
};
