import { useEffect, useState } from 'react';

import { BREAKPOINTS } from '../constants';

export const checkIsDesktop = () => innerWidth > BREAKPOINTS.TABLET;
export const checkIsTablet = () =>
  innerWidth <= BREAKPOINTS.TABLET && innerWidth > BREAKPOINTS.MOBILE;
export const checkIsMobile = () => innerWidth <= BREAKPOINTS.MOBILE;

export default function useResponsive() {
  const [isDesktop, setIsDesktop] = useState(checkIsDesktop());
  const [isTablet, setIsTablet] = useState(checkIsTablet());
  const [isMobile, setIsMobile] = useState(checkIsMobile());
  useEffect(() => {
    let timeoutId = null;
    function handleResize() {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        setIsDesktop(checkIsDesktop());
        setIsTablet(checkIsTablet());
        setIsMobile(checkIsMobile());
      }, 100);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return {
    isDesktop,
    isTablet,
    isMobile,
  };
}
