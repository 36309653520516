import React, { useEffect, useContext } from 'react';
import { Container, Card, Image, Col } from 'react-bootstrap';
import { AppContext } from '@rewards/contexts';

import BackNav from './components/BackNav';

import { useResponsive } from './util/hooks';
import { PAGE } from './analytics/events';

// import 'bootstrap/dist/css/bootstrap.min.css'
import './App.scss';
import './styles.css';

const App = () => {
  const { isDesktop } = useResponsive();
  const { source } = useContext(AppContext.AppContext);
  useEffect(() => {
    source(PAGE, { ui_version: import.meta.env.APP_VERSION });
  }, []);

  return (
    <Container className={isDesktop ? 'flex justify-content-between' : 'mt-3'}>
      <Col className="p-0 mb-3" md={isDesktop ? 7 : 12}>
        <Card className="border-0 f-card">
          <Card.Img
            variant="top"
            src="https://cdn.spinwheel.io/images/tcc/food-banner.png"
          />
          <Card.Body>
            <Card.Title className="f-card-title">Food Savings</Card.Title>
            <Card.Text className="f-card-text">
              <span className="f-card-imp-info">
                Every 100 points = $1 off{' '}
              </span>{' '}
              your total order at any Price Chopper / Market 32 location.
            </Card.Text>
            <Card.Text className="f-card-text">
              Simply present your physical or digital AdvantEdge Card at
              checkout to redeem your points. Your savings and an updated points
              balance will print at the bottom of your register receipt.
            </Card.Text>
            <Card.Text className="f-card-text">
              Want to learn more? Visit{' '}
              <a
                href="https://pricechopper.com/Rewards"
                target="_blank"
                rel="noreferrer"
              >
                https://pricechopper.com/Rewards
              </a>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col
        className={`p-0 my-auto ${isDesktop ? 'mr-n5' : ''}`}
        md={isDesktop ? 5 : 12}
      >
        <Image
          src="https://cdn.spinwheel.io/images/tcc/AdvantEdge.png"
          fluid
          className="block mx-auto"
        />
      </Col>
    </Container>
  );
};

export default BackNav(App);
