import React from 'react';
import type { PropsWithChildren } from 'react';
import * as Modal from '../Modal';
import Button from '../../lib/Button';

export const NeedHelp = ({ children }: PropsWithChildren) => {
  return (
    <Modal.Root>
      <>
        <Modal.Trigger asChild>
          <Button intent="secondary">Need Help?</Button>
        </Modal.Trigger>
        <Modal.Content>
          <Modal.Header>
            <Modal.Title className="font-medium">Need Help?</Modal.Title>
            <Modal.CloseButton></Modal.CloseButton>
          </Modal.Header>
          <Modal.Body>{children}</Modal.Body>
          <Modal.Footer>
            <Button asChild>
              <a
                href="https://www.pricechopper.com/about-us/customer-service/contact-us/"
                target="_blank"
              >
                Contact Us
              </a>
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </>
    </Modal.Root>
  );
};
