import React from 'react';
import { Modal, Col } from 'react-bootstrap';
import { useMsal } from '@azure/msal-react';

import './UpsertFailSafeToast.scss';
const UpsertFailSafeToast = ({ upsertFailSafeToast, handleCloseToast }) => {
  const { instance, accounts } = useMsal();

  const handleClose = () => {
    if (instance) {
      if (accounts?.length) {
        const index = accounts.findIndex(
          ({ idTokenClaims }) => idTokenClaims?.acr === 'B2C_1A_SIGNUP'
        );
        instance.logoutRedirect({
          account: accounts?.[index],
        });
      } else {
        handleCloseToast();
      }
    }
  };
  return (
    <Modal
      show={!!upsertFailSafeToast}
      onHide={handleClose}
      className="mt-0"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton className="border-0 lc-upsert-toast bg-danger">
        <Col className="p-0">{upsertFailSafeToast}</Col>
      </Modal.Header>
    </Modal>
  );
};

export default UpsertFailSafeToast;
