import React, { useEffect, useState, useContext } from 'react';
import { Container, Row, Col, Badge, Nav, Spinner } from 'react-bootstrap';
import { Button, NewWaysTo } from '@rewards/ui';
import { useHistory } from 'react-router-dom';
import { AuthContext, AppContext, ACTIONS } from '@rewards/contexts';
import { useUserInfo } from '@rewards/data-provider';
// const { NativeEventSource, EventSourcePolyfill } = require('event-source-polyfill');
// const EventSource = NativeEventSource || EventSourcePolyfill;

import ConfirmationShareModal from './ConfirmationShareModal';
import { getRedirectingPath } from '../../util/paths';
import { useMsal } from '@azure/msal-react';
import { useResponsive } from '../../util/hooks';
import Icon from '../../assets/svg';
import './Survey.scss';
import {
  fetchSurveyResponses,
  fetchTransactions,
} from '../../services/campaignService';
import { EVENTS, PAGES, EVENT_LOCATION } from '../../analytics/events';
import { FEATURES_FLAGS } from '../../util/constants';
import useLocalStorage from '../../util/useLocalStorage';
import ConfirmModal from './confirmModal/ConfirmModal';
import CampaignEnded from './CampaignEnded/CampaignEnded';
import { useAtom } from 'jotai';
import { surveyTimeResetAtom } from './atoms';
import { RESET } from 'jotai/utils';
import { EXIT_EVENT } from './constants';

const { useAuth } = AuthContext;

function GetTitle(props) {
  return (
    <div className="flex content-center w-full justify-between">
      <div>
        <Icon
          className="inline justify-self-start"
          icon={props.faIcon}
          width={props?.width || '17'}
          height={props.height || '17'}
        />

        <span className="ml-3 nav-item-text">{props.title}</span>
      </div>
      <div>
        {props?.isNew && (
          <Badge className="is-new-badge-survey rounded ">New</Badge>
        )}
        <Icon
          className="inline "
          icon="angle-right"
          width="7"
          height="13"
          // className="float-right"
        />
      </div>
    </div>
  );
}

const Survey = () => {
  const { checkValidToken, featureFlags, unAuthToken, tokenStatus, userId } =
    useAuth();
  const {
    data: userInfo,
    status: userStatus,
    isFetching: isUserFetching,
  } = useUserInfo(tokenStatus === ACTIONS.STATUS.RESOLVED);
  const { data: user } = userInfo || {};
  const { track, source } = useContext(AppContext.AppContext);
  const { isDesktop } = useResponsive();
  const history = useHistory();
  const { instance } = useMsal();
  const [loading, setLoading] = useState(true);
  const [showSurvey, setShowSurvey] = useState(false);
  const [loadCount, setLoadCount] = useState(0);
  const [isLoadingTransactions, setIsLoadingTransactions] = useState(true);
  const [arePointsAwarded, setArePointsAwarded] = useState(false);
  const [surveySubmitted, setSurveySubmitted] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showAlreadyPlayed, setShowAlreadyPlayed] = useState(false);
  const [isFirstTimePlayed, setIsFirstTimePlayed] = useState(false);
  const [showSocialShare, setShowSocialShare] = useState(false);
  const [userTransactionPoints, setUserTransactionPoints] = useState('0');
  const [surveyData, setSurveyData] = useState({});
  const [txnFailedMsg, setTxnFailedMsg] = useState('');
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  const [isSurveyComingSoonFlag, setIsSurveyComingSoonFlag] = useState(false);
  const [shouldReloadTransactions, setShouldReloadTransactions] =
    useState(false);
  const [isSurveyTaken, setIsSurveyTaken] = useLocalStorage(
    'isSurveyTaken',
    false
  );
  const [endScreen, setEndScreen] = useState(false);
  // console.log('🚀 ~ file: Survey.jsx:92 ~ Survey ~ endScreen:', endScreen);
  const [diffMins, setDiffMins] = useState(0);
  const [showWaitMessage, setShowWaitMessage] = useState(false);
  const [isProcessingTxn, setIsProcessingTxn] = useState(false);
  const [isStartingTxn, setIsStartingTxn] = useState(false);
  const today = new Date();
  const failedMsg =
    'Transaction failed. We will check if the points were awarded, and if not, we will post them soon.';
  const { envInfo } = useContext(AuthContext.AuthContext);
  let transactionTimer = undefined;

  const [surveyTimeReset, setSurveyTimeReset] = useAtom(surveyTimeResetAtom);

  useEffect(() => {
    const today = new Date().getTime();
    if (surveyTimeReset && surveyTimeReset >= today) {
      setIsProcessingTxn(true);
      setShowAlreadyPlayed(true);
    } else if (surveyTimeReset) {
      setSurveyTimeReset(RESET);
    }
  }, [surveySubmitted]);
  useEffect(() => {
    const listener = (event) => {
      // Make sure the messages come from the correct origin:
      if (event.origin !== location.origin) {
        return;
      }
      if (event.data === EXIT_EVENT) {
        history.push('/earn');
      }
    };
    window.addEventListener('message', listener);
    return () => {
      window.removeEventListener('message', listener);
    };
  }, []);

  function checkSurvey() {
    if (loadCount >= 1) {
      setSurveySubmitted(true);
    }
    setLoadCount(loadCount + 1);
  }

  const getTransactions = (params) => {
    return checkValidToken().then(() => {
      return fetchTransactions(params);
    });
  };

  const hasUserRespondedToSurvey = async () => {
    if (await checkValidToken()) {
      // console.log(userInfo);
      return await fetchSurveyResponses(userInfo.data._id);
    }
  };

  const handleSelectLink = (navItem = '') => {
    track(`${PAGES.CHARITY_DETAILS} ${navItem} Clicked`, {
      location: EVENT_LOCATION.DONATE_CONFIRM_MODAL,
      ui_version: import.meta.env.APP_VERSION,
    });

    if (navItem === 'Gift Cards') {
      window.open(envInfo.giftCardsPath, '_blank').focus();
    } else {
      history.push(getRedirectingPath(navItem));
    }
  };

  function checkUserCompletedSurvey() {
    // const getAccessToken = sessionStorage.getItem('accessToken') || sessionStorage.getItem('unAuthAccessToken');
    // const authorization = `Bearer ${getAccessToken}`;

    const source = new EventSource(
      `/api/surveyEvents/user`
      // , {
      // headers: {
      //   'Authorization': authorization, // this seems to not be working
      //   'Access-Control-Allow-Headers': 'content-type',
      //   'Access-Control-Allow-Origin': '*',
      //   'Content-Type': 'text/event-stream',
      //   'Cache-Control': 'no-cache'
      // },
      //   withCredentials: true
      // }
    );

    source.onmessage = (event) => {
      const {
        ammount = 0,
        userId = '',
        collectorId = '',
        status = '',
      } = JSON.parse(event.data);
      // Set rewards points and show popup
      if (user._id === userId && surveyData?.collectorId === collectorId) {
        if (
          ammount >= 0 &&
          ['pass', 'failed', 'survey empty'].includes(status)
        ) {
          if (status === 'failed') {
            setTxnFailedMsg(failedMsg);
          } else if (status === 'survey empty') {
            setTxnFailedMsg(
              'Survey response is empty. You can retake the survey later.'
            );
          }

          setIsFirstTimePlayed(true);
          setUserTransactionPoints(ammount);
          // console.log('SET FROM ONMSG');
          setArePointsAwarded(true);
          setShowSurvey(false);
          setShowConfirmation(true);
        } else {
          if (status === 'starting') {
            setIsStartingTxn(true);
            setShowAlreadyPlayed(true);
          } else if (status === 'processing') {
            setIsProcessingTxn(true);
            setShowAlreadyPlayed(true);
          }
        }
      }
    };
    source.onerror = (err) => {
      console.log('EventSource error: ', err);
    };
  }

  const moneyFormat = (number) => {
    const numberToString = number.toString();

    return numberToString?.replace(/./g, function (c, i, a) {
      return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c;
    });
  };

  const createSurvey = (window, document, script, smcx) => {
    const { _id, partnerAccounts } = user;

    const iFrameSurvey = document.createElement('iframe');

    iFrameSurvey.src = `${surveyData?.url}?customer_id=${_id}&email=${partnerAccounts[0]?.profile?.email}&cardNumber=${partnerAccounts[0]?.cardNumber}`;
    iFrameSurvey.onload = checkSurvey();
    iFrameSurvey.id = surveyData.collectorId;
    iFrameSurvey.width = '100%';
    iFrameSurvey.height = '100%';
    iFrameSurvey.frameBorder = '0';
    iFrameSurvey.allowTransparency = 'true';
    const container = document.createElement('div');

    container.id = smcx;
    container.classList.add(
      'smcx-widget',
      'smcx-embed',
      'smcx-show',
      'smcx-widget-dark',
      'smcx-hide-branding'
    );

    const innerContainer = document.createElement('div');

    innerContainer.classList.add('smcx-iframe-container');
    container.appendChild(innerContainer);
    innerContainer.appendChild(iFrameSurvey);

    setLoading(false);
    document.getElementById('survey').append(container);
    // document.getElementById('survey').append(a);
  };

  const activateSurvey = () => {
    const timeTaken = new Date();

    setIsSurveyTaken({ timeTaken });
    setLoading(true);
    setShowSurvey(true);
  };

  const beforeUnload = (e) => {
    const message =
      'Are you sure you want to leave the page? If you completed the survey, ignore this.';

    e.preventDefault();
    e.stopPropagation();

    e.returnValue = message;

    return message;
  };

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnload);

    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, []);

  useEffect(() => {
    source(PAGES.LOYALTY_CONNECT_SURVEY, {
      ui_version: import.meta.env.APP_VERSION,
    });
  }, []);

  useEffect(() => {
    const surveyModule = featureFlags.find(
      (ff) => ff.name === FEATURES_FLAGS.SURVEY_MODULE && ff.isActive
    );
    const activeSurvey = !surveyModule?.otherDetails?.campaignEnded
      ? surveyModule?.timeFlag?.find(
          (survey) => survey.startTs <= today && survey.endTs >= today
        )
      : {};

    if (!surveyModule) {
      history.replace('/');
    }

    if (activeSurvey?.url) {
      setSurveyData(activeSurvey);
    }
    // console.log(surveyModule);
    // console.log(
    //   surveyModule?.otherDetails?.commingSoon &&
    //     surveyModule?.otherDetails?.commingSoonStartTs <= today &&
    //     surveyModule?.otherDetails?.commingSoonEndTs >= today
    // );
    setIsSurveyComingSoonFlag(
      !!surveyModule?.otherDetails?.commingSoon &&
        surveyModule?.otherDetails?.commingSoonStartTs <= today &&
        surveyModule?.otherDetails?.commingSoonEndTs >= today
    );

    if (!activeSurvey?.url || surveyModule?.otherDetails?.campaignEnded) {
      setEndScreen(true);
    }
  }, [featureFlags]);

  useEffect(() => {
    if (!isLoadingTransactions) {
      if (
        !arePointsAwarded &&
        showSurvey &&
        surveyData?.url &&
        userStatus === 'success' &&
        !isUserFetching
      ) {
        createSurvey(window, document, 'script', 'smcx-sdk');
        setLoading(false);
        // checkUserCompletedSurvey();
      } else if (arePointsAwarded && !isFirstTimePlayed) {
        setShowAlreadyPlayed(true);
      }
    }
  }, [
    arePointsAwarded,
    isLoadingTransactions,
    showSurvey,
    surveyData,
    isUserFetching,
    userStatus,
    isFirstTimePlayed,
  ]);

  useEffect(() => {
    const effect = async () => {
      if (
        userStatus === 'success' &&
        !isUserFetching &&
        surveyData?.collectorId &&
        (isLoadingTransactions || shouldReloadTransactions)
      ) {
        // console.log('Here');
        const responseToSurvey = await hasUserRespondedToSurvey();
        // No responses
        if (!responseToSurvey.length) {
          setShouldReloadTransactions(false);
          setIsLoadingTransactions(false);
          setLoading(false);
          // console.log('end');

          return;
        } else {
          // We have responses
          const lastResponse = responseToSurvey.shift();
          if (lastResponse.status === '0_POINTS') {
            setShouldReloadTransactions(false);
            setIsLoadingTransactions(false);
            setLoading(false);
          }
          if (lastResponse.status === 'COMPLETED') {
            setUserTransactionPoints(lastResponse.points);
            setArePointsAwarded(true);
            setShowAlreadyPlayed(true);
            // setShowConfirmation(true);
          }
          if (lastResponse.status === 'FAILED') {
            setTxnFailedMsg(failedMsg);
            setArePointsAwarded(true);
            setShowAlreadyPlayed(true);
            setShowConfirmation(true);
          }
          if (lastResponse.status === 'PROCESSING') {
            setIsProcessingTxn(true);
            setShowAlreadyPlayed(true);
          }
          // Processing
          /**
           *    setIsStartingTxn(true);
            console.log('STATUS', status)
            setShowAlreadyPlayed(true);
           */

          //Completed
          // console.log('end');

          return;
        }
      }
    };

    effect();
  }, [
    userStatus,
    isUserFetching,
    surveyData,
    isLoadingTransactions,
    shouldReloadTransactions,
  ]);

  useEffect(() => {
    if (unAuthToken.unAuthToken?.status === ACTIONS.STATUS.RESOLVED) {
      setIsLoadingTransactions(false);
      setLoading(false);
    }
  }, [unAuthToken]);

  const handleBackPropagation = () => {
    history.push('/earn');
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const handleCloseConfirm = () => {
    track(EVENTS.SURVEY_CLOSE_BUTTON_CLICKED, {
      location: EVENT_LOCATION.LOYALTY_CONNECT_SURVEY_PAGE,
      ui_version: import.meta.env.APP_VERSION,
    });

    setShowAlreadyPlayed(false);

    history.push('/earn');
  };

  const openShareModal = () => {
    track(EVENTS.SURVEY_SHARE_BUTTON_CLICKED, {
      location: EVENT_LOCATION.LOYALTY_CONNECT_SURVEY,
      ui_version: import.meta.env.APP_VERSION,
    });
    setShowSocialShare(true);
  };

  const ConfirmationDetail = () => {
    return <></>;
  };

  const ConfirmationBody = ({ ammount, isDesktop }) => {
    return (
      <>
        <div className="mb-1 p-0 w-100">
          <div className="header-text p-0 pt-1 text-left">
            {txnFailedMsg
              ? txnFailedMsg
              : `👋 Thank you, ${user?.partnerAccounts?.[0]?.profile?.name}!`}
          </div>
        </div>
        <div
          className={`flex flex-row header-badge header-badge-points rounded mt-2 mb-2 ${
            isDesktop ? 'w-100 mx-auto' : 'w-100'
          }`}
        >
          <div className="header-points-title mr-auto">Points Earned:</div>
          <div>
            <span className="header-points-text">{moneyFormat(ammount)}</span>
          </div>
        </div>
        <Row className="pt-1 pb-1">
          <div className="points-after-image-text py-3 px-2 w-100 text-center">
            Thank you for participating! <br />
            Come back next month for
            <br /> another chance to earn points!
          </div>
        </Row>
      </>
    );
  };

  const NewWaysToEarn = ({}) => {
    return (
      <div className="py-2 w-100">
        <Col
          className={`py-0 text-center mt-0 ${
            isDesktop ? 'w-100 mx-auto px-0' : 'px-3'
          }`}
        >
          Here are other ways to earn more points:
        </Col>

        <NewWaysTo.Earn />
      </div>
    );
  };

  const ConfirmationFooter = ({ isDesktop, processTxn, txnSuccess }) => {
    return (
      <>
        <div className="mt-2 mb-1 flex flex-row w-100 mx-0">
          <Button onClick={handleBackPropagation} block className="flex-1">
            DONE
          </Button>
        </div>
      </>
    );
  };

  const handleSignUp = () => {
    setIsLoadingLogin(true);
    track(EVENTS.LOG_IN_REGISTER_CLICKED, {
      location: EVENT_LOCATION.LOYALTY_CONNECT_SURVEY_PAGE,
      ui_version: import.meta.env.APP_VERSION,
    });
    instance.config.auth.authority = instance.config.auth.loginAuthority;
    instance.loginRedirect({
      ...instance.config.auth.loginRequest,
      extraQueryParameters: {
        anonymousId: window?.analytics?.user?.()?.anonymousId?.(),
      },
    });
  };

  return (
    <>
      {!isDesktop && (
        <Container fluid className="back-nav">
          <Row>
            <Col xs="1" sm="1" md="1" className="back-nav-heading">
              <span onClick={handleBackPropagation} className="hover">
                <Icon icon="angle-left" width="9" height="17" />
              </span>
            </Col>
            <Col
              xs="10"
              sm="10"
              md="10"
              className="back-nav-heading text-center"
            >
              <span className="">Question of the Month</span>
            </Col>
          </Row>
        </Container>
      )}

      {isSurveyComingSoonFlag || endScreen ? (
        <CampaignEnded isComingSoon={isSurveyComingSoonFlag} />
      ) : (
        <>
          <Container fluid>
            <div id="survey">
              {(isLoadingTransactions || loading) &&
                unAuthToken?.status !== ACTIONS.STATUS.RESOLVED && (
                  <div id="spinner-icon">
                    <div className="text-center spinner survey-spinner mt-5">
                      {showWaitMessage && (
                        <span>
                          Wait some minutes before (re)taking the survey
                          <br />
                          <br />
                        </span>
                      )}
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    </div>
                  </div>
                )}
              {((!isLoadingTransactions && !shouldReloadTransactions) ||
                unAuthToken?.status === ACTIONS.STATUS.RESOLVED) &&
                !showSurvey &&
                (!arePointsAwarded ? (
                  <div>
                    <div className="survey-title-square mx-auto mb-5 mt-3">
                      <span className="m-4">
                        Answer questions and earn Rewards points!
                      </span>
                      {/* <Image
                              fluid
                              src='https://cdn.spinwheel.io/images/tcc/survey-laptop.png'
                              alt='Take Survey'
                            /> */}
                      <div className="survey-image-bg">
                        <div
                          className={`flex justify-center slide-icon-center-${
                            isDesktop ? 'desktop' : 'mobile'
                          }`}
                        >
                          <Icon
                            icon="question of the month"
                            width="292"
                            height="152"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="w-75 mx-auto flex justify-center mb-5 survey-buttons">
                      {unAuthToken?.status === ACTIONS.STATUS.RESOLVED ? (
                        <Button
                          width="full"
                          // className='survey-button mx-auto'
                          block
                          onClick={handleSignUp}
                        >
                          {isLoadingLogin && (
                            <>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                variant="secondary"
                                className="align-middle"
                              />{' '}
                            </>
                          )}
                          Log In / Register
                        </Button>
                      ) : (
                        <Button className="" onClick={activateSurvey}>
                          TAKE SURVEY
                        </Button>
                      )}
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="survey-title-square mx-auto mb-5 mt-3">
                      <span className="m-4">
                        Answer questions and earn AdvantEdge Rewards points!
                      </span>
                      {/* <Image
                          fluid
                          src='https://cdn.spinwheel.io/images/tcc/survey-laptop.png'
                          alt='Take Survey'
                        /> */}
                      <div className="survey-image-bg">
                        <div
                          className={`text-center slide-icon-center-${
                            isDesktop ? 'desktop' : 'mobile'
                          }`}
                        >
                          <Icon
                            icon="question of the month"
                            width="292"
                            height="152"
                          />
                        </div>
                      </div>
                    </div>
                    <span className="survey-subtitle mx-auto mb-5">
                      Come back next month for a new chance to earn points!
                    </span>
                  </div>
                ))}
            </div>
          </Container>
          <ConfirmationShareModal
            ammount={userTransactionPoints}
            show={showConfirmation}
            isDesktop={isDesktop}
            handleClose={handleCloseConfirmation}
            detail={
              <ConfirmationDetail
                isDesktop={isDesktop}
                ammount={userTransactionPoints}
              />
            }
            body={
              <ConfirmationBody
                ammount={userTransactionPoints}
                isDesktop={isDesktop}
              />
            }
            footer={
              <ConfirmationFooter
                isDesktop={isDesktop}
                processTxn={() => {}}
                txnSuccess={''}
              />
            }
            newWaysToEarn={<NewWaysToEarn />}
            showSocialShare={showSocialShare}
          />
          <ConfirmModal
            show={showAlreadyPlayed && !showConfirmation}
            handleClose={handleCloseConfirm}
            isProcessingTxn={isProcessingTxn}
            isStartingTxn={isStartingTxn}
            name={user?.partnerAccounts[0]?.profile?.name}
          />
        </>
      )}
    </>
  );
};

export default Survey;
