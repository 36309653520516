export const config = {
  accountInfo: {
    clientId: 'loyalty_connect',
    appId: '295256691978389',
    ssoClientId: 'dd322bb6-8ced-4083-a80a-13a544ceb94c',
    knownAuthorities: ['pricechopperdevsso.b2clogin.com'],
    tenant: 'pricechopperdevsso',
    policies: {
      signUpPolicy: 'B2C_1A_SIGNUP',
      signInPolicy: 'B2C_1A_signup_signin',
    },
    travelsPath:
      'https://claimrewardmiles.mileageplus.com/united/claim.html#claim_codes',
    allTravelListPath: 'https://pricechopper.travelgoldcard.com/browse/',
  },
};

export const BREAKPOINTS = {
  MOBILE: 768,
  TABLET: 992,
};

export const TRAVEL = {
  PARTNER: 'PARTNER',
  ETRAVEL: 'TRAVEL',
  COMPLETE: 'COMPLETE',
  DAILY_SURPRISES: 'DAILY_SURPRISES',
};
