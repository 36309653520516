import { useContext } from 'react';

import { SchoolContext } from '../../contexts/SchoolContexts';

const useToolsForSchools = () => {
  const schoolState = useContext(SchoolContext);
  if (!schoolState) {
    throw new Error('Undefined school state!');
  }

  const {
    schoolData,
    schoolStatus,
    connectSchoolStatus,
    inviteFriendsLinkData,
  } = schoolState;

  return {
    school: schoolData?.school,
    schoolStatus,
    connectSchoolStatus,
    inviteFriendsLink: inviteFriendsLinkData?.inviteFriendsLink,
  };
};

export default useToolsForSchools;
