import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';

import AppContext from './AppContext';
import { AuthContext } from '../AuthContext';

import { useAsync, ACTIONS } from '../utility';
import { useAuth } from '../AuthContext';
import { track, source, identifyUserForAnalytics } from '../../analytics';
import { fetchCurrencyExchanges } from '../../services/ComponentRegistryService';
import { useUserInfo } from '@rewards/data-provider';

const AppContextState = ({ children, ...props }) => {
  const {
    isSSOEnabled,
    checkUserSession,
    isUnAuthorizsedFlow,
    isToolsForSchoolsInviteFriends,
  } = useContext(AuthContext);
  const history = useHistory();
  const { instance, accounts } = useMsal();

  const { token, checkValidToken, unAuthToken } = useAuth();
  useUserInfo(!!token);

  const asyncCurrencyExchanges = useAsync({
    status: ACTIONS.STATUS.IDLE,
    data: { currencyExchanges: null },
  });
  const {
    data: currencyExchangesData,
    run: runAsyncCurrencyExchanges,
    status: currencyExchangesStatus,
  } = asyncCurrencyExchanges;

  const getCurrencyExchanges = () => {
    checkValidToken().then(() => {
      runAsyncCurrencyExchanges(
        fetchCurrencyExchanges().then((data) => ({
          currencyExchanges: data,
        }))
      );
    });
  };

  useEffect(() => {
    if (isSSOEnabled && !isUnAuthorizsedFlow) {
      isToolsForSchoolsInviteFriends && checkUserSession();
      const unListen = history.listen(() => {
        const accounts = instance.getAllAccounts();
        accounts?.length && checkUserSession(accounts);
      });
      return () => {
        unListen();
      };
    }
  }, [
    isSSOEnabled,
    accounts,
    isUnAuthorizsedFlow,
    isToolsForSchoolsInviteFriends,
  ]);

  useEffect(() => {
    if (token) {
      if (currencyExchangesStatus === ACTIONS.STATUS.IDLE) {
        getCurrencyExchanges();
      }
    }
  }, [token]);

  useEffect(() => {
    if (unAuthToken?.status === ACTIONS.STATUS.RESOLVED) {
      getCurrencyExchanges();
    }
  }, [unAuthToken?.status]);

  const sourceEvent = (page, properties) => {
    source({ page, properties, ...props, history });
  };

  return (
    <AppContext.Provider
      value={{
        currencyExchangesData,
        currencyExchangesStatus,
        track,
        source: sourceEvent,
        identifyUserForAnalytics,
        ...props,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextState;
