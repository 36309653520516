import React, { useCallback, useState, useContext, useEffect } from 'react';
import {
  Container,
  Card,
  InputGroup,
  Form,
  Spinner,
  Col,
  Row,
  Image,
} from 'react-bootstrap';
import jwtDecode from 'jwt-decode';
import { AuthContext, AppContext, ACTIONS } from '@rewards/contexts';

import SelectSchoolModals from '../SelectSchoolModals';
import AccountLinkedModal from '../AccountLinkedModal';

import { debounce, validateEmail } from '../../util/optimizer';
import { ToolsForSchoolsContext } from '../../contexts';
import { useToolsForSchools, useSchool, useResponsive } from '../../util/hooks';
import { TRANSFER_MONEY_TXNS_PARAMS } from '../../util/constants';
import { PAGES, EVENTS } from '../../analytics/events';

import './SignUp.scss';
import TermsAndConditions from '../TermsAndConditions/TermsAndConditions';
import FAQs from '../FAQs/FAQs';
import { NeedHelp } from '@rewards/ui';

const { useAuth } = AuthContext;
const SignUp = () => {
  const { token, tokenStatus, isSSOEnabled } = useAuth();
  const { cardData, schoolForUserStatus, schoolForUser } = useToolsForSchools();
  // console.log('🚀 ~ SignUp ~ schoolForUser:', schoolForUser);
  const { connectSchoolStatus } = useSchool();
  // console.log('🚀 ~ SignUp ~ connectSchoolStatus:', connectSchoolStatus); // Stays IDLE on Sign up with Account
  // on Sign up with account

  const { isDesktop } = useResponsive();

  const [isEmailValid, setisEmailValid] = useState(true);
  const [isCardNumberValid, setIsCardNumberValid] = useState(true);
  const [email, setEmail] = useState();
  const [advantEdgeCardNumber, setAdvantEdgeCardNumber] = useState();
  const [showSignupFlowModal, setShowSignupFlowModal] = useState(true);
  const [showSignUpFlowFindSchoolModal, setShowSignUpFlowFindSchoolModal] =
    useState(false);
  const [showAccountLinkedModal, setShowAccountLinkedModal] = useState(false);

  const { getSSOToken, resetSSOToken } = useContext(AuthContext.AuthContext);
  const { source, track } = useContext(AppContext.AppContext);
  const { validateCardNumberForUser, getSchoolForUser } = useContext(
    ToolsForSchoolsContext
  );

  useEffect(() => {
    source(PAGES.VIEWED_TOOLS_FOR_SCHOOLS_SIGNUP_PAGE, {
      ui_version: import.meta.env.APP_VERSION,
    });
    return () => {
      resetSSOToken();
    };
  }, []);

  useEffect(() => {
    if (cardData?.status === ACTIONS.STATUS.RESOLVED) {
      const {
        data: { cardDetails },
      } = cardData;
      if (!cardDetails?.pcValid) {
        setIsCardNumberValid(false);
        setShowSignupFlowModal(true);
      } else if (cardDetails?.isRegistered) {
        setShowSignupFlowModal(true);
      } else {
        getSSOToken({
          emailId: email,
          cardNumber: advantEdgeCardNumber,
          isRegistered: cardDetails?.isRegistered,
          registeredType: TRANSFER_MONEY_TXNS_PARAMS.TO_ACCOUNT_TYPE,
        });
      }
    } else if (cardData?.status === ACTIONS.STATUS.REJECTED) {
      handleResetSignUpFlow(true);
    }
  }, [cardData?.status]);

  useEffect(() => {
    if (connectSchoolStatus === ACTIONS.STATUS.RESOLVED) {
      setShowAccountLinkedModal(true);
    } else if (connectSchoolStatus === ACTIONS.STATUS.REJECTED) {
      handleResetSignUpFlow(true);
    }
  }, [connectSchoolStatus]);

  useEffect(() => {
    if (tokenStatus === ACTIONS.STATUS.RESOLVED) {
      const decodedToken = jwtDecode(token);
      if (!decodedToken?.isRegistered) {
        getSchoolForUser();
      }
    } else if (tokenStatus === ACTIONS.STATUS.REJECTED) {
      handleResetSignUpFlow(true);
    }
  }, [tokenStatus]);

  useEffect(() => {
    if (schoolForUserStatus === ACTIONS.STATUS.RESOLVED) {
      if (!schoolForUser) {
        setShowSignUpFlowFindSchoolModal(true);
      } else {
        setShowAccountLinkedModal(true);
      }
    }
  }, [schoolForUserStatus]);

  const debouncedValidateEmail = useCallback(
    debounce((nextValue) => {
      const isValid = validateEmail(nextValue);
      setisEmailValid(isValid);
      setEmail(nextValue);
    }, 0),
    [] // will be created only once initially
  );

  const validateUserEmail = (event) => {
    const { value: nextValue } = event.target;
    debouncedValidateEmail(nextValue);
  };

  const handleAdvantEdgeCardNumber = (event) => {
    const { value } = event.target;
    setAdvantEdgeCardNumber(value);
    if (value) {
      setIsCardNumberValid(true);
    } else {
      setIsCardNumberValid(false);
    }
  };
  const handleClearSignUpInputs = () => {
    setEmail();
    setAdvantEdgeCardNumber();
  };

  const handleResetSignUpFlow = (shouldReset = false) => {
    setShowSignUpFlowFindSchoolModal?.(false);
    if (shouldReset) {
      setEmail();
      setAdvantEdgeCardNumber();
      setShowSignupFlowModal(true);
    }
  };

  const handleSelectSchool = () => {
    track(EVENTS.T4S_SIGNUP_SELECT_SCHOOLS_CLICKED, {
      email,
      advantEdgeCardNumber,
      ui_version: import.meta.env.APP_VERSION,
    });

    return new Promise((resolve) => {
      return validateCardNumberForUser({
        cardNumber: advantEdgeCardNumber,
      }).then((response) => {
        resolve(cardData);
      });
    });
  };

  const handleCloseAccountLinkedModal = () => {
    setShowAccountLinkedModal(!showAccountLinkedModal);
    handleResetSignUpFlow(true);
  };

  if (!isSSOEnabled) {
    return (
      <div className="text-center spinner">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }
  return (
    <Container className="py-2 text-center">
      <Row className={`tfs-signup-banner ${!isDesktop ? 'mt-n2' : ''}`}>
        <Col className="mx-auto p-0" lg={8}>
          <Col
            className={`tfs-signup-banner-header ${
              isDesktop ? 'tfs-border-top-rounded-2' : ''
            }`}
          >
            <Col
              className={`tfs-signup-banner-title ${isDesktop ? 'p-2' : ''}`}
            >
              Sign up now for our 2024-2025 Tools for Schools program if you
              haven&#8217;t signed up recently.
            </Col>
            <Col className="tfs-signup-banner-body">
              Earn 1 star for every AdvantEdge Rewards point. Plus create an
              online AdvantEdge Rewards account and earn 2x stars for every
              AdvantEdge Rewards point earned.
            </Col>
          </Col>
          <Col
            className={`tfs-signup-title flex items-center justify-center ${
              isDesktop ? 'tfs-border-bottom-rounded-2 pr-4' : 'p-2'
            }`}
          >
            {isDesktop && (
              <Image
                alt="AdvantEdge Card"
                src="https://cdn.spinwheel.io/images/tcc/t4s_banner.png"
                className="tfs-advantedge-image pr-2 mb-n4"
              />
            )}
            SIGN UP today with your AdvantEdge Card!
          </Col>
        </Col>
      </Row>
      <Col className="mx-auto p-0" lg={7}>
        <Card
          className={`rounded-2 border-0 tfs-signup-card ${
            isDesktop ? 'my-3' : ''
          }`}
        >
          <Card.Header className="border-0  tfs-signup-card-header px-0">
            <Col className="p-0">
              <Card.Img
                variant="bottom"
                className="rounded-2"
                src="https://cdn.spinwheel.io/images/tcc/tools-for-school.png"
              />
            </Col>
          </Card.Header>
          <Card.Body
            className={`tfs-signup-card-body px-0 pt-0 ${
              isDesktop ? 'px-5 pb-2' : ''
            }`}
          >
            {cardData?.data?.cardDetails?.isRegistered && (
              <Col className="tfs-registered-signup-title rounded-2">
                Your account is already created! Please visit{' '}
                <a
                  href={window?.location?.origin}
                  className="tfs-signup-title-link"
                >
                  <u>rewards.pricechopper.com</u>
                </a>{' '}
                to log in to your account and connect to your school.
              </Col>
            )}
            {/* <InputGroup hasValidation className={`text-left my-3 ${isDesktop ? 'w-50 mx-auto' : 'w-100'}`}>
              <Form.Control
                type="email"
                isInvalid={!isEmailValid}
                placeholder='Email'
                value={email || ''}
                className='border-0 bg-white'
                onChange={validateUserEmail}
              />
              <Form.Control.Feedback type="invalid">
                Please enter valid email.
              </Form.Control.Feedback>
            </InputGroup> */}
            <InputGroup
              hasValidation
              className={`text-left ${isDesktop ? 'w-50 mx-auto' : 'w-100'}`}
            >
              <Form.Control
                type="text"
                required
                isInvalid={!isCardNumberValid}
                placeholder="AdvantEdge Card Number"
                className="border-0 bg-white"
                value={advantEdgeCardNumber || ''}
                onChange={handleAdvantEdgeCardNumber}
              />
              <Form.Control.Feedback type="invalid">
                Please enter valid AdvantEdge card number.
              </Form.Control.Feedback>
            </InputGroup>
          </Card.Body>
          <Card.Footer className="border-0 flex flex-col items-center  tfs-signup-card-footer">
            <div className="flex flex-col ">
              <SelectSchoolModals
                showSignUpFlowFindSchoolModal={showSignUpFlowFindSchoolModal}
                showSignupFlowModal={showSignupFlowModal}
                setShowSignUpFlowFindSchoolModal={
                  setShowSignUpFlowFindSchoolModal
                }
                setShowSignupFlowModal={setShowSignupFlowModal}
                disabled={
                  !(isCardNumberValid && advantEdgeCardNumber?.length) ||
                  cardData?.status === ACTIONS.STATUS.PENDING ||
                  tokenStatus === ACTIONS.STATUS.PENDING ||
                  schoolForUserStatus === ACTIONS.STATUS.PENDING
                }
                isLoading={
                  cardData?.status === ACTIONS.STATUS.PENDING ||
                  tokenStatus === ACTIONS.STATUS.PENDING ||
                  schoolForUserStatus === ACTIONS.STATUS.PENDING
                }
                handleSelectSchool={handleSelectSchool}
                handleResetSignUpFlow={handleResetSignUpFlow}
                handleClearSignUpInputs={handleClearSignUpInputs}
              />
              <NeedHelp>
                If you are having difficulty adding your school, please contact
                Customer Service{' '}
                <a
                  href="https://www.pricechopper.com/about-us/customer-service/contact-us/"
                  target="_blank"
                  className="underline text-[#007bff]"
                >
                  here
                </a>{' '}
                or at{' '}
                <a
                  href="tel:+0800-666-7667"
                  className="underline text-[#007bff]"
                >
                  {' '}
                  1 (800) 666-7667
                </a>{' '}
                Monday-Friday 8:30am-6pm, Saturday/Sunday 10am-4pm.
              </NeedHelp>
            </div>
            <TermsAndConditions />
            <FAQs />
          </Card.Footer>
        </Card>
      </Col>
      <AccountLinkedModal
        show={showAccountLinkedModal}
        email={email}
        schoolForUser={schoolForUser}
        extension_AdvantEdgeCard={advantEdgeCardNumber}
        handleClose={handleCloseAccountLinkedModal}
      />
    </Container>
  );
};

export default SignUp;
