import { useContext } from 'react';
import { TravelsContext } from '../../contexts/TravelsContext';

const useTravels = () => {
  const travelsState = useContext(TravelsContext);
  if (!travelsState) {
    throw new Error('Undefined travels state!');
  }
  const { eTravelsData } = travelsState;

  return {
    eTravels: eTravelsData?.eTravels,
  };
};

export default useTravels;
