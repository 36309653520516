import React, { useEffect, useContext } from 'react';
import { Col, Card, Container, Image } from 'react-bootstrap';
import { AppContext } from '@rewards/contexts';

import BackNav from '../BackNav';

import useResponsive from '../../util/hooks/useResponsive';
import { PAGES, EVENT_LOCATION } from '../../analytics/events';
import { getLandingPath, getShopFlyerPath } from '../../util/paths';

import './LandingPage.scss';

const LandingPage = () => {
  const { source } = useContext(AppContext.AppContext);

  const { isDesktop } = useResponsive();

  useEffect(() => {
    source(PAGES.VIEWED_GIFT_CARDS, {
      ui_version: import.meta.env.APP_VERSION,
    });
    LandingPage.landingPath = getLandingPath();
    LandingPage.LOCATION = EVENT_LOCATION.GIFT_CARDS_LANDING_PAGE;
    LandingPage.showBackBtn = false;
  }, []);

  return (
    <Container
      className={`my-3 mx-auto text-center ${isDesktop && 'flex flex-row'} `}
    >
      <Col className={`${isDesktop ? 'ml-5 mr-3' : 'mx-autp'}`} lg={7}>
        <Card className="border-0">
          <Card.Img
            variant="top"
            src="https://cdn.spinwheel.io/images/tcc/shop-in-store-banner.png"
          />
          <Card.Body>
            <Card.Title className="lc-sis-header text-left">
              Shop in Store
            </Card.Title>
            <Card.Text className="lc-sis-card-text">
              <Col className="text-left py-3 px-0">
                Earn Rewards points when you shop at Price Chopper / Market 32
                using your AdvantEdge Card!
              </Col>
              <Col className="text-left py-3 px-0">
                $1 spent = 1 point earned
              </Col>
              <Col className="flex flex-row px-0 justify-center">
                <Col className="text-left py-3 px-0">
                  Check out our{' '}
                  <a
                    href={getShopFlyerPath()}
                    target="_blank"
                    className="lc-sis-earn-weekly-flyer"
                    rel="noreferrer"
                  >
                    Weekly Flyer
                  </a>{' '}
                  for bonus points events and look for these tags in store to
                  pile up the points even faster!
                </Col>
                {!isDesktop && (
                  <Image
                    className="my-4 lc-sis-point-tag-logo"
                    src="https://cdn.spinwheel.io/images/tcc/shop-in-store/shop-in-store-logo.png"
                    alt="Materchef logo"
                  />
                )}
              </Col>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      {isDesktop && (
        <Image
          className="my-auto lc-sis-point-tag-logo"
          src="https://cdn.spinwheel.io/images/tcc/shop-in-store/shop-in-store-logo-desktop.png"
          alt="Materchef logo"
        />
      )}
    </Container>
  );
};

export default BackNav(LandingPage);
