import { useContext } from 'react';
import jwtDecode from 'jwt-decode';

import AuthContext from './AuthContext';

const useAuth = () => {
  const authState = useContext(AuthContext);
  if (!authState) {
    throw new Error('Undefined token state!');
  }

  const {
    isUserAuthenticated,
    data,
    unAuthToken,
    featureFlagsData,
    tokenStatus,
    code,
    sourceUserName,
    isChampionsBorrower,
    userId,
    loanServicerId,
    userSessionData,
    isSSOEnabled,
    isToolsForSchoolsInviteFriends,
    handleSwitchSignupSso,
    switchSignUpSso,
    isMigrated,
    resetToolsForSchoolsInviteFriendsFlow,
  } = authState;

  const checkValidToken = () => {
    return new Promise((resolve, reject) => {
      if (data?.token || unAuthToken?.data) {
        const decoded = jwtDecode(data?.token || unAuthToken?.data);
        let currentTimeStamp = new Date().getTime();
        const tokenExpiryTime = decoded.exp ? decoded.exp : 0;
        currentTimeStamp = Math.floor(currentTimeStamp / 1000);
        if (!tokenExpiryTime || currentTimeStamp >= tokenExpiryTime) {
          window.location.reload(); // new token api call
        } else {
          return resolve(decoded.extUserId);
        }
      }
      return reject();
    });
  };

  return {
    isUserAuthenticated,
    token: data?.token,
    unAuthToken,
    tokenStatus,
    featureFlags: featureFlagsData?.featureFlags,
    checkValidToken,
    code,
    sourceUserName,
    isChampionsBorrower,
    userId,
    loanServicerId,
    isSSOEnabled,
    isSessionExpired: userSessionData?.isSessionExpired,
    isToolsForSchoolsInviteFriends,
    switchSignUpSso,
    handleSwitchSignupSso,
    isMigrated,
    resetToolsForSchoolsInviteFriendsFlow,
  };
};

export default useAuth;
