export const LOG_MESSAGES = {
  APPLICATION_CRASHED:
    "This app has crashed! We've already reported the error and we'll fix it soon.",
  PAGE_ERROR: 'Page Error',
};

export const APP_URLS = {
  LOGGING: '/api/logs',
};

export const INVALID_TOKEN = 'invalidToken';
export const TOKEN_EXPIRED_PAGE_TITLE = 'Your session has expired.';
export const TOKEN_EXPIRED_PAGE_SUB_TITLE =
  'You’ve timed out due to inactivity. You may close and try again. ';
export const TOKEN_EXPIRED_PAGE_EXIT_BUTTON_LABEL = 'Close';

export const POST_MESSAGE_EVENT = {
  INVALID_TOKEN: {
    EVENT_NAME: 'INVALID_TOKEN',
    EVENT_MESSAGE: 'Looks like invalid token is passed',
  },
  EXPIRED_TOKEN: {
    EVENT_NAME: 'EXPIRED_TOKEN',
    EVENT_MESSAGE: 'Looks like token is expired',
  },
  DROPIN_EXIT: {
    EVENT_NAME: 'DROPIN_EXIT',
    EVENT_MESSAGE: 'Drop in Module Closed.',
  },
  INVALID_MODULE_NAME: {
    EVENT_NAME: 'INVALID_MODULE_NAME',
    EVENT_MESSAGE: 'Invalid module name. Please pass a valid module name.',
  },
  DIM_ERROR: {
    EVENT_NAME: 'DIM_ERROR',
    EVENT_MESSAGE: 'Dropin Error. Something went wrong.',
  },
};

export const INVALID_MODULE_NAME = 'invalidModuleName';
