import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '@rewards/contexts';
import { Col, Container, Card, Row } from 'react-bootstrap';
import { Button } from '@rewards/ui';
import { useResponsive } from '../../../util/hooks';

import './CampaignEnded.scss';
import { getLandingPath, getEarnPath } from '../../../util/paths';
import { EVENTS } from '../../../analytics/events';
import Icon from '../../../assets/svg';

const CampaignEnded = ({ isComingSoon = true }) => {
  const { isDesktop } = useResponsive();
  const history = useHistory();

  const { landingPath, track } = useContext(AppContext.AppContext);

  const goToLandingPage = (landingPage) => {
    if (landingPage === 'earn') {
      track(EVENTS.SURVEY_EARN_BUTTON_CLICKED, {
        ui_version: import.meta.env.APP_VERSION,
      });
      history.push(getEarnPath());
    } else {
      track(EVENTS.SURVEY_REDEEM_BUTTON_CLICKED, {
        ui_version: import.meta.env.APP_VERSION,
      });
      history.push(getLandingPath());
    }
  };

  useEffect(() => {
    CampaignEnded.landingPath = landingPath;
  }, [landingPath]);

  useEffect(() => {
    history.listen(() => {
      if (history.action === 'POP') {
        history.replace(landingPath);
      }
    });
  }, [history]);

  return (
    <Container>
      <Col className={`${isDesktop ? 'mx-auto' : 'p-0'}`} lg={8}>
        <Card className="rounded-2 borderless">
          {!isComingSoon ? (
            <Col
              className={`text-center ${
                isDesktop
                  ? 'campaign-ended-header pt-5 mb-4'
                  : 'campaign-ended-header-mobile pt-2 mt-2 mb-3'
              }`}
            >
              Our recent Survey campaign has ended. Come back again soon for a
              new campaign for more chances to win AdvantEdge points!
            </Col>
          ) : (
            <Col
              className={`text-center ${
                isDesktop
                  ? 'campaign-ended-header pt-5 mb-4'
                  : 'campaign-ended-header-mobile pt-2 mt-2 mb-3'
              }`}
            >
              Coming soon on May 1st
            </Col>
          )}
          <Card.Body className="px-0 flex flex-row">
            <Col className="p-0">
              <div className="survey-image-bg">
                <div
                  className={`text-center slide-icon-center-${
                    isDesktop ? 'desktop' : 'mobile'
                  }`}
                >
                  <Icon icon="question of the month" width="292" height="152" />
                </div>
              </div>
            </Col>
          </Card.Body>
          <Row className={`mx-5 pt-2 ${isDesktop ? 'mt-3' : 'mt-2'}`}>
            <Col
              className={`text-center campaign-ended-body-text ${
                isDesktop ? 'mt-4 mb-4' : ''
              }`}
              md={isDesktop ? 6 : 12}
            >
              Earn more points through gift card purchases, student loan
              refinancing, and more!
            </Col>
            {!isDesktop && (
              <Col
                className="text-center mt-4 mb-4 pb-2 campaign-ended-body-text"
                md={12}
              >
                <Button
                  onClick={() => goToLandingPage('earn')}
                  // className='campaign-ended-button'
                >
                  Earn
                </Button>
              </Col>
            )}
            <Col
              className={`text-center campaign-ended-body-text ${
                isDesktop ? 'mt-4 mb-4' : ''
              }`}
              md={isDesktop ? 6 : 12}
            >
              Check out great ways to redeem points like entering sweepstakes,
              paying down student loans, and more!
            </Col>
            {!isDesktop && (
              <Col
                className="text-center mt-4 mb-4 campaign-ended-body-text"
                md={12}
              >
                <Button
                  onClick={() => goToLandingPage('redeem')}
                  // className='campaign-ended-button'
                >
                  Redeem
                </Button>
              </Col>
            )}
          </Row>
          {isDesktop && (
            <Row className="mx-5">
              <Col
                className="px-0 text-center mt-1 mb-4 campaign-ended-body-text"
                md={6}
              >
                <Button
                  onClick={() => goToLandingPage('earn')}
                  // className='campaign-ended-button'
                >
                  Earn
                </Button>
              </Col>
              <Col
                className="px-0 text-center mt-1 mb-4 campaign-ended-body-text"
                md={6}
              >
                <Button
                  onClick={() => goToLandingPage('redeem')}
                  // className='campaign-ended-button'
                >
                  Redeem
                </Button>
              </Col>
            </Row>
          )}
        </Card>
      </Col>
    </Container>
  );
};

export default CampaignEnded;
