import { useQuery } from '@tanstack/react-query';
import getFeatureFlags from '../services/featureFlags';
import { TOKEN_KEY, UN_AUTH_TOKEN_KEY } from '../utils/constants';

export default function useFeatureFlags(enabled = false) {
  // @TODO: Remove onSuccess they should be at the start of the app
  return useQuery({
    queryKey: ['feature-flags'],
    queryFn: getFeatureFlags,
    staleTime: 1000,
    enabled,
    retry: false,
    refetchOnWindowFocus: false,
  });
}
