import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ButtonGroup, Spinner, Dropdown } from 'react-bootstrap';
import { Button } from '@rewards/ui';
import { useMsal } from '@azure/msal-react';
import { AuthContext, AppContext } from '@rewards/contexts';

import { useResponsive } from '../../util/hooks';
import { EVENTS } from '../../analytics/events';

import './Login.scss';
import Icon from '../../assets/svg';
const Login = () => {
  const history = useHistory();
  const { isDesktop } = useResponsive();

  const { instance } = useMsal();

  const { track } = useContext(AppContext.AppContext);
  const { handleShowUnAuthenticatedUserModal, handleSwitchSignupSso } =
    useContext(AuthContext.AuthContext);

  const [isLoading, setIsLoading] = useState(false);

  const handleLoginRegister = () => {
    setIsLoading(true);
    track(EVENTS.LOG_IN_REGISTER_CLICKED, {
      ui_version: import.meta.env.APP_VERSION,
    });
    instance.config.auth.authority = instance.config.auth.loginAuthority;
    instance.loginRedirect({
      ...instance.config.auth.loginRequest,
      extraQueryParameters: {
        anonymousId: window?.analytics?.user?.()?.anonymousId?.(),
      },
    });
  };

  return (
    <>
      <div
        className={`l-locations-dropdown text-white ${
          isDesktop &&
          'l-sso-btn-group desktop-dropdown-container desktop-dropdown-user'
        }`}
      >
        <Dropdown className="">
          {/* TODO: Remove Dropdown Toggle for Radix  */}
          {/* TODO: When removed bootstrap remove the p-lg-0 for lg:p-0 */}
          <Dropdown.Toggle
            as={Button}
            disabled={isLoading}
            title="Log In / Register"
            onClick={handleLoginRegister}
            intent={isDesktop ? 'link' : ''}
            className={`flex  ${
              isDesktop ? '' : 'l-sso-login '
            } py-3 px-6 p-lg-0 no-underline normal-case text-black hover:text-black  lg:text-white lg:hover:text-white !text-base xlg:!text-navbar`}
          >
            {isLoading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                variant="secondary"
                className="align-middle"
              />
            )}
            {isDesktop && (
              <Icon
                className="inline-block"
                icon="user-profile-transparent"
                width="41"
                height="22"
              />
            )}
            Log In / Register
          </Dropdown.Toggle>
        </Dropdown>
      </div>
      {/* <ButtonGroup className='l-sso-btn-group'>
        <Button disabled={isLoading} onClick={handleLoginRegister} variant={isDesktop ? 'link' : 'default'} className={`rounded px-1 ${isDesktop ? 'l-sso-desk-login p-0 mr-2' : 'l-sso-login mr-1'} border-0`}>
          {isLoading &&
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="secondary"
              className='align-middle'
            />
          }
          Log In / Register
        </Button>
      </ButtonGroup> */}
    </>
  );
};

export default Login;
