import React from 'react';

import App from './App';
import Icon from './assets/svg';
import {
  LOYALTY_CONNECT_MODULES_LABEL_BUTTONS,
  config,
} from './util/constants';

const register = () => {
  return {
    parent: 'earn',
    label: 'Community Solar',
    path: config.accountInfo.communitySolarNYPath,
    icon: <Icon icon="community solar" width="130" height="130" />,
    labelButton: LOYALTY_CONNECT_MODULES_LABEL_BUTTONS.COMMUNITY_SOLAR?.label,
    secondLabelButton:
      LOYALTY_CONNECT_MODULES_LABEL_BUTTONS.COMMUNITY_SOLAR?.secondLabel,
    secondPath: config.accountInfo.communitySolarMAPath,
    content: function Content() {
      return <App />;
    },
  };
};

export default { register };
