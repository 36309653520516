import { loyaltyAxios } from '@rewards/data-provider';
import { AxiosResponse } from 'axios';
import {
  QueryObserverOptions,
  UseQueryOptions,
  useQuery,
} from '@tanstack/react-query';
// import { createQuery } from 'react-query-kit';

type SurveyResponse = {
  status: 'COMPLETE' | 'FAILED' | 'PENDING';
  _id: string;
  collectorId: string;
  points: number;
};
type Data = {
  status: {
    desc: string;
    code: number;
  };
  data: SurveyResponse[];
};
type Variables = {
  userId: string;
};

const useSurveyResponses = ({ variables }: { variables: Variables }) => {
  return useQuery<Data>({
    queryKey: ['survey-response', variables.userId],
    queryFn: () => {
      return loyaltyAxios
        .get(`/api/v2/survey/response/${variables.userId}`)
        .then((res: AxiosResponse<Data>) => res.data);
    },
    enabled: !!variables.userId,
  });
};

useSurveyResponses.getFetchOptions = ({
  variables,
}: {
  variables: Variables;
}) => {
  return {
    queryKey: ['survey-response', variables.userId],
    queryFn: () => {
      return loyaltyAxios
        .get(`/api/v2/survey/response/${variables.userId}`)
        .then((res: AxiosResponse<Data>) => res.data);
    },
  };
};

export { useSurveyResponses };
