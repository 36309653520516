import React from 'react';
import { Col } from 'react-bootstrap';

import './Menu1.scss';

const Menu1 = () => {
  return (
    <Col className="p-0 footer-smallest-text-center" sm={2}>
      <div className="menu-footer-menu-one-container">
        <ul id="menu-footer-menu-one" className="menu-footer-menu-one">
          <li className="menu-item">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.pricechopper.com/stores"
            >
              Grocery Store &amp; Pharmacy Locations
            </a>
          </li>
          <li className="menu-item">
            <a href="https://www.pricechopper.com/departments/">Departments</a>
          </li>
          <li className="menu-item">
            <a href="https://www.pricechopper.com/rebates/">Rebates</a>
          </li>
          <li className="menu-item">
            <a href="https://www.pricechopper.com/about-us/">About Us</a>
          </li>
          <li className="menu-item">
            <a href="https://www.pricechopper.com/blog/category/news/">News</a>
          </li>
          <li className="menu-item">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://my.pricechopper.com/"
            >
              For Our Teammates
            </a>
          </li>
          <li className="menu-item">
            <a href="https://www.pricechopper.com/about-us/customer-service/trade-partners/">
              For Our Trade Partners
            </a>
          </li>
          <li className="menu-item">
            <a href="https://www.pricechopper.com/services/">Services</a>
          </li>
          <li className="menu-item">
            <a href="https://shop.pricechopper.com/shop/recipes">Recipes</a>
          </li>
          <li className="menu-item">
            <a href="https://www.pricechopper.com/blog/">Blog</a>
          </li>
        </ul>
      </div>
    </Col>
  );
};

export default Menu1;
