export const CHARITY_STATUS = {
  ACTIVE: 'ACTIVE',
};

export const config = {
  accountInfo: {
    clientId: 'loyalty_connect',
    redirectUri: 'https://rewards.pricechopper.com:8085',
    authBaseUrl: 'https://dev-dim-api.spinwheel.io',
    appId: '295256691978389',
    ssoClientId: 'dd322bb6-8ced-4083-a80a-13a544ceb94c',
    knownAuthorities: ['pricechopperdevsso.b2clogin.com'],
    tenant: 'pricechopperdevsso',
    policies: {
      signUpPolicy: 'B2C_1A_SIGNUP',
      signInPolicy: 'B2C_1A_signup_signin',
    },
  },
};

export const TEXT_IMG_TOP = 'IMAGE_TOP';
export const TEXT_IMG_BELOW = 'IMAGE_BELOW';
export const TEXT_MIDDLE = 'MIDDLE';
export const TEXT_BOTTOM = 'BOTTOM';
export const FEATURED_CHARITY = 'FEATURED';

export const BREAKPOINTS = {
  MOBILE: 768,
  TABLET: 992,
};
