import { Toast } from './Toast';
import { useAtom, useAtomValue } from 'jotai';
import { isToastOpenAtom, toastMessageAtom } from './IntegratedToastAtoms';

export const IntegratedToast = () => {
  const [isOpen, setIsOpen] = useAtom(isToastOpenAtom);
  const message = useAtomValue(toastMessageAtom);

  return (
    <Toast isOpen={isOpen} onOpenChange={setIsOpen}>
      {message}
    </Toast>
  );
};
