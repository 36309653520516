import { useQuery } from '@tanstack/react-query';

import getDigitalDiscounts from '../services/useDigitalDiscounts';

export default function useDigitalDiscounts(enabled = false) {
  return useQuery({
    queryKey: ['digitalDiscounts'],
    queryFn: getDigitalDiscounts,
    staleTime: 1000,
    enabled,
    retry: false,
  });
}
