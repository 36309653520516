import { useQuery } from '@tanstack/react-query';
import initiateAuth from '../services/auth';

export default function useAuth(
  enabled = false,
  requestParams = null,
  liabilityType = ''
) {
  return useQuery({
    queryKey: ['auth', requestParams, liabilityType],
    queryFn: initiateAuth,
    staleTime: 1000,
    enabled,
    retry: false,
    refetchOnWindowFocus: false,
  });
}
