import {
  ModuleMessages,
  Modules,
  getModule,
  getPrizeAmount,
  getPrizeAmountMsg,
} from './utils';
import { Modal, BalancePointIcon, ShareLink, Button } from '@rewards/ui';
import { useInmarCoupon } from '../../api/useInmarCoupon';
import { useAtom } from 'jotai';
import * as Popover from '@radix-ui/react-popover';
import { forwardRef, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

const PrizePopUpCopyButton = forwardRef((props, ref) => {
  const { onClickCopy } = useContext(ShareLink.ShareLinkContext);

  return (
    <Popover.Trigger asChild>
      <Button onClick={onClickCopy}>Share with your friends</Button>
    </Popover.Trigger>
  );
});

export const PrizePopup = ({
  open = false,
  onOpenChange,
  txnStatus,
  prize,
}) => {
  const history = useHistory();
  const module = getModule(prize);
  const moduleMessage = ModuleMessages[module];
  const prizeAmount = getPrizeAmountMsg(prize);
  const amount = getPrizeAmount(prize);
  const { data: coupon, error } = useInmarCoupon(prize.ecouponid);
  const [showTooltip, setShowTooltip] = useAtom(ShareLink.CopyTooltipAtom);
  const [shouldNavigateAway, setShouldNavigateAway] = useState(false);
  const handleOpenChange = (value) => {
    onOpenChange(value);
    if (!value) {
      switch (module) {
        case Modules.SWEEPSTAKE: {
          console.log('redeem/enter-sweepstakes');
          history.push('/enter-sweepstakes');
          break;
        }
        case Modules.MAGAZINE: {
          history.push('/magazines');
          break;
        }
        default: {
          history.push('/earn');
        }
      }
    }
  };
  return (
    <Modal.Root type="cta" open={open} onOpenChange={handleOpenChange}>
      <Modal.Content className=" max-w-sm max-h-90dvh">
        <Modal.Header className={'flex-col pb-0'}>
          <>
            {txnStatus === 'COMPLETE' ? (
              <>
                <Modal.Title className="text-h3">👋 Prize Awarded!</Modal.Title>
              </>
            ) : (
              <>
                <Modal.Title className=" !text-base font-normal text-white bg-cobalt p-3 rounded-lg mb-2 mt-3 lg:mb-4">
                  You may experience a delay in receiving your prize. Please
                  check back shortly.
                </Modal.Title>
              </>
            )}
            <Modal.CloseButton className={'bg-transparent'} />
          </>
        </Modal.Header>
        <ShareLink.Root
          url={`https://${window.location.hostname}/api/priceReservation/reservation/share/${module}/${amount}`}
          shareData={{
            title:
              '👋 I won playing the Price Chopper / Market 32 Daily SurPRIZE!™',
            text: 'Play every day to see what you can win! \nGrand Prize up to 50,000 Rewards points, plus exclusive eCoupons and other rewards!',
          }}
        >
          <Modal.Body className="flex flex-col items-center pt-0">
            {txnStatus === 'COMPLETE' ? (
              <div className="w-full flex justify-between items-center bg-green-apple text-white !p-2 rounded-lg !my-2 lg:!my-4 text-sm md:text-base">
                <span>{moduleMessage}</span>
                <span className="flex items-center ">
                  {module === Modules.REWARDS_POINTS ? (
                    <BalancePointIcon className={'fill-white mx-4'} />
                  ) : null}
                  {prizeAmount}
                </span>
              </div>
            ) : null}
            {coupon?.data ? (
              <img
                className="max-h-64	"
                src={coupon?.data?.enhancedImageUrl}
                alt={coupon?.data?.description}
              />
            ) : (
              <picture className="max-h-64	">
                <source
                  srcset={`https://cdn.spinwheel.io/images/tcc/daily-surprises/daily-${module?.toLowerCase()}-${amount}.webp`}
                  type="image/webp"
                />
                <source
                  srcset={`https://cdn.spinwheel.io/images/tcc/daily-surprises/daily-${module?.toLowerCase()}-${amount}.png`}
                  type="image/png"
                />
                <img
                  src={`https://cdn.spinwheel.io/images/tcc/daily-surprises/daily-${module?.toLowerCase()}-${amount}.png`}
                  alt={`prize: ${module?.toLowerCase()} - amount: ${amount} `}
                />
              </picture>
            )}

            {module === Modules.E_COUPON && (
              <div className="text-center text-cobalt w-full">
                Coupon may take up to 24 hours to appear in your wallet.
              </div>
            )}
            {module === Modules.SWEEPSTAKE && (
              <div className="text-center text-cobalt w-full">
                Your entry has been recorded.
              </div>
            )}
            {module === Modules.MAGAZINE && (
              <div className="text-center text-cobalt w-full">
                Visit Magazines to redeem your prize.
              </div>
            )}
            <div className=" text-center text-cobalt w-full">
              Come back tomorrow to play again!
            </div>
            {/* {!true && <ShareLink.Link />} */}
          </Modal.Body>
          <Modal.Footer className="!mt-0 !py-0    md:!py-2">
            {!window.navigator.share && (
              <Popover.Root
                delayDuration={0}
                open={showTooltip}
                onOpenChange={setShowTooltip}
                disableHoverableContent={true}
              >
                <PrizePopUpCopyButton />
                <Popover.Portal>
                  <Popover.Content className="text-white bg-green-apple z-10 !px-4 !py-2 rounded-lg ">
                    Copied link!
                    <Popover.Arrow
                      width={11}
                      height={5}
                      className="text-green-apple fill-green-apple"
                    />
                  </Popover.Content>
                </Popover.Portal>
              </Popover.Root>
            )}
            <ShareLink.Share>Share with your friends</ShareLink.Share>
          </Modal.Footer>
        </ShareLink.Root>
      </Modal.Content>
    </Modal.Root>
  );
};
