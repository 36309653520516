import React from 'react';
import { Col } from 'react-bootstrap';

import Icon from '../../../assets/svg';

const EarnPointsInfo = ({ isDesktop = false }) => (
  <Col
    className={`tfs-earn-dashboard-info ${
      isDesktop ? 'fixed-bottom p-3' : 'p-0'
    }`}
  >
    <div className="flex flex-row p-3 bg-white rounded-2">
      <Icon icon="question-o" width="32" height="32" />
      <div className="tfs-earn-dashboard-sub-info text-left ml-2 my-auto">
        <span>Complete each activity</span> to earn more stars
      </div>
    </div>
  </Col>
);

export default EarnPointsInfo;
