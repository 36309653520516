// import { CancelToken } from 'axios'
import axiosInstance from '../utils/axiosInstance';
const getReservationInfo = ({ queryKey, signal }) => {
  // const source = CancelToken.source()
  const [, reservationId] = queryKey;
  const promise = axiosInstance
    .get(`/api/priceReservation/${reservationId}`, { signal })
    .then((response) => ({ data: response?.data.data }));

  // promise.cancel = () => {
  //   source.cancel('Query was cancelled by React Query')
  // }
  return promise;
};
export default getReservationInfo;
