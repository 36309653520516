import React from 'react';
import { Col } from 'react-bootstrap';

import './ConnectUs.scss';

const ConnectUs = () => {
  return (
    <Col className="p-0 footer-smallest-text-center" sm={3}>
      <Col className="p-0 l-footer-header">Get in touch with us.</Col>
      <Col className="p-0">
        <a href="tel:+18006667667" className="l-footer-contact-num p-0">
          1 (800) 666-7667
        </a>
      </Col>
      <Col className="p-0 l-footer-contact-day-info">MON-FRI 8:30a - 6p</Col>
      <Col className="p-0 l-footer-contact-day-info">SAT & SUN 10a - 4p</Col>
    </Col>
  );
};

export default ConnectUs;
