function bufferUntilReady(fn, readyPromise) {
  const buffer = [];
  let isReady = false;

  readyPromise.then(() => {
    isReady = true;
    buffer.forEach((args) => fn(...args));
    buffer.length = 0;
  });

  return (...args) => {
    if (isReady) {
      fn(...args);
    } else {
      buffer.push(args);
    }
  };
}

export default bufferUntilReady;
