import React, { useEffect, useContext } from 'react';
import { Container, Col, Nav, Badge } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { AppContext } from '@rewards/contexts';

import Icon from '../../../assets/svg';

import { useResponsive } from '../../../util/hooks';
import { getRedirectingPath } from '../../../util/paths';
import { PAGES, events, EVENT_LOCATION } from '../../../analytics/events';
import { LoyaltyConnectContext } from '../../../contexts/LoyaltyConnectContext';

import './BonusPointsPage.scss';

const BonusPointsPage = () => {
  const history = useHistory();
  const { isDesktop } = useResponsive();

  const { source, track } = useContext(AppContext.AppContext);
  const { updateIsRedeemBalancePage } = useContext(LoyaltyConnectContext);

  const handleSelectLink = (navItem = '') => {
    track(events(navItem), {
      location: EVENT_LOCATION.LOYALTY_CONNECT_BONUS_POINTS,
      ui_version: import.meta.env.APP_VERSION,
    });
    history.replace(getRedirectingPath(navItem));
  };

  useEffect(() => {
    source(PAGES.LOYALTY_CONNECT_BONUS_POINTS, {
      ui_version: import.meta.env.APP_VERSION,
    });
    return () => {
      updateIsRedeemBalancePage();
    };
  }, []);

  return (
    <Container className="py-2 text-center">
      <Col
        className="p-2 text-center m-auto bonus-points-info flex justify-content-center"
        lg={9}
      >
        <div className={`my-auto ${isDesktop ? 'mr-3' : 'mr-1'}`}>
          <Icon icon="trophy-0" width="27" height="27" />
        </div>
        <div className="p-0">
          <Col className="bonus-points-imp-info p-0">
            Congratulations, your 100 rewards points have been applied!
          </Col>
        </div>
      </Col>

      <Col className="bonus-points-redeem-info mt-3 mx-auto" lg={9}>
        Use your rewards points now!
      </Col>
      <Col className="bonus-points-redeem-info mb-3 mx-auto" lg={9}>
        Check out these ways to redeem online today!
      </Col>

      <Nav
        className={`flex flex-column ${isDesktop && 'w-75 mx-auto'}`}
        onSelect={handleSelectLink}
      >
        <Nav.Item className="p-3 bg-white rounded">
          <Nav.Link eventKey="Sweepstakes" className="pl-3 pr-0 py-0 text-dark">
            <GetTitle
              title="Enter Sweepstakes"
              faIcon="enter sweepstakes"
              width="32"
              height="29"
            />
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="p-3 bg-white my-2 rounded">
          <Nav.Link eventKey="Donate" className="pl-3 pr-0 py-0 text-dark">
            <GetTitle title="Donate" faIcon="donate" width="29" height="29" />
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="p-3 bg-white rounded">
          <Nav.Link
            eventKey="Student Debt"
            className="pl-3 pr-0 py-0 text-dark"
          >
            <GetTitle
              title="Student Debt"
              faIcon="student debt"
              width="29"
              height="29"
            />
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <Col className="bonus-points-redeem-extra-info my-5 mx-auto p-4" lg={9}>
        You may use your additional 100 rewards points in store or at the pump
        for food and fuel.
      </Col>
    </Container>
  );
};

export default BonusPointsPage;

const GetTitle = (props) => {
  return (
    <div className="text-left">
      <Icon
        icon={props.faIcon}
        width={props?.width || '17'}
        height={props.height || '17'}
      />
      <span className="ml-3 nav-item-text">{props.title}</span>
      <span className="float-right">
        <Icon
          icon="angle-right"
          width="7"
          height="13"
          className="float-right"
        />
      </span>
    </div>
  );
};
