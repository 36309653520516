export const $white = '#FFF';

export const APP_TABS = {
  REDEEM: 'redeem',
  EARN: 'earn',
};

export const BREAKPOINTS = {
  MOBILE: 768,
  TABLET: 992,
};

export const LOYALTY_CONNECT_MODULES = {
  SWEEPSTAKES: 'Sweepstakes',
  DONATE: 'Donate',
  STUDENT_DEBT: 'Student Loan',
  PRODUCTS: 'In Store Products',
  MAGAZINES: 'Magazines',
  TOOLS_FOR_SCHOOLS: 'Tools For Schools',
  SHIP_TO_HOME: 'Ship To Home',
  FOOD: 'Food',
  FUEL: 'Fuel',
  GIFT_CARDS: 'Gift Cards',
  SHOP: 'Shop',
  MULTIPLIER_PROMOTION: 'Multiplier Promotion',
  WEEKLY_FLYER: 'Weekly Flyer',
  SHOP_IN_STORE: 'Shop in Store',
  SHOP_ONLINE: 'Shop Online',
  STUDENT_LOAN_REFINANCE: 'Student Loan Refinance',
  DAILY_SURPRISES: 'Daily Surprise',
  UTILITY_OFFERS: 'Utility Offers',
  COMMUNITY_SOLAR: 'Community Solar',
  TRAVEL: 'Travel',
  SURVEY: 'Question of the Month',
  BONUS_POINTS: 'Bonus Points',
};

export const LOYALTY_CONNECT_MODULES_LABEL_BUTTONS = {
  COMMUNITY_SOLAR: {
    label: 'NY Residents',
    secondLabel: 'MA Residents',
  },
};

export const LOYALTY_CONNECT_MODULES_DETAILS = {
  Sweepstakes: {
    description: 'Use your Rewards points for a chance to win great prizes!',
    rule: '100 points = <br/>1 entry',
  },
  Donate: {
    description:
      'Use your Rewards points to donate to one of the featured charities!',
    rule: '100 points = $1',
  },
  'Student Loan': {
    description: 'Use Rewards points to chip away at student loans!',
    rule: 'Service Ended on July 31st',
    rule_ended: 'Service Ended on July 31st',
  },
  'In Store Products': {
    description:
      'Redeem your Rewards points for products shipped directly to your home!',
    rule: 'Points for exclusive products shipped to your home',
    description_coming_soon:
      'Our recent In-Store Products promotion has ended.',
    rule_coming_soon: 'Please check back soon for new updates.',
  },
  Magazines: {
    description: 'Redeem Rewards points for a magazine subscription!',
    rule: '400 points = 1 Subscription',
  },
  'Tools For Schools': {
    description: 'Support your school and make a difference in the classroom!',
    rule: 'Register for free or donate points.<br/>100 points =<br/> $1 for your school',
  },
  'Ship To Home': {
    description: 'Redeem your Rewards points for MasterChef Knives!',
    rule: 'Points for exclusive products redeemed in store',
    description_coming_soon: 'Our recent Ship To Home promotion has ended.',
    rule_coming_soon: 'Please check back soon for new updates.',
  },
  Food: {
    description: '',
    rule: '',
  },
  Fuel: {
    description: '',
    rule: '',
  },
  'Gift Cards': {
    description: 'Buy gift cards and earn Rewards points!',
    rule: '$1 spent = 1 point earned',
  },
  Shop: {
    description: '',
    rule: '',
  },
  'Multiplier Promotion': {
    description: '',
    rule: '',
  },
  'Weekly Flyer': {
    description: '',
    rule: '',
  },
  'Shop in Store': {
    description:
      'Look for bonus points on everyday items to pile up the points even faster!',
    rule: '$1 spent = 1 point earned',
  },
  'Shop Online': {
    description: 'Place an order for pickup or delivery!',
    rule: '$1 spent = 1 point earned',
  },
  'Student Loan Refinance': {
    description:
      'Earn 10,000 Rewards points ($100 value) when you refinance your student loans!',
    rule: 'Earn 500 points ($5 value) just for applying!',
  },
  'Question of the Month': {
    description:
      'Check back for new questions every month. Earn 50 Rewards points!',
    rule: 'Answer questions and earn points!',
    description_ended: 'Our recent campaign has ended. ',
    rule_ended: 'Come back for a chance<br/> to earn more points!',
  },
  'Daily Surprise': {
    description_ended: 'Our recent Daily SurPRIZE&trade; game has ended.',
    rule_ended: 'Please check back soon for updates on a new game.',
    description: 'Play Daily for a<br/> chance to win Frozen Food Frenzy!',
    description_coming_soon: 'Coming soon on <br/> February 25th!',
    rule: 'Grand Prize of 50,000 Rewards points plus exclusive eCoupons and other rewards!',
    rule_coming_soon:
      'Grand Prize of 50,000 Rewards points plus exclusive eCoupons and other rewards!',
  },
  'Utility Offers': {
    description:
      'Sign up for a 100% clean pollution free energy plan!<br/><br/>PA & MA Residents in National Grid',
    rule: 'Earn 10,000 points ($100 value).<br/><br/>Plus 1,000 points ($10 value) every 6 months!',
  },
  'Community Solar': {
    description:
      'Earn 6,000 Rewards points<br/> ($60 value) with <br/>Green Mountain Energy<br/> Community Solar Program!',
    rule: 'NY Residents<br/>in<br/>National Grid',
  },
  Travel: {
    description:
      'Redeem Your Rewards points for United Airlines MileagePlus&reg; miles!',
    rule: 'Limited time to Redeem! <br/><br/> Service ends on September 30',
  },
};

export const OFFER_CAMPAIGNS_TYPES = {
  BALANCE: 'BALANCE',
};

export const OFFER_CAMPAIGNS_CONFIG_TYPES = {
  POINTS: 'POINTS',
};

export const FEATURES_FLAGS = {
  ENABLE_EMAGAZINE: 'ENABLE_EMAGAZINE',
  ENABLE_SHIP_TO_HOME: 'ENABLE_SHIP_TO_HOME',
  ENABLE_GIFT_CARD: 'ENABLE_GIFT_CARD',
  ENABLE_SHOP_ONLINE: 'ENABLE_SHOP_ONLINE',
  ENABLE_SHOP_IN_STORE: 'ENABLE_SHOP_IN_STORE',
  ENABLE_WEEKLY_FLYER: 'ENABLE_WEEKLY_FLYER',
  ENABLE_MULTIPLIER_PROMOTION: 'ENABLE_MULTIPLIER_PROMOTION',
  ENABLE_SHOP: 'ENABLE_SHOP',
  ENABLE_USER_PROFILE: 'ENABLE_USER_PROFILE',
  ENABLE_REFI_STUDENT_LOANS: 'ENABLE_REFI_STUDENT_LOANS',
  ENABLE_DAILY_SURPRISES: 'ENABLE_DAILY_SURPRISES',
  ENABLE_FOOD: 'ENABLE_FOOD',
  ENABLE_FUEL: 'ENABLE_FUEL',
  ENABLE_UTILITY_OFFERS: 'ENABLE_UTILITY_OFFERS',
  ENABLE_COMMUNITY_SOLAR: 'ENABLE_COMMUNITY_SOLAR',
  ENABLE_TRAVEL: 'ENABLE_TRAVEL',
  ENABLE_COMING_SOON_PRODUCTS: 'ENABLE_COMING_SOON_PRODUCTS',
  ENABLE_COMING_SOON_UTILITY_OFFERS: 'ENABLE_COMING_SOON_UTILITY_OFFERS',
  ENABLE_COMMUNITY_SOLAR_MA: 'ENABLE_COMMUNITY_SOLAR_MA',
  ENABLE_TOOLS_FOR_SCHOOLS: 'ENABLE_TOOLS_FOR_SCHOOLS',
  SURVEY_MODULE: 'SURVEY_MODULE',
  BONUS_POINTS: 'ENABLE_BONUS_POINTS',
};

export const CAMPAIGN_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  COMPLETE: 'COMPLETE',
};

export const WINNER_SELECTION_INFO_TYPE = {
  INTERMEDIATE: 'INTERMEDIATE',
  INTERMEDIATE_GRAND: 'INTERMEDIATE_GRAND',
  GRAND: 'GRAND',
};

export const CAMPAIGN_POPUP_HOUR_OFFSET = 350;
