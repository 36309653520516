import { useEffect, useState } from 'react';
export const checkIsDesktopDevice = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return false; // tablet
  }
  if (
    /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return false; //mobile
  }
  return true;
};

const useCheckIsDesktop = () => {
  const [isDesktopDevice, setIsDesktopDevice] = useState(
    checkIsDesktopDevice()
  );

  useEffect(() => {
    let timeoutId = null;
    function handleResize() {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        setIsDesktopDevice(checkIsDesktopDevice());
      }, 100);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { isDesktopDevice };
};

export default useCheckIsDesktop;
