export const config = {
  accountInfo: {
    appId: '295256691978389',
    redirectUri: 'https://rewards.pricechopper.com:8081',
    authBaseUrl: 'https://dev-dim-api.spinwheel.io',
    clientId: 'loyalty_connect',
    ssoClientId: 'f499baeb-b011-4344-baa8-65572607c1ad',
    knownAuthorities: ['developersso.b2clogin.com'],
    tenant: 'developersso',
    surveryMonkeyLink: 'https://www.surveymonkey.com/r/C8FRSW7',
    REACT_APP_SITE_KEY: '6Le4UZEbAAAAAGqJRhbsP827j_gztWghm5Xm7nmL',
    REACT_APP_SECRET_KEY: '6Le4UZEbAAAAADFcLrrvc8W_Z2p6TD_jKUKPMY3a',
    policies: {
      signUpPolicy: 'B2C_1A_SIGNUP',
      signInPolicy: 'B2C_1A_signup_signin',
      resetPasswordPolicy: 'B2C_1A_PASSWORDRESET',
    },
    dailySurprisePath:
      'https://gmteurope3.gametation.com/p/pchopper/?customerToken=',
  },
};

export const BREAKPOINTS = {
  MOBILE: 768,
  TABLET: 992,
};

export const GAMETATION_EVENTS = {
  REDEEM: 'REDEEM',
  DONATE: 'DONATE',
  USER_WON: 'USER_WON',
  USER_LOST: 'USER_LOST',
};

export const MODULES = {
  SWEEPSTAKE: 'Sweepstakes',
  REWARDS_POINTS: 'RewardsPoints',
  GIFT_CARD: 'GiftCard',
  MAGAZINE_SUBSCRIPTION: 'MagazineSubscription',
  CHARITY_DONATION: 'CharityDonation',
  STUDENT_LOAN: 'StudentLoan',
  TRAVEL: 'UnitedMileagePlus',
  COUPON: 'Coke',
};

export const GAMETATION_REDIRECTS = {
  [MODULES.SWEEPSTAKE]: '/enter-sweepstakes',
  [MODULES.REWARDS_POINTS]: '',
  [MODULES.GIFT_CARD]: '',
  [MODULES.MAGAZINE_SUBSCRIPTION]: '/magazines',
  [MODULES.CHARITY_DONATION]: '/donate',
  [MODULES.STUDENT_LOAN]: '/student-debt',
  [MODULES.STUDENT_LOAN]: '/travel',
  [MODULES.COUPON]: '',
};

export const EVENT_TYPES = {
  SWEEPSTAKE: 'SWEEPSTAKE',
  BONUS_POINTS: 'BONUS_POINTS',
  GIFT_CARD: 'GIFT_CARD',
  MAGAZINE_SUBSCRIPTION: 'MAGAZINE_SUBSCRIPTION',
  DONATION_CHARITY: 'DONATION_CHARITY',
  STUDENT_LOAN: 'STUDENT_LOAN',
};

export const FEATURES_FLAGS = {
  ENABLE_DAILY_SURPRISES: 'ENABLE_DAILY_SURPRISES',
};
