import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '@rewards/contexts';

import CharitableCauseDetails from './CharitableCauseDetails';
import { CHARITY_STATUS } from '../../constants';
import CommunityRootContext from '../../contexts/CommunityRootContext';
import { PAGES } from '../../analytics/events';

export default function CharitableCauses() {
  const { charities } = useContext(CommunityRootContext);
  const params = useParams();
  const charityInfo = charities?.[params.id];
  const { source } = useContext(AppContext.AppContext);

  useEffect(() => {
    source(PAGES.CHARITY_DETAILS, { ui_version: import.meta.env.APP_VERSION });
  }, []);

  const sortTextFromPosition = (displayText = null) => {
    let textFromPostionMapper = {};
    displayText?.forEach((item) => {
      let eachPositionArray = [{ ...item }];
      textFromPostionMapper[item['textPosition']] &&
        (eachPositionArray = [
          ...textFromPostionMapper[item['textPosition']],
          ...eachPositionArray,
        ]);

      textFromPostionMapper = {
        ...textFromPostionMapper,
        [item['textPosition']]: eachPositionArray,
      };
    });
    return textFromPostionMapper;
  };

  return (
    <React.Fragment>
      <CharitableCauseDetails
        charity={charityInfo}
        activeCharity={charityInfo?.status === CHARITY_STATUS.ACTIVE}
        textMapper={sortTextFromPosition(charityInfo?.displayText)}
      />
    </React.Fragment>
  );
}
