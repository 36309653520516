import { createContext, useContext } from 'react';
export interface IModalContextVariants {
  type?: 'cta' | 'document' | 'notification' | 'transaction' | undefined;
}
export const ModalContextStyles = createContext<IModalContextVariants>({
  type: 'cta',
});

export const useModalContext = () => {
  const context = useContext(ModalContextStyles);

  if (context === undefined) {
    throw Error('Component must be inside of a Modal');
  }

  return context;
};
