export const EVENTS = {
  DONATE_BACK_CLICKED: 'Redemption - Donate - Back Button Clicked',
  DONATE_VIEWED: 'Redemption - Donate - Charity Details Viewed',
  DONATE_BOUGHT: 'Redemption - Donate - Donation Purchased',
  DONATE_BUTTON_CLICKED:
    'Redemption - Donate - Donate Button Clicked (Charity)',
  DONATE_DONE_CLICKED: 'Redemption - Donate - Done Button Clicked',
  DONATE_CLICKED: 'Redemption - Donate - Donate Button Clicked',
  LOG_IN_REGISTER_CLICKED: 'Log In / Register Clicked',
  DAILY_SURPRISES_SHARE_BUTTON_CLICKED:
    'Earning - Daily surPrize - Share Button Clicked',
};

export const EVENT_LOCATION = {
  DONATE_CONFIRM_MODAL: 'Donate Confirm Modal',
  CHARITY_LIST_PAGE: 'Charity List Page',
  CHARITY_DETAILS_PAGE: 'Charity Details Page',
  DONATE_DAILY_SURPRISE_CONFIRM_MODAL: 'Donate Daily surPrize Confirm Modal',
};

export const PAGES = {
  CHARITY_LIST: 'Charity List',
  CHARITY_DETAILS: 'Charity Details',
};
