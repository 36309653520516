import React, { useEffect, useState, useRef, useContext } from 'react';
import { Form, Col } from 'react-bootstrap';

import { SchoolContext } from '../../../../contexts/SchoolContexts';

import './DonateSlider.scss';
import Icon from '../../../../assets/svg';
import styleConst from '../../../../util/styleConsts';

const DonateSlider = ({
  advantPoints = 0,
  currentAdvantPointsDonation = 0,
  handleSliderAdvantPoints,
}) => {
  const target = useRef(null);

  const { currencyExchange, advantPointsMax } = useContext(SchoolContext);

  const [AValues, setAValues] = useState([]);
  const [sliderMaxValue, setSliderMaxValue] = useState(0);

  const changePaymentValue = (e) => {
    e.persist();
    const val = +e.target.value;
    if (val <= sliderMaxValue) {
      handleSliderAdvantPoints(val);
    }
  };

  function setBubble(range, bubble) {
    const val = advantPoints;
    const min = +(range.min ? range.min : 0);
    const max = +(range.max ? range.max : 100);
    const newVal = val === 0 ? 0 : Number(((val - min) * 100) / (max - min));
    bubble.innerHTML = currentAdvantPointsDonation;

    // Sorta magic numbers based on size of the native UI thumb
    bubble.style.left = `calc(${newVal}% + (${5 - newVal * 0.15}px))`;
  }

  useEffect(() => {
    if (currencyExchange) {
      const len = Math.floor(
        advantPointsMax / currencyExchange?.sourceDebitIncrements
      );
      setSliderMaxValue(len * currencyExchange?.sourceDebitIncrements);
      const newArray = [...Array(len + 1).keys()].map(
        (x) => x++ * currencyExchange?.sourceDebitIncrements
      );
      setAValues(newArray);
    }
  }, [currencyExchange, advantPointsMax]);

  useEffect(() => {
    const allRanges = document.querySelectorAll('.lce-range-wrap');
    allRanges.forEach((wrap) => {
      const range = wrap.querySelector('.range');
      const bubble = wrap.querySelector('.bubble');

      range.addEventListener('input', () => {
        setBubble(range, bubble);
      });
      setBubble(range, bubble);
    });
  }, [
    advantPoints,
    advantPointsMax,
    currentAdvantPointsDonation,
    sliderMaxValue,
  ]);

  return (
    <>
      <Col className="mt-2 mb-4 tsf-slidercontainer">
        <Form>
          <Form.Group controlId="formBasicRange">
            <div className="lce-range-wrap">
              <Form.Control
                type="range"
                name="foo"
                value={advantPoints}
                min={0}
                max={sliderMaxValue || 0}
                step={
                  advantPointsMax
                    ? currencyExchange?.sourceDebitIncrements || 0
                    : 0
                }
                className="tfs-donate-slider range"
                ref={target}
                onChange={changePaymentValue}
              />
              <output className="bubble rounded">1</output>
            </div>
          </Form.Group>
        </Form>
      </Col>
    </>
  );
};

export default DonateSlider;
