import { React, useState } from 'react';
import './index.css';
const COOKIENAME = 'confirmPolicyPCH';

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString() + ';';
  const domain = 'domain=' + location.hostname.match(/[^\.]*\.[^.]*$/)[0] + ';';
  const path = 'path=/;';
  const cookie = cname + '=' + cvalue + ';' + expires + path + domain;
  document.cookie = cookie;
}

function getCookie(cname) {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export const CookiePolicyBanner = () => {
  const [hasConfirmCookiePolicy, setConfirmCookiePolicy] = useState(
    getCookie(COOKIENAME)
  );
  const saveCookie = () => {
    setCookie(COOKIENAME, true, 356);
    console.log('Save Cookie');
    setConfirmCookiePolicy(true);
  };

  return (
    <div
      id="pch_footer_bar"
      className={`fixed-bottom w-100 bg-white pch-cookie-policy-bar ${
        hasConfirmCookiePolicy ? 'h-0' : ''
      }`}
    >
      <div className="wrapper flex flex-column flex-md-row justify-center">
        <span>
          By continuing to browse our site, you agree to our use of{' '}
          <a href="https://linkprotect.cudasvc.com/url?a=https%3a%2f%2fwww.pricechopper.com%2fabout-us%2fcustomer-service%2fprivacy-policy%2f&c=E,1,SqMWhwVKO6Xvwf-eP3nxi50cNcrAzkbcvOb7tNa_nYIVmeBpGPUP4RpXP4Z97s1bIbWnAxhGzSr3OG3MqrRVqD8m_rWM3v3cB_WgsK83ptxu5QsWfxGu&typo=1">
            Cookies, Privacy Policy and Terms of Service
          </a>
          .
        </span>
        <button onClick={saveCookie} id="confirm_policy">
          I Agree
        </button>
      </div>
    </div>
  );
};
