import React from 'react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { Button } from '../../lib/Button';
import { AngleRightIcon } from '../../lib/icons';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';

type BannerProps = {
  to: string;
  name: string;
};

const REDEEM_ITEMS = [
  {
    name: 'Sweepstake',
    to: '/enter-sweepstakes',
  },
  {
    name: 'Donate',
    to: '/donate',
  },
  {
    name: 'Travel',
    to: '/travel',
  },
  {
    name: 'Magazines',
    to: '/magazines',
  },
  {
    name: 'Tools for Schools',
    to: '/tools-for-schools',
  },
];

const EARN_ITEMS = [
  {
    name: 'Question of the Month',
    to: '/earn/question-of-the-month',
  },
  {
    name: 'Utility Offers',
    to: '/earn/utility-offers',
  },
  {
    name: 'Gift Car5ds',
    to: '',
  },
  {
    name: 'Shop in Store',
    to: '/magazines',
  },
  {
    name: 'Shop Online',
    to: '/tools-for-schools',
  },
];

const Banner = ({ to, name }: BannerProps) => {
  return (
    <Button intent="secondary" className="bg-white" asChild>
      <Link to={to} className="flex justify-between normal-case font-normal">
        {name}
        <AngleRightIcon width="7" height="13" />
      </Link>
    </Button>
  );
};

type NewWaysToProps = {
  items: BannerProps[];
};
const Root = ({ items }: NewWaysToProps) => {
  return (
    <NavigationMenu.Root>
      <NavigationMenu.List className={`flex flex-column w-9/12 lg:mx-auto`}>
        {items.map(({ name, to }) => {
          return (
            <NavigationMenu.Item className=" bg-white mb-2 rounded">
              <NavigationMenu.Link className="p-0 text-dark">
                <Banner name={name} to={to} />
              </NavigationMenu.Link>
            </NavigationMenu.Item>
          );
        })}
      </NavigationMenu.List>
    </NavigationMenu.Root>
  );
};

const useDynamicItems = (items: BannerProps[]) => {
  const { pathname } = useLocation();

  return items
    .filter((item) => {
      return !pathname.includes(item.to);
    })
    .slice(0, 3);
};

export const Redeem = () => {
  const items = useDynamicItems(REDEEM_ITEMS);
  return <Root items={items} />;
};

export const Earn = () => {
  const items = useDynamicItems(EARN_ITEMS);
  return <Root items={items} />;
};
