import { useMutation } from '@tanstack/react-query';
import initUnAuthToken from '../services/useUnAuthToken';
import { TOKEN_KEY } from '../utils/constants';

export default function useUnAuthToken() {
  return useMutation({
    mutationKey: ['unAuthToken'],
    mutationFn: initUnAuthToken,
    onSuccess: () => {
      // console.log('useUnAuthToken onSuccess removeItem: TOKEN_KEY')
      sessionStorage.removeItem(TOKEN_KEY);
    },
  });
}
