import axiosInstance from '../utils/axiosInstance';
const getPartnerConfiguration = ({ queryKey, signal }) => {
  const [, clientId] = queryKey;
  const promise = axiosInstance
    .get(`/api/partners/configuration/${clientId}`, { signal })
    .then((response) => ({ data: response?.data }));

  // promise.cancel = () => {
  //   source.cancel('Query was cancelled by React Query')
  // }
  return promise;
};
export default getPartnerConfiguration;
