import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { useResponsive } from '../../../../util/hooks';
import Icon from '../../../../assets/svg';

import './ContactViaSocialMedia.scss';

const ContactViaSocialMedia = () => {
  const { isDesktop } = useResponsive();
  return (
    <>
      <Col
        className={`p-0 ${isDesktop ? 'text-left' : 'text-center mt-3'}`}
        sm={2}
      >
        <Row className="p-0 footer-smallest-justify-content l-footer-header mb-3">
          Connect with us.
        </Row>
        <Row className={`p-0 mb-3 flex footer-smallest-justify-content`}>
          <a href="https://www.facebook.com/PriceChopper" target="_blank">
            <Icon icon="facebook" width="26" height="36" />
          </a>
          <a
            href="https://instagram.com/pricechopper/"
            className="p-0 mx-2"
            target="_blank"
          >
            <Icon icon="instagram" width="26" height="36" />
          </a>
          <a
            href="https://www.pinterest.com/pricechopper/"
            className="p-0"
            target="_blank"
          >
            <Icon icon="pinterest" width="26" height="36" />
          </a>
          <a
            href="https://twitter.com/pricechopper"
            className="p-0 mx-2"
            target="_blank"
          >
            <Icon icon="twitter" width="26" height="36" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCavdk7RdstrI_CS71zpYdVw"
            className="p-0"
            target="_blank"
          >
            <Icon icon="youtube" width="40" height="36" />
          </a>
        </Row>
      </Col>
    </>
  );
};

export default ContactViaSocialMedia;
