import {
  BackNav,
  Button,
  LOYALTY_CONNECT_MODULES,
  LoginButton,
  Modal,
  ModuleLayout,
  TransactionPopup,
  Toast,
  ShareLink,
} from '@rewards/ui';
import { AuthContext } from '@rewards/contexts';
import { useUserInfo } from '@rewards/data-provider';
import { useEffect, useRef, useState, useMemo } from 'react';
import useModule from '../hooks/useModule';
import { useSetAtom } from 'jotai';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { NewWaysToEarn } from '../components/NewWaysToEarnPoints';
import { useSurveyResponses } from '../api/useSurveyResponses';
import { surveyTimeResetAtomV2 } from '../atoms';

// const DEMO_URL = `https://fm.addxt.com/form/?vf=1FAIpQLSfY4ow8oOPlqoGBX8TJ2elWlk5o92jSZP1tg_GeKzY7Zd38fw&entry.2056812726=CUSTOMER_ID&entry.416841201=CARD_NUMBER&entry.1728358069=TEST_CODE`;
const CARD_NUMBER_PATTERN = 'CARD_NUMBER';
const CUSTOMER_ID_PATTERN = 'CUSTOMER_ID';
const enum SURVEY_MESSAGE_STATUS {
  STARTING = 'starting',
  FAILED = 'failed',
  PROCESSING = 'processing',
  COMPLETE = 'pass',
}

type SurveyGenericEvent = {
  status: Exclude<
    SURVEY_MESSAGE_STATUS,
    SURVEY_MESSAGE_STATUS.COMPLETE | SURVEY_MESSAGE_STATUS.FAILED
  >;
};
type SurveyErrorEvent = {
  status: SURVEY_MESSAGE_STATUS.FAILED;
  error: string;
};
type SurveyCompleteEvent = {
  status: SURVEY_MESSAGE_STATUS.COMPLETE;
  response: Record<string, any>;
};
type SurveyEvent = { clientId: string } & (
  | SurveyCompleteEvent
  | SurveyErrorEvent
  | SurveyGenericEvent
);

export const Survey = () => {
  const { token } = AuthContext.useAuth();
  const { data: userInfoData } = useUserInfo(!!token);
  const user = userInfoData?.data;
  const flag = useModule('SURVEY_MODULE');
  const history = useHistory();
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const setToastMessage = useSetAtom(Toast.setToastMessageAtom);
  const setSurveyTimeReset = useSetAtom(surveyTimeResetAtomV2);

  const [transaction, setTransaction] = useState<
    Record<string, any> | undefined
  >();
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const { data: surveyTransactions } = useSurveyResponses({
    variables: {
      userId: user?._id,
    },
  });

  useEffect(() => {
    if (surveyTransactions && surveyTransactions.data.length > 0) {
      history.push('/earn');
      setToastMessage(
        <>
          Thank you for participating in
          <br /> Question of the Month! <br />
          Come back next month for another chance to earn points!
        </>
      );
    }
  }, [surveyTransactions]);

  useEffect(() => {
    if (user && flag) {
      const today = new Date().getTime();
      console.log('FLAG ,', flag);
      const userCardNumber = user?.partnerAccounts[0].cardNumber;
      const activeSurvey = flag?.timeFlag?.find(
        (survey) => survey.startTs <= today && survey.endTs >= today
      );

      console.log('🚀 ~ useEffect ~ activeSurvey:', activeSurvey);

      const url = activeSurvey?.url
        ?.replace(CUSTOMER_ID_PATTERN, user?.extUserId)
        .replace(CARD_NUMBER_PATTERN, userCardNumber);
      console.log('🚀 ~ useEffect ~ activeSurvey?.url:', activeSurvey?.url);
      if (iframeRef.current && url) {
        iframeRef.current.src = url;
      }
    }
  }, [user, flag]);

  useEffect(() => {
    const beforeUnload = (e: BeforeUnloadEvent) => {
      const message =
        'Are you sure you want to leave the page? If you completed the survey, ignore this.';

      e.preventDefault();
      e.stopPropagation();

      e.returnValue = message;

      return message;
    };
    window.addEventListener('beforeunload', beforeUnload);

    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, []);

  useEffect(() => {
    const eventSource = new EventSource(`/api/v2/survey/${user?.extUserId}`);

    if (user) {
      eventSource.onmessage = (event) => {
        const eventPayload: SurveyEvent = JSON.parse(event.data);
        if (eventPayload.status === SURVEY_MESSAGE_STATUS.FAILED) {
          setToastMessage(eventPayload.error);
          setTimeout(() => history.push('/earn'), 2000);
        }
        if (eventPayload.status === SURVEY_MESSAGE_STATUS.PROCESSING) {
          setSurveyTimeReset(new Date().getTime() + 5 * 60 * 1000);
        }

        if (eventPayload.status === SURVEY_MESSAGE_STATUS.COMPLETE) {
          setTransaction({ data: eventPayload.response });
          setShowConfirmationPopup(true);
        }
      };
    }
    return () => {
      eventSource.close();
    };
  }, [user]);

  return (
    <BackNav module={LOYALTY_CONNECT_MODULES.SURVEY} to="/earn">
      <ModuleLayout>
        <iframe className="w-full h-[500px]  " ref={iframeRef}></iframe>
      </ModuleLayout>
      <TransactionPopup.Root
        user={user}
        isSuccess
        transaction={transaction}
        points={transaction?.data?.data?.destCurrencyValue}
        onClose={setShowConfirmationPopup}
        open={showConfirmationPopup}
        isEarn
      >
        <TransactionPopup.Body>
          {query.get('share') ? (
            <NewWaysToEarn />
          ) : (
            <div className=" radius-sm py-3 px-2 w-full text-center">
              Thank you for participating! <br />
              Come back next month for
              <br /> another chance to earn points!
            </div>
          )}
        </TransactionPopup.Body>
        <TransactionPopup.Footer className="flex-col-reverse">
          <Modal.CloseButtonWrapper asChild>
            <Button
              intent="secondary"
              onClick={() => {
                history.push('/earn');
              }}
            >
              Close
            </Button>
          </Modal.CloseButtonWrapper>
          <ShareLink.Root
            url={`https://${window.location.hostname}/api/surveyEvents/share`}
            shareData={{
              title:
                '👋 I won filling the Price Chopper / Market 32 Question of the Month Survey!',
              text: 'Answer questions and earn AdvantEdge Rewards points!',
            }}
          >
            <ShareLink.Share className="flex">Share</ShareLink.Share>
          </ShareLink.Root>
        </TransactionPopup.Footer>
      </TransactionPopup.Root>
    </BackNav>
  );
};
