import React from 'react';
import { Spinner } from 'react-bootstrap';
import { AuthContext, ACTIONS } from '@rewards/contexts';

import CommunitySolarContext from './CommunitySolarContext';

const { useAuth } = AuthContext;

const CommunitySolarContextState = ({ children }, ...props) => {
  const { tokenStatus, unAuthToken } = useAuth();

  if (
    !(
      tokenStatus === ACTIONS.STATUS.RESOLVED ||
      unAuthToken?.status === ACTIONS.STATUS.RESOLVED
    )
  ) {
    return (
      <div className="text-center spinner">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <CommunitySolarContext.Provider value={{}} {...props}>
      {children}
    </CommunitySolarContext.Provider>
  );
};
export default CommunitySolarContextState;
