import authAxios from './authAxios';

import { constructComponentRegistry } from '../factories/registryFactory';
import {
  APP_TABS,
  LOYALTY_CONNECT_MODULES,
  FEATURES_FLAGS,
} from '../util/constants';

const headerConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const featureResponse = () =>
  Promise.resolve({
    features: [
      {
        name: LOYALTY_CONNECT_MODULES.SWEEPSTAKES,
        component: LOYALTY_CONNECT_MODULES.SWEEPSTAKES,
        tab: APP_TABS.REDEEM,
      },
      {
        name: LOYALTY_CONNECT_MODULES.TOOLS_FOR_SCHOOLS,
        component: LOYALTY_CONNECT_MODULES.TOOLS_FOR_SCHOOLS,
        tab: APP_TABS.REDEEM,
      },
      // {
      //   name: LOYALTY_CONNECT_MODULES.STUDENT_DEBT,
      //   component: LOYALTY_CONNECT_MODULES.STUDENT_DEBT,
      //   tab: APP_TABS.REDEEM,
      //   FF: 'PC_STUDENT_LOAN_MODULE',
      // },
      {
        name: LOYALTY_CONNECT_MODULES.TRAVEL,
        component: LOYALTY_CONNECT_MODULES.TRAVEL,
        tab: APP_TABS.REDEEM,
        FF: FEATURES_FLAGS.ENABLE_TRAVEL,
      },
      {
        name: LOYALTY_CONNECT_MODULES.MAGAZINES,
        component: LOYALTY_CONNECT_MODULES.MAGAZINES,
        tab: APP_TABS.REDEEM,
        FF: FEATURES_FLAGS.ENABLE_EMAGAZINE,
      },
      {
        name: LOYALTY_CONNECT_MODULES.DONATE,
        component: LOYALTY_CONNECT_MODULES.DONATE,
        tab: APP_TABS.REDEEM,
      },
      {
        name: LOYALTY_CONNECT_MODULES.PRODUCTS,
        component: LOYALTY_CONNECT_MODULES.PRODUCTS,
        tab: APP_TABS.REDEEM,
      },
      {
        name: LOYALTY_CONNECT_MODULES.FOOD,
        component: LOYALTY_CONNECT_MODULES.FOOD,
        tab: APP_TABS.REDEEM,
        FF: FEATURES_FLAGS.ENABLE_FOOD,
      },
      {
        name: LOYALTY_CONNECT_MODULES.FUEL,
        component: LOYALTY_CONNECT_MODULES.FUEL,
        tab: APP_TABS.REDEEM,
        FF: FEATURES_FLAGS.ENABLE_FUEL,
      },
      {
        name: LOYALTY_CONNECT_MODULES.SHIP_TO_HOME,
        component: LOYALTY_CONNECT_MODULES.SHIP_TO_HOME,
        tab: APP_TABS.REDEEM,
        FF: FEATURES_FLAGS.ENABLE_SHIP_TO_HOME,
      },
      {
        name: LOYALTY_CONNECT_MODULES.DAILY_SURPRISES,
        component: LOYALTY_CONNECT_MODULES.DAILY_SURPRISES,
        tab: APP_TABS.EARN,
        isNew: false,
        FF: FEATURES_FLAGS.ENABLE_DAILY_SURPRISES,
      },
      {
        name: LOYALTY_CONNECT_MODULES.SURVEY,
        component: LOYALTY_CONNECT_MODULES.SURVEY,
        tab: APP_TABS.EARN,
        isNew: true,
        FF: FEATURES_FLAGS.SURVEY_MODULE,
      },
      {
        name: LOYALTY_CONNECT_MODULES.GIFT_CARDS,
        component: LOYALTY_CONNECT_MODULES.GIFT_CARDS,
        tab: APP_TABS.EARN,
        FF: FEATURES_FLAGS.ENABLE_GIFT_CARD,
      },
      {
        name: LOYALTY_CONNECT_MODULES.COMMUNITY_SOLAR,
        component: LOYALTY_CONNECT_MODULES.COMMUNITY_SOLAR,
        tab: APP_TABS.EARN,
        FF: FEATURES_FLAGS.ENABLE_COMMUNITY_SOLAR,
      },
      {
        name: LOYALTY_CONNECT_MODULES.UTILITY_OFFERS,
        component: LOYALTY_CONNECT_MODULES.UTILITY_OFFERS,
        tab: APP_TABS.EARN,
        FF: FEATURES_FLAGS.ENABLE_UTILITY_OFFERS,
      },
      // {
      //   name: LOYALTY_CONNECT_MODULES.STUDENT_LOAN_REFINANCE,
      //   component: LOYALTY_CONNECT_MODULES.STUDENT_LOAN_REFINANCE,
      //   tab: APP_TABS.EARN,
      //   FF: FEATURES_FLAGS.ENABLE_REFI_STUDENT_LOANS,
      // },
      {
        name: LOYALTY_CONNECT_MODULES.SHOP,
        component: LOYALTY_CONNECT_MODULES.SHOP,
        tab: APP_TABS.EARN,
        FF: FEATURES_FLAGS.ENABLE_SHOP,
      },
      {
        name: LOYALTY_CONNECT_MODULES.SHOP_ONLINE,
        component: LOYALTY_CONNECT_MODULES.SHOP_ONLINE,
        tab: APP_TABS.EARN,
        FF: FEATURES_FLAGS.ENABLE_SHOP_ONLINE,
      },
      {
        name: LOYALTY_CONNECT_MODULES.MULTIPLIER_PROMOTION,
        component: LOYALTY_CONNECT_MODULES.MULTIPLIER_PROMOTION,
        tab: APP_TABS.EARN,
        FF: FEATURES_FLAGS.ENABLE_MULTIPLIER_PROMOTION,
      },
      {
        name: LOYALTY_CONNECT_MODULES.WEEKLY_FLYER,
        component: LOYALTY_CONNECT_MODULES.WEEKLY_FLYER,
        tab: APP_TABS.EARN,
        FF: FEATURES_FLAGS.ENABLE_WEEKLY_FLYER,
      },
      {
        name: LOYALTY_CONNECT_MODULES.SHOP_IN_STORE,
        component: LOYALTY_CONNECT_MODULES.SHOP_IN_STORE,
        tab: APP_TABS.EARN,
        FF: FEATURES_FLAGS.ENABLE_SHOP_IN_STORE,
      },
    ],
  });

export const fetchRegistry = async (props) => {
  const today = new Date();
  return featureResponse().then((response) => {
    Object.values(FEATURES_FLAGS).map((FF) => {
      try {
        // console.log('FF',FF)
        const hasTimeFlag = props?.featureFlags?.findIndex(
          ({ name, timeFlag }) => name === FF && timeFlag
        );
        // console.log('hasTimeFlag',hasTimeFlag)
        // console.log(props?.featureFlags)
        if (hasTimeFlag > -1) {
          const moduleIndex = response?.features?.findIndex(
            ({ FF: moduleFF }) => moduleFF === FF
          );
          // console.log('Flag',props.featureFlags[hasTimeFlag], 'Contains Array',Array.isArray( props.featureFlags[hasTimeFlag].timeFlag))
          // console.log('Find', props.featureFlags[hasTimeFlag].timeFlag.find(data => data.startTs <= today && data.endTs >= today))
          const currentTimeFlag =
            Array.isArray(props.featureFlags[hasTimeFlag].timeFlag) &&
            props.featureFlags[hasTimeFlag].timeFlag.find(
              (data) => data.startTs <= today && data.endTs >= today
            );
          // console.log('Current',currentTimeFlag)
          const timeFlag = !currentTimeFlag
            ? props?.featureFlags[moduleIndex]?.timeFlag
            : currentTimeFlag;

          response.features[moduleIndex] = {
            ...response.features[moduleIndex],
            ...timeFlag,
          };
          // console.log(response.features[moduleIndex])
        }
        const index = props?.featureFlags?.findIndex(
          ({ name, isActive }) => name === FF && !isActive
        );
        // console.log('Index',index)
        if (index > -1) {
          const moduleIndex = response?.features?.findIndex(
            ({ FF: moduleFF }) => moduleFF === FF
          );

          moduleIndex > -1 && response.features.splice(moduleIndex, 1);
        }
      } catch (e) {
        console.log(e);
      }
    });
    // console.log('RESPONSE', response)

    return constructComponentRegistry({ response, ...props });
  });
};

export const fetchMobileSideNav = async () => {
  return fetch(
    'https://www.pricechopper.com/wp-json/unata/v1/menus/sidenav-mobile-web',
    { mode: 'cors' }
  )
    .then((response) => response.json())
    .then((response) => {
      return response;
    });
};

export const fetchMobileSideNavSecure = async () => {
  return fetch(
    'https://www.pricechopper.com/wp-json/unata/v1/menus/sidenav-mobile-secure',
    { mode: 'cors' }
  )
    .then((response) => response.json())
    .then((response) => {
      return response;
    });
};

export const fetchOfferCampaigns = async () => {
  return authAxios.get('/api/offerCampaigns').then((response) => {
    if (response?.status === 200) {
      const { data } = response?.data;
      return data;
    }
    return null;
  });
};

export const fetchOfferCampaignsIsUserValid = async (params) => {
  return authAxios
    .post('/api/offerCampaigns/isUserValid', { data: { ...params } })
    .then((response) => {
      if (response?.status === 200) {
        const { data } = response?.data;
        return data?.isValid;
      }
      return null;
    });
};

export const fetchOfferCampaignsIssueRewards = async (params) => {
  return authAxios
    .post('/api/offerCampaigns/issueRewards', { data: { ...params } })
    .then((response) => {
      if (response?.status === 200) {
        const { data } = response?.data;
        return data;
      }
      return null;
    });
};

export const fetchUserInfoViaCardNumber = async ({ cardNumber }) => {
  return authAxios
    .post('/api/users/getUserByCardnumber', { data: { cardNumber } })
    .then((response) => {
      if (response?.status === 200) {
        const { data } = response?.data;
        return data;
      }
      return null;
    });
};

export const fetchUserInfoViaEmail = async ({ email }) => {
  return authAxios
    .post('/api/users/searchUser', { data: { emailAddress: email } })
    .then((response) => {
      if (response?.status === 200) {
        const { data } = response?.data;
        return data;
      }
      return null;
    });
};

export const fetchSweepstakesCampaigns = async () => {
  return authAxios
    .get('/api/sweepstakeCampaigns', headerConfig)
    .then((response) => {
      if (response.status === 200) {
        const { data } = response.data;
        return data;
      } else {
        return response;
      }
    });
};
