import React, { useState, useContext, useEffect, useRef } from 'react';
import {
  Media,
  Col,
  Card,
  Row,
  Spinner,
  Overlay,
  Tooltip,
  Container,
  Alert,
  Image,
} from 'react-bootstrap';
import { Button } from '@rewards/ui';
import { useHistory } from 'react-router-dom';
import {
  AuthContext,
  AppContext,
  PartnerContext,
  DailySurpriseContext,
  ACTIONS,
} from '@rewards/contexts';
import {
  useUserInfo,
  useMoneyTransferTxns,
  useReservation,
} from '@rewards/data-provider';
import Icon from '../../assets/svg';
import CommunityRootContext from '../../contexts/CommunityRootContext';
import UserProfileContext from '../../contexts/UserProfileContext';
import { EVENTS, EVENT_LOCATION } from '../../analytics/events';

import BackNav from '../BackNav';
import DonationSuccess from './ConfirmDonation';
import Slider from '../slider/Slider';
import {
  TEXT_IMG_TOP,
  TEXT_IMG_BELOW,
  TEXT_MIDDLE,
  TEXT_BOTTOM,
  FEATURED_CHARITY,
} from '../../constants';
import { useResponsive } from '../../util/customHooks';
import { getCharitiesPath } from '../../util/paths';
import styleConsts from '../../util/styleConsts';
import { roundToHundredsFloor } from '../../util/formatters';

import './charitable.scss';
import DailySurpriseConfirmation from './DailySurpriseConfirmation';

const isDarkPeriod = false;
const CharitableCauseDetails = ({ charity, ...props }) => {
  const counterRef = useRef();
  const history = useHistory();
  const queryString = new URL(document.location).searchParams;
  const reservationId = queryString.get('reservationId');
  const { data: reservationInfo } = useReservation(reservationId);

  const { checkUserSession } = useContext(AuthContext.AuthContext);
  const { checkValidToken } = AuthContext.useAuth();
  const { track } = useContext(AppContext.AppContext);
  const { currencies, charities } = useContext(CommunityRootContext);
  const { useDailySurprise } = DailySurpriseContext;
  const { dailySurpriseEvent } = useDailySurprise(
    DailySurpriseContext.DailySurpriseContext
  );

  const { isDesktop } = useResponsive();
  const { currencyExchanges: currencyExchangesDetails } = AppContext.useApp();
  const { data: userInfo, isFetching: isUserInfoFetching } = useUserInfo();
  const { data: userDetails } = userInfo || {};
  const { mutateAsync: handleMoneyTransferTxns, status: moneyTransferStatus } =
    useMoneyTransferTxns();
  const { partner: partnerDetails } = PartnerContext.usePartner();
  const [showDonationSuccessModal, setShowDonationSuccessModal] =
    useState(false);
  const [currency, setCurrency] = useState();
  const [currencyExchange, setCurrencyExchange] = useState();
  const [selectedCharity, setSelectedCharity] = useState();
  const [partnerAccount, setPartnerAccount] = useState();
  const [advantPoints, setAdvantPoints] = useState(0);
  const [donationAmount, setDonationAmount] = useState(0);
  const [isDonating, setIsDonating] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showTxnErrorTooltip, setTxnErrorTooltip] = useState(false);
  const [txnErrorTooltipMessage, setTxnErrorTooltipMessage] = useState(
    ACTIONS.TXN_ERROR.INTERNAL
  );
  const [isDailySurprise, setIsDailySurprise] = useState(false);

  useEffect(() => {
    CharitableCauseDetails.EVENT_LOCATION = EVENT_LOCATION.CHARITY_DETAILS_PAGE;
    CharitableCauseDetails.landingPath = getCharitiesPath();
  }, []);

  useEffect(() => {
    if (
      reservationInfo?.data &&
      reservationInfo.data.redeem === false &&
      Object.keys(dailySurpriseEvent).length > 0 &&
      currencyExchange
    ) {
      // OPEN THE MODAL
      const { sourceDebitIncrements } = currencyExchange;

      if (sourceDebitIncrements) {
        setAdvantPoints(dailySurpriseEvent.ammount * sourceDebitIncrements);
        setIsDailySurprise(true);
      }
    }
  }, [reservationInfo, currencyExchange]);

  useEffect(() => {
    if (isDailySurprise) {
      handleClickDonate();
    }
  }, [isDailySurprise]);

  const charityTransx = (params) =>
    new Promise((resolve, reject) => {
      return checkValidToken()
        .then(() => {
          handleMoneyTransferTxns(params);
        })
        .then(resolve)
        .catch(reject);
    });

  const renderAllText = (arrayToParse = null) => {
    return (
      arrayToParse &&
      Object.keys(arrayToParse)?.map((item, index) => {
        let { textPosition, text } = arrayToParse[item];
        return (
          <span key={`${textPosition}_${index}`}>
            {textPosition !== TEXT_BOTTOM ? (
              <div style={{ fontWeight: 500 }}> {text || ''} </div>
            ) : (
              <span style={{ fontWeight: 500 }}> {`${text} ` || ''} </span>
            )}
          </span>
        );
      })
    );
  };

  const handlePoints = (shouldDecrease = false) => {
    if (!currencyExchange) return;
    const { sourceDebitIncrements, sourceDebitMax } = currencyExchange;
    if (
      !shouldDecrease &&
      partnerAccount?.accountBalance < sourceDebitIncrements
    ) {
      setShowTooltip(true);
      return;
    }
    let calculatedPoints = 0;
    if (shouldDecrease) {
      calculatedPoints = advantPoints - sourceDebitIncrements;
    } else {
      calculatedPoints = advantPoints + sourceDebitIncrements;
    }
    if (calculatedPoints > partnerAccount?.accountBalance) return;
    if (calculatedPoints < 0) setAdvantPoints(0);
    else if (calculatedPoints > sourceDebitMax) setAdvantPoints(sourceDebitMax);
    else setAdvantPoints(calculatedPoints);
  };

  async function handleClickDonate() {
    track(EVENTS.DONATE_BUTTON_CLICKED, {
      id: selectedCharity?._id,
      name: selectedCharity?.name,
      points_selected: advantPoints,
      is_daily_surprise: isDailySurprise,
      ui_version: import.meta.env.APP_VERSION,
    });
    if (
      (!advantPoints ||
        advantPoints < currencyExchange?.sourceDebitMin ||
        advantPoints > currencyExchange?.sourceDebitMax) &&
      !isDailySurprise
    ) {
      const scrollTopEle = document.getElementById('scrollToPosition');
      document.documentElement.scrollTop = scrollTopEle.offsetTop;
      setShowTooltip(true);
      return;
    }

    let data = {};

    if (isDailySurprise) {
      data = {
        fromAccount: {
          fromUserId: userDetails._id,
          // fromAccountId: partnerAccount.accountId, // wallet id
          fromAccountValue: advantPoints,
          fromCurrencyId: dailySurpriseEvent.code,
          fromAccountType: 'DAILY_SURPRISES',
        },
        toAccount: {
          toUserId: userDetails._id,
          subAccountId: selectedCharity?._id,
          toAccountType: 'CHARITY',
          toAccountValue: advantPoints * currencyExchange?.destCurrencyValue,
        },
      };
    } else {
      data = {
        fromAccount: {
          fromUserId: userDetails._id,
          fromAccountId: partnerAccount.accountId,
          fromAccountValue: advantPoints,
          fromCurrencyId: partnerDetails?.currencyId,
          fromAccountType: 'PARTNER',
        },
        toAccount: {
          toUserId: userDetails._id,
          subAccountId: selectedCharity?._id,
          toAccountType: 'CHARITY',
          toAccountValue: advantPoints * currencyExchange?.destCurrencyValue,
        },
      };
    }

    setDonationAmount(advantPoints);
    setIsDonating(true);
    checkUserSession()
      .then(() => {
        return charityTransx(data);
      })
      .then(() => {
        track(EVENTS.DONATE_BOUGHT, {
          id: selectedCharity?._id,
          name: selectedCharity?.name,
          points_selected: advantPoints,
          is_daily_surprise: isDailySurprise,
          ui_version: import.meta.env.APP_VERSION,
        });
      })
      .then(() => {
        setShowDonationSuccessModal(true);
        setAdvantPoints(0);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setIsDonating(false);
      });
  }

  function handleCloseDonationModal() {
    track(EVENTS.DONATE_DONE_CLICKED, {
      id: selectedCharity?._id,
      name: selectedCharity?.name,
      is_daily_surprise: isDailySurprise,
      ui_version: import.meta.env.APP_VERSION,
    });
    setShowDonationSuccessModal(false);
    setIsDailySurprise(false);
    if (!isDesktop) history.push(getCharitiesPath());
  }

  useEffect(() => {
    if (selectedCharity) {
      track(EVENTS.DONATE_VIEWED, {
        id: selectedCharity?._id,
        name: selectedCharity?.name,
        is_daily_surprise: isDailySurprise,
        ui_version: import.meta.env.APP_VERSION,
      });
    }
  }, [selectedCharity]);

  useEffect(() => {
    if (
      !userDetails ||
      !partnerDetails ||
      !currencies ||
      !currencyExchangesDetails ||
      !charities
    )
      return;
    const { partnerAccounts, partnerId } = userDetails;

    setPartnerAccount(partnerAccounts[0]);

    const currency = currencies.find(
      (currency) => currency._id === partnerDetails?.currencyId
    );
    setCurrency(currency);

    const selectedCharity = charity;
    setSelectedCharity(selectedCharity);
    const selectedCurrencyExchange = currencyExchangesDetails.find(
      (exchange) =>
        exchange.sourceCurrencyId === partnerDetails?.currencyId &&
        exchange.destCurrencyId === selectedCharity?.currencyId
    );
    setCurrencyExchange(selectedCurrencyExchange);
  }, [
    userDetails,
    partnerDetails,
    currencies,
    currencyExchangesDetails,
    charities,
  ]);

  useEffect(() => {
    if (moneyTransferStatus === 'error' && !isUserInfoFetching) {
      userDetails?.partnerAccounts?.[0].accountBalance < advantPoints &&
        setTxnErrorTooltipMessage(ACTIONS.TXN_ERROR.POINTS);
      setTxnErrorTooltip(true);
    }
  }, [isUserInfoFetching, moneyTransferStatus]);

  useEffect(() => {
    if (showTooltip) {
      const timeoutId = setTimeout(() => {
        setShowTooltip(false);
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [showTooltip]);

  useEffect(() => {
    if (showTxnErrorTooltip) {
      const timer = setTimeout(() => {
        setTxnErrorTooltip(false);
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [showTxnErrorTooltip]);

  if (!(partnerAccount && currencyExchange)) {
    return (
      <Spinner animation="border" role="status" className="fullscreen">
        <span className="sr-only">Loading...</span>
      </Spinner>
    );
  }
  const maxDonationPoints = Math.min(
    currencyExchange?.sourceDebitMax,
    roundToHundredsFloor(partnerAccount?.accountBalance)
  );
  const maxDonationAmount = Math.min(
    10,
    (maxDonationPoints || 0) * (currencyExchange?.destCurrencyValue || 0)
  );

  function renderPointsImpact() {
    return (
      <React.Fragment>
        <Media className={`${isDesktop ? '' : 'pb-2'}`}>
          <Media.Body className="text-center font-weight-bold">
            <h4 className="mb-1 pt-2 font-weight-bold currency-value">
              {partnerAccount?.accountBalance >=
                currencyExchange?.sourceDebitIncrements &&
                partnerAccount?.accountBalance}{' '}
              {currency?.name}{' '}
              {partnerAccount?.accountBalance >=
                currencyExchange?.sourceDebitIncrements && 'Points'}
            </h4>
            <div className="impact-static-text">
              {renderAllText(props?.textMapper[TEXT_IMG_TOP])}
            </div>
          </Media.Body>
        </Media>
        {!isDesktop && <Row className="lcc-divider" />}
      </React.Fragment>
    );
  }

  function renderMatchedContributionText() {
    return (
      <div
        className={`text-center card-wrapper ${isDesktop ? '' : 'py-2'}`}
        style={{ fontWeight: '500' }}
      >
        {/* TODO: Change Render All text Black) */}
        <Card
          className={`matched-contribution-check fluid top-card ${
            !isDesktop && 'bg-transparent'
          }`}
        >
          <Card.Body className={`${isDesktop ? 'w-75 mx-auto' : 'py-2'}`}>
            {renderAllText(props?.textMapper[TEXT_BOTTOM])}
          </Card.Body>
        </Card>
      </div>
    );
  }

  function renderBanner() {
    return (
      <div className="card-wrapper mb-2">
        <Card className="fluid top-card py-3">
          <div className="featured-img">
            <div className="flex flex-column">
              {charity?.flag === FEATURED_CHARITY && (
                <Icon
                  icon="featured-tag"
                  width={isDesktop ? '90%' : '100%'}
                  height="66"
                  className="ml-3 mt-2"
                  color={styleConsts.white}
                />
              )}
              <div className="featured-img-wrap">
                <Image
                  src={charity?.image}
                  className="rounded-top border-bottom-0 charity-logo mr-2 ml-3 mt-2"
                />
              </div>
            </div>
          </div>
          <Card.Body className="text-left">
            <div className="font-weight-bold text-left donate-details-title card-text">
              {renderAllText(props?.textMapper[TEXT_IMG_BELOW])}
            </div>

            <div className="font-weight-light donate-details-subtext mt-3 card-text px-2">
              {renderAllText(props?.textMapper[TEXT_MIDDLE])}
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  }

  function renderDonationAmountDetails() {
    return (
      <>
        <Col className="p-0 price-chopper mt-3" id="scrollToPosition">
          <Icon
            icon="donate-text"
            width={isDesktop ? '90%' : '100%'}
            height="66"
          />
          <div className="price-chopper-text position-absolute text-white px-5 font-weight-bold text-center w-100">
            100 points ={' '}
            {currencyExchange
              ? '$' + currencyExchange.destCurrencyValue * 100 + ' donation'
              : 'calculating...'}{' '}
          </div>
        </Col>

        <div className="flex justify-content-center align-items-center pt-4 mx-3">
          <div
            onClick={() => {
              if (!isDailySurprise) {
                handlePoints(true);
              }
            }}
            className="mx-n4"
          >
            <Icon icon="minus" width="61" height="61" />
          </div>
          <div className="points my-auto px-1 flex justify-content-center align-items-center">
            {' '}
            <Icon
              className="inline"
              icon="balance-bolt"
              width="33"
              height="33"
            />
            <span className="pl-1" ref={counterRef}>
              {advantPoints}
            </span>
          </div>
          <div
            onClick={() => {
              if (!isDailySurprise) {
                handlePoints(false);
              }
            }}
            className="mx-n4"
          >
            <Icon icon="plus" width="61" height="61" />
          </div>
          <Overlay
            target={counterRef.current}
            show={showTooltip}
            placement="top"
            delay={{ show: 250, hide: 400 }}
          >
            {(props) => (
              <Tooltip className="lc-donate-points-tooltip" {...props}>
                Minimum points should be{' '}
                {currencyExchange?.sourceDebitIncrements}
              </Tooltip>
            )}
          </Overlay>
          <Overlay
            target={counterRef.current}
            show={showTxnErrorTooltip}
            placement="top"
            delay={{ show: 250, hide: 400 }}
          >
            {(props) => (
              <Tooltip className="lc-donate-points-tooltip" {...props}>
                {txnErrorTooltipMessage}
              </Tooltip>
            )}
          </Overlay>
        </div>
        <Col className="donate-slider-value mx-auto p-3 text-dark font-weight-bold text-center">
          {advantPoints * (currencyExchange?.destCurrencyValue || 0)}
          {advantPoints * (currencyExchange?.destCurrencyValue || 0) > 1
            ? ' dollars '
            : ' dollar '}
          donation
        </Col>
        <Slider
          points={advantPoints}
          entries={advantPoints * (currencyExchange?.destCurrencyValue || 0)}
          min={0}
          max={maxDonationPoints}
          onChange={(e) => {
            const { value } = e.target;
            const calculatedValue =
              Math.round(value / currencyExchange.sourceDebitIncrements) *
              currencyExchange.sourceDebitIncrements;
            if (
              calculatedValue > currencyExchange?.sourceDebitMax ||
              calculatedValue > partnerAccount?.accountBalance
            )
              return;
            setAdvantPoints(calculatedValue);
          }}
        />
        {!isDesktop && renderMatchedContributionText()}
        {/* <Col className={`${isDesktop ? '' : 'slot-margin'}`}></Col> */}
        {!isDesktop && <Col className="slot-margin"></Col>}
        {/* TODO: Replace Style w/ p-5 once bs is removed */}
        <div
          className={`!p-5 lg:!py-3 flex justify-center ${
            isDesktop ? '' : 'fixed-bottom bg-white'
          }`}
        >
          <Button
            // className="get-statrted-button rounded"
            block
            onClick={handleClickDonate}
            disabled={isDonating || isDarkPeriod || isUserInfoFetching}
          >
            {isDonating || isUserInfoFetching ? 'Donating...' : 'Donate'}
          </Button>
        </div>
      </>
    );
  }

  function renderMobileView() {
    return (
      <Container fluid className="text-center">
        <DarkPeriodCharityAlert />
        {renderPointsImpact()}
        {renderBanner()}
        {renderDonationAmountDetails()}
      </Container>
    );
  }
  function renderDesktopView() {
    return (
      <Container fluid className="text-center">
        <div className="flex justify-content-around mt-2">
          <Col md="7">
            <DarkPeriodCharityAlert />
            {renderBanner()}
            {renderMatchedContributionText()}
          </Col>
          <Col md="5" className="card-wrapper pr-0">
            <Card className="fluid top-card bg-white pt-3">
              {renderPointsImpact()}
              {renderDonationAmountDetails()}
            </Card>
          </Col>
        </div>
      </Container>
    );
  }
  return (
    <>
      {isDesktop ? renderDesktopView() : renderMobileView()}
      {showDonationSuccessModal && (
        <UserProfileContext.Provider value={partnerAccount?.profile}>
          {isDailySurprise ? (
            <DailySurpriseConfirmation
              handleClose={handleCloseDonationModal}
              show={showDonationSuccessModal}
              points={donationAmount}
              value={
                donationAmount * (currencyExchange?.destCurrencyValue || 0)
              }
              charityName={selectedCharity?.name}
            />
          ) : (
            <DonationSuccess
              handleClose={handleCloseDonationModal}
              show={showDonationSuccessModal}
              points={donationAmount}
              value={
                donationAmount * (currencyExchange?.destCurrencyValue || 0)
              }
              charityName={selectedCharity?.name}
            />
          )}
        </UserProfileContext.Provider>
      )}
    </>
  );
};

const DarkPeriodCharityAlert = () => {
  const [showAlert, setshowAlert] = useState(isDarkPeriod);
  const handleCloseAlert = (event) => {
    event.stopPropagation();
    setshowAlert(false);
  };

  return (
    <Alert
      show={showAlert}
      className="p-2 rounded hover my-1 dark-period-charity-alert"
    >
      Come back soon to use points for charity!
      <span
        className="position-absolute charity-alert-close hover"
        onClick={handleCloseAlert}
      >
        <Icon icon="close-o" width="20" height="20" />
      </span>
    </Alert>
  );
};

export default BackNav(CharitableCauseDetails);
