const styleConst = {
  white: '#FFF',
  black: '#000',
  grayDark: '#C0C0C0',
  blue: '0000FF',
  electricVioLet: '#9004FE',
  cobalt: '#005BAA',
  capeHoney: '#FFE6A6',
  darkTangerine: '#FFA41B',
  appleGreen: '#61BB46',
  gainsboro: '#DEDEDE',
  lightGrey: '#F9F9F9',
  lynxWhite: '#F7F7F7',
  moonYellow: '#FFBF1B',
  Schooner: '#898785',
  Tomato: '#ff5757',
  tomatoShade1: '#EC2039',
  whiteSmoke: '#f4f4f4',
  fireBrick: '#266cb2',

  'space-20': '1.25rem',
  styleValueToStyleName: (color = '#0000FF') => color,
};

export default styleConst;
