export const config = {
  accountInfo: {
    clientId: 'loyalty_connect',
    appId: '295256691978389',
    ssoClientId: 'dd322bb6-8ced-4083-a80a-13a544ceb94c',
    knownAuthorities: ['pricechopperdevsso.b2clogin.com'],
    tenant: 'pricechopperdevsso',
    policies: {
      signUpPolicy: 'B2C_1A_SIGNUP',
      signInPolicy: 'B2C_1A_signup_signin',
    },
  },
};

export const KNIFE_INFO = 'KNIFE_INFO';

export const BREAKPOINTS = {
  MOBILE: 768,
  TABLET: 992,
};

export const KNIVES = {
  PARTNER: 'PARTNER',
  SHIP_TO_HOME: 'SHIP_TO_HOME',
};

export const KNIFE_STATUS = {
  ACTIVE: 'ACTIVE',
};

export const KNIFE_TXNS_STATUS = {
  COMPLETE: 'COMPLETE',
  OUT_OF_STOCK: 'OUT_OF_STOCK',
};

export const FEATURES_FLAGS = {
  ENABLE_COMING_SOON_PRODUCTS: 'ENABLE_COMING_SOON_PRODUCTS',
};
