import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Col, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Button } from '@rewards/ui';
import { AuthContext, AppContext } from '@rewards/contexts';

import { SchoolContext } from '../../contexts/SchoolContexts';
import { getDonateDashboardPath } from '../../util/paths';
import { useResponsive, useSchool } from '../../util/hooks';
import { PAGES, EVENTS, EVENT_LOCATION } from '../../analytics/events';

import SelectSchoolModals from '../SelectSchoolModals';
import EarnModal from '../EarnPoints/EarnModal';
import InviteFriendsModal from '../InviteFriendsModal';
import BackNav from '../BackNav';
import TermsAndConditions from '../TermsAndConditions';
import FAQs from '../FAQs';

import './SelectedSchoolDasboard.scss';
import { ToolsForSchoolsContext } from '../../contexts';
import { StarsEarnedInfo } from './SelectedSchoolInfo';
import { FamilySupportInfo } from './FamilySupportInfo';

const SelectedSchoolDasboard = () => {
  const { isDesktop } = useResponsive();
  const { landingPath, source } = useContext(AppContext.AppContext);

  useEffect(() => {
    SelectedSchoolDasboard.landingPath = landingPath;
    SelectedSchoolDasboard.LOCATION =
      EVENT_LOCATION.TOOLS_FOR_SCHOOLS_SELECTED_SCHOOL_DASHBOARD;

    source(PAGES.TOOLS_FOR_SCHOOLS_SELECTED_SCHOOL_DASHBOARD, {
      ui_version: import.meta.env.APP_VERSION,
    });
  }, [landingPath]);

  return (
    <Container className="bg-module-tfs">
      {isDesktop ? (
        <SelectedSchoolDesktopView isDesktop={isDesktop} />
      ) : (
        <SelectedSchoolMobileView isDesktop={isDesktop} />
      )}
    </Container>
  );
};

export default SelectedSchoolDasboard;

const SelectedSchoolDesktopView = (props) => {
  return (
    <div className="text-center flex justify-content-between">
      <Col md="7" className="p-0">
        <Col
          className={`${
            props.isDesktop ? 'pt-4 pl-3' : ''
          } tfs-selected-school-title text-left`}
        >
          Tools for Schools Dashboard
        </Col>
        <SelectedSchoolMobileView isDesktop={props.isDesktop} />
        <TermsAndConditions />
        <FAQs />
      </Col>
      <Col md="4" className={`p-0`}>
        <SelectedSchoolActions isDesktop={props.isDesktop} />
      </Col>
    </div>
  );
};

const SelectedSchoolMobileView = (props) => {
  const { school } = useSchool();

  const SelectedSchoolInfo = () => (
    <>
      <Card.Title
        className={`tfs-${
          props.isDesktop ? 'desktop' : 'mob'
        }-landing-card-title m-0`}
      >
        You are currently supporting{props.isDesktop && ':'}
      </Card.Title>
      <Card.Title
        className={`tfs-${
          props.isDesktop ? 'desktop' : 'mob'
        }-landing-card-title-school-name m-0`}
      >
        {school?.name}
      </Card.Title>
    </>
  );

  return (
    <div className="text-center campaign">
      <Card className="text-center rounded-2 borderless tfs-landing-card my-3">
        <Card.Body>
          {!props.isDesktop && <SelectedSchoolInfo />}
          <Card.Img
            variant="bottom"
            className=""
            src="https://cdn.spinwheel.io/images/tcc/tools-for-school.png"
          />
          {props.isDesktop && (
            <div className="flex justify-content-between">
              <div>
                <SelectedSchoolInfo />
              </div>
              <SelectSchoolModals disabled={!school} />
            </div>
          )}
          {!props.isDesktop && <SelectSchoolModals />}
        </Card.Body>
      </Card>
      {!props.isDesktop && <SelectedSchoolActions disabled={!school} />}
    </div>
  );
};

const SelectedSchoolActions = ({ isDesktop = false }) => {
  const history = useHistory();
  const { school } = useSchool();

  const { checkUserSession } = useContext(AuthContext.AuthContext);
  const { featureFlag } = useContext(ToolsForSchoolsContext);

  const { track } = useContext(AppContext.AppContext);
  const { selectedSchool } = useContext(SchoolContext);

  const [showEarnModal, setShowEarnModal] = useState(false);
  const [starEarnDate, setStartEarnDate] = useState();
  const [showInviteFriendsModal, setShowInviteFriendsModal] = useState(false);

  useEffect(() => {
    const currentDate = new Date();
    const diff =
      currentDate.getDate() -
      currentDate.getDay() +
      (currentDate.getDay() < 3 ? -6 : 1);
    const starEarnDate = new Date(currentDate.setDate(diff));
    const month = starEarnDate.getMonth() + 1;
    const date = starEarnDate.getDate();
    const year = starEarnDate.getFullYear().toString().substr(-2);
    setStartEarnDate(`${month}/${date}/${year}`);
  }, []);

  // const renderTooltip = (props) => (
  //   <Tooltip id="button-tooltip" {...props} className="question-tooltip">
  //     Every star earned goes towards purchasing equipment and resources for your
  //     school.
  //   </Tooltip>
  // );
  const handleEarnStars = (shouldHandleEarnModal = true) => {
    if (!showEarnModal && shouldHandleEarnModal) {
      const {
        _id,
        name,
        address: { city },
      } = school;
      track(EVENTS.TOOLS_FOR_SCHOOLS_EARN_STARS_CLICKED, {
        id: _id,
        name,
        city,
        location: EVENT_LOCATION.TOOLS_FOR_SCHOOLS_SELECTED_SCHOOL_DASHBOARD,
        ui_version: import.meta.env.APP_VERSION,
      });
    }

    checkUserSession().then(() => {
      setShowEarnModal(!showEarnModal);
    });
  };

  const handleInviteFriends = (shouldHandleEarnModal = false) => {
    shouldHandleEarnModal && handleEarnStars(!shouldHandleEarnModal);
    setShowInviteFriendsModal(!showInviteFriendsModal);
  };

  const handleDonatePoints = () => {
    const {
      _id,
      name,
      address: { city },
    } = school;
    track(EVENTS.TOOLS_FOR_SCHOOLS_DONATE_POINTS_CLICKED, {
      id: _id,
      name,
      city,
      location: EVENT_LOCATION.TOOLS_FOR_SCHOOLS_SELECTED_SCHOOL_DASHBOARD,
      ui_version: import.meta.env.APP_VERSION,
    });
    history.push(getDonateDashboardPath(school?._id));
  };

  const handleTooltip = () => {
    const {
      _id,
      name,
      address: { city },
    } = school;
    track(EVENTS.TOOLS_FOR_SCHOOLS_STARS_EARNED_ONLINE_INFO_CLICKED, {
      id: _id,
      name,
      city,
      location: EVENT_LOCATION.TOOLS_FOR_SCHOOLS_SELECTED_SCHOOL_DASHBOARD,
      ui_version: import.meta.env.APP_VERSION,
    });
  };

  // const FamilySupportInfo = () => (
  //   <Col className={`my-3 ${isDesktop ? '' : 'p-0'}`}>
  //     <div className=" rounded-20 pl-3 pt-3 pr-3  tfs-selected-school-dashboard-families-support">
  //       <div className="flex justify-content-between">
  //         <div className="flex flex-row">
  //           <div className="my-auto">
  //             <Icon icon="heart-o" width="9" height="17" />
  //           </div>
  //           <div className="mx-2 my-auto">Families Supporting your School</div>
  //         </div>
  //         <div className="tfs-selected-school-dashboard-families-support-number my-auto">
  //           {school?.noOfFamilies || 0}
  //         </div>
  //       </div>
  //       <div className="tfs-selected-school-dashboard-total-stars-earned">
  //         {school?.totalStarsEarned} Total stars earned
  //       </div>
  //     </div>
  //   </Col>
  // );

  const SelectedSchoolDonateEarnAction = () => (
    <>
      <Col
        className={`flex flex-row p-3 justify-content-between tfs-landing-btns ${
          !isDesktop && 'fixed-bottom'
        }`}
      >
        <Button
          width="full"
          // className='w-50 mr-1 borderless tfs-landing-earn-btn'
          disabled={!school}
          onClick={handleEarnStars}
        >
          EARN STARS
        </Button>
        <Button
          width="full"
          // className='w-50 ml-1 borderless tfs-landing-donate-btn'
          onClick={handleDonatePoints}
          disabled={!school}
        >
          DONATE POINTS
        </Button>
      </Col>
    </>
  );

  return (
    <div
      className={`${featureFlag?.otherDetails?.commingSoon ? 'hidden' : ''} ${
        isDesktop ? `bg-white rounded py-3` : ''
      }`}
    >
      {isDesktop ? (
        <>
          <FamilySupportInfo />
          <StarsEarnedInfo isDesktop={isDesktop} />
        </>
      ) : (
        <>
          <StarsEarnedInfo isDesktop={isDesktop} />
          <FamilySupportInfo />
          <TermsAndConditions />
          <FAQs />
          <Col className="tfs-landing-slot p-0"></Col>
        </>
      )}
      <SelectedSchoolDonateEarnAction />
      {showEarnModal && (
        <EarnModal
          show={showEarnModal}
          handleClose={handleEarnStars}
          handleInviteFriends={handleInviteFriends}
        />
      )}
      {showInviteFriendsModal && (
        <InviteFriendsModal
          show={showInviteFriendsModal}
          handleClose={handleInviteFriends}
        />
      )}
    </div>
  );
};
