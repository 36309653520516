import { authAxios } from '@rewards/contexts';

export const fetchKnives = async () => {
  return authAxios.get('/api/shiptohome').then((response) => {
    if (response.status === 200) {
      const { data } = response.data;
      return data;
    } else {
      return response;
    }
  });
};

export const fetchShippingAddress = async (userId) => {
  return authAxios
    .get(`/api/shiptohome/shippingaddress/${userId}`)
    .then((response) => {
      if (response.status === 200) {
        const { data } = response.data;
        return data;
      } else {
        return response;
      }
    });
};

export const fetchPurchasedTxns = async (userId) => {
  return authAxios
    .get(`/api/shiptohome/purchased/${userId}`)
    .then((response) => {
      if (response.status === 200) {
        const { data } = response.data;
        return data;
      } else {
        return response;
      }
    });
};

export const createShippingAddress = async (params) => {
  return authAxios
    .post('/api/shiptohome/shippingaddress/create', { data: { ...params } })
    .then((response) => {
      if (response?.status === 200) {
        const { data } = response.data;
        return data;
      }
    });
};
