import React, { Fragment } from 'react';

import TopNav from '../TopNav';
import Footer from '../Footer';

function BaseLayout({ children }) {
  return (
    <Fragment>
      <TopNav />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          zIndex: 0,
          flex: '1 0',
          paddingBottom: '2.5rem',
          background: '#F9F9F9',
        }}
      >
        {children}
      </div>
      <Footer />
    </Fragment>
  );
}

export default BaseLayout;
