import { atom } from 'jotai';
import { MouseEventHandler, Ref, createContext } from 'react';

interface IShareLinkContext {
  onClickCopy: MouseEventHandler<HTMLButtonElement>;
  onClickShare: MouseEventHandler<HTMLButtonElement>;

  linkRef: Ref<HTMLInputElement>;
  url: string;
}

export const ShareLinkContext = createContext<IShareLinkContext>({
  onClickCopy: () => {},
  onClickShare: () => {},
  linkRef: null,
  url: '',
});

export const CopyTooltipAtom = atom(false);
