import bufferUntilReady from './bufferUntilReady';
import { CLIENT_ID } from './constants';

// buffer calls to the `track` function until segment is ready
const track = window.analytics
  ? bufferUntilReady((event, properties = {}) => {
      if (typeof event === 'undefined') {
        throw new Error('Event name is required');
      }

      window.analytics.track(event, {
        ...properties,
        client_id: CLIENT_ID,
      });
    }, new Promise((resolve) => window.analytics.ready(resolve)))
  : () => {};

export default track;
