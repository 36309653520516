import axiosInstance from '../utils/axiosInstance';
const getExtraImpact = ({ queryKey, signal }) => {
  const [, requestParams, projectionsHit] = queryKey;
  const promise = axiosInstance
    .post(
      `/api/impacts/calculate/extraPaymentImpact${
        projectionsHit ? 'Projections' : ''
      }`,
      requestParams || {},
      {
        signal,
      }
    )
    .then((response) => ({ data: response?.data }));

  return promise;
};

export default getExtraImpact;
