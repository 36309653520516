export const FEATURES_FLAGS = {
  PRICECHOPPPER_SSO_ENABLE: 'PRICECHOPPPER_SSO_ENABLE',
};

export const PARTNER_TYPES = {
  LOYALTY: 'LOYALTY',
  BANK: 'BANK',
};

export const MSAL_EVENT_TYPES = {
  HANDLE_REDIRECTE_END: 'msal:handleRedirectEnd',
  LOGIN_START: 'msal:loginStart',
  LOGIN_SUCCESS: 'msal:loginSuccess',
  ACQUIRE_TOKEN_START: 'msal:acquireTokenStart',
};

export const MSAL_SERVER_ERROR = {
  errorCode: 'access_denied',
  name: 'ServerError',
};

export const config = {
  accountInfo: {
    clientId: 'loyalty_connect',
    appId: '295256691978389',
    ssoClientId: 'f499baeb-b011-4344-baa8-65572607c1ad',
    tenant: 'developersso',
    knownAuthorities: ['developersso.b2clogin.com'],
    policies: {
      signUpPolicy: 'B2C_1A_SIGNUP_SIGNIN',
      signInPolicy: 'B2C_1A_signup_signin',
    },
  },
};

export const ERROR_STATUS_CODES = {
  ERROR_400: 400,
  ERROR_500: 500,
};

export const BREAKPOINTS = {
  MOBILE: 768,
  TABLET: 992,
};
