export const EVENTS = {
  TRAVELS_BACK_CLICKED: 'Redemption - Travel - Travel Back Clicked',
  CLICKED_LIST_OF_TITLE: 'Clicked List of Titles',
  CLICKED_CANCEL_BUTTON:
    'Redemption - Travel - Clicked Points for Travel Cancel Button',
  CLICKED_SELECT_TRAVEL_BUTTON:
    'Redemption - Travel - Clicked Points for Travel Done Button',
  CLICKED_TRAVEL_CONFIRM_BUTTON:
    'Redemption - Travel - Clicked Points for Travel Confirm Button',
  CLICKED_TRAVEL_LIST: 'Redemption - Travel - Clicked Travel List',
  CLICKED_SUBSCRIBE_BUTTON:
    'Redemption - Travel - Points for Travel Redeem Clicked',
  LOG_IN_REGISTER_CLICKED: 'Log In / Register Clicked',
  DAILY_SURPRISES_CLOSE_BUTTON_CLICKED:
    'Earning - Daily surPrize - Close Button Clicked',
  DAILY_SURPRISES_SHARE_BUTTON_CLICKED:
    'Earning - Daily surPrize - Share Button Clicked',
  TRAVELS_COPY_LINK_BUTTON_CLICKED: 'Redemption - Travel - Copy Button Clicked',
};

export const PAGES = {
  VIEWED_TRAVEL_HOME_PAGE:
    'Redemption - Travel - Viewed Points for Travel Home Page',
  VIEWED_TRAVEL_CONFIRMATION_PAGE:
    'Redemption - Travel - Viewed Points for Travel Confirmation Page',
  VIEWED_TRAVEL_SUCCESS_PAGE:
    'Redemption - Travel - Viewed Points for Travel Success Page',
};

export const EVENT_LOCATION = {
  TRAVEL_SUCCESS_PAGE: 'Travel Success Page',
  VIEWED_TRAVEL_CONFIRMATION_PAGE: 'Viewed Travel Confirmation Page',
  VIEWED_TRAVEL_LIST_PAGE: 'Viewed Travel List Page',
  TRAVEL_CONFIRMATION_PAGE: 'Travel Confirmation Page',
  TRAVEL_LIST_PAGE: 'Travel List Page',
  TRAVEL_HOME_PAGE: 'Travel Home Page',
  DAILY_SURPRISES_CONFIRM_POPUP: 'Daily surPrize - Travel - Confirmation Popup',
};
