import { useEffect } from 'react';
import styles from './survey-end.module.scss';
import { useSetAtom } from 'jotai';
import { surveyTimeResetAtom } from '../atoms';
import { useHistory } from 'react-router-dom';
import { EXIT_EVENT } from '../constants';
import { Button } from '@rewards/ui';

const SurveyEnd = () => {
  const setSurveyTimeReset = useSetAtom(surveyTimeResetAtom);
  const onClick = () => {
    window.parent.postMessage(EXIT_EVENT);
  };
  useEffect(() => {
    // Set the Survey Reset Time 20min * 60sec * 1000ms
    setSurveyTimeReset(new Date().getTime() + 5 * 60 * 1000);
  }, []);
  return (
    <div className="w-100 pt-[10vh] flex flex-col items-center ">
      Thank you for participating in the monthly survey. Your Rewards points
      will be awarded shortly.
      <Button className="max-w-xs" onClick={onClick}>
        Go Back
      </Button>
    </div>
  );
};

export default SurveyEnd;
