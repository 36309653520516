const styleConst = {
  white: '#FFF',
  black: '#000',
  grayDark: '#C0C0C0',
  blue: '0000FF',
  electricVioLet: '#9004FE',
  cobalt: '#005BAA',
  appleGreen: '#61BB46',
  lightGrey: '#F9F9F9',
  lynxWhite: '#F7F7F7',
  moonYellow: '#FFBF1B',
  baliHai: '#80929B',
  blueWhale: '#0B324F',
  ceruleanShade: '#F78E6B',
  doveGrey: '#72716F',
  deepSapphire: '#0B3D6B',
  denimShade: '#082D4E',
  gainsboro: '#E1E1E1',
  ghostWhite: '#F4F4FB',
  gray97: '#F7F7F7',
  grey: '#828282',
  greenBlue: '#0E6EC0',
  hawkesBlue: '#D7DCEA',
  lightGrey1: '#CCCCCC',
  lynch: '#697780',
  panache: '#F6FBF4',
  pattensBlue: '#CDDFEC',
  persimmonShade: '#F9AA8F',
  schooner: '#898785',
  softPeach: '#D7EAD9',
  snow: '#FAFAFA',
  sulu: '#96ED7C',
  tomato: '#ff5757',
  veryLightGrey: '#FEFEFE',
  whiteSmoke: '#f5f5f5',
  whiteSmoke1: '#f4f4f4',
  darkTangerine: '#ffa70f',

  'space-20': '1.25rem',
  styleValueToStyleName: (color = '#0000FF') => color,
};

export default styleConst;
