import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Container,
  Col,
  Card,
  Button,
  Row,
  Nav,
  Overlay,
  Tooltip,
} from 'react-bootstrap';
import { ProgressBar, Step } from 'react-step-progress-bar';
import { useUserInfo, useReservation } from '@rewards/data-provider';
import {
  AuthContext,
  AppContext,
  PartnerContext,
  ACTIONS,
} from '@rewards/contexts';

import TravelConfirm from '../TravelConfirm';
import TravelSubscriptionSuccess from '../TravelSubscriptionSuccess';
import BackNav from '../BackNav';

import Icon from '../../assets/svg';
import Slider from './Slider';
import styleConsts from '../../util/styleConsts';
import { dateString, sortOnKey } from '../../util/formatters';
import { useResponsive, useTravels } from '../../util/hooks';
import { PAGES, EVENTS, EVENT_LOCATION } from '../../analytics/events';
import { TravelsContext } from '../../contexts/TravelsContext';

import 'react-step-progress-bar/styles.css';
import './Travels.scss';

const { useAuth } = AuthContext;
const { useApp } = AppContext;
const { usePartner } = PartnerContext;

const Travels = () => {
  const { source, landingPath } = useContext(AppContext.AppContext);

  const { currencyExchanges } = useApp();
  const { partner } = usePartner();
  const { eTravels } = useTravels();
  console.log('🚀 ~ Travels ~ eTravels:', eTravels);
  const { isDesktop } = useResponsive();

  const [currencyExchange, setCurrencyExchange] = useState();

  useEffect(() => {
    source(PAGES.VIEWED_TRAVEL_HOME_PAGE, {
      ui_version: import.meta.env.APP_VERSION || '2.0.0',
    });
    Travels.landingPath = landingPath;
    Travels.LOCATION = EVENT_LOCATION.TRAVEL_HOME_PAGE;
  }, []);

  useEffect(() => {
    if (partner && currencyExchanges && eTravels) {
      const filteredCurrencyExchange = currencyExchanges.filter(
        (ce) =>
          ce.sourceCurrencyId === partner.currencyId &&
          ce.destCurrencyId === eTravels?.[0]?.currencyId
      );
      setCurrencyExchange(filteredCurrencyExchange?.[0]);
    }
  }, [partner, currencyExchanges, eTravels]);

  return (
    <>
      {isDesktop ? (
        <TravelsDesktopView
          currencyExchange={currencyExchange}
          eTravels={eTravels}
        />
      ) : (
        <TravelsMobileView
          currencyExchange={currencyExchange}
          eTravels={eTravels}
        />
      )}
    </>
  );
};

export default BackNav(Travels);

const TravelsDesktopView = ({ currencyExchange, eTravels }) => (
  <>
    <Container className="flex justify-content-between bg-white">
      <Col md="7" className="p-0">
        <TravelsMobileView />
      </Col>
      <Col md="4" className="sweepstake-actions-desktop p-0">
        <TravelAction currencyExchange={currencyExchange} eTravels={eTravels} />
      </Col>
    </Container>
  </>
);

const TravelsMobileView = ({ currencyExchange, eTravels }) => {
  const { unAuthToken } = useAuth();
  const { isDesktop } = useResponsive();

  return (
    <>
      <Container className="my-5">
        {/* {unAuthToken?.status === ACTIONS.STATUS.RESOLVED ?
          <Col className='lc-travels-title text-center'>Redeem AdvantEdge Rewards points for United Airlines MileagePlus&reg; miles!</Col> :
          <Col className='lc-travels-title text-center'>Redeem AdvantEdge Rewards points for United Airlines MileagePlus&reg; miles!</Col>
        } */}
        <Col className="lc-travels-title text-center"></Col>
        <Card
          className={`border-0 rounded ${
            isDesktop ? 'lc-desk-travels-card' : 'lc-travels-card mt-2'
          }`}
        >
          <Card.Body>
            <div className="text-center mb-5">
              <Card.Img
                variant="top"
                src="https://cdn.spinwheel.io/images/pricechopper/travel/united-airlines.png"
              />
            </div>

            <Card.Title className="lc-travels-card-title">
              <strong>Redeem Rewards points for MileagePlus® miles!</strong>
            </Card.Title>
            <br />
            <Card.Text className="lc-travels-card-text">
              The MileagePlus® program makes it easy to earn and use award miles
              on the purchases you make every day, from flights and hotel stays
              to car rentals and more. And since miles don’t expire, you can
              really get anywhere you want to go, when you’re ready.
              <br />
              <br />
              {/* Redeem AdvantEdge Rewards points for MileagePlus&reg; miles! */}
              <p className="mb-2">
                400 Rewards points = 100 MileagePlus® miles
              </p>
              <p className="mb-2">
                700 Rewards points = 200 MileagePlus® miles
              </p>
              <p>950 Rewards points = 300 MileagePlus® miles</p>
            </Card.Text>
            <Card.Text className="mt-8 !font-medium !text-black !text-h5                                                                              ">
              Services ends on September 30, 2024
            </Card.Text>
          </Card.Body>
        </Card>
      </Container>
      {!isDesktop && (
        <TravelAction currencyExchange={currencyExchange} eTravels={eTravels} />
      )}
    </>
  );
};

const TravelAction = ({ currencyExchange, eTravels }) => {
  const { unAuthToken } = useAuth();
  const { isDesktop } = useResponsive();
  const { data: user } = useUserInfo();
  const { partner } = usePartner();
  const target = useRef(null);
  const [isEntriesClosed, setIsEntriesClosed] = useState(false);
  const [entriesHoldData, setEntriesHoldData] = useState();
  const [availablePoints, setAvailablePoints] = useState(0);
  const [travelSubscription, setTravelsSubscription] = useState(0);
  const [showMagSubscription, setShowMagSubscription] = useState(false);
  const [showMagSubscriptionSuccess, setShowMagSubscriptionSuccess] =
    useState(false);
  const [currentAdvantPointsEntries, setCurrentAdvantPointsEntries] =
    useState(0);
  const [advantPoints, setAdvantPoints] = useState(0);
  const [advantPointsMax, setAdvantPointsMax] = useState(0);
  const [userSweepStakeCampaign, setUserSweepStakeCampaign] = useState({});
  // const [currencyExchange, setCurrencyExchange] = useState(null)
  const [completedInfo, setCompletedInfo] = useState([]);
  const [pendingInfo, setPendingInfo] = useState([]);
  const [activeCampaign, setActiveCampaign] = useState();
  const [isActiveCampaign, setIsActiveCampaign] = useState(false);
  const [showTooltip, setTooltip] = useState(false);
  const [showMaxLimitToolTip, setMaxLimitToolTip] = useState(false);
  const [showMaxLimitToolTipAlt, setMaxLimitToolTipAlt] = useState(false);
  const [showTxnErrorToolTip, setTxnErrorToolTip] = useState(false);
  const [txnErrorTooltipMessage, setTxnErrorTooltipMessage] = useState(
    ACTIONS.TXN_ERROR.INTERNAL
  );
  const [toolTipPointsMessage, setToolTipPointsMessage] = useState('');
  const [loaded, setLoaded] = useState(false);

  const queryString = new URL(document.location).searchParams;
  const reservationId = queryString.get('reservationId');
  const { data: reservationInfo } = useReservation(reservationId);

  const resetValue = () => {
    setAdvantPoints(0);
    setCurrentAdvantPointsEntries(0);
    setAdvantPointsMax(
      Math.min(
        currencyExchange?.sourceDebitMax,
        user?.data?.partnerAccounts[0]?.accountBalance
      )
    );
  };

  useEffect(() => {
    if (showMagSubscriptionSuccess === true) {
      resetValue();
    }
  }, [showMagSubscriptionSuccess]);

  useEffect(() => {
    if (reservationInfo) {
      setShowMagSubscription(true);
    }
  }, [reservationInfo]);

  useEffect(() => {
    if (currencyExchange?.sourceDebitIncrements) {
      setLoaded(true);
    }
  }, [currencyExchange]);

  useEffect(() => {
    if (user && currencyExchange) {
      const points = Math.min(
        user?.data?.partnerAccounts[0]?.accountBalance,
        currencyExchange?.sourceDebitMax
      );
      // setAdvantPoints(points);
      setAvailablePoints(points);
      // console.log('puntos Disponibles => ', points)
      setAdvantPointsMax(
        950
        // Math.min(
        //   currencyExchange?.sourceDebitMax,
        //   user?.data?.partnerAccounts[0]?.accountBalance
        // )
      );

      // setTravelsSubscription(Math.floor(points / currencyExchange?.sourceDebitMax))
    }
  }, [user, currencyExchange]);

  const toolTipMessageNoPoints = () => {
    if (availablePoints === 0 && advantPoints === 0) {
      setToolTipPointsMessage("You don't have Rewards points available");
      return true;
    }

    return false;
  };

  const toolTipReacheMaxPoints = () => {
    if (advantPoints === 950) {
      setToolTipPointsMessage('You have reached the limit of points');
      return true;
    }

    return false;
  };

  const toolTipMessageMinRequired = (nextStep) => {
    // console.log({
    //   disponible: availablePoints,
    //   calculated: nextStep
    // })
    if (availablePoints < nextStep) {
      setToolTipPointsMessage(
        'Your Rewards points are less than the required amount for the airline miles.'
      );
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (showTooltip) {
      const timer = setTimeout(() => {
        setTooltip(false);
        setToolTipPointsMessage('');
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [showTooltip]);

  const handleSubscribeConfirm = (isMoneyTransferFailed = false) => {
    if (toolTipMessageNoPoints()) {
      setTooltip(true);
      return;
    }

    if (!isMoneyTransferFailed) {
      if (
        advantPoints <
        (currencyExchange?.sourceDebitMin ?? Number.MAX_SAFE_INTEGER)
      ) {
        if (!isDesktop) {
          const scrollTopEle = document.getElementById('scrollToPosition');
          scrollTopEle.scrollIntoView();
        }
        setToolTipPointsMessage("You haven't selected any miles to redeem");
        setTooltip(true);
      } else {
        setShowMagSubscription(!showMagSubscription);
        setTooltip(false);
      }
    } else {
      setShowMagSubscription(!showMagSubscription);
    }
  };

  const handleSubscribeSuccess = () => {
    setAdvantPoints(0);
    setShowMagSubscriptionSuccess(!showMagSubscriptionSuccess);
  };

  const handlePoints = (shouldDecrease = false) => {
    const { sourceDebitIncrements } = currencyExchange;
    const limitPerDay = partner?.freeEntriesLimitPerDay || 5;
    // if (activeCampaign?.numberOfEntriesPerDay > limitPerDay || (!shouldDecrease && activeCampaign?.numberOfEntriesPerDay + (currentAdvantPointsEntries + 1)) > limitPerDay) {
    //   setMaxLimitToolTip(true)
    //   return
    // }
    // if (!shouldDecrease && advantPointsMax < sourceDebitIncrements) {
    //   setTooltip(true)
    //   return
    // }

    if (toolTipMessageNoPoints() && !shouldDecrease) {
      setTooltip(true);
      return;
    }

    if (toolTipReacheMaxPoints() && !shouldDecrease) {
      setTooltip(true);
      return;
    }

    let currentAdvantPoints = advantPoints;
    // if (!shouldDecrease && partner.paidEntriesPerDay < currentAdvantPointsEntries) {
    //   currentAdvantPoints = advantPoints - sourceDebitIncrements
    //   // setMaxLimitToolTipAlt(true)
    // }

    let calculatedPoints = 0;
    let entries = 0;
    let available = availablePoints;

    if (shouldDecrease) {
      // Restart
      if (currentAdvantPoints === 950) {
        calculatedPoints = 700;
        entries = 2;
        available = available + 250;
      } else if (currentAdvantPoints === 700) {
        calculatedPoints = 400;
        entries = 1;
        available = available + 300;
      } else if (currentAdvantPoints === 400) {
        calculatedPoints = 0;
        entries = 0;
        available = available + 400;
      }
    } else {
      // Sumar

      let left = 0;
      if (currentAdvantPoints === 0) {
        entries = 1;
        available = available - 400;
        calculatedPoints = 400;
        left = 400;
      } else if (currentAdvantPoints === 400) {
        entries = 2;
        available = available - 300;
        calculatedPoints = 700;
        left = 300;
      } else if (currentAdvantPoints === 700) {
        entries = 3;
        available = available - 250;
        calculatedPoints = 950;
        left = 250;
      } else if (currentAdvantPoints === 950) {
        calculatedPoints = 950;
        entries = 3;
        left = 250;
      }

      if (toolTipMessageMinRequired(left) && !shouldDecrease) {
        setTooltip(true);
        return;
      }
    }

    // console.log('CONTROLLER')
    // console.log({
    //   points: calculatedPoints,
    //   entries: entries,
    //   availablePoints: available
    // })

    setAdvantPoints(calculatedPoints);
    setCurrentAdvantPointsEntries(entries);
    setAvailablePoints(available);
  };

  const handleSliderAdvantPoints = (value) => {
    if (toolTipMessageNoPoints()) {
      setTooltip(true);
      return;
    }

    let currentAdvantPoints = advantPoints;
    let calculatedPoints = 0;
    let entries = 0;
    let available = availablePoints;
    let shouldDecrease = advantPoints > value;

    if (shouldDecrease) {
      // Resta
      // console.log('RESTA')
      if (currentAdvantPoints === 950) {
        calculatedPoints = 700;
        entries = 2;
        available = available + 250;
      } else if (currentAdvantPoints === 700) {
        calculatedPoints = 400;
        entries = 1;
        available = available + 300;
      } else if (currentAdvantPoints === 400) {
        calculatedPoints = 0;
        entries = 0;
        available = available + 400;
      }
    } else {
      // suma
      // console.log('SUMAR')
      let left = 0;
      if (currentAdvantPoints === 0) {
        entries = 1;
        available = available - 400;
        calculatedPoints = 400;
        left = 400;
      } else if (currentAdvantPoints === 400) {
        entries = 2;
        available = available - 300;
        calculatedPoints = 700;
        left = 300;
      } else if (currentAdvantPoints === 700) {
        entries = 3;
        available = available - 250;
        calculatedPoints = 950;
        left = 250;
      } else if (currentAdvantPoints === 950) {
        calculatedPoints = 950;
        entries = 3;
        left = 250;
      }

      if (toolTipMessageMinRequired(left) && !shouldDecrease) {
        setTooltip(true);
        return;
      }
    }

    // console.log('SLIDER')
    // console.log({
    //   points: calculatedPoints,
    //   entries: entries,
    //   availablePoints: available
    // })

    setAdvantPoints(calculatedPoints);
    setCurrentAdvantPointsEntries(entries);
    setAvailablePoints(available);
  };

  return (
    <>
      <TravelsContext.Provider
        value={{
          availablePoints,

          isActiveCampaign,
          activeCampaign,
          advantPoints,
          currentAdvantPointsEntries,
          advantPointsMax,
          currencyExchange,
          campaignId: activeCampaign?._id,
          userSweepStakeCampaign,
          pendingInfo,
          completedInfo,
          resetValue,
          setTooltip,
          setMaxLimitToolTip,
          setTxnErrorToolTip,
          setTxnErrorTooltipMessage,
          isEntriesClosed,
          entriesHoldData,
        }}
      >
        <Container
          className={`${!isDesktop && 'lc-travels-action-container'} pb-5`}
        >
          <Col
            className={`${
              unAuthToken?.status !== ACTIONS.STATUS.RESOLVED ? 'pt-5' : ''
            }`}
            id="scrollToPosition"
          ></Col>
          {unAuthToken?.status !== ACTIONS.STATUS.RESOLVED && (
            <Col className={`rounded ${isDesktop && 'lc-travels-desk-action'}`}>
              <Col
                className="text-center pt-2 flex justify-content-center align-items-center plus-minus-points"
                ref={target}
              >
                <div
                  role="button"
                  onClick={() => (loaded ? handlePoints(true) : null)}
                  className="mx-n4"
                >
                  <Icon icon="minus-updated" width="61" height="61" />
                </div>
                <div className="points my-auto px-1">
                  {' '}
                  <Icon
                    className="inline"
                    icon="balance-bolt"
                    width="28"
                    height="28"
                  />
                  <span
                    className="lc-travels-poins ml-2 align-middle"
                    ref={target}
                  >
                    {advantPoints}
                  </span>
                </div>
                <div
                  role="button"
                  onClick={() => (loaded ? handlePoints(false) : null)}
                  className="mx-n4"
                >
                  <Icon icon="plus-updated" width="61" height="61" />
                </div>
              </Col>
              <Overlay
                target={target.current}
                show={showTooltip}
                placement="top"
                delay={{ show: 250, hide: 400 }}
              >
                {(props) => (
                  <Tooltip className="lc-travels-points-tooltip" {...props}>
                    {toolTipPointsMessage}
                  </Tooltip>
                )}
              </Overlay>
              <Slider
                handleSliderAdvantPoints={handleSliderAdvantPoints}
                currencyExchange={currencyExchange}
              />
            </Col>
          )}
          {isDesktop ? (
            <>
              <Col className="flex flex-column p-0 py-2">
                {/* <ViewTravelList /> */}
                <TravelConfirm
                  show={showMagSubscription}
                  handleSubscribeConfirm={handleSubscribeConfirm}
                  handleSubscribeSuccess={handleSubscribeSuccess}
                  eTravels={eTravels}
                />
              </Col>
              {unAuthToken?.status === ACTIONS.STATUS.RESOLVED ? (
                <></>
              ) : (
                <PreviousCode eTravels={eTravels} />
              )}
            </>
          ) : (
            <>
              <Col className="fixed-bottom bg-white lc-travels-subscribe p-3 text-center">
                <TravelConfirm
                  show={showMagSubscription}
                  handleSubscribeConfirm={handleSubscribeConfirm}
                  handleSubscribeSuccess={handleSubscribeSuccess}
                  eTravels={eTravels}
                />
                {/* <ViewTravelList /> */}
              </Col>
              <Row className="border-top my-2"></Row>
              {unAuthToken?.status === ACTIONS.STATUS.RESOLVED ? (
                <></>
              ) : (
                <PreviousCode eTravels={eTravels} />
              )}
              <Col className="slot-margin"></Col>
            </>
          )}
          <TravelSubscriptionSuccess
            handleSubscribeSuccess={handleSubscribeSuccess}
            show={showMagSubscriptionSuccess}
          />
        </Container>
      </TravelsContext.Provider>
    </>
  );
};

const PreviousCode = (eTravels) => {
  const { envInfo } = useContext(AuthContext.AuthContext);

  // const { eTravels } = useTravels();

  const [sortedCodes, setSortedCodes] = useState([]);

  useEffect(() => {
    if (eTravels) {
      const sortedCodes = sortOnKey(
        eTravels?.eTravels?.[0]?.codes,
        'updatedOn'
      );
      setSortedCodes(sortedCodes);
    }
  }, [eTravels]);

  return (
    <Col className="lc-travels-codes my-2 p-0">
      <Col className="p-0 my-2 lc-travels-codes-title text-center">
        Previous Codes
      </Col>
      <Col className="lc-travels-codes-list p-0">
        <Nav className="flex-column my-2">
          {sortedCodes?.map(({ updatedOn, code }) => (
            <Col className="p-0" key={code}>
              <Nav.Item className="bg-white my-1 rounded p-2">
                {/* TODO: Once this is change for a normal link remove the ! */}
                <Nav.Link
                  href={`${envInfo?.travelsPath}?v=${code}`}
                  target="_blank"
                  className="!flex w-full lc-travels-code-info "
                >
                  <div className="px-4">{dateString(updatedOn)}</div>
                  <div>{code}</div>
                </Nav.Link>
              </Nav.Item>
            </Col>
          ))}
        </Nav>
      </Col>
    </Col>
  );
};
