import React, { useEffect, useRef } from 'react';
import { Col, Form } from 'react-bootstrap';
import './_slider.scss';
import { useResponsive } from '../../util/customHooks';
export default function Slider({ points, entries, onChange, min, max }) {
  const rangeRef = useRef();
  const bubbleRef = useRef();

  function setBubble(range, bubble) {
    const val = points;
    const min = range.min ? range.min : 0;
    const max = range.max ? range.max : 100;
    const newVal = val === 0 ? 0 : Number(((val - min) * 100) / (max - min));
    bubble.innerHTML = entries;

    // Sorta magic numbers based on size of the native UI thumb
    bubble.style.left = `calc(${newVal}% + (${8 - newVal * 0.15}px))`;
  }

  useEffect(() => {
    setBubble(rangeRef.current, bubbleRef.current);
  }, [points, max]);

  return (
    <React.Fragment>
      <Col className="slidecontainer px-2 px-md-3 m-0">
        <Form>
          <Form.Group controlId="formBasicRange">
            <div className="lcd-range-wrap">
              <Form.Control
                type="range"
                name="foo"
                value={points}
                min={min}
                max={max}
                className="loyalty-connect-donate-slider range"
                ref={rangeRef}
                onChange={onChange}
              />
              <output className="bubble rounded" ref={bubbleRef}>
                1
              </output>
            </div>
          </Form.Group>
        </Form>
      </Col>
    </React.Fragment>
  );
}
