import { PropsWithChildren, useContext } from 'react';
import * as Popover from '@radix-ui/react-popover';
import Button, { ButtonProps } from '../Button';
import { ShareLinkContext, CopyTooltipAtom } from './Context';
import { useAtom } from 'jotai';

export const Share = (props: PropsWithChildren<ButtonProps>) => {
  const { onClickShare, onClickCopy } = useContext(ShareLinkContext);
  const [showTooltip, setShowTooltip] = useAtom(CopyTooltipAtom);

  if (!window.navigator.share) {
    return (
      <>
        <Popover.Root open={showTooltip} onOpenChange={setShowTooltip}>
          <Popover.Trigger asChild>
            <Button {...props} onClick={onClickCopy}>
              {props.children || 'Share'}
            </Button>
          </Popover.Trigger>
          <Popover.Portal>
            <Popover.Content className="text-white bg-green-apple z-10 !px-4 !py-2 rounded-lg ">
              Copied link!
              <Popover.Arrow
                width={11}
                height={5}
                className="text-green-apple fill-green-apple"
              />
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
      </>
    );
  }
  return (
    <Button {...props} onClick={onClickShare}>
      {props.children || 'Share'}
    </Button>
  );
};
