import React, { useEffect, useState } from 'react';
import './carousel.scss';

const CarouselModules = (props) => {
  const { children, desktopShow, mobileShow, isDesktop } = props;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [length, setLength] = useState(children.length);

  const [touchPosition, setTouchPosition] = useState(null);
  const show = isDesktop ? desktopShow : mobileShow;

  useEffect(() => {
    setLength(children.length);
  }, [children]);

  const next = () => {
    if (currentIndex < length - show) {
      setCurrentIndex((prevState) => prevState + 1);
    } else {
      setCurrentIndex(0);
    }
  };

  const prev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
    } /*else {
            setCurrentIndex(length - 1)
        } */
  };

  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e) => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5) {
      next();
    }

    if (diff < -5) {
      prev();
    }

    setTouchPosition(null);
  };

  return (
    <div className="carousel-container">
      <div className="carousel-wrapper">
        {
          <button
            disabled={!(currentIndex > 0)}
            onClick={prev}
            className="left-arrow"
          >
            <span
              aria-hidden="true"
              className="carousel-control-prev-icon mt-2"
            ></span>
          </button>
        }
        <div
          className="carousel-content-wrapper"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
        >
          <div
            className={`carousel-content show-${show}`}
            style={{
              transform: `translateX(-${currentIndex * (100 / show)}%)`,
            }}
          >
            {children}
          </div>
        </div>
        {
          <button onClick={next} className="right-arrow">
            <span
              aria-hidden="true"
              className="carousel-control-next-icon mt-2"
            ></span>
          </button>
        }
      </div>
    </div>
  );
};

export default CarouselModules;
