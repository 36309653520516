import axiosInstance from '../utils/axiosInstance';
import { LIABILITIES } from '../utils/constants';
const initiateAuth = ({ queryKey }) => {
  const [, requestParams, liabilityType] = queryKey;

  const updatedReqParams = {
    ...requestParams,
    liabilityType: liabilityType || LIABILITIES.STUDENT_LOAN,
    ...(requestParams?.nextAuthStepParams
      ? { authStepParams: requestParams?.nextAuthStepParams }
      : null),
  };

  const promise = axiosInstance
    .post(`/api/users/auth`, updatedReqParams, {})
    .then((response) => ({ data: response?.data }));
  // promise.cancel = () => {
  //   source.cancel('Query was cancelled by React Query')
  // }
  return promise;
};

export default initiateAuth;
