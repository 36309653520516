import React, { useState, useContext, useEffect } from 'react';
import { Col, Card, Carousel } from 'react-bootstrap';

import { CampaignContext } from '../../contexts';
import useResponsive from '../../util/hooks/useResponsive';
import { daysRemaining } from '../../util/formatters';
import { WINNER_SELECTION_INFO_TYPE } from '../../util/constants';
import { WinnersList } from '../PrizesModals';
import './Campaign.scss';
import './WeeklyPrize.scss';

const WeeklyPrize = () => {
  const { pendingInfo, isActiveCampaign, activeCampaign } =
    useContext(CampaignContext);
  const [index, setIndex] = useState(0);
  const [weeklyPrizes, setWeeklyPrizes] = useState();
  const { isDesktop } = useResponsive();

  useEffect(() => {
    if (pendingInfo) {
      const weeklyPrizesInfo = pendingInfo.filter(
        ({ type }) => type === WINNER_SELECTION_INFO_TYPE.INTERMEDIATE
      );
      setWeeklyPrizes(weeklyPrizesInfo?.[0]);
    }
  }, [pendingInfo]);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  if (!weeklyPrizes?.prizes?.length) {
    return null;
  }
  return (
    <>
      <Col className="weekly-prizes-text text-left p-0">
        <span>Weekly</span> Prize & Winners
      </Col>
      {weeklyPrizes?.prizes?.length > 0 && (
        <Col className="p-0 weekly-prize-carousal">
          <Carousel
            activeIndex={index}
            onSelect={handleSelect}
            controls={false}
            indicators={false}
            pause="hover"
            keyboard
          >
            <Carousel.Item>
              <Card className="text-left">
                <Card.Header as="span" className="p-0">
                  <Card className={`w-100 ${isDesktop ? 'py-2' : ''}`}>
                    <Card.Img
                      src={weeklyPrizes?.prizes?.[0]?.images?.[0]?.url}
                      alt="Card image"
                      className={isDesktop ? 'm-auto w-50' : ''}
                    />
                  </Card>
                </Card.Header>

                <Card.Body className={'pb-0 ' + isDesktop ? 'text-center' : ''}>
                  <Card.Text
                    className={`weekly-prize-title ${
                      isDesktop ? 'mb-2' : 'mb-0'
                    }`}
                  >
                    {weeklyPrizes?.prizes?.[0]?.description}
                  </Card.Text>
                </Card.Body>
                {isActiveCampaign && (
                  <Card.Footer className="p-0 border-0 m-0">
                    <Col className="text-center winner-footer-text mx-auto rounded-bottom ">
                      {weeklyPrizes?.numberOfWinners}{' '}
                      {weeklyPrizes?.numberOfWinners > 1 ? 'winners' : 'winner'}{' '}
                      announced in {daysRemaining(weeklyPrizes?.selectionTime)}
                    </Col>
                  </Card.Footer>
                )}
              </Card>
            </Carousel.Item>
          </Carousel>
        </Col>
      )}
      <WinnersList campaign={activeCampaign} />
    </>
  );
};

export default WeeklyPrize;
