import React, { useContext, useState } from 'react';
import { Modal, Col } from 'react-bootstrap';
import { Button } from '@rewards/ui';
import Icon from '../../assets/svg';
import styleConsts from '../../util/styleConsts';
import { useResponsive } from '../../util/hooks';

import './TermsAndConditions.scss';
import { ToolsForSchoolsContext } from '../../contexts';

const TermsAndConditions = () => {
  const [show, setShow] = useState(false);
  const { featureFlag } = useContext(ToolsForSchoolsContext);
  const { isDesktop } = useResponsive();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Col
        className={`p-0 flex justify-center ${
          featureFlag.otherDetails?.commingSoon ? 'hidden' : ''
        }`}
      >
        <Button intent="link" onClick={handleShow}>
          <Icon
            icon="link-0"
            width="15"
            height="16"
            color={styleConsts.cobalt}
          />
          <span className="ml-2">Terms & Conditions</span>
        </Button>
      </Col>

      <Modal
        size="lg"
        backdrop="static"
        keyboard={false}
        className="tfs-tc-modal"
        dialogClassName="modal-90w"
        scrollable
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton className="">
          <Modal.Title
            className={`tfs-tc-title w-100 ${isDesktop && 'text-center'}`}
          >
            Tools for Schools – Terms & Conditions
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-justify">
          <Col className="px-0 tfs-tc-subheading">
            The promotion period begins on August 18, 2024 and runs until
            February 15, 2025. It is open to customers with an AdvantEdge Card.
            Customers that wish to participate must enroll their AdvantEdge Card
            on{' '}
            <a
              href={`${window.location.origin}/tools-for-schools`}
              target="_blank"
              className="tfs-link"
              rel="noreferrer"
            >
              {window.location.hostname}/tools-for-schools
            </a>{' '}
            and designate a school, unless they enrolled in last year's program.
            All purchases* using your AdvantEdge Card will be automatically
            credited to your selected school where one AdvantEdge Rewards point
            = one Star. Based upon Stars earned, your school will earn its share
            of over $200,000 in educational equipment. Stars will be credited to
            schools each week based on each customer's designated school at the
            end of the week. Catalog Points will be earned by schools based on
            their proportion of the total Stars earned at the conclusion of the
            promotion.
          </Col>
          <Col className="px-0 my-3 tfs-tc-subheading">
            Customers can also donate their AdvantEdge Rewards points directly
            to registered schools above and beyond its proportional share.
            AdvantEdge Rewards points redeemed in the digital platform as
            donations will be awarded to your selected school where every 100
            AdvantEdge Rewards points redeemed = 100 Tools for Schools Catalog
            Points.
          </Col>
          <Col className="px-0 my-3 tfs-tc-subheading">
            During the promotion, customers can visit{' '}
            <a
              href={`${window.location.origin}/tools-for-schools`}
              target="_blank"
              className="tfs-link"
              rel="noreferrer"
            >
              {window.location.hostname}/tools-for-schools
            </a>{' '}
            to view the number of Stars they have earned for their school and
            view how many AdvantEdge Rewards points they have donated to their
            school. The final Catalog Points amount for each school are made
            available to that school to facilitate the selection of educational
            equipment from the Tools for Schools catalog. Price Chopper will
            match the value of AdvantEdge Rewards points donated to become
            Catalog Points up to $35,000 in value.
          </Col>
          <Col className="px-0 my-3 tfs-tc-subheading">
            *Excludes purchases of Price Chopper, Market 32 and Market Bistro
            gift cards, alcohol, tobacco, money orders, Western Union, lottery
            tickets, postage stamps, tickets, bottle deposits, copy/fax
            services, taxes and other items prohibited by law, including
            prescription co-pays in NY. Points are calculated after all of the
            aforementioned items, all store discounts and all coupons (including
            manufacturer’s coupons) have been subtracted from the order. Visit{' '}
            <a
              href="https://www.pricechopper.com/rewards/"
              target="_blank"
              className="tfs-link"
              rel="noreferrer"
            >
              pricechopper.com/rewards
            </a>{' '}
            for full details on earning AdvantEdge Rewards points or for more
            information on AdvantEdge Rewards.
          </Col>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TermsAndConditions;
