import { PropsWithChildren } from 'react';
import { tv } from '../../util';
import { useModalContext } from '../../contexts/ModalContext';

const styles = tv({
  base: 'flex',
  variants: {
    type: {
      cta: 'text-h5',
      document: 'text-h2',
      transaction: 'flex flex-col w-100 pb-0',
      notification: '',
    },
  },
  defaultVariants: {
    type: 'cta',
  },
});

export const ModalHeader = ({
  children,
  className = '',
}: PropsWithChildren<{
  className?: string;
}>) => {
  const variantProps = useModalContext();
  const headerStyles = styles({ ...variantProps, className });
  return (
    <section aria-label="dialogheader" className={headerStyles}>
      {children}
    </section>
  );
};
