export const CAMPAIGN_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  COMPLETE: 'COMPLETE',
};

export const WINNER_SELECTION_INFO_STATUS = {
  COMPLETED_SUCCESS: 'COMPLETED_SUCCESS',
  PENDING: 'PENDING',
};
export const WINNER_SELECTION_INFO_TYPE = {
  INTERMEDIATE: 'INTERMEDIATE',
  INTERMEDIATE_GRAND: 'INTERMEDIATE_GRAND',
  GRAND: 'GRAND',
};

export const config = {
  accountInfo: {
    redirectUri: 'https://rewards.pricechopper.com:8081',
    authBaseUrl: 'https://dev-dim-api.spinwheel.io',
    clientId: 'loyalty_connect',
    appId: '295256691978389',
    ssoClientId: 'dd322bb6-8ced-4083-a80a-13a544ceb94c',
    knownAuthorities: ['pricechopperdevsso.b2clogin.com'],
    tenant: 'pricechopperdevsso',
    surveryMonkeyLink: 'https://www.surveymonkey.com/r/C8FRSW7',
    REACT_APP_SITE_KEY: '6Le4UZEbAAAAAGqJRhbsP827j_gztWghm5Xm7nmL',
    REACT_APP_SECRET_KEY: '6Le4UZEbAAAAADFcLrrvc8W_Z2p6TD_jKUKPMY3a',
    policies: {
      signUpPolicy: 'B2C_1A_SIGNUP',
      signInPolicy: 'B2C_1A_signup_signin',
    },
  },
};

export const WEEKLY_PRIZE_TYPE = {
  [WINNER_SELECTION_INFO_TYPE.INTERMEDIATE]: 'Weekly Prize',
  [WINNER_SELECTION_INFO_TYPE.GRAND]: 'Grand Prize',
};

export const BREAKPOINTS = {
  MOBILE: 768,
  TABLET: 992,
};
