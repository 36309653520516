import React, { useContext, useEffect } from 'react';
import { Modal, Col, Card } from 'react-bootstrap';
import { Button } from '@rewards/ui';
import { useMsal } from '@azure/msal-react';
import { AppContext } from '@rewards/contexts';

import Icon from '../../assets/svg';
import { useResponsive } from '../../util/hooks';
import { PAGES, EVENTS } from '../../analytics/events';

import ModalCloseBtn from '../SelectSchoolModals/ModalCloseBtn';

import './AccountLinkedModal.scss';

const AccountLinkedModal = ({
  show,
  handleClose,
  email,
  extension_AdvantEdgeCard,
  schoolForUser,
}) => {
  const { instance } = useMsal();
  const { isDesktop } = useResponsive();

  const { source, track } = useContext(AppContext.AppContext);

  useEffect(() => {
    if (show) {
      source(PAGES.VIEWED_TOOLS_FOR_SCHOOLS_SUCCESS_MODAL, {
        ui_version: import.meta.env.APP_VERSION,
      });
    }
  }, [show]);

  const handleCreateAccount = () => {
    track(EVENTS.T4S_SUCCESS_CREATE_ACCOUNT_CLICKED, {
      ui_version: import.meta.env.APP_VERSION,
    });
    instance.config.auth.authority = instance.config.auth.loginAuthority;
    instance.loginRedirect({
      ...instance.config.auth.loginRequest,
      extraQueryParameters: {
        email,
        extension_AdvantEdgeCard,
        anonymousId: window?.analytics?.user?.()?.anonymousId?.(),
      },
    });
  };

  const handleLinkAnotherCard = () => {
    track(EVENTS.T4S_SUCCESS_LINK_ANOTHER_CARD_CLICKED, {
      ui_version: import.meta.env.APP_VERSION,
    });
    handleClose();
  };

  return (
    <Modal
      show={show}
      backdrop="static"
      keyboard={false}
      centered={true}
      dialogClassName={`account-linked-modal rounded' ${
        isDesktop ? '' : 'tfs-mobile-dialog mx-auto'
      }`}
      contentClassName="border-0 account-linked-content-modal mt-3"
    >
      <Modal.Header className="border-0 flex-column">
        <Modal.Title className="text-center account-linked-modal-adv-card-success">
          Your AdvantEdge Card{' '}
          {schoolForUser
            ? 'is already associated with a school'
            : 'has been successfully linked'}
          ! Your shopping will earn stars for your school!
        </Modal.Title>
        <ModalCloseBtn handleClose={handleClose} />
      </Modal.Header>
      <Modal.Body className="pt-0">
        <Card.Img src="https://cdn.spinwheel.io/images/tcc/tools-for-school.png" />

        <Col className="py-3 px-0">
          <Col className="bg-white p-0 flex flex-row account-linked-modal-advantEdge-stars-card">
            <Col className="px-0 mx-3 flex flex-column justify-content-center account-linked-modal-advantEdge-stars-icon">
              <Icon icon="advantEdge-stars" width="65" height="65" />
            </Col>
            <Col className="flex flex-column py-3 px-2 account-linked-modal-advantEdge-stars-text">
              <Col className="pt-2 px-0 account-linked-modal-advantEdge-stars">
                Earn 2x stars
              </Col>
              <Col className="px-0 py-1 account-linked-modal-advantEdge-create-account">
                when you create an online AdvantEdge Rewards account
              </Col>
            </Col>
          </Col>
        </Col>
        <Col className="mt-3 account-linked-modal-account-holder-info">
          AdvantEdge Rewards account holders can track their school’s progress,
          donate Rewards points directly to their school, and earn even more
          stars by inviting friends!
        </Col>
      </Modal.Body>
      <Modal.Footer className="bg-white">
        <Button
          // className='account-linked-modal-create-button rounded p-2 mt-4'
          className="flex-grow"
          onClick={handleCreateAccount}
        >
          Create Account
        </Button>
        <Button
          variant="link"
          // className='account-linked-modal-link-advedge-card'
          onClick={handleLinkAnotherCard}
          block
        >
          Link another AdvantEdge Card
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AccountLinkedModal;
