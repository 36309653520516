import React, { useContext, useEffect, useState } from 'react';
import { Modal, Col } from 'react-bootstrap';
import { Button } from '@rewards/ui';
import { AppContext } from '@rewards/contexts';

import { EVENTS, PAGES, EVENT_LOCATION } from '../../analytics/events';

import './FAQs.scss';

const FAQs = () => {
  const [show, setShow] = useState(false);

  const { track, source } = useContext(AppContext.AppContext);

  useEffect(() => {
    if (show) {
      source(PAGES.LOYALTY_CONNECT_FAQS_MODAL, {
        ui_version: import.meta.env.APP_VERSION,
      });
    }
  }, [show]);

  const handleClose = () => {
    track(EVENTS.LOYALTY_CONNECT_CLOSE_CLICKED, {
      location: EVENT_LOCATION.LOYALTY_CONNECT_FAQS_MODAL,
      ui_version: import.meta.env.APP_VERSION,
    });
    setShow(false);
  };
  const handleShow = () => {
    track(EVENTS.LOYALTY_CONNECT_FAQS_CLICKED, {
      ui_version: import.meta.env.APP_VERSION,
    });
    setShow(true);
  };
  return (
    <>
      <Button
        intent="link"
        // className='FAQs-btn pl-3'
        onClick={handleShow}
      >
        FAQs
      </Button>
      <Modal
        size="lg"
        backdrop="static"
        keyboard={false}
        className="FAQs-modal"
        dialogClassName="modal-90w"
        scrollable
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton className="">
          <Modal.Title className="FAQs-title">
            AdvantEdge Rewards - Digital Platform – Frequently Asked Questions
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-justify">
          <Col className="px-0 pb-2 FAQs-heading">Accessing the Platform</Col>
          <Col className="px-0 FAQs-subheading">
            <ol className="pl-2 FAQs-ol">
              <li className="FAQs-ol-li">
                How do I access the AdvantEdge Rewards digital platform?
                <ul className="pl-3 mt-2" style={{ listStyleType: 'disc' }}>
                  <li>
                    Visit{' '}
                    <a href={window.location.origin} target="_blank">
                      {window.location.hostname}
                    </a>
                  </li>
                  <li>
                    First time users will need to register for an account. An
                    email address and AdvantEdge Card are required:
                    <ul className="pl-3" style={{ listStyleType: 'circle' }}>
                      <li>
                        Enter your email address to receive a confirmation code
                      </li>
                      <li>Enter that confirmation code</li>
                      <li>
                        Fill out First Name, Last Name, AdvantEdge Card number,
                        and set your password
                      </li>
                    </ul>
                  </li>
                  <li>
                    Returning users can login with their email address and
                    password
                  </li>
                </ul>
              </li>

              <li className="FAQs-ol-li mt-3">
                Can I create a digital Rewards platform account without an
                AdvantEdge Card or add my card to my account later?
                <ul className="pl-3 mt-2" style={{ listStyleType: 'disc' }}>
                  <li>
                    No. You need to include your current AdvantEdge Card number{' '}
                    <u>when</u> you create an account.
                  </li>
                </ul>
              </li>

              <li className="FAQs-ol-li mt-3">
                I already have an account at pricechopper.com or the Price
                Chopper / Market 32 mobile app where I browse, shop, and clip
                eCoupons. Why do I need to create another account?
                <ul className="pl-3 mt-2" style={{ listStyleType: 'disc' }}>
                  <li>
                    This new digital Rewards platform requires a brand new
                    account with added security so only registered users can
                    access and redeem their Rewards points online.
                  </li>
                </ul>
              </li>

              <li className="FAQs-ol-li mt-3">
                Do I need a pricechopper.com account to access the Digital
                Rewards Platform?
                <ul className="pl-3 mt-2" style={{ listStyleType: 'disc' }}>
                  <li>
                    No.This new digital Rewards platform is different than
                    access to pricechoper.com or the Price Chopper / Market 32
                    mobile app.
                  </li>
                </ul>
              </li>
            </ol>
          </Col>

          <Col className="px-0 pb-2 FAQs-heading">
            Using the AdvantEdge Rewards Digital Platform
          </Col>
          <Col className="px-0 FAQs-subheading">
            <ol className="pl-2 FAQs-ol">
              <li className="FAQs-ol-li">
                What can I expect?
                <ul className="pl-3 mt-2" style={{ listStyleType: 'disc' }}>
                  <li>
                    Once signed in, your available AdvantEdge Rewards points
                    balance is shown at the top right in the platform.
                  </li>
                  <li>
                    Toggle between EARN and REDEEM to discover new and exciting
                    ways to engage with AdvantEdge Rewards.
                  </li>
                  <li>
                    EARN more Rewards points by shopping online or instore and
                    be sure to check out Bonus point promotions and limited time
                    Bonus earning opportunities.
                  </li>
                  <li>
                    REDEEM your Rewards points in a variety of ways including:
                    <ul className="pl-3" style={{ listStyleType: 'circle' }}>
                      <li>Enter for a chance to win exciting prizes</li>
                      <li>Donate to a charity or local School</li>
                      <li>Pay down student debt for you or a loved one</li>
                    </ul>
                  </li>
                  <li>
                    Check back often to discover new ways to redeem your Rewards
                    points.
                  </li>
                </ul>
              </li>
              <li className="FAQs-ol-li mt-3">
                How does the AdvantEdge Rewards digital platform work?
                <ul className="pl-3 mt-2" style={{ listStyleType: 'disc' }}>
                  <li>
                    Select how you’d like to redeem your Rewards points by
                    visiting the various pages.
                    <ul className="pl-3" style={{ listStyleType: 'circle' }}>
                      <li>
                        For Example: to enter sweepstakes, donate to charity, or
                        to pay down student debt.
                      </li>
                    </ul>
                  </li>
                  <li>Rewards points must be redeemed in increments of 100.</li>
                  <li>
                    Toggle the “+” sign to the desired point level and click the
                    button below it to redeem.
                  </li>
                  <li>Your new balance will be shown in the upper right.</li>
                </ul>
              </li>
              <li className="FAQs-ol-li mt-3">
                Are these the same points that are used for Food or Fuel
                discounts?
                <ul className="pl-3 mt-2" style={{ listStyleType: 'disc' }}>
                  <li>
                    Yes. The new AdvantEdge Rewards digital platform adds
                    additional ways to redeem Rewards points. It’s your choice!
                  </li>
                </ul>
              </li>
              <li className="FAQs-ol-li mt-3">
                Did anything about the existing AdvantEdge Rewards program
                change?
                <ul className="pl-3 mt-2" style={{ listStyleType: 'disc' }}>
                  <li>
                    No. For more information about AdvantEdge Rewards please
                    visit{' '}
                    <a
                      href="https://www.pricechopper.com/rewards/"
                      target="_blank"
                    >
                      pricechopper.com/rewards
                    </a>
                    .
                  </li>
                </ul>
              </li>
            </ol>
          </Col>
          <Col className="px-0 pb-2 FAQs-heading">Detailed Ways to Redeem</Col>
          <Col className="px-0 FAQs-subheading">
            <ol className="pl-2 FAQs-ol">
              <li className="FAQs-ol-li">
                How do I find more information about the Sweepstakes and its
                prize information?
                <ul className="pl-3 mt-2" style={{ listStyleType: 'disc' }}>
                  <li>
                    For full sweepstakes details and prize info, please view the
                    Terms and Conditions document available at the bottom of the
                    “Enter Sweepstakes” page.
                  </li>
                </ul>
              </li>
              <li className="FAQs-ol-li mt-3">
                Are Sweepstakes prize winners announced?
                <ul className="pl-3 mt-2" style={{ listStyleType: 'disc' }}>
                  <li>
                    Sweepstakes prize winners will be posted on the AdvantEdge
                    Rewards digital platform and contacted directly by Price
                    Chopper / Market 32 or the sweepstakes administrator. Check
                    back often during a sweepstakes campaign to see who won
                    weekly prizes!
                  </li>
                </ul>
              </li>
              <li className="FAQs-ol-li mt-3">
                If I redeem my Rewards points for a Charity or Local school,
                will I receive a tax form?
                <ul className="pl-3 mt-2" style={{ listStyleType: 'disc' }}>
                  <li>
                    No. The value of your Rewards points redeemed for Charity or
                    Schools via the digital Rewards platform is pooled for a
                    payment in that amount to be made from Price Chopper /
                    Market 32 directly to the organization.
                  </li>
                </ul>
              </li>
              <li className="FAQs-ol-li mt-3">
                Can I donate my Rewards points to another Charity not listed on
                the digital platform?
                <ul className="pl-3 mt-2" style={{ listStyleType: 'disc' }}>
                  <li>
                    Not at this time. The charities listed will be updated from
                    time to time, so check back often to see who you can
                    support.
                  </li>
                </ul>
              </li>
              <li className="FAQs-ol-li mt-3">
                What are the steps for redeeming Rewards points to pay down
                student debt for me or a loved one?
                <ul className="pl-3 mt-2" style={{ listStyleType: 'disc' }}>
                  <li>
                    Visit the ‘Student Debt’ page on the digital Rewards
                    platform
                  </li>
                  <li>
                    Hit the “+” button to designate the amount of points you
                    want to redeem
                  </li>
                  <li>Choose “My Loan” or “My Loved One”</li>
                  <li>
                    For “My Loan” – follow the prompts to connect to your loan
                    servicer, enter your loan information and hit connect. You
                    only have to do this step once. Once connected, each time
                    you visit the Digital Rewards platform your connected loan
                    will automatically be available.
                  </li>
                  <li>
                    For “My Loved One” – once chosen, you’ll be promoted to send
                    a link to your loved one. Your loved one must visit that
                    link and follow the prompts to connect to their loan
                    servicer, enter their loan information and hit connect. They
                    only have to do this step once. Once connected, each time
                    you visit the Digital Rewards platform their connected loan
                    will automatically be available.
                  </li>
                </ul>
              </li>
              <li className="FAQs-ol-li mt-3">
                If I choose to use my Rewards points to pay down student debt,
                how soon will the applied points become payment for the
                connected student loan?
                <ul className="pl-3 mt-2" style={{ listStyleType: 'disc' }}>
                  <li>
                    Rewards points applied towards student debt (100 points =
                    $1) will be made as a payment within 5 business days. In
                    most cases the transaction will go through within 24 hours.
                  </li>
                </ul>
              </li>
              <li className="FAQs-ol-li mt-3">
                What should I do if I am having trouble with any of these new
                redemption options?
                <ul className="pl-3 mt-2" style={{ listStyleType: 'disc' }}>
                  <li>
                    If you are having difficulty with the AdvantEdge Rewards
                    Digital Platform please visit{' '}
                    <a
                      href="http://www.pricechopper.com/about-us/customer-service/contact-us/"
                      target="_blank"
                    >
                      https://www.pricechopper.com/about-us/customer-service/contact-us/
                    </a>{' '}
                    or call us at 800-666-7667.
                  </li>
                </ul>
              </li>
            </ol>
          </Col>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FAQs;
