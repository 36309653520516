import { PAGES } from '../../analytics/events';
import {
  CAMPAIGN_POPUP_HOUR_OFFSET,
  CAMPAIGN_STATUS,
  FEATURES_FLAGS,
} from '../../util/constants';

const IS_SWEEPSTAKE_SHOWN_KEY = 'isSweepstakeShown';
const getSweepstakeShown = () => {
  const value = window.localStorage.getItem(IS_SWEEPSTAKE_SHOWN_KEY);
  const sweepstakeShown = value ? JSON.parse(value) : false;
  return sweepstakeShown;
};

const setIsSweepstakeShown = (valueToStore) => {
  window.localStorage.setItem(
    IS_SWEEPSTAKE_SHOWN_KEY,
    JSON.stringify(valueToStore)
  );
};

const contentSelector = async ({
  featureFlags,
  handleShow,
  track,
  daysBetweenDates,
  checkValidToken,
  setSweepstake,
  activeSweepstake,
  setIsDailySurpriseCampaignEndedFlag,
}) => {
  // Daily Surprise Selector
  const featureFlag = featureFlags?.find(
    (ff) => ff.name === FEATURES_FLAGS.ENABLE_DAILY_SURPRISES
  );
  const dailySurprisesEnded = !featureFlag?.otherDetails?.campaignActive;
  setIsDailySurpriseCampaignEndedFlag(dailySurprisesEnded);
  if (!dailySurprisesEnded) {
    const localStorageDate = localStorage.getItem(
      'PromoteSweepstakeLastSeenDateUTC'
    );
    const currentDateUTC = new Date().toJSON().slice(0, 10).replace(/-/g, '-');
    const frequency = featureFlag?.otherDetails?.frequency;
    if (localStorageDate) {
      const daysBetween = daysBetweenDates(localStorageDate, currentDateUTC);
      if (daysBetween >= frequency) {
        handleShow();
      }
    } else {
      handleShow();
    }
    track(PAGES?.POPUP_DAILY_SURPRISE_VIEWED, {
      sweepstakeCampaign: 'Daily SurPRIZE',
      ui_version: import.meta.env.APP_VERSION,
    });
    localStorage.setItem('PromoteSweepstakeLastSeenDateUTC', currentDateUTC);
  } else {
    // const sweepstakes = await fetchSweepstakesCampaigns();
    // const activeCampaigns = [];
    const today = new Date();

    // for (const sweepstake of activeSweepstakes) {
    //   if (sweepstake.status === CAMPAIGN_STATUS.ACTIVE) {
    //     if (new Date(sweepstake.startTime) <= today) {
    //       activeCampaigns.push(sweepstake);
    //     }
    //   }
    // }

    if (activeSweepstake?.length) {
      const currentCampaign = activeSweepstake.sort((a, b) => {
        const result = b.displayOrder - a.displayOrder;
        if (result === 0) {
          return b.displayStartTime - a.displayStartTime;
        }
        return result;
      });
      setSweepstake(currentCampaign[0]);
      const isSweepstakeShown = getSweepstakeShown();
      if (
        currentCampaign[0] &&
        (!isSweepstakeShown ||
          new Date(isSweepstakeShown?.nextSeen).getTime() < today.getTime() ||
          isSweepstakeShown?.campaign !== currentCampaign[0]?._id)
      ) {
        let nextSeen = new Date();
        // @TODO: Replace Magic Hour with Feature Flag Days
        // const ff =  featureFlags?.find(ff =>
        //     ff.name === FEATURES_FLAGS.)
        nextSeen = nextSeen.setTime(
          nextSeen.getTime() + CAMPAIGN_POPUP_HOUR_OFFSET * 60 * 60 * 1000
        );
        setIsSweepstakeShown({
          nextSeen: nextSeen,
          campaign: currentCampaign[0]?._id,
        });
        track(PAGES.POPUP_SWEEPSTAKES_VIEWED, {
          sweepstakeCampaign: currentCampaign[0]?.name,
          ui_version: import.meta.env.APP_VERSION,
        });
        handleShow();
      }
    }
  }
};

export default contentSelector;
