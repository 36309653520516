import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal, InputGroup, FormControl, Col } from 'react-bootstrap';
import { AuthContext } from '@rewards/contexts';

import config from '../../config';
import { getPriceChopperSSOPath, getPriceChopperPath } from '../../util/paths';
import Icon from '../../assets/svg';
import { LoyaltyConnectContext } from '../../contexts/LoyaltyConnectContext';

const { useAuth } = AuthContext;

import './AuthenticateUser.scss';
const AuthenticateUser = () => {
  const { isSSOEnabled, isSessionExpired } = useAuth();
  const { isDesktop } = useContext(LoyaltyConnectContext);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!isSSOEnabled && isSessionExpired) {
      setShowModal(isSessionExpired);
    }
  }, [isSSOEnabled, isSessionExpired]);

  const handleClose = () => {};
  const handleCopyText = () => {
    var copyText = document.getElementById('copy-link');
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
  };
  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title className="session-expired-title">
            Session Expired!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="session-expired-info">
          {isDesktop ? (
            <>
              Please <a href={getPriceChopperPath()}>login to the site</a> and
              if you are not automatically redirected here, go to{' '}
              <a href={config?.accountInfo?.redirectUri}>
                {config?.accountInfo?.redirectUri}
              </a>
            </>
          ) : (
            <>
              Copy and paste the link below in your browser to login to the
              site. After login, please go to{' '}
              <a href={config?.accountInfo?.redirectUri}>
                {config?.accountInfo?.redirectUri}{' '}
              </a>
              to redeem your points
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {isDesktop ? (
            <Button
              className="session-expired-ok-btn"
              href={`${getPriceChopperSSOPath()}?redirect=${
                config?.accountInfo?.redirectUri
              }/`}
            >
              Go to Login
            </Button>
          ) : (
            <>
              <Col className="copy-link text-left bg-light rounded" md={12}>
                <InputGroup>
                  <InputGroup.Prepend className="border-0">
                    <InputGroup.Text
                      id="basic-addon1"
                      className="border-0 py-0 bg-light"
                    >
                      <Icon icon="link-0" width="16" height="16" />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    type="text"
                    id="copy-link"
                    readOnly
                    className="p-0 border-0 w-80 bg-light"
                    value={getPriceChopperSSOPath()}
                  />
                </InputGroup>
              </Col>
              <Col
                className="flex flex-row justify-content-between lending-buttons p-0"
                md={12}
              >
                <Button
                  className="border-0 rounded my-loan justify-content-center py-2 w-100"
                  onClick={handleCopyText}
                >
                  Copy Link
                </Button>
              </Col>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AuthenticateUser;
