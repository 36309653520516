import React, { createRef, useEffect, useState, useContext } from 'react';
import { Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { AuthContext } from '@rewards/contexts';

import BackNav from '../BackNav';

import { getElementPosition } from '../../util/Optimizers';
import { getLandingPath } from '../../util/paths';
import { EVENT_LOCATION } from '../../analytics/events';

import './MagazinesList.scss';

const CONNECT_LOAN_PLACEHOLDER_ID = 'connect-loan-placeholder';
const MagazinesList = () => {
  const { envInfo } = useContext(AuthContext.AuthContext);
  const iframeRef = createRef();

  const params = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [iframeHeight, setIframeHeight] = useState('100vh');

  useEffect(() => {
    MagazinesList.LOCATION = EVENT_LOCATION.MAGAZINE_LIST_PAGE;
    MagazinesList.landingPath = getLandingPath();
  }, []);

  const updateIframeHeight = () => {
    const iframePositionFromTop = iframeRef.current
      ? getElementPosition(iframeRef.current).y
      : 0;
    const iframeHeight = `calc(100vh - ${iframePositionFromTop}px)`;
    setIframeHeight(iframeHeight);
  };

  useEffect(() => {
    if (iframeRef) {
      updateIframeHeight();
      setIsLoading(false);
      window.addEventListener('resize', updateIframeHeight);
      return () => {
        window.removeEventListener('resize', updateIframeHeight);
      };
    }
  }, [iframeRef]);

  return (
    <div className="iframe-container">
      {isLoading && (
        <div className="text-center spinner">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
      <iframe
        id={CONNECT_LOAN_PLACEHOLDER_ID}
        src={`${envInfo?.magazinesPath}?v=${params.code}`}
        title="Magazine Gold Card - Price Chopper / Market 32 - Rewards"
        className="p-0 responsive-iframe border-0"
        ref={iframeRef}
        style={{ height: iframeHeight }}
      ></iframe>
    </div>
  );
};

export default BackNav(MagazinesList);
