import { forwardRef, PropsWithChildren } from 'react';
import { type VariantProps } from 'tailwind-variants';
import { tv } from '../../util';
import { Slot } from '@radix-ui/react-slot';

/* eslint-disable-next-line */
const buttons = tv({
  base: `!rounded hover:scale-105 transition-transform !m-1 font-medium uppercase no-underline hover:no-underline flex justify-center items-center`,
  variants: {
    variant: {
      outline: `border-2 `,
    },
    intent: {
      primary: `bg-green-apple text-white hover:text-white hover:border-white hover:border hover:border-solid`,
      secondary: `bg-grey text-black hover:text-black `,
      tertiary:
        'bg-transparent text-black hover:text-black hover:!bg-grey hover:bg-opacity-70',
      accent: `bg-cobalt text-white hover:text-white hover:border-white hover:border hover:border-solid`,

      link: `bg-transparent text-cobalt hover:text-cobalt underline`,
      transparent: `bg-transparent`,
      danger: 'bg-tomato  text-white hover:text-white  ',
    },
    size: {
      wrapper: 'p-0',
      medium: `!px-6 !py-3`,
    },
    width: {
      regular: '',
      fit: 'max-w-fit',
      full: `w-100 text-center`,
      w75: 'w-75',
    },
  },
  compoundVariants: [
    {
      variant: 'outline',
      intent: 'danger',
      className:
        'border-tomato text-tomato bg-transparent hover:text-white hover:!bg-tomato transition',
    },
  ],
  defaultVariants: {
    intent: 'primary',
    size: 'medium',
    width: 'regular',
  },
});

// Circle Button - for Icons
// Looks like there are some 75% buttons
// One Button for URL - Transparent Backgroun

export type ButtonVariantProps = VariantProps<typeof buttons>;
type ButtonBaseProps = Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  'size' | 'width'
>;
type HTMLTypes = ButtonBaseProps;
export interface ButtonProps extends ButtonVariantProps, HTMLTypes {
  as?: React.ElementType;
  href?: string;
  asChild?: boolean;
}

export const Button = forwardRef(
  (
    {
      as: Component = 'button',
      asChild,
      ...props
    }: PropsWithChildren<ButtonProps>,
    ref
  ) => {
    const { intent, size, width, variant, ...restOfProps } = props;
    const styles = buttons({
      width,
      size,
      intent,
      variant,
      className: props.className,
    });
    // console.log(width, intent, styles);
    restOfProps.className = `${styles}`;
    const Comp = asChild ? Slot : Component;
    return <Comp ref={ref} {...restOfProps} />;
  }
);

export default Button;
