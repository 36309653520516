export const config = {
  accountInfo: {
    clientId: 'loyalty_connect',
    redirectUri: 'https://rewards.pricechopper.com:8085',
    authBaseUrl: 'https://dev-dim-api.spinwheel.io',
    appId: '164477602254704',
    ssoClientId: 'dd322bb6-8ced-4083-a80a-13a544ceb94c',
    tenant: 'pricechopperdevsso',
    knownAuthorities: ['pricechopperdevsso.b2clogin.com'],
    policies: {
      signUpPolicy: 'B2C_1A_SIGNUP',
      signInPolicy: 'B2C_1A_signup_signin',
    },
  },
};

export const TRANSFER_MONEY_TXNS_PARAMS = {
  FROM_ACCOUNT_TYPE: 'PARTNER',
  TO_ACCOUNT_TYPE: 'TOOLS_FOR_SCHOOLS',
};

export const BACK_NAV_HEADING = {
  MY_DASHBOARD: 'My Dashboard',
  TOOLS_FOR_SCHOOLS: 'Tools for Schools',
};

export const SHARE_ACTIVITY_TYPE = {
  INVITE_FRIENDS: 'INVITE_FRIENDS',
  SHARE_FACEBOOK: 'SHARE_FACEBOOK',
};

export const BREAKPOINTS = {
  MOBILE: 768,
  TABLET: 992,
};

export const SCHOOL_SEARCH_ID = {
  GET_SCHOOL: 'get-school',
  ALL_SCHOOL: 'all-school',
};

export const FEATURES_FLAGS = {
  ENABLE_TOOLS_FOR_SCHOOLS: 'ENABLE_TOOLS_FOR_SCHOOLS',
};
