import React, { useEffect, useContext, useState } from 'react';
import { Container, Col, Button, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { AppContext } from '@rewards/contexts';

import Icon from '../../assets/svg';
import useResponsive from '../../util/hooks/useResponsive';
import styleConsts from '../../util/styleConsts';
import { EVENTS } from '../../analytics/events';

import './BackNav.scss';

export default (WrappedComponent) => {
  const BackNav = (props) => {
    const { landingPath, track } = useContext(AppContext.AppContext);

    const history = useHistory();
    const { isDesktop } = useResponsive();

    const [showBackBtn, setShowBackBtn] = useState(false);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
      if (WrappedComponent?.showBackBtn) {
        setShowBackBtn(WrappedComponent?.showBackBtn);
      }
    }, [WrappedComponent?.showBackBtn]);

    const handleBackPropagation = () => {
      track(EVENTS.SHIP_TO_HOME_BACK_CLICKED, {
        location: WrappedComponent?.LOCATION,
        ui_version: import.meta.env.APP_VERSION,
      });
      history.push(WrappedComponent?.landingPath || landingPath);
    };

    return (
      <>
        {isDesktop ? (
          <div className="flex justify-center">
            <Col
              md={showBackBtn ? '6' : '12'}
              className={`py-4 rounded' ${showBackBtn && 'bg-white'}`}
            >
              <WrappedComponent {...props} />
            </Col>
          </div>
        ) : (
          <>
            <Container fluid className="back-nav">
              <Row>
                <Col xs="1" sm="1" md="1" className="back-nav-heading">
                  <span onClick={handleBackPropagation} className="hover">
                    <Icon icon="angle-left" width="9" height="17" />
                  </span>
                </Col>
                <Col
                  xs="10"
                  sm="10"
                  md="10"
                  className="back-nav-heading text-center"
                >
                  <span className="">Ship to Home</span>
                </Col>
              </Row>
            </Container>
            <WrappedComponent {...props} />
          </>
        )}
      </>
    );
  };

  return BackNav;
};
