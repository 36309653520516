import { toTitleCase } from '../util/formatters';
import { CLIENT_ID } from './constants';
import { getSourcePageProperties } from '../util/utilMethods';

const source = window.analytics
  ? ({ page = null, properties = null, envInfo, history }) => {
      window.analytics.page(toTitleCase(page), {
        ...properties,
        client_id: CLIENT_ID,
        ...getSourcePageProperties(history, envInfo),
      });
    }
  : () => {};

export default source;
