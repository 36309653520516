import { PropsWithChildren } from 'react';
import { Footer } from '../Modal';
type TransactionPopupFooterProps = {
  className: string;
};
export const TransactionPopupFooter = ({
  children,
  className = '',
}: PropsWithChildren<TransactionPopupFooterProps>) => {
  return <Footer className={className}>{children}</Footer>;
};
