import React from 'react';
import SocialShareModal from '../SocialShareModal';
import { Modal, Col } from 'react-bootstrap';
import CloseButton from 'react-bootstrap/CloseButton';
import { useHistory } from 'react-router-dom';

import './ConfirmationEarnModal.scss';
import Icon from '../../../assets/svg';

const ConfirmationModal = ({
  name = '',
  show,
  handleClose,
  isDesktop,
  showSocialShare,
  detail = '',
  body,
  footer,
  newWaysToEarn,
  ammount = 0,
}) => {
  const history = useHistory();

  const handleCloseOverloaded = () => {
    handleClose();
    history.push('/');
  };

  return (
    <Modal
      show={show}
      onHide={handleCloseOverloaded}
      className={isDesktop ? 'text-center px-5 py-1' : ''}
    >
      <Modal.Header className="flex flex-column w-100 p-relative mx-auto">
        <CloseButton
          className="position-absolute"
          style={{
            right: '20px',
          }}
          onClick={handleCloseOverloaded}
        />
        <div className="p-0 w-100">
          <div className="header-text p-0">
            {name ? `👋 Congratulations ${name}!` : ''}
          </div>
        </div>
        {detail}
      </Modal.Header>
      <Modal.Body className="py-0">
        <Col
          className={`p-0 justify-content-around w-100 pr-3 pl-3 ${
            isDesktop && ' mx-auto'
          }`}
        >
          {!showSocialShare ? (
            body
          ) : (
            <>
              <h4 className="text-center text-bold confirmation-header">
                👋 I won AdvantEdge points from filling the Price Chopper /
                Market 32 Question of the Month Survey!
              </h4>
              <h6 className="text-left confirmation-subheader ml-3">
                Answer every month to gain AdvantEdge points!
              </h6>
              <hr className="mt-2 mb-3 pb-3" />
              <div className="survey-image-bg">
                <div
                  className={`text-center slide-icon-center-${
                    isDesktop ? 'desktop' : 'mobile'
                  }`}
                >
                  <Icon icon="question of the month" width="292" height="152" />
                </div>
              </div>
            </>
          )}
        </Col>
      </Modal.Body>
      <Modal.Footer className="flex flex-column pb-4 bg-modal-footer-gray !items-stretch">
        {newWaysToEarn && newWaysToEarn}
        {!showSocialShare ? (
          footer
        ) : (
          <SocialShareModal
            handleClose={handleCloseOverloaded}
            ammount={ammount}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
