import { useQuery } from '@tanstack/react-query';
import { loyaltyAxios } from '@rewards/data-provider';
export const useInmarCoupon = (offerId) => {
  return useQuery({
    queryKey: ['offer', offerId],
    queryFn: () => {
      return loyaltyAxios
        .get(`/api/inmar/offers/${offerId}`)
        .then((res) => res.data);
    },
    enabled: !!offerId,
  });
};
