import useBonusPointsByCard from '../../api/getBonusPointsByCardNumber';
import { useUserInfo } from '@rewards/data-provider';

const useUserBonusPoints = (isAuthenticated) => {
  const userInfo = useUserInfo(isAuthenticated);
  // console.log(userInfo.data)
  const cardNumber = userInfo?.data?.data?.partnerAccounts[0].cardNumber;
  const query = useBonusPointsByCard({
    cardNumber,
    enabled: !!cardNumber,
  });

  return query;
};
export default useUserBonusPoints;
