import { PropsWithChildren, useRef } from 'react';
import { CopyTooltipAtom, ShareLinkContext } from './Context';
import { useSetAtom } from 'jotai';

export const Root = ({
  children,
  url,
  shareData,
}: PropsWithChildren<{ url: string; shareData: ShareData }>) => {
  const linkRef = useRef<HTMLInputElement>(null);
  const setTooltip = useSetAtom(CopyTooltipAtom);
  const onClickCopy = () => {
    if (url) {
      if (window.navigator && window.navigator.clipboard) {
        window.navigator.clipboard.writeText(url);
      } else {
        const input = document.createElement('input');
        input.value = url;
        input.select();
        input.setSelectionRange(0, url.length);
        document.execCommand('copy');
      }
      console.log('Calling');
      setTooltip(true);
    }
  };

  const onClickShare = () => {
    if (navigator.share && url) {
      navigator.share({ ...shareData, url });
    }
  };

  return (
    <ShareLinkContext.Provider
      value={{
        onClickCopy,
        onClickShare,
        linkRef,
        url,
      }}
    >
      {children}
    </ShareLinkContext.Provider>
  );
};
