export const EVENTS = {
  REFI_BACK_CLICKED: 'Earning - Student Loan Refinance - Refi Button Clicked ',
  LOG_IN_REGISTER_CLICKED: 'Log In / Register Clicked',
  COMMUNITY_SOLAR_CLICKED:
    'Earning - Student Loan Refinance - Connect My Loan Account Clicked',
  REFI_APPLY_CLICKED: 'Refi Apply Clicked',
  PRE_QUALIFICATION_SUCCESS: 'Pre-qualification success',
};

export const PAGES = {
  VIEWED_REFI_INFO_PAGE: 'Viewed Refi Info Page',
};

export const EVENT_LOCATION = {};
