import PropTypes from 'prop-types';
import React from 'react';

import styleConsts from '../util/styleConsts';

export default class Icon extends React.Component {
  render() {
    const { width, height, stroke, className, ...rest } = this.props;
    let classNameString = `${className} svg-${this.props.icon}`;
    const colorStyleName = styleConsts.styleValueToStyleName(this.props.color);
    if (colorStyleName) {
      classNameString += ` ${this.props.colorClassName}-${colorStyleName}`;
    }
    const colorAuxStyleName = styleConsts.styleValueToStyleName(
      this.props.colorAux
    );
    if (colorAuxStyleName) {
      classNameString += ` ${this.props.colorAuxClassName}-${colorAuxStyleName}`;
    }
    const colorBorderStyleName = styleConsts.styleValueToStyleName(
      this.props.colorBorder
    );
    if (colorBorderStyleName) {
      classNameString += ` ${this.props.colorBorderClassName}-${colorBorderStyleName}`;
    }
    if (this.props.iconType) {
      classNameString += ` svg-icon-type-${this.props.iconType}`;
    }

    switch (this.props.icon) {
      case 'angle-left': {
        return (
          <svg
            className={classNameString}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 9 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.365 8.28255L8.555 1.89435C8.935 1.49636 8.935 0.853459 8.555 0.455473C8.175 0.0574857 7.545 0.0574857 7.165 0.455473L0.285 7.55801C-0.095 7.95599 -0.095 8.5989 0.285 8.99688L7.165 16.0994C7.355 16.2933 7.605 16.3954 7.855 16.3954C8.105 16.3954 8.355 16.2933 8.545 16.0994C8.925 15.7014 8.925 15.0585 8.545 14.6605L2.365 8.28255Z"
              fill={this.props.color}
            />
          </svg>
        );
      }
      case 'food': {
        return (
          <svg
            height="45"
            width="45"
            viewBox="0 0 45 45"
            fill={styleConsts.fireBrick}
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="22.5" cy="22.5" r="22.5" fill={styleConsts.fireBrick} />
            <svg
              width="25"
              height="25"
              viewBox="0 0 42 46"
              x="10"
              y="10"
              fill="none"
            >
              <path
                d="M21.9778 6.20412C22.9293 4.83422 24.3502 3.861 25.9714 3.46892C27.1986 6.37052 23.8914 8.87692 21.6242 10.2393C21.2115 8.89959 21.3384 7.45166 21.9778 6.20412V6.20412ZM20.8754 13.5465C21.1156 13.5595 21.3543 13.5017 21.5618 13.3801C23.3591 12.3627 25.0954 11.2411 26.7618 10.0209C28.9354 7.84732 30.2042 5.34092 27.9058 1.25372C27.7738 1.0164 27.5729 0.824719 27.3296 0.704017C27.0864 0.583316 26.8122 0.539278 26.5434 0.577723C26.3458 0.577723 21.749 1.30572 19.6898 4.84172C18.4418 6.99452 18.4106 9.63612 19.617 12.7041C19.7181 12.9609 19.8966 13.1799 20.1276 13.3308C20.3586 13.4817 20.6308 13.5571 20.9066 13.5465H20.8754ZM32.0346 38.8809C30.381 40.5241 26.0338 44.1537 21.489 42.1361C21.3173 42.0589 21.1312 42.019 20.943 42.019C20.7548 42.019 20.5687 42.0589 20.397 42.1361C15.457 44.3305 10.2882 40.1289 7.3554 35.8961C3.445 30.1969 1.7082 22.1889 5.9722 17.9249C6.38114 17.5402 6.82311 17.1922 7.293 16.8849C12.8986 13.2241 20.1578 17.7065 20.2306 17.7585C20.4485 17.8907 20.6985 17.9606 20.9534 17.9606C21.2083 17.9606 21.4583 17.8907 21.6762 17.7585C21.749 17.7065 28.9562 13.2137 34.6034 16.8953C35.6835 17.6081 36.5825 18.563 37.2288 19.6842C37.8752 20.8053 38.251 22.0618 38.3266 23.3537C38.8362 28.3249 36.2466 34.7937 32.0346 38.9537V38.8809ZM40.9786 23.0001C40.8595 21.3102 40.3529 19.6703 39.4981 18.2076C38.6432 16.745 37.463 15.4988 36.049 14.5657C30.0898 10.6553 23.153 13.7753 20.917 14.9817C18.6914 13.7753 11.7546 10.6449 5.785 14.5553C5.15606 14.9662 4.57063 15.4401 4.0378 15.9697C-1.2662 21.2737 0.553801 30.7481 5.0778 37.3729C9.6018 43.9977 15.6962 46.7329 20.8858 44.8193C25.0458 46.3689 29.7362 44.9233 33.8858 40.7841C38.7114 35.9585 41.5506 28.8137 40.9578 23.0001"
                fill={styleConsts.white}
              />
            </svg>
          </svg>
        );
      }
      default: {
        return (
          <svg
            className="svg-default"
            xmlns="http://www.w3.org/2000/svg"
            width="200"
            height="100"
          >
            <text fontSize="25">
              <tspan x="0" dy="1.2em">
                ICON
              </tspan>
              <tspan x="0" dy="1.2em">
                FAILURE
              </tspan>
            </text>
          </svg>
        );
      }
    }
  }
}

Icon.defaultProps = {
  children: null,
  classificationHeight: '30px',
  classificationId: 'default',
  classificationWidth: '30px',
  color: styleConsts.white,
  colorClassName: 'svg-color',
  colorAux: styleConsts.grayDark,
  colorAuxClassName: 'svg-colorAux',
  colorBorder: styleConsts.blue,
  colorBorderClassName: 'svg-colorBorder',
  height: '30px',
  iconType: '',
  includeSolutionBorder: undefined,
  standardSolutionName: '',
  style: null,
  time: '',
  width: '30px',
  x: '0',
  y: '0',
  className: 'inline',
};
Icon.propTypes = {
  children: PropTypes.element,
  classificationHeight: PropTypes.string,
  classificationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  classificationWidth: PropTypes.string,
  color: PropTypes.string,
  colorClassName: PropTypes.string,
  colorAux: PropTypes.string,
  colorAuxClassName: PropTypes.string,
  colorBorder: PropTypes.string,
  colorBorderClassName: PropTypes.string,
  height: PropTypes.string,
  icon: PropTypes.string.isRequired,
  iconType: PropTypes.string,
  includeSolutionBorder: PropTypes.bool,
  standardSolutionName: PropTypes.string,
  style: PropTypes.object,
  time: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  width: PropTypes.string,
  x: PropTypes.string,
  y: PropTypes.string,
  className: PropTypes.string,
};
