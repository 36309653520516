import { useMutation } from '@tanstack/react-query';
import initGenerateTokenPublic from '../services/useGenerateTokenPublic';

import { UN_AUTH_TOKEN_KEY, TOKEN_KEY } from '../utils/constants';

export default function useGenerateTokenPublic() {
  return useMutation({
    mutationKey: ['generateTokenPublic'],
    mutationFn: initGenerateTokenPublic,
    onMutate: () => {
      sessionStorage.removeItem(UN_AUTH_TOKEN_KEY);
      sessionStorage.removeItem(TOKEN_KEY);
    },
  });
}
