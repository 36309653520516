import { Root, type DialogProps } from '@radix-ui/react-dialog';
import {
  type IModalContextVariants,
  ModalContextStyles,
} from '../../contexts/ModalContext';
import { useMemo } from 'react';

type TModalProps = DialogProps & IModalContextVariants;
export const Modal = ({ type, ...props }: TModalProps) => {
  // console.log('🚀 ~ file: Modal.tsx:8 ~ Modal ~ props:', props);
  const variants = useMemo(() => ({ type }), [type]);

  return (
    <ModalContextStyles.Provider value={variants}>
      <Root {...props}></Root>
    </ModalContextStyles.Provider>
  );
};
