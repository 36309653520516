export const getToolsForSchoolsPath = (path = 'tools-for-schools') =>
  `/${path}`;
export const getSignUpPath = () => `${getToolsForSchoolsPath()}/signup`;
export const getComingSoonPath = () =>
  `${getToolsForSchoolsPath()}/coming-soon`;
export const getSelectedSchoolDashboardPath = (schoolId) =>
  `${getToolsForSchoolsPath()}/${schoolId}`;
export const getDonateDashboardPath = (schoolId) =>
  `${getSelectedSchoolDashboardPath(schoolId)}/donate`;
export const getEarnDashboardPath = (schoolId) =>
  `${getSelectedSchoolDashboardPath(schoolId)}/earn`;
