import { useQuery } from '@tanstack/react-query';
import getPartnerConfiguration from '../services/partnerConfiguration';

export default function usePartnerConfiguration({ clientId = null }) {
  return useQuery({
    queryKey: ['client', clientId],
    queryFn: getPartnerConfiguration,
    staleTime: 1000,
    enabled: !!clientId,
    retry: false,
  });
}
