import { Title, Close, Description } from '@radix-ui/react-dialog';
import MultiplierCircle from '../MultiplierCircle';
import { Button, Spinner } from '@rewards/ui';
import { InfoCampaign } from '../InfoCampaign';
import { STAGES } from './constants';
const ActivePopupContent = ({ userClass, stage }) => {
  return (
    <>
      <Title className={`sr-only text-h2 mt-2 pt-1 text-cobalt text-center`}>
        Bonus Points{' '}
        {stage === STAGES.LOADING
          ? 'are being activated'
          : 'Activation Confirmed'}
      </Title>
      {stage !== STAGES.LOADING ? (
        <>
          <section
            className={`text-h2 mt-2 pt-1 text-cobalt font-bold text-center`}
          >
            Congratulations!
          </section>
          <Description className="mt-1 text-cobalt text-center text-h5">
            You'll earn {userClass?.replace('!', '')} points through December
            24!
          </Description>
        </>
      ) : (
        <section
          className={`text-h2 mt-2 pt-1 text-cobalt font-bold text-center`}
        >
          Your Points Multiplier <br /> is being activated!
        </section>
      )}

      <MultiplierCircle className={'my-4 font-medium'}>
        {stage === STAGES.LOADING ? (
          <Spinner className={'fill-white text-white/25 stroke-[4px] '} />
        ) : (
          userClass?.replace('!', '')?.toUpperCase()
        )}
      </MultiplierCircle>
      {stage === STAGES.ACTIVATED && (
        <Close asChild>
          {/* TODO: Padding X should be 4rem */}
          <Button
            className={`!py-1 !px-4 md:!px-6 text-small sm:text-base  font-normal md:font-medium`}
          >
            Let's go
          </Button>
        </Close>
      )}
      <InfoCampaign duration className={'text-cobalt'} />
    </>
  );
};

export default ActivePopupContent;
