import React, { useContext } from 'react';
import { Col } from 'react-bootstrap';
import { SocialIcon } from 'react-social-icons';
import { AuthContext, AppContext } from '@rewards/contexts';

import { SchoolContext } from '../../../../contexts/SchoolContexts';
import { EVENTS, EVENT_LOCATION } from '../../../../analytics/events';

import { getToolsForSchoolsPath } from '../../../../util/paths';

const shareText =
  'Earn stars for your school when you shop at Price Chopper Market 32! Every star goes towards new equipment and resources for your school.';

const ShareOnSocial = () => {
  const { envInfo } = useContext(AuthContext.AuthContext);
  const { track } = useContext(AppContext.AppContext);
  const { getStarsViaSocialShare } = useContext(SchoolContext);

  const handleShareOnFacebook = () => {
    track(EVENTS.TOOLS_FOR_SCHOOLS_SHARE_ON_FACEBOOK_CLICKED, {
      location: EVENT_LOCATION.TOOLS_FOR_SCHOOLS_EARN_STARS_MODAL,
      ui_version: import.meta.env.APP_VERSION,
    });

    window.FB.ui(
      {
        app_id: envInfo.appId,
        method: 'share',
        display: 'popup',
        href: `${window.location.origin}${getToolsForSchoolsPath()}`,
        quote: shareText,
      },
      function (response) {
        if (Array.isArray(response)) {
          track(EVENTS.TOOLS_FOR_SCHOOLS_SHARED_ON_FACEBOOK, {
            location: EVENT_LOCATION.TOOLS_FOR_SCHOOLS_EARN_STARS_MODAL,
            ui_version: import.meta.env.APP_VERSION,
          });
          getStarsViaSocialShare();
        }
      }
    );
  };

  return (
    <Col className="flex justify-content-center p-3">
      <SocialIcon
        network="facebook"
        className="hover ml-2"
        onClick={handleShareOnFacebook}
      />
    </Col>
  );
};

export default ShareOnSocial;
