import React from 'react';
import { Container, Card } from 'react-bootstrap';

import { useResponsive } from '../../util/hooks';

import BackNav from '../BackNav';

import './ComingSoon.scss';

const ComingSoon = () => {
  const { isDesktop } = useResponsive();
  return (
    <Container className="text-center my-4 px-3 p-coming-soon">
      <Card
        className={`bg-white border-0 ${
          isDesktop
            ? 'w-75 mx-auto desk-p-coming-soon-card'
            : 'rounded p-coming-soon-card'
        }`}
      >
        <Card.Body
          className={`px-0 p-0 flex flex-col items-center ${
            isDesktop ? 'desk-p-coming-soon-body' : 'p-coming-soon-body'
          }`}
        >
          <Card.Title className="p-coming-soon-info-1 p-desk-coming-soon-info-1 mt-3">
            Start saving your Rewards points!
          </Card.Title>
          <Card.Text className="p-coming-soon-info-2 p-desk-coming-soon-info-2">
            More products are coming soon!
          </Card.Text>
          <Card.Img
            className={`p-coming-soon-card-image-1 mb-5`}
            // src={`https://cdn.spinwheel.io/images/tcc/products-coming-soon-banner-2022-${isDesktop ? 'desktop' : 'mobile'}.png`}
            src="https://cdn.spinwheel.io/images/pricechopper/advantedge-card.png"
            alt="Card Image LifeStyle"
          />
        </Card.Body>
      </Card>
    </Container>
  );
};

export default BackNav(ComingSoon);
