import { useContext } from 'react';
import AppContext from './AppContext';

const useApp = () => {
  const appState = useContext(AppContext);
  if (!appState) {
    throw new Error('Undefined app state!');
  }
  const { currencyExchangesData, currencyExchangesStatus } = appState;

  return {
    currencyExchangesStatus,
    currencyExchanges: currencyExchangesData?.currencyExchanges,
  };
};

export default useApp;
