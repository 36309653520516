import React, { useState, useContext } from 'react';
import { Nav, Badge, Accordion } from 'react-bootstrap';
import { AppContext } from '@rewards/contexts';

import ShareOnSocial from './ShareOnSocial';

import Icon from '../../../assets/svg';
import { EVENT_LOCATION, EVENTS } from '../../../analytics/events';

import './EarnPoints.scss';

const EarnPointsNavLinks = (props) => {
  const { track } = useContext(AppContext.AppContext);

  const [showSocialShare, setShowSocialShare] = useState(false);

  const handleShareOnSocial = () => {
    track(EVENTS.TOOLS_FOR_SCHOOLS_SHARE_ON_SOCIAL_CLICKED, {
      location: EVENT_LOCATION.TOOLS_FOR_SCHOOLS_EARN_STARS_MODAL,
      ui_version: import.meta.env.APP_VERSION,
    });
    setShowSocialShare(!showSocialShare);
  };

  const handleInviteFriends = () => {
    track(EVENTS.TOOLS_FOR_SCHOOLS_INVITE_FRIENDS_CLICKED, {
      location: EVENT_LOCATION.TOOLS_FOR_SCHOOLS_EARN_STARS_MODAL,
      ui_version: import.meta.env.APP_VERSION,
    });
    props.handleInviteFriends(true);
  };
  // TODO: Add Icons once we have update for Share and Invite
  return (
    <Nav
      className={`flex flex-column mt-3 earn-points-nav ${
        props.isDesktop ? 'w-75 px-0 mx-auto' : ''
      }`}
    >
      <Nav.Item className="mb-2 rounded tfs-confirm-donate-modal-nav-item bg-white">
        <Accordion>
          <Accordion.Toggle
            as={Nav.Link}
            variant="default"
            eventKey="0"
            className="p-3 text-dark"
            onClick={handleShareOnSocial}
          >
            <GetTitle
              title="Share on social"
              faIcon="trophy"
              width="32"
              height="32"
              stars={10}
              showAngleDown={showSocialShare}
            />
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <ShareOnSocial />
          </Accordion.Collapse>
        </Accordion>
      </Nav.Item>

      <Nav.Item
        onClick={handleInviteFriends}
        className="rounded tfs-confirm-donate-modal-nav-item"
      >
        <Nav.Link eventKey="donate" className="text-dark bg-white p-3">
          <GetTitle
            title="Invite Friends"
            faIcon="contacts"
            width="32"
            height="32"
            stars={20}
          />
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default EarnPointsNavLinks;

const GetTitle = (props) => {
  return (
    <div className="items-center flex flex-row justify-content-between">
      <span>
        {/* <Icon
          icon={props.faIcon}
          width={props?.width || '17'}
          height={props.height || '17'}
        /> */}
        <span className="nav-item-text ml-3">{props.title}</span>
      </span>

      <span className="flex my-auto items-center">
        <Badge
          pill
          className="flex tfs-earn-dashboard-stars font-medium mr-2 pl-3  items-center"
        >
          +{props?.stars}
          <Icon icon="star" width="25" height="20" />
        </Badge>
        <Icon
          icon={props?.showAngleDown ? 'angle-down' : 'angle-right'}
          width="13"
          height="13"
        />
      </span>
    </div>
  );
};
