import axiosInstance from '../utils/axiosInstance';
const useUnAuthToken = (
  requestParams = { data: { tokenType: 'NON_SIGNIN' } }
) => {
  const promise = axiosInstance
    .post('/api/auth/generateToken', requestParams, {})
    .then((response) => ({ data: response?.data }));

  return promise;
};

export default useUnAuthToken;
