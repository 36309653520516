import {
  Overlay,
  Portal,
  Content,
  type DialogContentProps,
} from '@radix-ui/react-dialog';
import { PropsWithChildren, forwardRef } from 'react';
import { tv } from '../../util';
import { useModalContext } from '../../contexts/ModalContext';

const styles = tv({
  slots: {
    container:
      'rounded w-[90%] relative flex flex-col text-center bg-white !px-4 !py-5 max-h-[80dvh] overflow-auto',
    overlay: 'fixed flex justify-center  items-center  w-full h-dvh inset-0 ',
  },
  variants: {
    type: {
      cta: {
        container: 'max-w-xl min-w-[270px]',
      },
      document: {
        container: 'max-w-[900px] ',
      },
      transaction: {
        container: ' max-w-sm max-h-[90dvh]',
      },
      notification: {},
    },
  },
  defaultVariants: {
    type: 'cta',
  },
});
export const ModalContent = forwardRef<
  HTMLDivElement,
  PropsWithChildren<DialogContentProps>
>(
  (
    {
      children,
      className,
      style,
      ...props
    }: PropsWithChildren<DialogContentProps>,
    forwardedRef
  ) => {
    const { type } = useModalContext();
    // console.log('🚀 ~ file: ModalContent.tsx:42 ~ type:', type);
    const { overlay, container } = styles({ type, className });
    // console.log(container());
    return (
      <Portal>
        <Overlay
          style={{
            backgroundColor: 'rgba(0,0,0,0.25)',

            zIndex: 1,
          }}
          className={overlay()}
        >
          <Content
            className={container({ className })}
            style={{
              ...style,
            }}
            ref={forwardedRef}
            {...props}
          >
            {children}
          </Content>
        </Overlay>
      </Portal>
    );
  }
);
