import * as RadixToast from '@radix-ui/react-toast';
import { PropsWithChildren, ReactNode } from 'react';
import { tailwindMerge } from '../../util';

export type ToastComponentProps = {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  className?: string;
};

export const Toast = ({
  children,
  className = '',
  isOpen,
  onOpenChange,
}: PropsWithChildren<ToastComponentProps>) => {
  const classes = tailwindMerge(
    `fixed top-5 left-1/2 transform -translate-x-1/2 flex items-center w-full max-w-xs p-4 space-x-4 text-gray-500 bg-cobalt divide-x rtl:divide-x-reverse divide-gray-200 rounded-lg shadow space-x dark:bg-gray-800 z-10 ${
      isOpen ? 'animate-slideInToast' : 'animate-slideOutToast'
    }`,
    className
  );
  return (
    <>
      <RadixToast.Root
        open={isOpen}
        duration={5000}
        className={classes}
        onOpenChange={onOpenChange}
      >
        <RadixToast.Title className="text-base font-normal text-white">
          {children}
        </RadixToast.Title>
      </RadixToast.Root>
    </>
  );
};
