import type { ReactNode } from 'react';
import { Badge } from 'react-bootstrap';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { Link } from 'react-router-dom';
import {
  AngleRightIcon,
  GiftCardIcon,
  StudentLoanRefinanceIcon,
} from '@rewards/ui';

type GetTitleProps = {
  icon: ReactNode | undefined;
  title: string;
  isNew?: boolean;
};
function Item({ icon, title, isNew }: GetTitleProps) {
  return (
    <div className="flex items-center w-full justify-between relative px-2 ">
      <div className="flex justify-between">
        {icon}

        <span className="ml-3">{title}</span>
      </div>

      <div className="flex items-center ">
        {isNew && <Badge className="is-new-badge-survey rounded ">New</Badge>}
        <AngleRightIcon
          className=" w-2 h-[14px] fill-schooner "
          // className="float-right"
        />
      </div>
    </div>
  );
}

export const NewWaysToEarn = ({}) => {
  return (
    <div className="py-2 w-100">
      <div
        className={`py-0 text-center mt-0 px-3 lg:w-full lg:mx-auto lg:px-4 `}
      >
        Here are other ways to earn more points:
      </div>

      <NavigationMenu.Root
        className={`flex flex-column pt-4 lg:w-100 mx-auto `}
      >
        <NavigationMenu.List>
          <NavigationMenu.Item className="p-3 bg-white mb-2 rounded" asChild>
            <NavigationMenu.Link className="p-0 text-dark" asChild>
              <Link to="/earn/gift-cards" className="hover:no-underline">
                <Item
                  title="Gift Cards"
                  icon={<GiftCardIcon className="w-6 aspect-square" />}
                />
              </Link>
            </NavigationMenu.Link>
          </NavigationMenu.Item>
          <NavigationMenu.Item className="p-3 bg-white mb-2 rounded" asChild>
            <NavigationMenu.Link className="p-0 text-dark" asChild>
              <Link to="/earn/utility-offers" className="hover:no-underline">
                <Item
                  title="Utility Offers"
                  isNew={true}
                  icon={
                    <img
                      className="aspect-square w-6"
                      src="https://cdn.spinwheel.io/images/tcc/modules/GME_LOGO_image.png"
                    ></img>
                  }
                />
              </Link>
            </NavigationMenu.Link>
          </NavigationMenu.Item>
          <NavigationMenu.Item className=" p-3 bg-white rounded" asChild>
            <NavigationMenu.Link className="p-0 text-dark" asChild>
              <Link to="/earn/student-loan-refi" className="hover:no-underline">
                <Item
                  title="Student Loan Refinance"
                  icon={
                    <StudentLoanRefinanceIcon className="aspect-square w-6" />
                  }
                />
              </Link>
            </NavigationMenu.Link>
          </NavigationMenu.Item>
        </NavigationMenu.List>
      </NavigationMenu.Root>
    </div>
  );
};
