import { tailwindMerge } from '@rewards/ui';

const MultiplierCircle = ({ className, style, children }) => {
  const mergedClasses = tailwindMerge(
    `flex sm:flex rounded-full  items-center justify-center  text-h1 text-white align-middle ${className}`
  );
  return (
    <div
      className={mergedClasses}
      style={{
        backgroundColor: '#61bb46',
        width: '80px',
        aspectRatio: '1/1',
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default MultiplierCircle;
