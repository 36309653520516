import React from 'react';
import GiftCardsContext from './GiftCardsContext';

const GiftCardsContextState = ({ children }, ...props) => {
  return (
    <GiftCardsContext.Provider value={{}} {...props}>
      {children}
    </GiftCardsContext.Provider>
  );
};
export default GiftCardsContextState;
