import PropTypes from 'prop-types';
import React from 'react';

import styleConsts from '../util/styleConsts';

export default class Icon extends React.Component {
  render() {
    const { width, height, stroke, className, ...rest } = this.props;
    let classNameString = `${className} svg-${this.props.icon}`;
    const colorStyleName = styleConsts.styleValueToStyleName(this.props.color);
    if (colorStyleName) {
      classNameString += ` ${this.props.colorClassName}-${colorStyleName}`;
    }
    const colorAuxStyleName = styleConsts.styleValueToStyleName(
      this.props.colorAux
    );
    if (colorAuxStyleName) {
      classNameString += ` ${this.props.colorAuxClassName}-${colorAuxStyleName}`;
    }
    const colorBorderStyleName = styleConsts.styleValueToStyleName(
      this.props.colorBorder
    );
    if (colorBorderStyleName) {
      classNameString += ` ${this.props.colorBorderClassName}-${colorBorderStyleName}`;
    }
    if (this.props.iconType) {
      classNameString += ` svg-icon-type-${this.props.iconType}`;
    }

    switch (this.props.icon) {
      case 'angle-left': {
        return (
          <svg
            className={classNameString}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 9 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.365 8.28255L8.555 1.89435C8.935 1.49636 8.935 0.853459 8.555 0.455473C8.175 0.0574857 7.545 0.0574857 7.165 0.455473L0.285 7.55801C-0.095 7.95599 -0.095 8.5989 0.285 8.99688L7.165 16.0994C7.355 16.2933 7.605 16.3954 7.855 16.3954C8.105 16.3954 8.355 16.2933 8.545 16.0994C8.925 15.7014 8.925 15.0585 8.545 14.6605L2.365 8.28255Z"
              fill={this.props.color}
            />
          </svg>
        );
      }

      case 'shop-in-store': {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 45 45"
          >
            <g id="Layer_2" data-name="Layer 2">
              <g id="Layer_1-2" data-name="Layer 1">
                <g id="Layer_2-2" data-name="Layer 2">
                  <g id="Layer_1-2-2" data-name="Layer 1-2">
                    <g>
                      <circle
                        cx="22.5"
                        cy="22.5"
                        r="22.5"
                        fill={styleConsts.white}
                        opacity="0.1"
                      />
                    </g>
                    <g id="Layer_2-3" data-name="Layer 2">
                      <g id="Layer_1-2-3" data-name="Layer 1-2">
                        <path
                          d="M11.11,23.14l1.42,9.64a2.15,2.15,0,0,0,2.25,2H30.2a2.55,2.55,0,0,0,2.1-2.38c.35-2.22,1.05-9.24,1.05-9.24Z"
                          fill={styleConsts.white}
                          stroke={styleConsts.cobalt}
                          strokeWidth="0.75px"
                          strokeMiterlimit="10"
                        />
                        <path
                          d="M15.63,25.41h0a1.21,1.21,0,0,1,1.21,1.21v5a1.22,1.22,0,0,1-1.21,1.22h0a1.21,1.21,0,0,1-1.21-1.21h0v-5a1.21,1.21,0,0,1,1.2-1.21Z"
                          fill={styleConsts.white}
                          stroke={styleConsts.cobalt}
                          strokeWidth="0.75px"
                          strokeMiterlimit="10"
                        />
                        <path
                          d="M20.23,25.41h0a1.21,1.21,0,0,1,1.21,1.21v5a1.22,1.22,0,0,1-1.21,1.22h0A1.21,1.21,0,0,1,19,31.61h0v-5a1.21,1.21,0,0,1,1.2-1.21Z"
                          fill={styleConsts.white}
                          stroke={styleConsts.cobalt}
                          strokeWidth="0.75px"
                          strokeMiterlimit="10"
                        />
                        <path
                          d="M24.55,25.41h0a1.21,1.21,0,0,1,1.23,1.2h0v5a1.22,1.22,0,0,1-1.21,1.22h0a1.21,1.21,0,0,1-1.21-1.21h0v-5a1.21,1.21,0,0,1,1.2-1.21Z"
                          fill={styleConsts.white}
                          stroke={styleConsts.cobalt}
                          strokeWidth="0.75px"
                          strokeMiterlimit="10"
                        />
                        <path
                          d="M29.21,25.41h0a1.21,1.21,0,0,1,1.21,1.2h0v5a1.21,1.21,0,0,1-1.2,1.22h0A1.22,1.22,0,0,1,28,31.61h0v-5a1.2,1.2,0,0,1,1.19-1.21Z"
                          fill={styleConsts.white}
                          stroke={styleConsts.cobalt}
                          strokeWidth="0.75px"
                          strokeMiterlimit="10"
                        />
                        <rect
                          x="9.13"
                          y="20.3"
                          width="26.29"
                          height="2.84"
                          rx="1.42"
                          fill={styleConsts.white}
                          stroke={styleConsts.cobalt}
                          strokeWidth="0.75px"
                          strokeMiterlimit="10"
                        />
                        <g id="Layer_2-2-2" data-name="Layer 2-2">
                          <g id="Layer_1-2-2-2" data-name="Layer 1-2-2">
                            <polygon
                              points="23.55 12.67 17.6 12.67 18.54 11.25 24.49 11.25 23.55 12.67"
                              fill={styleConsts.white}
                              stroke={styleConsts.cobalt}
                              strokeWidth="0.75px"
                              strokeMiterlimit="10"
                            />
                            <rect
                              x="18.63"
                              y="9.6"
                              width="6.14"
                              height="1.37"
                              fill={styleConsts.white}
                              stroke={styleConsts.cobalt}
                              strokeWidth="0.75px"
                              strokeMiterlimit="10"
                            />
                            <path
                              className="cls-3"
                              d="M26.31,20.3l-.19-7.69-1.48-1.29-1.39,1.29V20.3Z"
                              fill={styleConsts.white}
                              stroke={styleConsts.cobalt}
                              strokeWidth="0.75px"
                              strokeMiterlimit="10"
                            />
                            <polygon
                              points="17.4 13.36 17.4 20.3 23.25 20.3 23.25 13.33 17.4 13.36"
                              fill={styleConsts.white}
                              stroke={styleConsts.cobalt}
                              strokeWidth="0.75px"
                              strokeMiterlimit="10"
                            />
                            <path
                              d="M11.11,20.3h6.33V16a.76.76,0,0,0-.43-.66l-1.33-.6V13.15a.48.48,0,0,0-.47-.48H13.52a.53.53,0,0,0-.54.52h0v1.5l-1.48.73a.67.67,0,0,0-.39.63Z"
                              fill={styleConsts.white}
                              stroke={styleConsts.cobalt}
                              strokeWidth="0.75px"
                              strokeMiterlimit="10"
                            />
                            <rect
                              x="11.29"
                              y="18.58"
                              width="4.59"
                              height="0.32"
                              fill={styleConsts.white}
                              stroke={styleConsts.cobalt}
                              strokeWidth="0.75px"
                              strokeMiterlimit="10"
                            />
                            <line
                              x1="12.98"
                              y1="14.61"
                              x2="15.68"
                              y2="14.61"
                              stroke={styleConsts.cobalt}
                              strokeWidth="0.75px"
                              fill="none"
                            />
                            <path
                              d="M21.67,22.54h0a1.31,1.31,0,0,0,1.84-.16l8.64-8.6a1.32,1.32,0,0,0,.17-1.86h0c-.47-.47-1.3-.4-2,.3L21.68,20.8A1.28,1.28,0,0,0,21.67,22.54Z"
                              fill={styleConsts.white}
                              stroke={styleConsts.cobalt}
                              strokeWidth="0.75px"
                              strokeMiterlimit="10"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        );
      }
      default: {
        return (
          <svg
            className="svg-default"
            xmlns="http://www.w3.org/2000/svg"
            width="200"
            height="100"
          >
            <text fontSize="25">
              <tspan x="0" dy="1.2em">
                ICON
              </tspan>
              <tspan x="0" dy="1.2em">
                FAILURE
              </tspan>
            </text>
          </svg>
        );
      }
    }
  }
}

Icon.defaultProps = {
  children: null,
  classificationHeight: '30px',
  classificationId: 'default',
  classificationWidth: '30px',
  color: styleConsts.white,
  colorClassName: 'svg-color',
  colorAux: styleConsts.grayDark,
  colorAuxClassName: 'svg-colorAux',
  colorBorder: styleConsts.blue,
  colorBorderClassName: 'svg-colorBorder',
  height: '30px',
  iconType: '',
  includeSolutionBorder: undefined,
  standardSolutionName: '',
  style: null,
  time: '',
  width: '30px',
  x: '0',
  y: '0',
  className: 'inline',
};
Icon.propTypes = {
  children: PropTypes.element,
  classificationHeight: PropTypes.string,
  classificationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  classificationWidth: PropTypes.string,
  color: PropTypes.string,
  colorClassName: PropTypes.string,
  colorAux: PropTypes.string,
  colorAuxClassName: PropTypes.string,
  colorBorder: PropTypes.string,
  colorBorderClassName: PropTypes.string,
  height: PropTypes.string,
  icon: PropTypes.string.isRequired,
  iconType: PropTypes.string,
  includeSolutionBorder: PropTypes.bool,
  standardSolutionName: PropTypes.string,
  style: PropTypes.object,
  time: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  width: PropTypes.string,
  x: PropTypes.string,
  y: PropTypes.string,
  className: PropTypes.string,
};
