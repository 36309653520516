import { useMutation } from '@tanstack/react-query';
import { loyaltyAxios } from '@rewards/data-provider';
export const useGameWin = (options) => {
  return useMutation({
    mutationFn: (prize) => {
      return loyaltyAxios
        .post('/api/v2/daily-game/win', prize)
        .then((response) => response.data);
    },

    ...options,
  });
};
