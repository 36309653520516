import authAxios from './authAxios';

const headerConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const fetchCampaigns = async () => {
  // not used
  return authAxios
    .get('/api/sweepstakeCampaigns', headerConfig)
    .then((response) => {
      if (response.status === 200) {
        const { data } = response.data;
        return data;
      } else {
        return response;
      }
    });
};

export const fetchCalculateExchange = async (params) => {
  return authAxios
    .get('/api/currencyExchanges/calculateExchange', {
      params: params,
    })
    .then((response) => {
      if (response?.status === 200) {
        const { data } = response.data;
        return data;
      }
    });
};

export const fetchBonusPointsTxns = async (params) => {
  return authAxios
    .post('/api/transactions/free', { data: { ...params } })
    .then((response) => {
      if (response?.status === 200) {
        return response;
      }
    });
};
