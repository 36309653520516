import { useMutation, useQueryClient } from '@tanstack/react-query';
import initDisconnectStudentLoan from '../services/useDisconnectStudentLoan';

export default function useDisconnectStudentLoan() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['disconnectStudentLoan'],
    mutationFn: initDisconnectStudentLoan,
    onSuccess: () => {},
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['user'],
      });
    },
  });
}
