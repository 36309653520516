import React, { useState } from 'react';

import KnifeConfirmModal from './components/KnifeConfirmModal';
import KnifeSuccessModal from './components/KnifeSuccessModal';
const KnifeConfirmation = (props) => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleSuccessModal = () => setShowSuccessModal(!showSuccessModal);
  return (
    <>
      <KnifeConfirmModal {...props} handleSuccessModal={handleSuccessModal} />
      <KnifeSuccessModal
        show={showSuccessModal}
        handleSuccessModal={handleSuccessModal}
      />
    </>
  );
};

export default KnifeConfirmation;
