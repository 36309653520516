import { useContext } from 'react';

import { ToolsForSchoolsContext } from '../../contexts';

const useToolsForSchools = () => {
  const toolsForSchoolsState = useContext(ToolsForSchoolsContext);
  if (!toolsForSchoolsState) {
    throw new Error('Undefined tools For SchoolsS state!');
  }

  const {
    toolsForSchoolsData,
    schoolForUserData,
    schoolsData,
    schoolsStatus,
    schoolForUserStatus,
    cardData,
  } = toolsForSchoolsState;

  return {
    toolsForSchools: toolsForSchoolsData?.toolsForSchools,
    schools: schoolsData?.schools,
    schoolsStatus,
    schoolForUser: schoolForUserData?.schoolForUser,
    schoolForUserStatus,
    cardData,
  };
};

export default useToolsForSchools;
