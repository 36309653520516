export const EVENTS = {
  GIFT_CARDS_BACK_CLICKED: 'Gift Cards Back Clicked',
  CLICKED_SIGN_UP_BUTTON: 'Clicked Sign Up Button',
};

export const PAGES = {
  VIEWED_GIFT_CARDS: 'Viewed Gift Cards',
};

export const EVENT_LOCATION = {
  GIFT_CARDS_LANDING_PAGE: 'Gift Cards Landing Page',
};
