import React, { useEffect, useContext } from 'react';
import {
  Modal,
  Col,
  InputGroup,
  ButtonGroup,
  FormControl,
} from 'react-bootstrap';
import { Button } from '@rewards/ui';
import { AppContext } from '@rewards/contexts';

import Icon from '../../assets/svg';
import ModalCloseBtn from '../SelectSchoolModals/ModalCloseBtn';
import { useResponsive, useSchool, useCheckIsDesktop } from '../../util/hooks';
import { EVENT_LOCATION, EVENTS, PAGES } from '../../analytics/events';
import styleConsts from '../../util/styleConsts';
import './InviteFriendsModal.scss';

const InviteFriendsModal = ({ show, handleClose }) => {
  const { track, source } = useContext(AppContext.AppContext);

  const { isDesktop } = useResponsive();
  const { isDesktopDevice } = useCheckIsDesktop();
  const { inviteFriendsLink } = useSchool();

  useEffect(() => {
    if (show) {
      source(PAGES.TOOLS_FOR_SCHOOLS_INVITE_FRIENDS_MODAL, {
        ui_version: import.meta.env.APP_VERSION,
      });
    }
  }, [show]);

  const handleCloseModal = () => {
    track(EVENTS.TOOLS_FOR_SCHOOLS_CLOSE_CLICKED, {
      location: EVENT_LOCATION.TOOLS_FOR_SCHOOLS_INVITE_FRIENDS_MODAL,
      ui_version: import.meta.env.APP_VERSION,
    });
    handleClose();
  };

  const handleBackClick = () => {
    track(EVENTS.TOOLS_FOR_SCHOOLS_BACK_CLICKED, {
      location: EVENT_LOCATION.TOOLS_FOR_SCHOOLS_INVITE_FRIENDS_MODAL,
      ui_version: import.meta.env.APP_VERSION,
    });
    handleClose(true);
  };

  const handleCopyText = () => {
    track(EVENTS.TOOLS_FOR_SCHOOLS_COPY_LINK_CLICKED, {
      location: EVENT_LOCATION.TOOLS_FOR_SCHOOLS_INVITE_FRIENDS_MODAL,
      ui_version: import.meta.env.APP_VERSION,
    });

    const copyText = document.getElementById('copy-link');
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
  };

  const handleShareLink = () => {
    track(EVENTS.TOOLS_FOR_SCHOOLS_SHARE_LINK_CLICKED, {
      location: EVENT_LOCATION.TOOLS_FOR_SCHOOLS_INVITE_FRIENDS_MODAL,
      ui_version: import.meta.env.APP_VERSION,
    });

    navigator
      .share({
        title: 'Invite friends share link',
        text: 'Invite friends share link',
        url: inviteFriendsLink || null,
      })
      .then(() => {
        console.log('Thanks for sharing!');
      })
      .catch(console.error);
  };
  return (
    <Modal
      show={show}
      backdrop="static"
      keyboard={false}
      centered={true}
      dialogClassName={`rounded' ${
        isDesktop ? '' : 'tfs-mobile-dialog mx-auto'
      }`}
    >
      <Modal.Header className="border-0 flex d-flex items-center align-items-center">
        <Button
          size="wrapper"
          intent="transparent"
          className="p-0"
          //  className='mr-2 p-0' variant='default'
          onClick={handleBackClick}
        >
          <Icon
            icon="angle-left"
            width="9"
            height="17"
            color={styleConsts.cobalt}
          />
        </Button>
        <Col className="tfs-invite-friends-title p-0 text-center">
          👋🏻 Invite Friends
        </Col>
        <ModalCloseBtn handleClose={handleCloseModal} />
      </Modal.Header>
      <Modal.Body className="text-left border-0">
        <Col className="p-0 tfs-invite-friends-info">
          <span className="tfs-invite-friends-imp-info">
            Send your friends this link
          </span>{' '}
          so they can start donating and earning stars for their school.
        </Col>
        <Col className="tfs-invite-friends-imp-info text-left my-3 p-0">
          You’ll earn 20 stars for every friend that signs up!
        </Col>
        <InputGroup>
          <InputGroup.Prepend className="border-0">
            <InputGroup.Text className="border-0 py-0 bg-light">
              <Icon icon="link-0" width="16" height="16" />
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            type="text"
            id="copy-link"
            readOnly
            className="p-0 border-0 w-80 bg-light text-secondary"
            value={inviteFriendsLink || ''}
          />
        </InputGroup>
      </Modal.Body>
      <Modal.Footer className="border-0">
        <ButtonGroup className="flex justify-content-between w-100">
          <Button
            // className={`tfs-invite-friends-copy mr-1 rounded border-0 ${navigator.share && !isDesktopDevice ? 'w-50' : 'w-100'}`}
            onClick={handleCopyText}
          >
            Copy Link
          </Button>
          {navigator.share && !isDesktopDevice && (
            <Button
              // className='tfs-invite-friends-share w-50 ml-1 rounded border-0'
              onClick={handleShareLink}
            >
              Share Link
            </Button>
          )}
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default InviteFriendsModal;
