import { atom } from 'jotai';
import type { ReactNode } from 'react';

export const isToastOpenAtom = atom(false);
export const toastMessageAtom = atom<ReactNode>('');
export const setToastMessageAtom = atom(
  null,
  (_get, set, message: ReactNode) => {
    set(toastMessageAtom, message);
    set(isToastOpenAtom, true);
  }
);
