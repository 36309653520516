import { atom } from 'jotai';
export const maxAdvantPoints = atom(0);
export const advantPoints = atom(0);

interface ICurrencyExchange {
  sourceDebitIncrements: number;
  destCurrencyValue: number;
  sourceDebitMax: number;
  sourceDebitMin: number;
}
export const currencyExchangeAtom = atom<ICurrencyExchange | null>(null);
export const pointsTooltipAtom = atom('');
export const increaseAdvantPoints = atom(null, (get, set) => {
  const currencyExchange = get(currencyExchangeAtom);
  // console.log(
  //   '🚀 ~ file: advantPoints.ts:14 ~ increaseAdvantPoints ~ currencyExchange:',
  //   currencyExchange
  // );
  if (currencyExchange) {
    set(advantPoints, (prev) => {
      const newValue = prev + currencyExchange.sourceDebitIncrements;
      if (get(maxAdvantPoints) >= newValue) {
        return newValue;
      } else {
        set(
          pointsTooltipAtom,
          () => `Minimum points should be ${currencyExchange?.sourceDebitMin}`
        );
      }
      return prev;
    });
  }
});

export const decreaseAdvantPoints = atom(null, (get, set) => {
  const currencyExchange = get(currencyExchangeAtom);
  if (currencyExchange) {
    set(advantPoints, (prev) => {
      const newValue = prev - currencyExchange.sourceDebitIncrements;
      // console.log('🚀 ~ file: advantPoints.ts:41 ~ set ~ newValue:', newValue);

      if (newValue >= 0) {
        return newValue;
      } else {
        // console.log('SET MINIMUN TOOLTIP');
        set(
          pointsTooltipAtom,
          () => `Minimum points should be ${currencyExchange?.sourceDebitMin}`
        );
      }
      return prev;
    });
  }
});
