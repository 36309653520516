import React, { useContext } from 'react';
import { Col, Button, Row } from 'react-bootstrap';
import { AppContext } from '@rewards/contexts';
import { useMsal } from '@azure/msal-react';

import './GetLatestDeals.scss';
import { EVENTS } from '../../../../analytics/events';

const GetLatestDeals = () => {
  const { track } = useContext(AppContext.AppContext);
  const { instance } = useMsal();

  const handleSignup = () => {
    track(EVENTS.LOG_IN_REGISTER_CLICKED, {
      ui_version: import.meta.env.APP_VERSION,
    });
    // window.open("https://pricechopperrewards.b2clogin.com/pricechopperrewards.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN/oauth2/v2.0/authorize?response_type=code&client_id=c8a3559b-085f-4dab-be76-0927593f5be9&scope=openid+offline_access+https%3A%2F%2Fpricechopperrewards.onmicrosoft.com%2Fc8a3559b-085f-4dab-be76-0927593f5be9%2FProfile.Read&state=RCMi9SrXJ8TIop5lgbsMbuUOzoVttF&redirect_uri=https%3A%2F%2Fshop.pricechopper.com%2Fsocial-redirect%2Fexternal_oauth2_idp&code_challenge=bUCk6ndn2XRoUn3QYh8QcSJn1T9zMs4aWbL2wozwJzY&code_challenge_method=S256&nonce=zG3NxMwG3VWdLDhnnvYelVLhw7bsLH", '_blank');
    instance.config.auth.authority = instance.config.auth.loginAuthority;
    instance.loginRedirect({
      ...instance.config.auth.loginRequest,
      extraQueryParameters: {
        anonymousId: window?.analytics?.user?.()?.anonymousId?.(),
      },
    });
  };

  return (
    <Col className="p-0 container-latest-deals" md={12}>
      <Col className="p-0">
        <h2 className="l-footer-header-latest-deals">
          AdvantEdge Rewards is better than ever.
        </h2>
      </Col>
      <Col className="p-0 mb-2 l-footer-subheader-latest-deals">
        Create an account to receive emails, earn and redeem rewards, save with
        eCoupons and more!
      </Col>
      <Col className="p-0 l-footer-subheader-latest-deals">
        <Button className="l-footer-signup" onClick={handleSignup}>
          <span>SIGN UP</span>
        </Button>
      </Col>
      <Col className="p-0 mb-2 l-footer-subheader-latest-deals-bigger">
        Shop our great deals, anywhere! Download our app today!
      </Col>
      <Col className="p-0 app-links">
        <a
          href="https://itunes.apple.com/us/app/price-chopper/id484577231"
          target="_blank"
          rel="nonpener"
        >
          <img
            src="https://www.pricechopper.com/wp-content/uploads/2019/04/download-on-the-app-store-min.png"
            alt="Download on the App Store"
          />
        </a>
        <a
          className="ml-2"
          href="https://play.google.com/store/apps/details?id=com.mercatustechnologies.android.pc"
          target="_blank"
          rel="nonpener"
        >
          <img
            src="https://www.pricechopper.com/wp-content/uploads/2019/04/get-it-on-google-play-min.png"
            alt="Get it on Google Play"
          />
        </a>
      </Col>
    </Col>
  );
};

export default GetLatestDeals;
