import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Container,
  Col,
  Card,
  Row,
  Nav,
  Overlay,
  Tooltip,
} from 'react-bootstrap';
import { Button } from '@rewards/ui';
import { ProgressBar, Step } from 'react-step-progress-bar';
import { useUserInfo, useReservation } from '@rewards/data-provider';
import {
  AuthContext,
  AppContext,
  PartnerContext,
  ACTIONS,
} from '@rewards/contexts';

import MagazineSubscriptionConfirm from '../MagazineSubscriptionConfirm';
import MagazineSubscriptionSuccess from '../MagazineSubscriptionSuccess';
import BackNav from '../BackNav';

import Icon from '../../assets/svg';
import styleConsts from '../../util/styleConsts';
import { dateString, sortOnKey } from '../../util/formatters';
import { useResponsive, useMagazines } from '../../util/hooks';
import { PAGES, EVENTS, EVENT_LOCATION } from '../../analytics/events';
import MagazineContext from '../../contexts/MagazineContext';

import 'react-step-progress-bar/styles.css';
import './Magazines.scss';
import { checkIsDesktop } from '../../util/hooks/useResponsive';

const { useAuth } = AuthContext;
const { useApp } = AppContext;
const { usePartner } = PartnerContext;
const MAX_POINTS = 400;
const Magazines = () => {
  const { source, landingPath } = useContext(AppContext.AppContext);

  const { currencyExchanges } = useApp();
  const { partner } = usePartner();
  const { eMagazines } = useMagazines();
  const { isDesktop } = useResponsive();

  const [currencyExchange, setCurrencyExchange] = useState();

  useEffect(() => {
    source(PAGES.VIEWED_MAGAZINE_HOME_PAGE);
    Magazines.landingPath = landingPath;
    Magazines.LOCATION = EVENT_LOCATION.MAGAZINE_HOME_PAGE;
  }, []);

  useEffect(() => {
    if (partner && currencyExchanges && eMagazines) {
      const filteredCurrencyExchange = currencyExchanges.filter(
        (ce) =>
          ce.sourceCurrencyId === partner.currencyId &&
          ce.destCurrencyId === eMagazines?.[0]?.currencyId
      );
      setCurrencyExchange(filteredCurrencyExchange?.[0]);
    }
  }, [partner, currencyExchanges, eMagazines]);

  return (
    <>
      {isDesktop ? (
        <MagazinesDesktopView currencyExchange={currencyExchange} />
      ) : (
        <MagazinesMobileView currencyExchange={currencyExchange} />
      )}
    </>
  );
};

export default BackNav(Magazines);

const MagazinesDesktopView = ({ currencyExchange }) => (
  <>
    <Container className="d-flex justify-content-between">
      <Col md="7" className="p-0">
        <MagazinesMobileView />
      </Col>
      <Col md="4" className="sweepstake-actions-desktop p-0">
        <MagazinesAction currencyExchange={currencyExchange} />
      </Col>
    </Container>
  </>
);

const MagazinesMobileView = ({ currencyExchange }) => {
  const { unAuthToken } = useAuth();
  const { isDesktop, isMobile } = useResponsive();

  let imgSrc =
    'https://cdn.spinwheel.io/images/pricechopper/eMagazines/magazine-image-pa-3139.png';

  return (
    <>
      <Container className={`my-3 ${!isDesktop ? 'mobile' : ''}`}>
        {unAuthToken?.status === ACTIONS.STATUS.RESOLVED ? (
          <Col className="lc-magazines-title text-center">
            Link your AdvantEdge Card to start redeeming your points for
            magazine subscriptions!
          </Col>
        ) : (
          <Col className="lc-magazines-title text-center p-0">
            Redeem AdvantEdge Rewards points for a Magazine Subscription!
          </Col>
        )}
        <Col className="lc-magazines-sub-title text-center mt-3 mb-1">
          Get a print or digital <br className="just-mobile"></br>subscription
          now for {currencyExchange?.sourceDebitMax ?? MAX_POINTS} Rewards
          points
        </Col>
        <Col className="lc-magazines-sub-title text-center mb-3 mt-1">
          (Regularly 500 points)
        </Col>
        <Card
          className={`border-0 rounded ${
            isDesktop ? 'lc-desk-magazines-card' : 'lc-magazines-card'
          }`}
        >
          <Card.Img variant="top" className="p-2 p-md-0" src={imgSrc} />
          <Card.Body>
            <Card.Title className="lc-magazines-card-title">
              Magazine Subscription
            </Card.Title>
            <Card.Text className="lc-magazines-card-text">
              Price Chopper and Market 32 give you immediate access to the most
              popular magazine titles, both print and digital! Redeem your
              AdvantEdge Rewards points toward a voucher code for a print or
              digital subscription to the magazine of your choice. Choose from
              nearly fifty titles including Time, Sports Illustrated,
              Cosmopolitan and many more! No credit card required, and your
              subscription order will never be auto-renewed.
            </Card.Text>
            <ViewMagazineList className="lg:hidden" />
          </Card.Body>
        </Card>
      </Container>
      {!isDesktop && <MagazinesAction currencyExchange={currencyExchange} />}
    </>
  );
};

const MagazinesAction = ({ currencyExchange }) => {
  const { isDesktop } = useResponsive();
  const { data: user } = useUserInfo();

  const target = useRef(null);

  const [availablePoints, setAvailablePoints] = useState(0);
  const [magazineSubscription, setMagazineSubscription] = useState(0);
  const [showMagSubscription, setShowMagSubscription] = useState(false);
  const [showMagSubscriptionSuccess, setShowMagSubscriptionSuccess] =
    useState(false);
  const [showTooltip, setTooltip] = useState(false);

  const queryString = new URL(document.location).searchParams;
  const reservationId = queryString.get('reservationId');
  // console.log(reservationId)
  const { data: reservationInfo } = useReservation(reservationId);

  useEffect(() => {
    if (reservationInfo) {
      // console.log(reservationInfo)
      setShowMagSubscription(true);
    }
  }, [reservationInfo]);

  useEffect(() => {
    if (user && currencyExchange) {
      const points = Math.min(
        user?.data?.partnerAccounts[0]?.accountBalance,
        currencyExchange?.sourceDebitMax
      );
      setAvailablePoints(points);
      setMagazineSubscription(
        Math.floor(points / currencyExchange?.sourceDebitMax)
      );
    }
  }, [user, currencyExchange]);

  useEffect(() => {
    if (showTooltip) {
      const timer = setTimeout(() => {
        setTooltip(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [showTooltip]);

  const handleSubscribeConfirm = (isMoneyTransferFailed = false) => {
    if (!isMoneyTransferFailed) {
      if (
        availablePoints <
        (currencyExchange?.sourceDebitMax ?? Number.MAX_SAFE_INTEGER)
      ) {
        const scrollTopEle = document.getElementById('scrollToPosition');
        scrollTopEle.scrollIntoView();
        setTooltip(true);
      } else {
        setShowMagSubscription(!showMagSubscription);
        setTooltip(false);
      }
    } else {
      setShowMagSubscription(!showMagSubscription);
    }
  };

  const handleSubscribeSuccess = () =>
    setShowMagSubscriptionSuccess(!showMagSubscriptionSuccess);

  return (
    <>
      <MagazineContext.Provider value={{ availablePoints, currencyExchange }}>
        <Container
          className={`${!isDesktop && 'lc-magazines-action-container'} pb-5`}
        >
          <Col
            className={`${isDesktop ? 'pt-5' : ''}`}
            id="scrollToPosition"
          ></Col>
          <Col className={`rounded ${isDesktop && 'lc-magazines-desk-action'}`}>
            <Col className="text-center pt-2">
              <Icon
                className="inline"
                icon="balance-bolt"
                width="45"
                height="45"
              />
              <span
                className={`lc-magazines-poins ${
                  !isDesktop ? 'mr-2' : ''
                } align-middle`}
                ref={target}
              >
                {availablePoints}
              </span>
            </Col>
            <Overlay
              target={target.current}
              show={showTooltip}
              placement="top"
              delay={{ show: 250, hide: 400 }}
            >
              {(props) => (
                <Tooltip className="lc-magazines-points-tooltip" {...props}>
                  {currencyExchange?.sourceDebitMax || MAX_POINTS} points
                  required to subscribe
                </Tooltip>
              )}
            </Overlay>
            <Col className="lc-magazines-sub text-center mt-3">
              {magazineSubscription} Magazine Subscription
            </Col>
            <MagazineSlider
              availablePoints={availablePoints}
              currencyExchange={currencyExchange}
            />
          </Col>
          {isDesktop ? (
            <>
              <Col className="border-top my-2"></Col>
              <Col className="flex flex-column p-0 py-2 ">
                <ViewMagazineList />
                <MagazineSubscriptionConfirm
                  show={showMagSubscription}
                  handleSubscribeConfirm={handleSubscribeConfirm}
                  handleSubscribeSuccess={handleSubscribeSuccess}
                />
                <PreviousCode />
              </Col>
            </>
          ) : (
            <>
              <Col className="slot-margin"></Col>
              <ViewMagazineList className={'hidden lg:block'} />
              <MagazineSubscriptionConfirm
                show={showMagSubscription}
                handleSubscribeConfirm={handleSubscribeConfirm}
                handleSubscribeSuccess={handleSubscribeSuccess}
              />
              <PreviousCode />
            </>
          )}
          <MagazineSubscriptionSuccess
            handleSubscribeSuccess={handleSubscribeSuccess}
            show={showMagSubscriptionSuccess}
          />
        </Container>
      </MagazineContext.Provider>
    </>
  );
};

const PreviousCode = () => {
  const { envInfo } = useContext(AuthContext.AuthContext);

  const { eMagazines } = useMagazines();

  const [sortedCodes, setSortedCodes] = useState([]);

  useEffect(() => {
    if (eMagazines) {
      const sortedCodes = sortOnKey(eMagazines?.[0]?.codes, 'updatedOn');
      setSortedCodes(sortedCodes);
    }
  }, [eMagazines]);

  return (
    <Col className="lc-magazines-codes my-2 p-0">
      <Col className="p-0 my-2 lc-magazines-codes-title text-center">
        Previous Codes
      </Col>
      <Col className="lc-magazines-codes-list p-0">
        <Nav className="flex-column my-2">
          {sortedCodes?.map(({ updatedOn, code }) => (
            <Col className="p-0" key={code}>
              <Nav.Item className="bg-white my-1 rounded p-2">
                <Nav.Link
                  href={`${envInfo?.magazinesPath}?v=${code}`}
                  target="_blank"
                  className="!flex w-full lc-magazines-code-info"
                >
                  <div className="px-4">{dateString(updatedOn)}</div>
                  <div>{code}</div>
                </Nav.Link>
              </Nav.Item>
            </Col>
          ))}
        </Nav>
      </Col>
    </Col>
  );
};

const step = 100;
const MagazineSlider = ({ availablePoints, currencyExchange }) => {
  return (
    <Col className="my-5 px-5">
      <ProgressBar
        percent={
          (availablePoints / (currencyExchange?.sourceDebitMax ?? MAX_POINTS)) *
          100
        }
        filledBackground={styleConsts.appleGreen}
      >
        {[
          ...Array(
            (currencyExchange?.sourceDebitMax ?? MAX_POINTS) / step + 1
          ).keys(),
        ].map((x, i) => (
          <Step transition="scale" key={i}>
            {() => (
              <div className="pt-2">
                <div
                  className={`px-1 rounded-circle ${
                    x === 0
                      ? 'lc-magazines-step-1 invisible'
                      : 'lc-magazines-step-3'
                  } ${
                    x * step === currencyExchange?.sourceDebitMax &&
                    'lc-magazine-last-circle-white'
                  }`}
                >
                  <div
                    className={`${
                      availablePoints >= x * step
                        ? 'rounded-circle mx-auto  mt-4 lc-magazines-step-1'
                        : 'rounded-circle mx-auto mt-4 lc-magazines-step-2'
                    } ${
                      x * step === currencyExchange?.sourceDebitMax &&
                      'lc-magazines-step-last flex-justify-content-center'
                    }`}
                  >
                    {/* {x * step === currencyExchange?.sourceDebitMax && <Icon icon='newspaperClipping' width='16' height='16' className='block m-auto h-100' />} */}
                  </div>
                </div>
                {!!x && (
                  <div className="mt-2 ck-progress-bar-text lc-magazines-slider-text text-center">
                    {x * step}
                  </div>
                )}
              </div>
            )}
          </Step>
        ))}
      </ProgressBar>
    </Col>
  );
};

const ViewMagazineList = ({ className }) => {
  const { envInfo } = useContext(AuthContext.AuthContext);
  const { track } = useContext(AppContext.AppContext);
  const { isDesktop } = useResponsive();

  const handleListClick = () => {
    track(EVENTS.CLICKED_MAGAZINE_LIST, {
      ui_version: import.meta.env.APP_VERSION,
    });
  };
  return (
    <Button
      intent="secondary"
      className={`mb-2 ${className}`}
      width="full"
      as="a"
      block
      onClick={handleListClick}
      href={envInfo?.allMagazineListPath}
      target="_blank"
    >
      View Magazine List
    </Button>
  );
};
