import { toTitleCase } from '../util/formatters';

export const EVENTS = {
  SWEEPSTAKES_BACK_CLICKED:
    'Redemption - Sweepstakes - Enter Sweeptakes back button clicked',
  SWEEPSTAKES_CLICKED: 'Redemption - Sweepstakes - Sweepstake Button Clicked',
  SWEEPSTAKES_VIEWED: 'Redemption - Sweepstakes - Sweepstake Details Viewed',
  SWEEPSTAKES_BOUGHT: 'Redemption - Sweepstakes - Enter Now Button Clicked',
  SWEEPSTAKES_OFFICIAL_RULES_CLICKED:
    'Redemption - Sweepstakes - Sweepstakes Offical Rules Button Clicked',
  SWEEPSTAKES_PRIZE_VIEW_DETAILS_CLICKED:
    'Sweepstakes Prize View Details Clicked',
  SWEEPSTAKES_ENTER_NOW_CLICKED:
    'Redemption - Sweepstakes - Enter Now Button Clicked',
  SWEEPSTAKES_DONE_CLICKED: 'Redemption - Sweepstakes - Done Button Clicked',
  SWEEPSTAKES_CLOSE_CLICKED: 'Sweepstakes Close Clicked',
  SWEEPSTAKES_SUBMIT_CLICKED: 'Sweepstakes Submit Clicked',
  SWEEPSTAKES_SURVEY_MONKEY_LINK_CLICKED:
    'Sweepstakes Survey Monkey Link Clicked',
  LOG_IN_REGISTER_CLICKED: 'Log In / Register Clicked',
  DAILY_SURPRISES_SHARE_BUTTON_CLICKED:
    'Earning - Daily surPrize - Share Button Clicked',
  DAILY_SURPRISES_CLOSE_BUTTON_CLICKED:
    'Earning - Daily surPrize - Close Button Clicked',
  SWEEPSTAKES_DONATE_CLICKED:
    'Redemption - Sweepstakes - Donate Button Clicked',
  SWEEPSTAKES_PRODUCTS_CLICKED:
    'Redemption - Sweepstakes - Products Button Clicked',
  SWEEPSTAKES_STUDENT_DEBT_CLICKED:
    'Redemption - Sweepstakes - Student Debt Button Clicked',
};

export const PAGES = {
  MULTI_SWEEPSTAKES: 'Multi Sweepstakes',
  SWEEPSTAKES: 'Sweepstakes',
  SWEEPSTAKES_BONUS_POINTS: 'Sweepstakes Bonus Points',
  SWEEPSTAKES_OFFICIAL_RULE_MODAL: 'Sweepstakes Official Rule Modal',
};

export const EVENT_LOCATION = {
  MULTI_SWEEPSTAKES_LANDING_PAGE: 'Multi Sweepstakes Landing Page',
  SWEEPSTAKES_LANDING_PAGE: 'Sweepstakes Landing Page',
  SWEEPSTAKES_PRIZE_MODAL_BOX: 'Sweepstakes Prize Modal Box',
  SWEEPSTAKES_CONFIRM_MODAL: 'Sweepstakes Confirm Modal',
  SWEEPSTAKES_BONUS_POINTS: PAGES.SWEEPSTAKES_BONUS_POINTS,
  SWEEPSTAKES_OFFICIAL_RULE_MODAL: PAGES.SWEEPSTAKES_OFFICIAL_RULE_MODAL,
};

export const ClickedEvents = (navItem = '') => {
  return `${PAGES.SWEEPSTAKES} ${toTitleCase(navItem)} Clicked`;
};
