import { useContext } from 'react';
import { MagazinesContext } from '../../contexts/MagazinesContext';

const useMagazines = () => {
  const magazinesState = useContext(MagazinesContext);
  if (!magazinesState) {
    throw new Error('Undefined magazines state!');
  }
  const { eMagazinesData } = magazinesState;

  return {
    eMagazines: eMagazinesData?.eMagazines,
  };
};

export default useMagazines;
