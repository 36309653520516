import React, { useState, useEffect, useContext } from 'react';
import { Navbar, Nav, Modal, Col, Container, Button } from 'react-bootstrap';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { tv } from '@rewards/ui';
import { Link, useHistory } from 'react-router-dom';
import { AuthContext, AppContext } from '@rewards/contexts';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { useUserInfo } from '@rewards/data-provider';
import Icon from '../../assets/svg';
import { useLoyaltyConnect, useResponsive } from '../../util/hooks';
import { EVENTS } from '../../analytics/events';

import AuthenticaUserSSO from '../AuthenticaUserSSO';
import Login from '../Login';

const { useAuth } = AuthContext;

import './TopNav.scss';
import { NotificationBanner } from './NotificationBanner';

// TODO: Remove ! styles
const styles = tv({
  slots: {
    navRoot:
      'relative flex-nowrap items-center flex desktop-top-navbar pch-header',
    list: 'flex my-0 items-center',
    item: 'desktop-dropdown-container relative dropdown-container-menu',
    trigger: `l-desk-user-name nav-icon text-base xlg:text-navbar pl-[6px] pr-[10px] text-white hover:!text-light-brown`,
    content: 'content',
    contentList: `w-max text-center absolute bg-dark-brown top-full left-0 flex flex-col items-center z-10`,
    locationLink: `l-desk-user-name text-base xlg:text-navbar pl-[6px] pr-[10px] text-white hover:!text-light-brown hover:no-underline`,
    link: 'flex leading-9 px-[1.25rem] text-base hover:no-underline hover:!text-light-brown text-white py-[3px]',
  },
});

function TopNav() {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const {
    code,
    loanServicerId,
    isSSOEnabled,
    isUserAuthenticated,
    ...otherProps
  } = useAuth();
  const { sidenav, sidenavSecure } = useLoyaltyConnect();
  const { isDesktop } = useResponsive();
  const history = useHistory();
  const { data: userInfo } = useUserInfo() || {};
  const user = userInfo?.data;

  const { isUnAuthorizsedFlow, envInfo } = useContext(AuthContext.AuthContext);
  const { track } = useContext(AppContext.AppContext);

  const [show, showModal] = useState(false);
  const [userName, setUserName] = useState('');

  const handleClose = () => showModal(false);

  useEffect(() => {
    if (isSSOEnabled && accounts?.length && user) {
      const currentAccount = accounts?.[0];
      const {
        idTokenClaims: { given_name, family_name },
      } = currentAccount;
      if (!otherProps.isMigrated) {
        setUserName(`${given_name} ${family_name}`);
      } else {
        setUserName(user?.partnerAccounts?.[0]?.profile?.name);
      }
    }
  }, [accounts, isSSOEnabled, user, otherProps.isMigrated]);

  const handleResetPassword = () => {
    track(EVENTS.RESET_PASSWORD_CLICKED, {
      ui_version: import.meta.env.APP_VERSION,
    });
    const index = accounts.findIndex(
      ({ idTokenClaims }) =>
        idTokenClaims?.acr === envInfo?.policies?.signUpPolicy
    );
    instance.config.auth.authority =
      instance.config.auth.resetPasswordAuthority;
    instance.loginRedirect({
      account: accounts?.[index],
    });
  };

  const handleLogout = () => {
    track(EVENTS.LOG_OUT_CLICKED, { ui_version: import.meta.env.APP_VERSION });
    const index = accounts.findIndex(
      ({ idTokenClaims }) =>
        idTokenClaims?.acr === envInfo?.policies?.signUpPolicy
    );
    instance.logoutRedirect({
      account: accounts?.[index],
    });
  };

  const handleProfile = () => {
    track(EVENTS.LOYALTY_CONNECT_MY_REWARDS_PROFILE_CLICKED, {
      ui_version: import.meta.env.APP_VERSION,
    });
    history.push('/profile');
  };

  const handleShopOnline = () => {
    track(EVENTS.LOYALTY_CONNECT_MY_REWARDS_SHOP_ONLINE_CLICKED, {
      ui_version: import.meta.env.APP_VERSION,
    });
    window.open('https://shop.pricechopper.com/', '_blank');
  };

  const handleDropDown = () => {
    track(EVENTS.LOYALTY_CONNECT_DROP_DOWN_MENU_CLICKED, {
      ui_version: import.meta.env.APP_VERSION,
    });
  };

  const handleClick = (link) => {
    track(EVENTS.LOYALTY_CONNECT_DROP_DOWN_MENU_CLICKED, {
      ui_version: import.meta.env.APP_VERSION,
      link,
    });
    window.open(link, '_blank');
  };

  const handleProfileAndClose = () => {
    handleProfile();
    handleClose();
  };

  // TODO: Replace px-[1.25rem] with px-5 after Bootstrap is removed
  const {
    navRoot,
    list,
    item,
    trigger,
    content,
    contentList,
    link,
    locationLink,
  } = styles({});
  return (
    <Container
      fluid
      className="px-0 pb-2 loyalty-connect w-full mx-auto relative"
    >
      <NotificationBanner />
      {isDesktop ? (
        <NavigationMenu.Root className={navRoot()}>
          <a
            href="https://pricechopper.com/"
            target="_blank"
            className="p-0 pr-2"
          >
            <img
              fluid
              src="https://cdn.spinwheel.io/images/tcc/pricechopper_logo.png"
              className="vendor-logo mr-0 max-w-full"
            />
          </a>
          <div className="mr-3">
            <a href="https://www.pricechopper.com/your-weekly-flyer/">
              <img
                fluid
                className="pch-header--weekly-ad max-w-full"
                src="https://www.pricechopper.com/wp-content/uploads/2021/04/2021-PCM32-Weekly-Flyer-min.png"
              />
            </a>
          </div>

          <NavigationMenu.List className={list()}>
            <NavigationMenu.Item className={item()}>
              <NavigationMenu.Trigger
                className={trigger()}
                onClick={handleDropDown}
              >
                Shop Now
              </NavigationMenu.Trigger>
              {/* <Dropdown.Toggle variant='default' title='Shop Now' onClick={handleDropDown}>
                Shop Now
              </Dropdown.Toggle> */}
              <NavigationMenu.Content className={content()}>
                <ul className={contentList()}>
                  <NavigationMenu.Link
                    title="Product Catalog"
                    href="https://shop.pricechopper.com/shop/categories?shopping_context=pickup&amp;_ga=2.106694283.986502910.1633376964-535485991.1633376964"
                    target="_blank"
                    className={link()}
                    onClick={() =>
                      handleClick(
                        'https://shop.pricechopper.com/shop/categories?shopping_context=pickup&amp;_ga=2.106694283.986502910.1633376964-535485991.1633376964'
                      )
                    }
                  >
                    Product Catalog
                  </NavigationMenu.Link>
                  <NavigationMenu.Link
                    title="Shop Pickup"
                    href="https://shop.pricechopper.com/shop/categories?shopping_context=pickup"
                    target="_blank"
                    className={link()}
                    onClick={() =>
                      handleClick(
                        'https://shop.pricechopper.com/shop/categories?shopping_context=pickup'
                      )
                    }
                  >
                    Shop Pickup
                  </NavigationMenu.Link>
                  <NavigationMenu.Link
                    title="Shop Delivery"
                    href="https://shop.pricechopper.com/shop/categories?shopping_context=delivery"
                    target="_blank"
                    className={link()}
                    onClick={() =>
                      handleClick(
                        'https://shop.pricechopper.com/shop/categories?shopping_context=delivery'
                      )
                    }
                  >
                    Shop Delivery
                  </NavigationMenu.Link>
                </ul>
              </NavigationMenu.Content>
            </NavigationMenu.Item>
            <NavigationMenu.Item className={item()}>
              <NavigationMenu.Trigger
                className={trigger()}
                title="Savings"
                onClick={handleDropDown}
              >
                Catering
              </NavigationMenu.Trigger>
              <NavigationMenu.Content className={content()}>
                <ul className={contentList()}>
                  <NavigationMenu.Link
                    className={link()}
                    href="https://takeoutmeals.pricechopper.com/"
                    target="_blank"
                    onClick={() =>
                      handleClick('https://takeoutmeals.pricechopper.com/')
                    }
                  >
                    <span title="Catering - Order now">Order now</span>
                  </NavigationMenu.Link>
                  <NavigationMenu.Link
                    className={link()}
                    href="https://takeoutmeals.pricechopper.com/items/category/takeout-meals?returnurl=%2f"
                    target="_blank"
                    onClick={() =>
                      handleClick(
                        'https://takeoutmeals.pricechopper.com/items/category/takeout-meals?returnurl=%2f'
                      )
                    }
                  >
                    <span title="Catering - Takeout Meals">Takeout Meals</span>
                  </NavigationMenu.Link>
                  <NavigationMenu.Link
                    className={link()}
                    href="https://www.pricechopper.com/services/catering/"
                    target="_blank"
                    onClick={() =>
                      handleClick(
                        'https://www.pricechopper.com/services/catering/'
                      )
                    }
                  >
                    <span title="Catering - Learn more">Learn More</span>
                  </NavigationMenu.Link>
                </ul>
              </NavigationMenu.Content>
            </NavigationMenu.Item>
            <NavigationMenu.Item className={item()}>
              <NavigationMenu.Trigger
                className={trigger()}
                title="Savings"
                onClick={handleDropDown}
              >
                Savings
              </NavigationMenu.Trigger>
              <NavigationMenu.Content className={content()}>
                <ul className={contentList()}>
                  <NavigationMenu.Link
                    className={link()}
                    href="https://www.pricechopper.com/rewards"
                    target="_blank"
                    onClick={() =>
                      handleClick('https://www.pricechopper.com/rewards')
                    }
                  >
                    <span title="AdvantEdge Rewards">AdvantEdge Rewards</span>
                  </NavigationMenu.Link>
                  <NavigationMenu.Link
                    className={link()}
                    href="https://shop.pricechopper.com/shop/coupons"
                    target="_blank"
                    onClick={() =>
                      handleClick('https://shop.pricechopper.com/shop/coupons')
                    }
                  >
                    <span title="eCoupons">eCoupons</span>
                  </NavigationMenu.Link>
                  <NavigationMenu.Link
                    className={link()}
                    href="https://www.pricechopper.com/your-weekly-flyer/"
                    target="_blank"
                    onClick={() =>
                      handleClick(
                        'https://www.pricechopper.com/your-weekly-flyer/'
                      )
                    }
                  >
                    <span title="Weekly Flyer">Weekly Flyer</span>
                  </NavigationMenu.Link>
                </ul>
              </NavigationMenu.Content>
            </NavigationMenu.Item>
            <NavigationMenu.Item className={item()}>
              <NavigationMenu.Trigger
                className={trigger()}
                variant="default"
                title="Pharmacy"
                onClick={handleDropDown}
              >
                Pharmacy
              </NavigationMenu.Trigger>
              <NavigationMenu.Content
                className={content()}
                align="left"
                renderOnMount={true}
              >
                <ul className={contentList()}>
                  <NavigationMenu.Link
                    className={link()}
                    href="https://www.pricechopper.com/pharmacy/"
                    target="_blank"
                    onClick={() =>
                      handleClick('https://www.pricechopper.com/pharmacy/')
                    }
                  >
                    <span title="Pharmacy Home">Pharmacy Home</span>
                  </NavigationMenu.Link>
                  <NavigationMenu.Link
                    className={link()}
                    href="https://pricechopper.medrefill.com/pcweb/#/home#%2F"
                    target="_blank"
                    onClick={() =>
                      handleClick(
                        'https://pricechopper.medrefill.com/pcweb/#/home#%2F'
                      )
                    }
                  >
                    <span title="Prescriptions">Prescriptions</span>
                  </NavigationMenu.Link>
                  <NavigationMenu.Link
                    className={link()}
                    href="https://www.pricechopper.com/covidvaccine/"
                    target="_blank"
                    onClick={() =>
                      handleClick('https://www.pricechopper.com/covidvaccine/')
                    }
                  >
                    <span title="COVID-19 Vaccine">COVID-19 Vaccine</span>
                  </NavigationMenu.Link>
                  <NavigationMenu.Link
                    className={link()}
                    href="https://www.pricechopper.com/health-wellness/"
                    target="_blank"
                    onClick={() =>
                      handleClick(
                        'https://www.pricechopper.com/health-wellness/'
                      )
                    }
                  >
                    <span title="Health &amp; Wellness">
                      Health &amp; Wellness
                    </span>
                  </NavigationMenu.Link>
                </ul>
              </NavigationMenu.Content>
            </NavigationMenu.Item>
            <NavigationMenu.Item className={item()}>
              <div className="l-desk-user-name">
                <NavigationMenu.Link
                  className={locationLink({
                    className: 'text-base xlg:text-navbar cursor-pointer',
                  })}
                  variant="default"
                  title="Locations"
                  onClick={() =>
                    handleClick('https://www.pricechopper.com/stores')
                  }
                >
                  Locations
                </NavigationMenu.Link>
              </div>
            </NavigationMenu.Item>
          </NavigationMenu.List>

          {!isUnAuthorizsedFlow &&
            isSSOEnabled &&
            (isUserAuthenticated ? (
              <NavigationMenu.Item className={item({ className: 'ml-auto' })}>
                <Icon icon="user-profile-transparent" width="23" height="20" />
                <NavigationMenu.Trigger
                  className={trigger()}
                  title={userName}
                  onClick={handleDropDown}
                >
                  {userName}
                </NavigationMenu.Trigger>
                <NavigationMenu.Content
                  className={content()}
                  align="right"
                  renderOnMount={true}
                >
                  <ul
                    className={contentList({
                      className: `bg-white right-0 left-[auto]  `,
                    })}
                  >
                    <NavigationMenu.Link
                      className={link({
                        className:
                          'px-6 py-3.5 text-dark-grey hover:text-dark-grey ',
                      })}
                      // className='l-desk-logout-item px-6 hover:no-underline hover:text-dark-grey text-dark-grey py-3.5'
                      onClick={handleProfile}
                      title="My Profile"
                    >
                      <Icon icon="user-outline" width="29" height="34" />
                      <span className="pl-4 ml-2">My Profile</span>
                    </NavigationMenu.Link>
                    <NavigationMenu.Link
                      className={link({
                        className:
                          'px-6 py-3.5 text-dark-grey hover:text-dark-grey ',
                      })}
                      onClick={handleShopOnline}
                      title="Shop Online"
                    >
                      <Icon icon="shop profile" width="29" height="34" />
                      <span className="pl-4 ml-2">Shop Online</span>
                    </NavigationMenu.Link>
                    <NavigationMenu.Link
                      className={link({
                        className:
                          'px-6 py-3.5 text-dark-grey hover:text-dark-grey ',
                      })}
                      onClick={handleLogout}
                      title="Logout"
                    >
                      <Icon icon="logout" width="29" height="34" />
                      <span className="pl-4 ml-2">Logout</span>
                    </NavigationMenu.Link>
                  </ul>
                  {/* <Dropdown.Item className='l-desk-logout-item' onClick={handleResetPassword}>Reset Password</Dropdown.Item> */}
                </NavigationMenu.Content>
              </NavigationMenu.Item>
            ) : (
              <Col className="ml-auto text-right">
                <Login />
              </Col>
            ))}
        </NavigationMenu.Root>
      ) : (
        <Col className="mobile-nav py-0">
          <div className="top-row flex">
            <span onClick={() => showModal(true)} className="bars hover">
              <Icon icon="bars" width="20" height="16" />
            </span>
            {/* <Link to={getLandingPath()} className='mx-auto'> */}
            {/* <Icon icon='advantEdge-rewards' width='96' height='30' /> */}
            <a
              href="https://pricechopper.com/"
              target="_blank"
              className="p-0 mr-0 mx-auto"
            >
              <img
                src="https://cdn.spinwheel.io/images/tcc/pricechopper_logo.png"
                className="vendor-logo mr-0 w-auto max-w-full"
                height="30"
              />
            </a>
            {/* </Link> */}
            <div className="nav-weekly-mobile-container">
              <a href="https://www.pricechopper.com/your-weekly-flyer/">
                <img
                  className="pch-header--weekly-ad max-w-full"
                  src="https://www.pricechopper.com/wp-content/uploads/2021/04/2021-PCM32-Weekly-Flyer-min.png"
                  width="160"
                  height="58"
                />
              </a>
            </div>
            <AuthenticaUserSSO />
          </div>
          <Modal
            show={show}
            onHide={handleClose}
            scrollable
            dialogClassName="h-100 m-0 mh-100 p-0"
            contentClassName="rounded-0"
            style={{ width: '300px' }}
            className="h-100 sideNavModal"
          >
            <Modal.Header className="mobile-navbar-menu-header" closeButton>
              <Nav.Item className="mobile-navbar-menu-header-items">
                <Nav.Link href="https://www.pricechopper.com" target="_blank">
                  <img
                    fluid
                    src="https://cdn.spinwheel.io/images/tcc/pricechopper_logo.png"
                    className="vendor-logo max-w-full"
                    height={60}
                  />
                </Nav.Link>
              </Nav.Item>
              <Modal.Title className="mobile-navbar-menu-header-title pl-3">
                Price Chopper Market 32
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Nav className="mr-auto">
                <Nav.Item className="text-capitalize w-100">
                  <Nav.Link to="/" as={Link}>
                    Rewards
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="text-capitalize w-100">
                  <Nav.Link href="https://www.pricechopper.com/stores/">
                    Locations
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="text-capitalize w-100">
                  <Nav.Link href="https://shop.pricechopper.com">
                    Shop Now
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="text-capitalize w-100">
                  <Nav.Link href="https://takeoutmeals.pricechopper.com/">
                    Catering
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="text-capitalize w-100">
                  <Nav.Link href="https://shop.pricechopper.com/shop/flyer">
                    Weekly Flyer
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item className="w-100">
                  <Nav.Link href="https://shop.pricechopper.com/shop/coupons">
                    eCoupons
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="text-capitalize w-100">
                  <Nav.Link href="https://www.pricechopper.com/pharmacy/">
                    Pharmacy
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="text-capitalize w-100">
                  <Nav.Link href="#" onClick={handleProfileAndClose}>
                    My Profile
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              {/* <Nav className='mr-auto'>
                  {sidenav?.items.map(navItem => (
                    <Nav.Item className='text-capitalize w-100' key={navItem.ID}>
                      <Nav.Link href={navItem.url}>{navItem.title}</Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav> */}
              {/* <Nav className='submodal-content'>
                  {sidenavSecure?.items.map(navItem => (
                    <Nav.Link href={navItem.url} key={navItem.ID}>{navItem.title}</Nav.Link>
                  ))}
                </Nav> */}
            </Modal.Body>
            {isSSOEnabled && isAuthenticated && (
              <Modal.Footer className="p-0 border-0 rounded-0">
                <Button
                  className="l-logout w-100 m-0 rounded-0"
                  variant="default"
                  onClick={handleLogout}
                >
                  Log out
                </Button>
                <Button
                  className="l-logout w-100 m-0 rounded-0"
                  variant="default"
                  onClick={handleResetPassword}
                >
                  Reset Password
                </Button>
              </Modal.Footer>
            )}
          </Modal>
        </Col>
      )}
    </Container>
  );
}

export default TopNav;
