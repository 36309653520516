import React, { useState, useContext, useEffect } from 'react';
import { Modal, Alert, ListGroup, Col } from 'react-bootstrap';
import { Button } from '@rewards/ui';
import { ACTIONS, AppContext, AuthContext } from '@rewards/contexts';

import {
  useToolsForSchools,
  useResponsive,
  useSchool,
} from '../../../util/hooks';
import Icon from '../../../assets/svg';
import { PAGES, EVENTS, EVENT_LOCATION } from '../../../analytics/events';
import styleConsts from '../../../util/styleConsts';
import { SchoolContext } from '../../../contexts/SchoolContexts';

import ModalCloseBtn from '../ModalCloseBtn';

import './ConfirmSchoolModal.scss';

const ConfirmSchoolModal = ({
  show,
  handleClose,
  handleClearSignUpInputs,
  handleDontSeeSchoolModal,
  handleBackClick,
  ...props
}) => {
  const { checkUserSession } = useContext(AuthContext.AuthContext);
  const { source, track } = useContext(AppContext.AppContext);
  const { updateSelectedSchool } = useContext(SchoolContext);

  const { schools } = useToolsForSchools();
  const { connectSchoolStatus } = useSchool();
  const { isDesktop } = useResponsive();

  const [showAlert, setShowAlert] = useState(false);
  const [schoolIndex, setSchoolIndex] = useState();

  useEffect(() => {
    if (show) {
      source(PAGES.TOOLS_FOR_SCHOOLS_CONFIRM_SCHOOL_MODAL, {
        ui_version: import.meta.env.APP_VERSION,
      });
    }
  }, [show]);

  const resetStates = () => {
    setSchoolIndex();
    setShowAlert(false);
  };

  const handleCloseModal = (shouldReset = true) => {
    resetStates();
    handleClose(shouldReset);
  };

  const handleConfirmSchool = () => {
    if (schoolIndex === undefined) {
      setShowAlert(true);
      return;
    }
    const _id =
      props.schoolSearchId === 'all-school'
        ? schools?.[schoolIndex]._id
        : props.filteredSchools?.[schoolIndex].id;
    const name =
      props.schoolSearchId === 'all-school'
        ? schools?.[schoolIndex].name
        : props.filteredSchools?.[schoolIndex].name;
    track(EVENTS.TOOLS_FOR_SCHOOLS_CONFIRM_SCHOOL_CLICKED, {
      id: _id,
      name,
      location: EVENT_LOCATION.TOOLS_FOR_SCHOOLS_CONFIRM_SCHOOL_MODAL,
      ui_version: import.meta.env.APP_VERSION,
    });
    updateSelectedSchool(
      props.schoolSearchId === 'all-school'
        ? schools?.[schoolIndex]._id
        : props.filteredSchools?.[schoolIndex]?.id
    );
    handleCloseModal(true);

    // Hotfix : PA-1057 - Commenting the below code temporarily as checkUserSession() is failing for the case when the user does not have an price chopper account and is directly trying to connect to a school through tfs sign up flow
    /*
    checkUserSession()
      .then(() => {
        updateSelectedSchool(props.schoolSearchId === 'all-school' ? schools?.[schoolIndex]._id : props.filteredSchools?.[schoolIndex]?.id)
      })
      .finally(() => {
        handleCloseModal(true)
      })
    */
  };

  const handleSelect = (item) => {
    setShowAlert(false);
    setSchoolIndex(item);
  };

  const handleDontSeeSchoolShowModal = () => {
    track(EVENTS.TOOLS_FOR_SCHOOLS_DONT_SEE_YOUR_SCHOOL_CLICKED, {
      location: EVENT_LOCATION.TOOLS_FOR_SCHOOLS_CONFIRM_SCHOOL_MODAL,
      ui_version: import.meta.env.APP_VERSION,
    });
    handleDontSeeSchoolModal();
    handleCloseModal(false);
  };

  const handleBackClicked = () => {
    track(EVENTS.TOOLS_FOR_SCHOOLS_BACK_CLICKED, {
      location: EVENT_LOCATION.TOOLS_FOR_SCHOOLS_CONFIRM_SCHOOL_MODAL,
      ui_version: import.meta.env.APP_VERSION,
    });
    resetStates();
    handleBackClick();
  };

  const handleCloseConfirmModal = () => {
    track(EVENTS.TOOLS_FOR_SCHOOLS_CLOSE_CLICKED, {
      location: EVENT_LOCATION.TOOLS_FOR_SCHOOLS_CONFIRM_SCHOOL_MODAL,
      ui_version: import.meta.env.APP_VERSION,
    });
    handleClearSignUpInputs?.();
    handleCloseModal(true);
  };

  return (
    <Modal
      show={show}
      backdrop="static"
      keyboard={false}
      centered={true}
      dialogClassName={`tfs-confirm-school-modal rounded' ${
        isDesktop ? '' : 'tfs-mobile-dialog mx-auto'
      }`}
      contentClassName="border-0 tfs-confirm-content-modal"
    >
      <Modal.Header className="border-0">
        <div className="flex flex-column">
          <Modal.Title className="tfs-confirm-school-modal-title">
            {/* <Button className='mr-2 p-0' variant='default' onClick={handleBackClicked}>
              <Icon
                icon='angle-left'
                width='9'
                height='17'
                color={styleConsts.cobalt}
              />
            </Button> */}
            Select your school
          </Modal.Title>
          <Alert show={showAlert} variant="warning" className="mt-2 mb-0">
            Please select your school.
          </Alert>
        </div>
        <ModalCloseBtn handleClose={handleCloseConfirmModal} />
      </Modal.Header>
      <Modal.Body
        className="tfs-confirm-school-body"
        style={{ maxHeight: '65vh', overflowY: 'auto' }}
      >
        <ListGroup onSelect={handleSelect}>
          {props.schoolSearchId === 'get-school'
            ? props.filteredSchools?.map((school, index) => (
                <ListGroup.Item
                  className={`tfs-confirm-list bg-white my-1 rounded ${
                    schoolIndex === index ? 'tfs-confirm-selectIndex' : ''
                  }`}
                  eventKey={index}
                  key={index}
                >
                  <Col className="tfs-confirm-list-name p-0">
                    {school?.name}
                  </Col>
                  <Col className="tfs-confirm-list-place ml-2 p-0">{`${school?.city}, ${school?.state}`}</Col>
                </ListGroup.Item>
              ))
            : schools?.map((school, index) => (
                <ListGroup.Item
                  className={`tfs-confirm-list bg-white my-1 rounded ${
                    schoolIndex === index ? 'tfs-confirm-selectIndex' : ''
                  }`}
                  eventKey={index}
                  key={index}
                >
                  <Col className="tfs-confirm-list-name p-0">
                    {school?.name}
                  </Col>
                  <Col className="tfs-confirm-list-place ml-2 p-0">{`${school?.address?.city}, ${school?.address?.state}`}</Col>
                </ListGroup.Item>
              ))}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer className="bg-white tfs-confirm-footer">
        {schools?.length > 0 && (
          <Button
            // className='tfs-confirm-school-modal-start-btn rounded p-2'
            width="full"
            onClick={handleConfirmSchool}
            disabled={connectSchoolStatus === ACTIONS.STATUS.PENDING}
          >
            {connectSchoolStatus === ACTIONS.STATUS.PENDING
              ? 'LOADING...'
              : 'CONFIRM SCHOOL'}
          </Button>
        )}
        <Button
          intent="secondary"
          // className='tfs-dont-see-school-modal-not-visible-btn'
          onClick={handleDontSeeSchoolShowModal}
          width="full"
          disabled={connectSchoolStatus === ACTIONS.STATUS.PENDING}
        >
          Don't see your school?
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmSchoolModal;
