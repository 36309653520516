import React from 'react';

import App from './App';
import Icon from './assets/svg';
const register = () => {
  return {
    parent: 'redeem',
    label: 'Travel',
    icon: <Icon icon="Travel" width="32" height="32" />,
    content: function Content() {
      return <App />;
    },
  };
};

export default { register };
