import React, { useEffect, useContext, useState, useRef } from 'react';
import {
  Modal,
  Col,
  ButtonGroup,
  Row,
  Overlay,
  Tooltip,
} from 'react-bootstrap';
import { Button } from '@rewards/ui';
import { queryClient } from '@rewards/data-provider';
import {
  AuthContext,
  AppContext,
  DailySurpriseContext,
} from '@rewards/contexts';

import { EVENT_LOCATION, EVENTS, PAGES } from '../../analytics/events';
import { useResponsive } from '../../util/hooks';
import { TRAVEL } from '../../util/constants';

import ModalCloseBtn from '../ModalCloseButton';

import './TravelSubscriptionSuccess.scss';
import Icon from '../../assets/svg';

const { useDailySurprise } = DailySurpriseContext;

const TravelSubscriptionSuccess = ({ handleSubscribeSuccess, show }) => {
  const { envInfo } = useContext(AuthContext.AuthContext);
  const { source, track } = useContext(AppContext.AppContext);

  const { isDesktop } = useResponsive();

  const [voucherCode, setVoucherCode] = useState();
  const moneyTransferTxnsData = queryClient.getQueryState('moneyTransferTxns');
  const { dailySurpriseEvent } = useDailySurprise(
    DailySurpriseContext.DailySurpriseContext
  );
  const linkRef = useRef(null);
  const target = useRef(null);
  const [copiedTooltip, setCopiedTooltip] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');

  function copyToClipboard(e) {
    track(EVENTS.TRAVELS_COPY_LINK_BUTTON_CLICKED, {
      location: EVENT_LOCATION.TRAVEL_SUCCESS_PAGE,
      ui_version: import.meta.env.APP_VERSION,
    });
    linkRef.current.select();
    document.execCommand('copy');
    e.target.focus();
    setCopySuccess('Copied!');
    setCopiedTooltip(true);
  }

  useEffect(() => {
    if (copiedTooltip) {
      const timer = setTimeout(() => {
        setCopiedTooltip(false);
        // handleClose()
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [copiedTooltip]);

  useEffect(() => {
    return () => {
      queryClient.removeQueries('moneyTransferTxns');
    };
  }, []);

  useEffect(() => {
    if (show) {
      source(PAGES.VIEWED_TRAVEL_SUCCESS_PAGE, {
        ui_version: import.meta.env.APP_VERSION || '2.0.0',
      });
    }
  }, [show]);

  useEffect(() => {
    if (moneyTransferTxnsData?.data?.txnStatus === TRAVEL.COMPLETE) {
      setVoucherCode(moneyTransferTxnsData?.data?.code?.code);
    }
  }, [moneyTransferTxnsData]);

  const handleSelectTravel = () => {
    track(EVENTS.CLICKED_SELECT_TRAVEL_BUTTON, {
      location: EVENT_LOCATION.TRAVEL_SUCCESS_PAGE,
      is_daily_surprise: dailySurpriseEvent?.code || false,
      ui_version: import.meta.env.APP_VERSION || '2.0.0',
    });
    handleSubscribeSuccess();
  };

  return (
    <>
      <Modal
        show={show && !dailySurpriseEvent.code}
        backdrop="static"
        keyboard={false}
        centered={true}
        className="ms-modal"
        dialogClassName={`rounded' ${
          isDesktop ? '' : 'ms-mobile-dialog mx-auto'
        }`}
        contentClassName="lc-travels-modal-content"
      >
        <Modal.Header className="border-0 justify-content-center lc-travels-subscription-success-header pt-3">
          Congratulations!
          <ModalCloseBtn handleClose={handleSubscribeSuccess} />
        </Modal.Header>
        <Modal.Body className="text-left border-0 px-3 pt-0">
          <Col className="bg-white pb-4 px-0 text-center lc-travels-subscription-success-voucher-card">
            <Col className="pt-2 pb-3 lc-travels-subscription-success-voucher-code-header">
              Here is your unique voucher code:
            </Col>
            <Col className="pt-3 pb-4 lc-travels-subscription-success-voucher-code">
              {voucherCode}
            </Col>
            <Col className="text-center pt-4 mt-2 pb-2 lc-travels-subscription-success-next-step-info">
              You will need to enter this code into your United MileagePlus&reg;
              account in order to access the miles.
            </Col>
          </Col>
          <div className="mt-1 mb-2 w-75 mx-auto">
            <div className={`p-0 flex flex-col my-3 w-75-lg mx-auto-lg`}>
              <div className="flex justify-center !mb-4">
                <Overlay
                  target={target.current}
                  show={copiedTooltip}
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                >
                  {(props) => (
                    <Tooltip className="points-tooltip" {...props}>
                      Copied
                    </Tooltip>
                  )}
                </Overlay>
                <div
                  className="copy-to-clipboard input-group max-w-fit m-0 h-fit flex !items-center "
                  id="clipboard"
                  ref={target}
                >
                  <div>
                    <span
                      className="copy-to-clipboard-icon-travel !p-4"
                      id="cp-clipboard-travel"
                    >
                      <Icon icon="link" width="16" height="16" />
                    </span>
                  </div>
                  <input
                    type="text"
                    ref={linkRef}
                    value={voucherCode}
                    onChange={() => {}}
                    className="copy-to-clipboard-input-travel !py-4"
                    id="copy-to-clipboard-input-travel"
                    aria-describedby="cp-clipboard"
                  />
                </div>
              </div>
              <Button
                onClick={copyToClipboard}
                block
                // className={`copy-link-button-travel mt-1 mx-auto-lg`}
                variant="link"
              >
                COPY CODE
              </Button>
            </div>
            <Row className="mt-1 mb-1 w-100 mx-auto row">
              <Col
                md={12}
                className="flex flex-column text-center mx-auto"
              ></Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 mt-1 bg-white flex">
          <Button
            as="a"
            width="full"
            // className={`lc-travels-subscription-select-travel mr-1 rounded w-100 py-2`}
            onClick={handleSelectTravel}
            href={`${envInfo?.travelsPath}?v=${voucherCode}`}
            target="_blank"
          >
            United MileagePlus&reg;
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TravelSubscriptionSuccess;
