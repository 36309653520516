import React, { useEffect, useContext, useState } from 'react';
import { Container, Col, Button, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { AppContext, AuthContext, ACTIONS } from '@rewards/contexts';

import BackNav from '../BackNav';

import { PAGES, EVENTS } from '../../analytics/events';
import { useResponsive } from '../../util/hooks';
import { getLandingPath } from '../../util/paths';
import Icon from '../../assets/svg';

import './LandingPage.scss';

const { useAuth } = AuthContext;

const LandingPage = () => {
  const { source, track } = useContext(AppContext.AppContext);
  const { envInfo } = useContext(AuthContext.AuthContext);
  const history = useHistory();
  const { isDesktop } = useResponsive();

  useEffect(() => {
    source(PAGES.VIEWED_REFI_INFO_PAGE, {
      ui_version: import.meta.env.APP_VERSION,
    });
    LandingPage.landingPath = getLandingPath();
  }, []);

  const handleClick = () => {
    track(EVENTS.COMMUNITY_SOLAR_CLICKED, {
      ui_version: import.meta.env.APP_VERSION,
    });
    window.open(envInfo?.communitySolarPath, '_blank').focus();
  };

  return (
    <Container>
      <Row className={`p-0 ${isDesktop && 'mx-auto'}`} lg={7}>
        <Col xs="8" sm="8" md="8" className="bg-banner-blue p-5 text-center">
          <div className="align-items-center pt-4">
            <span
              className={`text-white  ${isDesktop ? 'big-title' : 'lil-title'}`}
            >
              Banner
            </span>
          </div>
        </Col>
        <Col xs="4" sm="4" md="4" className="bg-banner-grey text-center p-0">
          <div className="align-items-center">
            <Icon
              className="v-center"
              icon="community solar"
              width={`${isDesktop ? '290' : '160'}`}
              height={`${isDesktop ? '290' : '160'}`}
            />
          </div>
        </Col>
      </Row>
      <Row
        className={`p-0 pt-3 ${isDesktop && 'mx-auto'} bg-gme-gray mb-3`}
        lg={7}
      >
        <Col xs="12" sm="12" md="8" className="text-center">
          <div className="my-5">
            <p>info</p>
          </div>
        </Col>

        <Col xs="12" sm="12" md="4" className="text-center mb-3 my-auto">
          <Button
            className={`btn-button-custom-green btn-rounded mb-4 ${
              isDesktop ? 'lg-link' : ''
            }`}
            onClick={handleClick}
          >
            button
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default BackNav(LandingPage);
