import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { MagazinesContextState } from './contexts/MagazinesContext';
import {
  fetchEMagazines,
  fetchEMagazinesUnAuthorized,
} from './services/magazinesService';
import { getLandingPath, getMagazinesListPath } from './util/paths';

import Magazines from './components/Magazines';
import MagazinesList from './components/MagazinesList';

// import 'bootstrap/dist/css/bootstrap.min.css'
import './App.scss';
import './styles.css';

const App = () => {
  return (
    <MagazinesContextState
      fetchEMagazines={fetchEMagazines}
      fetchEMagazinesUnAuthorized={fetchEMagazinesUnAuthorized}
    >
      <Switch>
        <Route exact path={getLandingPath()} component={Magazines} />
        <Route
          exact
          path={getMagazinesListPath(':code')}
          component={MagazinesList}
        />
        <Route path="*" render={() => <Redirect to={getLandingPath()} />} />
      </Switch>
    </MagazinesContextState>
  );
};

export default App;
