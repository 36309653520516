import React, { useState } from 'react';
import { LoginButton } from '@rewards/ui';
import styles from './index.module.css';
import ActivationCTA from '../ActivateButton';
import MultiplierCircle from '../MultiplierCircle';
import { InfoCampaign } from '../InfoCampaign';
import PopupWrapper from '../BonusPointsPopup/PopupWrapper';
import ActivePopupContent from '../BonusPointsPopup/ActivePopupContent';
import { PriceAnimation } from '../PriceAnimation';
import { STAGES } from '../BonusPointsPopup/constants';

const BannerContent = ({
  isAuthenticated,
  userClass = '',
  isActive,
  className,
  style,
  small,
}) => {
  const [open, setOpen] = useState(false);
  const [stage, setStage] = useState(STAGES.INITIAL_POPUP);
  // TODO Replace Stylees with TW classes
  const handleActivateSuccess = () => {
    const activationDelay = 3 * 1000;
    setStage(STAGES.LOADING);
    setOpen(true);
    setTimeout(() => {
      setStage(STAGES.ACTIVATED);
    }, activationDelay);
  };

  return (
    <div
      className={`flex items-center text-white !py-0 sm:!py-1 md:!py-3  !px-2 sm:!px-12 md:!px-6 bg-cobalt   ${
        isActive ? 'justify-around' : 'justify-between '
      } overflow-hidden ${className}`}
      style={{ ...style }}
    >
      {
        <img
          className={`hidden md:flex m-2 aspect-square w-[10%] lg:w-[7.5%] `}
          alt="Green Heart Love to Save"
          src={
            'https://cdn.spinwheel.io/images/pricechopper/lovetosavegraphic.png'
          }
        />
      }
      <div
        className={`flex flex-col flex-column align-items-center text-center ${
          !isActive ? `w-3/5 md:w-2/5 lg:w-5/12` : ''
        } `}
      >
        {!isActive && (
          <div className="text-left">
            <span
              className={`text-white text-base sm:text-h3 lg:text-h1 font-medium `}
            >
              Multiply Your Points!
            </span>{' '}
            <br />
            <p className={`text-white text-small sm:text-base  mb-0`}>
              Earn even more points when you activate your bonus points
              multiplier this holiday season!
            </p>
          </div>
        )}
        {isActive && (
          <div className="flex flex-col items-center">
            <span
              style={{ fontWeight: 500 }}
              className={` text-base ${
                small ? 'lg:text-h4' : 'lg:text-h3'
              } text-white justify-self-center`}
            >
              You are earning {userClass.replace('!', '')} points through
              December 24!
            </span>
            <InfoCampaign className={'text-white'} />
          </div>
        )}
      </div>
      {!isActive && !small && (
        <div className="hidden md:flex  mx-1">
          <MultiplierCircle
            className={'font-medium aspect-square translate-y-[30%] '}
          >
            2X
          </MultiplierCircle>
          <MultiplierCircle
            className={'font-medium aspect-square -translate-y-[25%] '}
          >
            3X
          </MultiplierCircle>
          <MultiplierCircle
            className={'font-medium aspect-square translate-y-[30%] '}
          >
            4X
          </MultiplierCircle>
        </div>
      )}
      {!isActive && (
        <div className="flex flex-col items-center">
          {!isAuthenticated && (
            <LoginButton
              className={`!py-1 !px-4 md:!px-6 text-small sm:text-base  font-weight-normal font-weight-md-bold`}
            />
          )}
          {isAuthenticated && (
            <ActivationCTA onSuccess={handleActivateSuccess} />
          )}
          <InfoCampaign duration className={'text-white'} />
        </div>
      )}

      {isActive && !small && (
        <MultiplierCircle
          className={`text-base hidden sm:hidden md:flex  !mx-2 !my-2 md:text-h3 w-[75px]  font-medium`}
        >
          {userClass.toUpperCase().replace('!', '')}
        </MultiplierCircle>
      )}
      <PopupWrapper open={open} onOpenChange={setOpen} stage={stage}>
        <ActivePopupContent
          stage={stage}
          userClass={userClass?.toUpperCase().replace('!', '')}
        />
      </PopupWrapper>
    </div>
  );
};

export default BannerContent;
