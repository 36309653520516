// import { CancelToken } from 'axios';
import axiosInstance from '../utils/axiosInstance';

const useSweepstakeCampaigns = ({ signal }) => {
  // const source = CancelToken.source();
  const promise = axiosInstance
    .get('/api/v2/sweepstakes', {
      signal,
    })
    .then((response) => {
      return response?.data?.data;
    });
  // promise.cancel = () => {
  //   source.cancel('Query was cancelled by React Query');
  // };
  return promise;
};

export default useSweepstakeCampaigns;
