import { useQuery } from '@tanstack/react-query';
import getReservationInfo from '../services/reservationInfo';

export default function useReservation(reservationId = null) {
  return useQuery({
    queryKey: ['reservation', reservationId],
    queryFn: getReservationInfo,
    staleTime: 1000,
    enabled: !!reservationId,
    retry: false,
  });
}
