import React, { useState, useEffect, useCallback } from 'react';
import { differenceInCalendarDays } from 'date-fns';
import { ACTIONS, AuthContext } from '@rewards/contexts';
import useModule from '../../utils/hooks/useModule';
import BonusPointsPopupContent from './InitialPopupContent';
import { LAST_SEEN_DATE } from '../../utils/constants';
import useActivateMutation from '../../utils/hooks/useActivateMutation';
import PopupWrapper from './PopupWrapper';
import ActivePopupContent from './ActivePopupContent';
import useUserBonusPoints from '../../utils/hooks/useUserBonusPoints';
import { STAGES } from './constants';

const BonusPointsPopup = ({ wasDisplayed = () => null }) => {
  const { tokenStatus } = AuthContext.useAuth();
  const isAuthenticated = tokenStatus === ACTIONS.STATUS.RESOLVED;
  const {
    isActive,
    otherDetails: { frequency, popupStartTs, uniquePopup, popupEndTs },
  } = useModule();
  const currentTime = new Date().getTime();
  const isPopupActive =
    (popupEndTs || currentTime) >= currentTime && popupStartTs <= currentTime;
  const canBeDisplayed = isActive && isPopupActive;

  const [show, setShow] = useState(false);
  const [stage, setStage] = useState(STAGES.INITIAL_POPUP);
  const { data } = useUserBonusPoints(isAuthenticated);
  const { mutationTrigger } = useActivateMutation({
    onStart: () => setStage(STAGES.LOADING),
    onSuccess: () => {
      const activationDelay = 3 * 1000;
      setStage(STAGES.LOADING);
      setTimeout(() => {
        setStage(STAGES.ACTIVATED);
      }, activationDelay);
    },
  });

  const handleWasDisplayed = useCallback(() => {
    if (!show && !uniquePopup && !isPopupActive) wasDisplayed();
  }, [show, wasDisplayed, uniquePopup, isPopupActive]);

  const onLoginHandler = () => {
    window.localStorage.removeItem(LAST_SEEN_DATE);
  };
  const displayPopupHandler = (isAuthenticated, bonusPointsUserInfo) => {
    if (!canBeDisplayed) {
      wasDisplayed();
    }
    const date = window.localStorage.getItem(LAST_SEEN_DATE);
    const epochDate = date ? Number.parseInt(date) : '';

    if (!epochDate) {
      if (isAuthenticated && bonusPointsUserInfo?.timestamp) {
        handleWasDisplayed();
      } else if (
        !isAuthenticated ||
        (isAuthenticated && !bonusPointsUserInfo?.timestamp)
      ) {
        setShow(true);
        window.localStorage.setItem(LAST_SEEN_DATE, new Date().getTime());
      }
    } else {
      const diff = differenceInCalendarDays(epochDate, new Date().getTime());
      if (diff > frequency) {
        if (isAuthenticated && bonusPointsUserInfo?.timestamp) {
          handleWasDisplayed();
        } else if (
          !isAuthenticated ||
          (isAuthenticated && !bonusPointsUserInfo?.timestamp)
        ) {
          setShow(true);
          window.localStorage.setItem(LAST_SEEN_DATE, new Date().getTime());
        }
      } else {
        handleWasDisplayed();
      }
    }
  };

  useEffect(() => {
    if (isAuthenticated && data) {
      displayPopupHandler(isAuthenticated, data);
    } else if (!isAuthenticated) {
      displayPopupHandler(isAuthenticated, data);
    }
  }, [isAuthenticated, data]);

  if (!canBeDisplayed) return null;
  return (
    <PopupWrapper
      onOpenChange={setShow}
      open={show}
      background={stage === STAGES.INITIAL_POPUP ? 'cobalt' : 'white'}
      stage={stage}
    >
      {stage === STAGES.INITIAL_POPUP && (
        <BonusPointsPopupContent
          show={show}
          activateMutationTrigger={mutationTrigger}
          onLogin={onLoginHandler}
          isAuthenticated={isAuthenticated}
        />
      )}
      {stage !== STAGES.INITIAL_POPUP && (
        <ActivePopupContent
          show={show}
          stage={stage}
          activateMutationTrigger={mutationTrigger}
          isAuthenticated={isAuthenticated}
          userClass={data?.class}
        />
      )}
    </PopupWrapper>
  );
};

export default BonusPointsPopup;
