//@ts-nocheck
import React, { useState, useEffect } from 'react';
import { Modal, Col, Image } from 'react-bootstrap';
import { Button } from '../../lib/Button';
import { useHistory } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';

// import {
//   track,
//   source,
// } from '../../../../loyalty-connect-contexts/src/analytics';
// import {
//   EVENTS,
//   EVENT_LOCATION,
//   PAGES,
// } from '../../../../loyalty-connect-contexts/src/analytics/events';
// import { getLandingPath } from '../../../../loyalty-connect-contexts/src/util/paths';

// import ModalCloseBtn from '../../../../loyalty-connect-contexts/src/components/ModalCloseBtn';
import './UnAuthenticatedUserModal.scss';
import { ModalCloseButton } from '../Modal/ModalCloseButton';
import { useAtom, useAtomValue } from 'jotai';
import {
  unAuthenticatedModalDisplayAtom,
  isToolsForSchoolInviteFriendsAtom,
  AuthContext,
} from '@rewards/contexts';

export const UnAuthenticatedUserModal = ({}: {}) => {
  const isToolsForSchoolsInviteFriends = useAtomValue(
    isToolsForSchoolInviteFriendsAtom
  );
  const [show, setClose] = useAtom(unAuthenticatedModalDisplayAtom);
  const { resetToolsForSchoolsInviteFriendsFlow } = AuthContext.useAuth();
  const handleClose = () => setClose(false);
  const { instance } = useMsal();
  const history = useHistory();

  const [isRedirecting, setIsRedirecting] = useState(false);

  useEffect(() => {
    if (show) {
      // source({ page: PAGES.UNAUTHENTICATED_USER_MODAL, history });
    }
  }, [show]);

  const checkToolsForSchoolsInviteFlow = () => {
    if (isToolsForSchoolsInviteFriends) {
      resetToolsForSchoolsInviteFriendsFlow();
      history.push('/');
    }
  };

  const handleRedirect = () => {
    setIsRedirecting(true);
    instance
      .loginRedirect({
        ...instance.config.auth.loginRequest,
        extraQueryParameters: {
          anonymousId: window?.analytics?.user?.()?.anonymousId?.(),
        },
      })
      .finally(() => {
        setIsRedirecting(false);
      });
  };

  const handleSignIn = () => {
    // track(EVENTS.LOG_IN_CLICKED, {
    //   location: EVENT_LOCATION.UNAUTHENTICATED_USER_MODAL,
    // });
    checkToolsForSchoolsInviteFlow();
    instance.config.auth.authority = instance.config.auth.loginAuthority;
    handleRedirect();
  };

  const handleCloseUserModal = () => {
    checkToolsForSchoolsInviteFlow();
    handleClose();
  };

  return (
    <Modal
      show={show}
      centered
      backdrop="static"
      keyboard={false}
      dialogClassName={`lg:session-exp-dialog mx-auto`}
    >
      <Modal.Header>
        <Image
          src="https://cdn.spinwheel.io/images/pricechopper/Login.png"
          alt="Login banner"
          className="w-100"
        />
        <ModalCloseButton handleClose={handleCloseUserModal} />
      </Modal.Header>
      <Modal.Body className="text-center">
        <Col style={{ fontWeight: 500 }} className="my-3">
          Create your AdvantEdge Rewards account, or log in to gain access to
          your AdvantEdge Rewards points!
        </Col>
      </Modal.Body>
      <div className="flex flex-column border-0 text-center justify-content-center p-3">
        <Button
          className="m-0"
          disabled={isRedirecting}
          onClick={handleSignIn}
          width="full"
        >
          Log In / Register
        </Button>
        {/* <Col className='my-1 p-0'>
						<Button
							className='session-exp-link-account-btn rounded w-100 text-center'
							disabled={isRedirecting}
							onClick={handleSignInWithSocial}
						>
							Continue with Social
							<>
								<Image src='https://cdn.spinwheel.io/images/tcc/google-logo.png' className='mx-1' />
								<Image src='https://cdn.spinwheel.io/images/tcc/fb-logo.png' />
							</>
						</Button>
					</Col> */}
      </div>
    </Modal>
  );
};
