import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import {
  useAsync,
  ACTIONS,
  AuthContext,
  AppContext,
  PartnerContext,
} from '@rewards/contexts';
import { useSweepstakeCampaigns, useUserInfo } from '@rewards/data-provider';

import CampaignsContext from './CampaignsContext';
import { useMutation } from '@tanstack/react-query';

const { useAuth } = AuthContext;
const { useApp } = AppContext;
const { usePartner } = PartnerContext;

const CampaignsContextState = (
  { fetchCampaigns, fetchCalculateExchange, fetchBonusPointsTxns, children },
  ...props
) => {
  const { token, unAuthToken, checkValidToken } = useAuth();
  const { currencyExchanges } = useApp();
  const { data: userInfo } = useUserInfo();
  const user = userInfo?.data;
  const { partner } = usePartner();

  const asyncCampaignsData = useAsync({
    status: ACTIONS.STATUS.IDLE,
    data: { campaigns: null },
  });
  const asyncCalculateExchangeData = useAsync({
    status: ACTIONS.STATUS.IDLE,
    data: { calculateExchange: null },
  });
  const asyncBonusPointsTxns = useAsync({
    status: ACTIONS.STATUS.IDLE,
    data: { bonusPointsTxns: null },
  });

  const {
    data: campaignsData,
    status: campaignsStatus,
    isSuccess,
  } = useSweepstakeCampaigns(!!token || !!unAuthToken);

  // console.log('🚀 ~ campaignsStatus:', campaignsStatus);

  const {
    data: calculateExchangeData,
    run: runAsyncCalculateExchange,
    status: calculateExchangeStatus,
  } = asyncCalculateExchangeData;
  const {
    run: runAsyncBonusPointsTxns,
    status: bonusPointsTxnsStatus,
    data: bonusPointsTxn,
    error: bonusPointsError,
  } = asyncBonusPointsTxns;

  const getCalculateExchange = (exchangeData) => {
    if (calculateExchangeStatus === ACTIONS.STATUS.IDLE) {
      checkValidToken().then(() => {
        runAsyncCalculateExchange(
          fetchCalculateExchange({
            fromCurrencyId: exchangeData.sourceCurrencyId,
            toCurrencyId: exchangeData.destCurrencyId,
            fromCurrencyValue: exchangeData.sourceDebitIncrements,
          }).then((data) => ({
            calculateExchange: data,
          }))
        );
      });
    }
  };

  const bonusSweepstakeMutation = useMutation({
    mutationFn: (activeCampaign) => {
      const { _id, partnerAccounts } = user;

      const filteredCurrencyExchange = currencyExchanges.filter(
        (ce) =>
          ce.sourceCurrencyId === partner.currencyId &&
          ce.destCurrencyId === activeCampaign.currencyId
      );

      const sweepstakeCampaignsAccount = user.sweepstakeCampaigns.filter(
        ({ campaignId }) => campaignId === activeCampaign?._id
      );
      let account;
      if (sweepstakeCampaignsAccount.length) {
        account = { toAccountId: sweepstakeCampaignsAccount[0]?.accountId };
      } else {
        account = { subAccountId: activeCampaign?._id };
      }

      const params = {
        fromAccount: {
          fromUserId: _id,
          fromAccountId: partnerAccounts[0]?.accountId,
          fromAccountValue: 0,
          fromAccountType: 'PARTNER',
          fromCurrencyId: filteredCurrencyExchange[0]?.sourceCurrencyId,
        },
        toAccount: {
          toUserId: _id,
          toAccountValue: 1,
          toAccountType: 'SWEEPSTAKE',
          ...account,
        },
      };
      return fetchBonusPointsTxns(params);
    },
  });

  const getBonusPointsTxns = ({ activeCampaign }) => {
    return new Promise((resolve, reject) => {
      return checkValidToken().then(() => {
        runAsyncBonusPointsTxns(
          fetchBonusPointsTxns(params).then((data) => {
            resolve({ bonusPointsTxn: data });
            return data.data;
          })
        );
      });
    });
  };

  if (!isSuccess) {
    return (
      <div className="text-center spinner">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }
  return (
    <CampaignsContext.Provider
      value={{
        campaignsData,
        calculateExchangeData,
        bonusPointsTxnsStatus,
        campaignsStatus,
        getCalculateExchange,
        getBonusPointsTxns,
        bonusPointsTxn,
        bonusPointsError,
        bonusSweepstakeMutation,
      }}
      {...props}
    >
      {children}
    </CampaignsContext.Provider>
  );
};
export default CampaignsContextState;
