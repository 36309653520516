import { BackNav, ModuleLayout, Button } from '@rewards/ui';
import { Link } from 'react-router-dom';

export const Maintenance = () => {
  return (
    <BackNav>
      <ModuleLayout>
        <div className="rounded-2 borderless !p-4  lg:!p-0">
          <section className="!pt-4">
            <h3 className="text-h3 text-cobalt text-center">
              Frozen Food Frenzy is temporarily unavailable.
              <br /> Please check back soon.
            </h3>
          </section>
          {/* <section className="px-0 flex flex-row justify-center">
            <img
              // height={isDesktop ? 208 : 208}
              // height={208px}
              // width={isDesktop ? 436 : 316}
              className="w-full lg:w-1/2"
              src={`https://cdn.spinwheel.io/images/daily-surprises/thanks.png`}
              alt={`
                Our recent Daily SurPRIZE&trade; game has ended. Come back again soon for a new game for more chances to win great prizes!
                `}
            />
          </section> */}
          <div
            className={`mx-5 pt-2 flex flex-col lg:flex-row justify-around  !mt-2 lg:mt-3`}
          >
            <div className="flex flex-col w-full  lg:w-2/6 items-center">
              <div className={`text-center campaign-ended-body-text lg:my-4`}>
                Earn more points through gift card purchases, student loan
                refinancing, and more!
              </div>
              <div className="text-center mt-4 mb-4 pb-2 campaign-ended-body-text w-full">
                <Button
                  // className='campaign-ended-button'
                  width="full"
                  asChild
                >
                  <Link to="/earn">Earn</Link>
                </Button>
              </div>
            </div>

            <div className="flex flex-col w-full lg:w-2/6 items-center">
              <div className={`text-center campaign-ended-body-text lg:my-4`}>
                Check out great ways to redeem points like entering sweepstakes,
                paying down student loans, and more!
              </div>
              <div className="text-center mt-4 mb-4 campaign-ended-body-text w-full">
                <Button
                  // className='campaign-ended-button'
                  width="full"
                  asChild
                >
                  <Link to="/redeem">Redeem</Link>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ModuleLayout>
    </BackNav>
  );
};
