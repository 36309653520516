export const UTILITY_OFFERS_MODULE = 'utility_offers';

export const config = {
  accountInfo: {
    clientId: 'loyalty_connect',
    ssoClientId: 'dd322bb6-8ced-4083-a80a-13a544ceb94c',
    knownAuthorities: ['pricechopperdevsso.b2clogin.com'],
    tenant: 'pricechopperdevsso',
    policies: {
      signUpPolicy: 'B2C_1A_SIGNUP',
      signInPolicy: 'B2C_1A_signup_signin',
    },
    greenMountainPath: 'https://www.greenmountainenergy.com/market32',
  },
};

export const FEATURES_FLAGS = {
  ENABLE_UTILITY_OFFERS: 'ENABLE_UTILITY_OFFERS',
  ENABLE_COMMUNITY_SOLAR_MA: 'ENABLE_COMMUNITY_SOLAR_MA',
  ENABLE_COMMUNITY_SOLAR_NY: 'ENABLE_COMMUNITY_SOLAR_NY',
};

export const BREAKPOINTS = {
  MOBILE: 768,
  TABLET: 992,
};

export const QUERY_STATUS = {
  LOADING: 'loading',
  ERROR: 'error',
  SUCCESS: 'success',
  IDLE: 'idle',
};
