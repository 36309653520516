import { useQuery } from '@tanstack/react-query';
import getUser from '../services/useUser';

export default function useUser(enabled = false) {
  return useQuery({
    queryKey: ['userInfo'],
    queryFn: getUser,
    staleTime: 1000,
    enabled,
    retry: false,
  });
}
