import { PropsWithChildren, useCallback, useState } from 'react';
import * as Modal from '../Modal';
import { BalancePointIcon } from '../../lib/icons';

export interface ITransactionPopupProps {
  open: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  isEarn?: boolean;
  onClose?: (value: boolean) => void;
  user: any;
  isFreeTxn?: boolean;
  points?: number;
  transaction?: any;
}

export const TransactionPopup = ({
  isSuccess,
  isError,
  onClose,
  user,
  open,
  isFreeTxn = false,
  isEarn = false,
  points,
  transaction,
  children,
}: PropsWithChildren<ITransactionPopupProps>) => {
  const succesfullTxn =
    isSuccess && transaction?.data?.data?.txnStatus === 'COMPLETE';
  return (
    <Modal.Root type="transaction" open={open} onOpenChange={onClose}>
      <Modal.Content className=" ">
        <Modal.Header className=" ">
          <Modal.CloseButton className={'bg-transparent'} />
          <Modal.Title className="sr-only">
            Transaction Confirmation Popup
          </Modal.Title>
          {succesfullTxn && (
            <div className="text-h3 p-0">
              👋 Thank you, {user?.partnerAccounts[0]?.profile?.name}!
            </div>
          )}
          {(isError || transaction?.data?.data?.txnStatus !== 'COMPLETE') && (
            <div className=" !text-base font-normal text-white bg-cobalt p-3 rounded-lg mb-2 mt-1  lg:mb-4">
              {transaction?.data?.data?.statusComment ||
                transaction?.status?.desc ||
                transaction?.response?.data?.status?.desc}
            </div>
          )}
          {!isFreeTxn && succesfullTxn && (
            <>
              <div className="  font-normal pt-1 pb-1 w-100">
                You have {isEarn ? 'earned ' : 'redeemed '} your points. Here
                are the details.
              </div>

              <div
                className={`w-full flex justify-between items-center bg-green-apple text-white !p-2 rounded-lg !my-2 lg:!my-4 text-sm md:text-base`}
              >
                <span>Points {isEarn ? 'earned ' : 'used '}:</span>
                <span className="flex items-center ">
                  <BalancePointIcon className={'fill-white mx-4'} />
                  {points}
                </span>
              </div>
            </>
          )}
        </Modal.Header>

        {children}
      </Modal.Content>
    </Modal.Root>
  );
};
