import { loyaltyAxios } from '@rewards/data-provider';
import { useQuery } from '@tanstack/react-query';

export const useUserEntries = (sweepstakeId) => {
  return useQuery({
    queryKey: ['sweepstake-entries', sweepstakeId],
    queryFn: () => {
      return loyaltyAxios
        .get(`/api/v2/sweepstakes/${sweepstakeId}/entries`)
        .then((res) => res.data.data);
    },
    enabled: !!sweepstakeId,
  });
};
