import React, { useEffect, useContext } from 'react';
import { Modal, Col } from 'react-bootstrap';
import { AppContext } from '@rewards/contexts';
import { Button } from '@rewards/ui';
import { useResponsive } from '../../../util/hooks';
import Icon from '../../../assets/svg';
import styleConsts from '../../../util/styleConsts';
import { PAGES, EVENTS, EVENT_LOCATION } from '../../../analytics/events';

import ModalCloseBtn from '../ModalCloseBtn';

import './DontSeeSchoolModal.scss';

const DontSeeSchoolModal = ({
  show,
  handleClose,
  handleBackClick,
  handleResetSignUpFlow,
}) => {
  const { source, track } = useContext(AppContext.AppContext);

  const { isDesktop } = useResponsive();

  useEffect(() => {
    if (show) {
      source(PAGES.TOOLS_FOR_SCHOOLS_SCHOOL_NOT_FOUND_MODAL, {
        ui_version: import.meta.env.APP_VERSION,
      });
    }
  }, [show]);

  const handleCloseModal = () => {
    track(EVENTS.TOOLS_FOR_SCHOOLS_CLOSE_CLICKED, {
      location: EVENT_LOCATION.TOOLS_FOR_SCHOOLS_SCHOOL_NOT_FOUND_MODAL,
      ui_version: import.meta.env.APP_VERSION,
    });
    handleResetSignUpFlow?.(true);
    handleClose({ shouldReset: true });
  };

  const handleMailLink = () => {
    track(EVENTS.TOOLS_FOR_SCHOOLS_MAIL_LINK_CLICKED, {
      location: EVENT_LOCATION.TOOLS_FOR_SCHOOLS_SCHOOL_NOT_FOUND_MODAL,
      ui_version: import.meta.env.APP_VERSION,
    });
  };
  const handleGoBack = () => {
    track(EVENTS.TOOLS_FOR_SCHOOLS_GO_BACK_CLICKED, {
      location: EVENT_LOCATION.TOOLS_FOR_SCHOOLS_SCHOOL_NOT_FOUND_MODAL,
      ui_version: import.meta.env.APP_VERSION,
    });
    handleBackClick({ shouldReset: false });
  };

  const handleModalBack = () => {
    track(EVENTS.TOOLS_FOR_SCHOOLS_BACK_CLICKED, {
      location: EVENT_LOCATION.TOOLS_FOR_SCHOOLS_SCHOOL_NOT_FOUND_MODAL,
      ui_version: import.meta.env.APP_VERSION,
    });
    handleBackClick({ shouldReset: false });
  };

  return (
    <Modal
      show={show}
      backdrop="static"
      keyboard={false}
      centered={true}
      dialogClassName={`tfs-dont-see-school-modal rounded' ${
        isDesktop ? '' : 'tfs-mobile-dialog mx-auto'
      }`}
      contentClassName={`border-0 tfs-dont-see-content-modal`}
    >
      <Modal.Header className="border-0 !bg-white !px-6">
        {/* <Button className='mr-2 p-0' variant='default' onClick={handleModalBack}>
          <Icon
            icon='angle-left'
            width='9'
            height='17'
            color={styleConsts.cobalt}
          />
        </Button> */}
        <Col
          className={`tfs-dont-see-school-modal-title !px-0 py-0 !text-black`}
        >
          Don’t see your school?
        </Col>
        <ModalCloseBtn handleClose={handleCloseModal} />
      </Modal.Header>
      <Modal.Body className="tfs-dont-see-school-body !bg-white !text-base px-4">
        If you do not see your school listed, please call 1-800-352-4658 (8:00 –
        5:00 M-F ET) or email:{' '}
        <a href="mailto:info@pricechoppertfs.com" onClick={handleMailLink}>
          info@pricechoppertfs.com
        </a>
      </Modal.Body>
      <Modal.Footer className="bg-white flex justify-center tfs-dont-see-footer">
        <Button
          // className='tfs-dont-see-school-modal-go-back-btn rounded p-2'
          block
          onClick={handleGoBack}
        >
          GO BACK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DontSeeSchoolModal;
