import authAxios from './authAxios';

export const fetchSchools = async ({ name = '', zipCode = '' }) => {
  //find school
  return authAxios
    .get(`/api/schools?name=${name}&zipCode=${zipCode}`)
    .then((response) => {
      if (response.status === 200) {
        const { data } = response?.data;
        return data;
      }
    });
};

export const fetchSchoolDetails = async (schoolId) => {
  //get school details
  return authAxios.get(`/api/schools/${schoolId}`).then((response) => {
    if (response?.status === 200) {
      const { data } = response?.data;
      return data;
    }
    return null;
  });
};

export const fetchSchoolForUser = async () => {
  // get connected schools for user
  return authAxios.get('/api/toolsForSchoolsAccounts').then((response) => {
    if (response?.status === 200) {
      const { data } = response?.data;
      return data;
    }
    return null;
  });
};

export const fetchToolsForSchools = async (toolsForSchoolsId) => {
  return authAxios
    .get(`/api/toolsForSchools/${toolsForSchoolsId}`)
    .then((response) => {
      if (response?.status === 200) {
        const { data } = response?.data;
        return data;
      }
      return null;
    });
};

export const updateConnectSchool = async (schoolId) => {
  // connect school
  return authAxios
    .post('/api/toolsForSchoolsAccounts/connectSchool', { data: { schoolId } })
    .then((response) => {
      if (response?.status === 200) {
        const { data } = response?.data;
        return data;
      }
      return null;
    });
};

export const fetchInviteFriendsLink = async (params) => {
  return authAxios
    .post('/api/links/generateLink', { data: { ...params } })
    .then((response) => {
      if (response?.status === 200) {
        const { data } = response?.data;
        return data?.url;
      }
    });
};

export const fetchStarsViaSocialShare = async ({ activityType, code }) => {
  return authAxios
    .post('/api/toolsForSchoolsAccounts/earnRewards', {
      data: {
        activityType,
        code,
      },
    })
    .then((response) => {
      if (response?.status === 200) {
        const { data } = response?.data;
        return data;
      }
      return null;
    });
};

export const fetchLinkReferralUser = async ({ code, userId }) => {
  return authAxios
    .post('/api/links/linkReferralUser', {
      data: {
        code,
        userId,
      },
    })
    .then((response) => {
      if (response?.status === 200) {
        const { data } = response?.data;
        return data;
      }
      return null;
    });
};
export const validateCardNumber = async ({ cardNumber }) => {
  return authAxios
    .post('/api/users/validateCardnumber', {
      data: {
        cardNumber,
      },
    })
    .then((response) => {
      if (response?.status === 200) {
        const { data } = response?.data;
        return data;
      }
      return null;
    });
};
