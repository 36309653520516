import { useResponsive, useSchool } from '../../util/hooks';
import { Container, Col, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Icon from '../../assets/svg';

export const FamilySupportInfo = () => {
  const { isDesktop } = useResponsive();
  const { school } = useSchool();

  return (
    <div className={`my-3 ${isDesktop ? '' : 'p-0'}`}>
      <div className=" rounded-20 pl-3 pt-3 pr-3  tfs-selected-school-dashboard-families-support">
        <div className="flex justify-content-between">
          <div className="flex flex-row">
            <div className="my-auto">
              <Icon icon="heart-o" width="9" height="17" />
            </div>
            <div className="mx-2 my-auto">Families Supporting your School</div>
          </div>
          <div className="tfs-selected-school-dashboard-families-support-number my-auto">
            {school?.noOfFamilies || 0}
          </div>
        </div>
        <div className="tfs-selected-school-dashboard-total-stars-earned">
          {school?.totalStarsEarned} Total stars earned
        </div>
      </div>
    </div>
  );
};
