import { Close } from '@radix-ui/react-dialog';
import Button from '../../lib/Button';
import { cva, type VariantProps } from 'class-variance-authority';
import { tv } from '../../util';

const styles = tv({
  base: ' w-6 top-0.5 right-0.5 aspect-square absolute text-base',
  variants: {
    color: {
      cobalt: ' text-white bg-cobalt ',
      white:
        'text-secondary text-dark-grey bg-white hover:text-dark-grey hover:border-0',
    },
  },
  defaultVariants: {
    color: 'white',
  },
});
export type ModalCloseButtonProps = VariantProps<typeof styles>;
interface ModalCloseBUttonPropsFinal extends ModalCloseButtonProps {
  className?: string;
}

export const ModalCloseButton = ({
  className = '',
  ...props
}: ModalCloseBUttonPropsFinal) => {
  return (
    <Close {...props} asChild>
      <Button
        size="wrapper"
        // className=' w-6 top-'
        className={styles({ ...props, className })}
      >
        x
      </Button>
    </Close>
  );
};
