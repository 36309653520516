import React, { useContext, useEffect, useState } from 'react';
import { Col, ProgressBar } from 'react-bootstrap';
import { Button } from '@rewards/ui';
import { useIsAuthenticated } from '@azure/msal-react';
import { AuthContext, AppContext, ACTIONS } from '@rewards/contexts';
import { useUserInfo } from '@rewards/data-provider';

import Icon from '../../../assets/svg';
import { useResponsive, useLoyaltyConnect } from '../../../util/hooks';
import { LoyaltyConnectContext } from '../../../contexts/LoyaltyConnectContext';
import { EVENTS } from '../../../analytics/events';
import './RedeemBonusPointsInfo.scss';

const { useAuth } = AuthContext;

let value = 10;
const RedeemBonusPointsInfo = () => {
  const { isSSOEnabled } = useAuth();
  const { data: userInfo } = useUserInfo() || {};
  const user = userInfo?.data;
  const isAuthenticated = useIsAuthenticated();
  const { isDesktop } = useResponsive();
  const { offerCampaignsIssueRewardsStatus } = useLoyaltyConnect();

  const { handleShowUnAuthenticatedUserModal } = useContext(
    AuthContext.AuthContext
  );
  const { track } = useContext(AppContext.AppContext);
  const { getOfferCampaignsIssueRewards, updateIsRedeemBalancePage } =
    useContext(LoyaltyConnectContext);

  const [progressBarValue, setProgressBarValue] = useState(false);

  const handleClaimClick = () => {
    if (isSSOEnabled && !isAuthenticated) {
      handleShowUnAuthenticatedUserModal();
      return;
    }
    track(EVENTS.LOYALTY_CONNECT_REDEEM_BONUS_POINTS_INFO_CLICKED, {
      id: user?._id,
      ui_version: import.meta.env.APP_VERSION,
    });
    getOfferCampaignsIssueRewards().then(() => {
      setProgressBarValue(100);
      track(EVENTS.LOYALTY_CONNECT_BONUS_POINTS_REDEEMED, {
        id: user?._id,
        bonus_points: 100,
        ui_version: import.meta.env.APP_VERSION,
      });
    });
  };
  const handleProgressBar = () => {
    value += 10;
    setProgressBarValue(value);
  };
  useEffect(() => {
    if (offerCampaignsIssueRewardsStatus === ACTIONS.STATUS.PENDING) {
      const timerId = setInterval(handleProgressBar, 1000);
      return () => {
        clearInterval(timerId);
      };
    }
  }, [offerCampaignsIssueRewardsStatus]);

  return (
    <>
      {progressBarValue && (
        <Col lg={9} className="p-0 mx-auto">
          <ProgressBar animated now={progressBarValue} variant="success" />
        </Col>
      )}
      <Col
        className="p-2 text-center mx-auto mt-2  bonus-points-info flex justify-content-center"
        lg={9}
      >
        <div className={`my-auto ${isDesktop ? 'mr-3' : 'mr-1'}`}>
          <Icon icon="trophy-0" width="27" height="27" />
        </div>
        <div className="p-0">
          <Col className="p-0">
            First 50,000 AdvantEdge members receive{' '}
            <span className="bonus-points-imp-info">100 rewards points!</span>
          </Col>
          <Button
            variant="link"
            // className='bonus-points-link-btn border-0'
            onClick={handleClaimClick}
            disabled={
              offerCampaignsIssueRewardsStatus === ACTIONS.STATUS.PENDING
            }
          >
            Click here to claim!
          </Button>
        </div>
      </Col>
    </>
  );
};

export default RedeemBonusPointsInfo;
