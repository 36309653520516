import React from 'react';
import { Col } from 'react-bootstrap';

import '../Menu1/Menu1.scss';

const Menu2 = () => {
  return (
    <Col className="p-0 footer-smallest-text-center" sm={2}>
      <div className="menu-footer-menu-one-container">
        <ul id="menu-footer-menu-one" className="menu-footer-menu-one">
          <li className="menu-item">
            <a href="https://www.pricechopper.com/about-us/our-community/">
              Our Community
            </a>
          </li>
          <li className="menu-item">
            <a href="https://www.pricechopper.com/careers/" aria-current="page">
              Careers
            </a>
          </li>
          <li className="menu-item">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.pcopinion.com/"
            >
              Customer Survey
            </a>
          </li>
          <li className="menu-item">
            <a href="https://www.pricechopper.com/about-us/customer-service/contact-us/">
              Contact Us
            </a>
          </li>
          <li className="menu-item">
            <a href="https://www.pricechopper.com/replacement-advantedge-card/">
              Replacement AdvantEdge Card
            </a>
          </li>
          <li className="menu-item">
            <a href="https://www.pricechopper.com/services/gift-cards/">
              Gift Cards
            </a>
          </li>
          <li className="menu-item">
            <a href="https://www.pricechopper.com/services/pick-up/">
              About Pickup
            </a>
          </li>
          <li className="menu-item">
            <a href="https://www.pricechopper.com/services/delivery/">
              About Delivery
            </a>
          </li>
          <li className="menu-item">
            <a href="https://www.pricechopper.com/any-occasion/">
              All Holidays Entertaining
            </a>
          </li>
        </ul>
      </div>
    </Col>
  );
};

export default Menu2;
