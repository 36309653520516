import React, { Suspense } from 'react';
import { Spinner } from 'react-bootstrap';
// import App from './App';
// import Icon from './assets/svg';

// import 'bootstrap/dist/css/bootstrap.min.css'
const App = React.lazy(() => import('./App.jsx'));
const Icon = React.lazy(() => import('./assets/svg.jsx'));
const register = () => {
  return {
    parent: 'redeem',
    label: 'In Store Products',
    icon: (
      <Suspense fallback={<Spinner animation="border" role="status" />}>
        <Icon icon="in store products" width="32" height="32" />
      </Suspense>
    ),
    content: function () {
      return (
        <Suspense fallback={<Spinner animation="border" role="status" />}>
          <App />
        </Suspense>
      );
    },
  };
};

export default { register };
