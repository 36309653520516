import { AuthContext } from '@rewards/contexts';
import { MODULE_FLAG } from '../constants';

const useModule = () => {
  const { featureFlags } = AuthContext.useAuth();
  const flag = featureFlags.find((ff) => ff.name === MODULE_FLAG);

  return flag || {};
};

export default useModule;
