import { createStore } from 'jotai';
import { surveyTimeResetAtom } from './components/Survey/atoms';
import { RESET } from 'jotai/utils';

/**
 * This file is to initialize a Store to allow creating Hooks for different Atoms that requires
 * accessing an Atom outside of React.
 * Example: Having a Interval / Timer that will update a value after it's been set
 *
 */
export const store = createStore();
// After Current Time  - Time to Reset  run Function to Reset Survey Time
const surveyTimeHook = store.sub(surveyTimeResetAtom, () => {
  const timeToReset = store.get(surveyTimeResetAtom);
  if (timeToReset) {
    const today = new Date().getTime();
    setTimeout(() => {
      store.set(surveyTimeResetAtom, RESET);
    }, timeToReset - today);
  }
});

// To Unsubscribe we call the returns value of store.sub()
