import React from 'react';

import Icon from '../../assets/svg';

import './ModalCloseBtn.scss';

const ModalCloseBtn = ({ handleClose }) => {
  return (
    <span
      className="position-absolute alert-close hover rounded-circle"
      onClick={handleClose}
    >
      <Icon
        icon="close-o"
        width="40"
        height="40"
        className="modal-close-button"
      />
    </span>
  );
};

export default ModalCloseBtn;
