import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { getToken, setToken } from './token';

const MAX_REQUESTS_COUNT = 1;
const INTERVAL_MS = 10;
let PENDING_REQUESTS = 0;

const instance = axios.create();
const updateRequestConfig = (config) => {
  const accessToken = instance.token || getToken();
  config.headers.Authorization = `Bearer ${accessToken}`;
  return config;
};
const updateResponse = (response) => {
  const spinAccessToken = response?.data?.data?.token;
  if (spinAccessToken) {
    setToken(spinAccessToken);
    instance.token = spinAccessToken;
  }
  return response;
};
instance.interceptors.request.use(function (config) {
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
        PENDING_REQUESTS++;
        clearInterval(interval);
        resolve(updateRequestConfig(config));
      }
    }, INTERVAL_MS);
  });
});
instance.interceptors.response.use(
  function (response) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);

    return Promise.resolve(updateResponse(response));
  },
  function (error) {
    const now = new Date(error?.response?.headers?.date);
    const token = instance.token || getToken();
    const decoded = token && jwtDecode(token);
    if (decoded?.exp && decoded.exp <= Math.floor(now?.getTime() / 1000)) {
      error.response.handleTokenExpiry = true;
    }
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    return Promise.reject(error);
  }
);
instance.defaults.headers.post['Content-Type'] = 'application/json';

export default instance;

export const setAccessToken = (accessToken) => {
  instance.token = accessToken;
  setToken(accessToken);
};
export const getAccessToken = () => instance.token || getToken();
