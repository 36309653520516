export const getLandingPath = (path = '') => `/${path}`;

export const getCategoryPath = (category, path) =>
  `${getLandingPath(path)}/${category}`;

export const getSweepstakePath = () => '/enter-sweepstakes';
export const getProfilePath = () => '/profile';
export const getSurveyPath = () => '/earn/survey';
export const getEarnPath = () => '/earn';
export const getSweepstakePathId = (_id) => `${getSweepstakePath()}/${_id}`;
export const getDailySurprisesPath = () => `${getEarnPath()}/daily-surprise`;

export const getStudentDebtPath = () => '/student-debt';
export const getDonatePath = () => '/donate';
export const getToolsForSchoolsPath = () => '/tools-for-schools';
export const getStudentLoanRefinancePath = () =>
  `${getEarnPath()}/refinance-student-loans`;
export const getUtilityOffersPath = () => `${getEarnPath()}/utility-offers`;

export const getPriceChopperPath = () => 'https://www.pricechopper.com';
export const getPriceChopperShopPath = () => 'https://shop.pricechopper.com';
export const getPriceChopperPromoPath = () => `${getPriceChopperPath()}/promo`;
export const getPriceChopperFlyerPath = () =>
  `${getPriceChopperShopPath()}/shop/flyer`;
export const getPriceChopperSSOPath = () =>
  'https://shop.pricechopper.com/social-sign-on';

export const getClaimBonusPagePath = () => `${getLandingPath()}redeem-points`;
export const getSignUpPath = () => `${getLandingPath()}signup`;

export const getRedirectingPath = (navItem = '') => {
  switch (navItem) {
    case 'Sweepstakes':
      return getSweepstakePath();
    case 'Student Debt':
      return getStudentDebtPath();
    case 'Donate':
      return getDonatePath();
    case 'Utility Offers':
      return getUtilityOffersPath();
    case 'Student Loan Refinance':
      return getStudentLoanRefinancePath();
    default:
      return getLandingPath();
  }
};
