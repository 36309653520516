// import { CancelToken } from 'axios'
import axiosInstance from '../utils/axiosInstance';
const featureFlags = ({ signal }) => {
  // const source = CancelToken.source()

  const promise = axiosInstance
    .get('/api/featureflags', {
      signal,
    })
    .then((response) => ({ data: response?.data?.data }));
  // promise.cancel = () => {
  //   source.cancel('Query was cancelled by React Query')
  // }
  return promise;
};

export default featureFlags;
