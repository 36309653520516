import React, { useContext } from 'react';
import { AuthContext } from '@rewards/contexts';

import BalancePoints from '../BalancePoints';
import Login from '../Login';

import { useResponsive } from '../../util/hooks';

const { useAuth } = AuthContext;

const AuthenticaUserSSO = () => {
  const { isUserAuthenticated } = useAuth();
  const { isDesktop } = useResponsive();

  const { isUnAuthorizsedFlow } = useContext(AuthContext.AuthContext);

  if (isUnAuthorizsedFlow) return null;
  return (
    <div className="ml-auto text-right float-right text-white font-weight-normal authenticate-user-container">
      {isUserAuthenticated ? <BalancePoints /> : !isDesktop && <Login />}
    </div>
  );
};

export default AuthenticaUserSSO;
