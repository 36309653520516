import React from 'react';
import { Spinner, Nav, Row, Col, Container, Button } from 'react-bootstrap';

import GetLatestDeals from './components/GetLatestDeals';
import ConnectUs from './components/ConnectUs';
import Menu1 from './components/Menu1';
import Menu2 from './components/Menu2';
import Menu3 from './components/Menu3';
import Copyright from './components/Copyright';
import ContactViaSocialMedia from './components/ContactViaSocialMedia';
import { AuthContext } from '@rewards/contexts';
import useResponsive from '../../util/hooks/useResponsive';
import './Footer.scss';

const { useAuth } = AuthContext;

const Footer = () => {
  const { isDesktop } = useResponsive();
  const { isUserAuthenticated } = useAuth();
  return (
    <>
      <div
        className={`l-footer l-footer-latest-deals ${
          !isDesktop ? 'margin-footer-mobile' : ''
        }`}
      >
        <Col
          className={`position-relative margin-footer-container ${
            isDesktop && 'p-0'
          }`}
          lg={9}
        >
          <GetLatestDeals />
        </Col>
      </div>
      <div className={`l-footer ${!isDesktop ? 'margin-footer-mobile' : ''}`}>
        <Col
          className={`position-relative margin-footer-container ${
            isDesktop && 'p-0 pb-2'
          }`}
          lg={9}
        >
          {/* flex-column text-center */}
          <Row className={`flex justify-content-between px-3 mb-2`}>
            <ConnectUs />
            <Menu1 />
            <Menu2 />
            <Menu3 />
            <ContactViaSocialMedia />
          </Row>

          <div
            className={`flex row l-footer-copyright ${
              isDesktop ? 'justify-content-between' : 'flex-column text-center'
            }`}
          >
            {/* <Col sm={12} md={12} lg={2} xl={2} className="p-0">
                            <Button
                                variant='link'
                                target='_blank'
                                className='privacy-btn-footer'
                                href='https://www.pricechopper.com/about-us/customer-service/privacy-policy/?_ga=2.160708669.1031206148.1659444057-1499499206.1633978639'>
                                Privacy Policy
                            </Button>
                        </Col> */}

            <Col
              sm={12}
              md={12}
              lg={8}
              xl={8}
              className={`l-copyright-info p-0 text-left mt-1`}
            >
              Copyright Info Price Chopper / Market 32{' '}
              {new Date().getFullYear()}
            </Col>
            <Col sm={12} md={12} lg={2} xl={2}>
              <span></span>
            </Col>
          </div>
        </Col>
      </div>
    </>
  );
};

export default Footer;
