import { Button } from '@rewards/ui';
import styles from './index.module.css';
import useActivateMutation from '../../utils/hooks/useActivateMutation';

const ActivationCTA = ({ onSuccess, onError, onStart }) => {
  const { mutationTrigger } = useActivateMutation({
    onSuccess,
    onError,
  });
  return (
    <Button
      className={`!py-1 !px-4 md:!py-1 md:!px-6 text-small sm:text-base font-normal md:font-medium`}
      onClick={mutationTrigger}
    >
      Activate
    </Button>
  );
};

export default ActivationCTA;
