import { Slot } from '@radix-ui/react-slot';
import type React from 'react';
import { forwardRef, isValidElement } from 'react';
import { tailwindMerge } from '@rewards/ui';

type ComponentPropsAs<
  C extends React.ElementType<any>,
  T extends React.ComponentPropsWithoutRef<C>['as']
> = Omit<
  Extract<React.ComponentPropsWithoutRef<C>, { as: T }>,
  'as' | 'asChild'
>;

// Omits the specified props from the component props. Autocomplete will suggest props
// of the component, but won't restrict the omittable props to those that actually exist.
type ComponentPropsWithout<
  T extends React.ElementType,
  O extends
    | Omit<string, keyof React.ComponentPropsWithoutRef<T>>
    | keyof React.ComponentPropsWithoutRef<T>
> = Omit<React.ComponentPropsWithoutRef<T>, O & string>;

type RemovedProps = 'asChild' | 'defaultChecked' | 'defaultValue' | 'color';

type SkeletonElement = React.ElementRef<'span'>;

interface StekeletonProps {
  loading: true;
}
interface SkeletonProps
  extends ComponentPropsWithout<'span', RemovedProps>,
    StekeletonProps {}

export const Skeleton = forwardRef<SkeletonElement, SkeletonProps>(
  (props, forwardedRef) => {
    const { children, className, loading } = props;
    if (!loading) return children;

    const isValidChildren = isValidElement(children);
    const Tag = isValidChildren ? Slot : 'span';
    const classes = tailwindMerge(
      '*:!hidden  bg-none borber-none !shadow-none bg-grey !text-transparent !outline-none !pointer-events-none	!user-select-none !cursor-none data-[inline-skeleton]:leading-[0] empty:block empty:h-3 font-sans animate-pulse',
      className
    );
    return (
      <Tag
        ref={forwardedRef}
        aria-hidden
        className={classes}
        data-inline-skeleton={isValidChildren ? undefined : true}
        tabIndex={-1}
      >
        {children}
      </Tag>
    );
  }
);
