export const EVENTS = {
  MAGAZINES_BACK_CLICKED: 'Redemption - Magazines - Magazines Back Clicked',
  CLICKED_LIST_OF_TITLE: 'Redemption - Magazines - Clicked List of Titles',
  CLICKED_CANCEL_BUTTON: 'Redemption - Magazines - Clicked Cancel Button',
  CLICKED_SELECT_MAGAZINE_BUTTON:
    'Redemption - Magazines - Clicked Select Magazine Button',
  CLICKED_MAGAZINE_CONFIRM_BUTTON:
    'Redemption - Magazines - Clicked Magazine Confirm Button',
  CLICKED_MAGAZINE_LIST: 'Redemption - Magazines - Clicked Magazine List',
  CLICKED_SUBSCRIBE_BUTTON: 'Redemption - Magazines - Clicked Subscribe Button',
  LOG_IN_REGISTER_CLICKED: 'Log In / Register Clicked',
  DAILY_SURPRISES_CLOSE_BUTTON_CLICKED:
    'Earning - Daily surPrize - Close Button Clicked',
  DAILY_SURPRISES_SHARE_BUTTON_CLICKED:
    'Earning - Daily surPrize - Share Button Clicked',
};

export const PAGES = {
  VIEWED_MAGAZINE_HOME_PAGE: 'Viewed Magazine Home Page',
  VIEWED_MAGAZINE_CONFIRMATION_PAGE: 'Viewed Magazine Confirmation Page',
  VIEWED_MAGAZINE_SUCCESS_PAGE: 'Viewed Magazine Success Page',
};

export const EVENT_LOCATION = {
  MAGAZINE_SUCCESS_PAGE: 'Magazine Success Page',
  VIEWED_MAGAZINE_CONFIRMATION_PAGE: 'Viewed Magazine Confirmation Page',
  VIEWED_MAGAZINE_LIST_PAGE: 'Viewed Magazine List Page',
  MAGAZINE_CONFIRMATION_PAGE: 'Magazine Confirmation Page',
  MAGAZINE_LIST_PAGE: 'Magazine List Page',
  MAGAZINE_HOME_PAGE: 'Magazine Home Page',
  DAILY_SURPRISES_CONFIRM_POPUP:
    'Daily surPrize - Magazine - Confirmation Popup',
};
