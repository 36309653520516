export const ACTION_TYPES = {
  PENDING: 'PENDING',
  RESOLVED: 'RESOLVED',
  REJECTED: 'REJECTED',
  RESET: 'RESET',
};

export const STATUS = {
  PENDING: 'PENDING',
  RESOLVED: 'RESOLVED',
  REJECTED: 'REJECTED',
  IDLE: 'IDLE',
};

export const QUERY_STATUS = {
  LOADING: 'loading',
  ERROR: 'error',
  SUCCESS: 'success',
  IDLE: 'idle',
};

export const TXN_ERROR = {
  POINTS:
    'Your AdvantEdge Rewards points are less than the required total for this product.',
  INTERNAL:
    'Your redemption could not be completed at this time. Please come back and try again later',
};
