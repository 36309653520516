const styleConst = {
  blue: '0000FF',
  fireBrick: '#266cb2',
  grayDark: '#C0C0C0',
  white: '#FFF',

  'space-20': '1.25rem',
  styleValueToStyleName: (color = '#0000FF') => color,
};

export default styleConst;
