import logError from '../service/logError';
import { LOG_MESSAGES } from './constants';

const dummyFn = () => {};
const dummyAnalytics = {
  identify: dummyFn,
  alias: dummyFn,
  group: dummyFn,
  track: dummyFn,
  trackLink: dummyFn,
  trackForm: dummyFn,
  page: dummyFn,
  ready: dummyFn,
  debug: dummyFn,
  on: dummyFn,
  timeout: dummyFn,
  reset: dummyFn,
  load: dummyFn,
};
export function getLogger() {
  return window.analytics || dummyAnalytics;
}

export default function handleError(
  error,
  errorInfo,
  { appName, userDetails }
) {
  const logger = getLogger();
  logger.track(`${appName} ${LOG_MESSAGES.PAGE_ERROR}`, {
    client_id: userDetails?.clientId,
    module: appName,
    error_message: error,
    error_details: errorInfo,
    stackTrace: errorInfo.componentStack,
    userDetails,
  });

  logError(`${appName}: ${LOG_MESSAGES.APPLICATION_CRASHED}`, {
    error,
    errorInfo,
    stackTrace: errorInfo.componentStack,
    userDetails,
  });
}
