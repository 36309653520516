// import { CancelToken } from 'axios';
import axiosInstance from '../utils/axiosInstance';
const useCurrencyExchanges = ({ signal }) => {
  // const source = CancelToken.source();
  const promise = axiosInstance
    .get('/api/currencyExchanges', {
      signal,
    })
    .then((response) => {
      return {
        data: response?.data?.data,
      };
    });
  // promise.cancel = () => {
  //   source.cancel('Query was cancelled by React Query');
  // };
  return promise;
};

export default useCurrencyExchanges;
