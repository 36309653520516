export const config = {
  accountInfo: {
    clientId: 'loyalty_connect',
    appId: '295256691978389',
    ssoClientId: '6a08c47d-f749-4326-8b1c-cabfde79417d',
    knownAuthorities: ['pricechopperdevsso.b2clogin.com'],
    tenant: 'pricechopperdevsso',
    surveryMonkeyLink: 'https://www.surveymonkey.com/r/C8FRSW7',
    redirectUri: 'https://rewards.pricechopper.com:8086',
  },
};

export const BREAKPOINTS = {
  MOBILE: 768,
  TABLET: 992,
};
