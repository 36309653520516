import React, { useContext, useEffect, useState, useRef } from 'react';
import { Form, Col } from 'react-bootstrap';

import { CampaignContext } from '../../contexts';
import Icon from '../../assets/svg';
import styleConsts from '../../util/styleConsts';

import './Slider.scss';

const Slider = ({ handleSliderAdvantPoints }) => {
  const {
    advantPoints,
    advantPointsMax,
    currentAdvantPointsEntries,
    currencyExchange,
    userSweepStakeCampaign,
  } = useContext(CampaignContext) || {};

  const [AValues, setAValues] = useState([]);
  const [sliderMaxValue, setSliderMaxValue] = useState(0);
  const target = useRef(null);
  const [spanWidth, setspanWidth] = useState(0);

  const handleSliderWidthChange = (len) => {
    const sliderEle = document.querySelector(
      '.loyalty-connect-experiences-slider'
    );
    const width = sliderEle.offsetWidth;
    setspanWidth(Math.max((width / len - len).toFixed(2), 0));
  };
  useEffect(() => {
    if ('onorientationchange' in window) {
      window.addEventListener(
        'orientationchange',
        function () {
          handleSliderWidthChange(AValues.length);
        },
        false
      );
    }
    if ('onresize' in window) {
      window.addEventListener(
        'onresize',
        function () {
          handleSliderWidthChange(AValues.length);
        },
        false
      );
    }

    return () => {
      window.removeEventListener(
        'onorientationchange',
        handleSliderWidthChange
      );
      window.removeEventListener('onresize', handleSliderWidthChange);
    };
  }, []);

  useEffect(() => {
    if (currencyExchange) {
      const len = Math.floor(
        advantPointsMax / currencyExchange?.sourceDebitIncrements
      );
      setSliderMaxValue(len * currencyExchange?.sourceDebitIncrements);
      const newArray = [...Array(len).keys()].map(
        (x) => ++x * currencyExchange?.sourceDebitIncrements
      );
      setAValues(newArray);
      handleSliderWidthChange(newArray.length);
    }
  }, [currencyExchange, advantPointsMax, userSweepStakeCampaign]);

  const changePaymentValue = (e) => {
    e.persist();
    const val = +e.target.value;
    if (val <= sliderMaxValue) {
      handleSliderAdvantPoints(val);
    }
  };

  function setBubble(range, bubble) {
    const val = advantPoints;
    const min = +(range.min ? range.min : 0);
    const max = +(range.max ? range.max : 100);
    const newVal = val === 0 ? 0 : Number(((val - min) * 100) / (max - min));
    bubble.innerHTML = currentAdvantPointsEntries;

    // Sorta magic numbers based on size of the native UI thumb
    bubble.style.left = `calc(${newVal}% + (${6 - newVal * 0.12}px))`;
  }

  useEffect(() => {
    const allRanges = document.querySelectorAll('.lce-range-wrap');
    allRanges.forEach((wrap) => {
      const range = wrap.querySelector('.range');
      const bubble = wrap.querySelector('.bubble');

      range.addEventListener('input', () => {
        setBubble(range, bubble);
      });
      setBubble(range, bubble);
    });
  }, [
    advantPoints,
    advantPointsMax,
    currentAdvantPointsEntries,
    userSweepStakeCampaign,
    sliderMaxValue,
  ]);
  return (
    <>
      <Col className="mt-2 mb-4 etw-slidercontainer">
        <Form className="pl-3 pr-3">
          <Form.Group controlId="formBasicRange">
            {/* <Form.Label
              className='flex  justify-content-between mr-0'
            // style={{ paddingLeft: `${spanWidth / 2}px` }}
            >
              {AValues.map((x) => (
                <span key={x} style={{ width: `${spanWidth}px` }}>
                  {x <= advantPoints ? (
                    <Icon icon='slider' width='25' height='24' color={styleConsts.cobalt} />
                  ) : (
                    <Icon key={x} icon='slider-grey' width='25' height='24' />
                  )}
                </span>
              ))}
            </Form.Label> */}
            <div className="lce-range-wrap">
              <Form.Control
                type="range"
                name="foo"
                value={advantPoints}
                min={0}
                max={sliderMaxValue || 0}
                step={
                  advantPointsMax
                    ? currencyExchange?.sourceDebitIncrements || 0
                    : 0
                }
                className="loyalty-connect-experiences-slider range"
                ref={target}
                onChange={changePaymentValue}
              />
              <output className="bubble rounded">1</output>
            </div>
          </Form.Group>
        </Form>
      </Col>
    </>
  );
};

export default Slider;
