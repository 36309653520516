import axiosInstance from '../utils/axiosInstance';

const useGenerateToken = (requestParams) => {
  const promise = axiosInstance
    .post('/v1/user/generateToken', requestParams, {})
    .then((response) => ({ data: response?.data }));

  return promise;
};

export default useGenerateToken;
