import axios from 'axios';
import { get } from './api';

export const fetchCharities = () => get('/api/charities');
export const fetchCurrencies = () => get('/api/currencies');

const upsertPartnerAccount = async (data) => {
  return axios
    .post(`/api/users/upsertPartnerAccount/${data.id}`, { data })
    .then((response) => {
      if (response?.status === 200) {
        const { data } = response;
        return data?.status?.data;
      }
    });
};

export const fetchUserProfile = async () => {
  return axios.get('/api/v2/user/profile').then((response) => {
    if (response?.status === 200) {
      const { data } = response;
      return upsertPartnerAccount(data);
    }
  });
};
