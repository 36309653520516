import { useQuery } from '@tanstack/react-query';
import getUserInfo from '../services/userInfo';

export default function useUserInfo(enabled = false) {
  return useQuery({
    queryKey: ['user'],
    queryFn: getUserInfo,
    staleTime: 1000,
    cacheTime: 10 * 60 * 1000,
    enabled,
    retry: false,
  });
}
