import { useReducer } from 'react';
import PromoteSweepstake from '../PromoteSweepstake/PromoteSweepstake';
import { AuthContext } from '@rewards/contexts';
import { FEATURES_FLAGS } from '../../util/constants';
import { BonusPointsPopup } from '@rewards/bonus-points';

const reducer = (state, action) => {
  const newState = { ...state };
  newState[action] = true;

  return newState;
};

const initialState = [FEATURES_FLAGS.BONUS_POINTS].reduce((acc, cur) => {
  acc[cur] = false;
  return acc;
}, {});

const BannerWrapper = () => {
  const { featureFlags } = AuthContext.useAuth();
  const {
    isActive: bonusPointsActive,
    otherDetails: { popupStartTs: bonusPointsPopupStartTs },
  } = featureFlags.find((ff) => ff.name === FEATURES_FLAGS.BONUS_POINTS);
  const [modulesPopUpShowed, dispatch] = useReducer(reducer, initialState);
  // console.log('Banner Wrapper', featureFlags)
  if (bonusPointsActive && !modulesPopUpShowed[FEATURES_FLAGS.BONUS_POINTS]) {
    return (
      <BonusPointsPopup
        wasDisplayed={() => dispatch(FEATURES_FLAGS.BONUS_POINTS)}
      />
    );
  }

  return <PromoteSweepstake />;
};
export default BannerWrapper;
