export const dateString = (date = new Date()) => {
  const d = new Date(date);
  return `${d.getMonth() + 1}/${d.getDate()}/${d
    .getFullYear()
    .toString()
    .substr(-2)}`;
};

export const sortOnKey = (data = [], key, asc = true) => {
  if (asc) {
    return data.sort((a, b) => a[key] - b[key]);
  }
  return data.sort((a, b) => b[key] - a[key]);
};
