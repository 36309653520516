import React, { useEffect } from 'react';
import { AuthContext, useAsync, ACTIONS } from '@rewards/contexts';
import { Spinner } from 'react-bootstrap';
import CommunityRootContext from './CommunityRootContext';
import { fetchCharities, fetchCurrencies } from '../util/api/charityApi';
import { CHARITY_STATUS } from '../constants';

export default function CommunityContextState({ children }) {
  const asyncCurrencies = useAsync({
    status: ACTIONS.STATUS.IDLE,
    data: null,
  });
  const asyncCharities = useAsync({
    status: ACTIONS.STATUS.IDLE,
    data: null,
  });

  const { checkValidToken, token, unAuthToken } = AuthContext.useAuth();

  async function updateCurrencies() {
    return checkValidToken()
      .then(() =>
        asyncCurrencies.run(fetchCurrencies().then((currencies) => currencies))
      )
      .catch(() => {});
  }

  const idToCharityMapper = (allCharities = null) => {
    let charityMap = {};
    allCharities?.forEach(
      (charity) => (charityMap = { ...charityMap, [charity['_id']]: charity })
    );
    return charityMap;
  };

  async function updateCharities() {
    return checkValidToken()
      .then(() =>
        asyncCharities.run(
          fetchCharities().then((charities) =>
            idToCharityMapper(
              charities.filter(
                (charity) => charity.status === CHARITY_STATUS.ACTIVE
              )
            )
          )
        )
      )
      .catch(() => {});
  }

  useEffect(() => {
    if (!token) {
      return;
    }
    (async function getCommonDetails() {
      await Promise.all([updateCurrencies(), updateCharities()]);
    })();
  }, [token]);

  useEffect(() => {
    if (unAuthToken?.status === ACTIONS.STATUS.IDLE) {
      return;
    }
    (async function getCommonDetails() {
      await Promise.all([updateCharities()]);
    })();
  }, [unAuthToken]);

  if (
    asyncCharities.status !== ACTIONS.STATUS.RESOLVED ||
    (asyncCurrencies.status !== ACTIONS.STATUS.RESOLVED && !unAuthToken)
  ) {
    return (
      <Spinner animation="border" role="status" className="fullscreen">
        <span className="sr-only">Loading...</span>
      </Spinner>
    );
  }

  return (
    <CommunityRootContext.Provider
      value={{
        currencies: asyncCurrencies.data,
        charities: asyncCharities.data,
      }}
    >
      {children}
    </CommunityRootContext.Provider>
  );
}
