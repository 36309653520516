import { tv as tvBase, type TV } from 'tailwind-variants';
import { extendTailwindMerge } from 'tailwind-merge';
import twMergeConfig from './twMergeConfig';

export const tv: TV = (options, config) => {
  return tvBase(options, {
    twMerge: true,
    twMergeConfig,
    ...config,
  });
};

export const tailwindMerge = extendTailwindMerge(twMergeConfig);
