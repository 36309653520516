import React, { useEffect, useContext, useState, useRef } from 'react';
import { Modal, Col, ButtonGroup, Overlay, Tooltip } from 'react-bootstrap';
import { Button } from '@rewards/ui';
import { useMsal } from '@azure/msal-react';
import {
  useUserInfo,
  useMoneyTransferTxns,
  queryClient,
} from '@rewards/data-provider';
import {
  AuthContext,
  AppContext,
  ACTIONS,
  DailySurpriseContext,
} from '@rewards/contexts';
import { ConfirmationShareModal } from '@rewards/daily-surprises';

import ModalCloseBtn from '../ModalCloseButton';

import { EVENT_LOCATION, EVENTS, PAGES } from '../../analytics/events';
import { useResponsive, useMagazines } from '../../util/hooks';
import Icon from '../../assets/svg';
import { MAGAZINE } from '../../util/constants';
import MagazineContext from '../../contexts/MagazineContext';

import './MagazineSubscriptionConfirm.scss';

const { useAuth } = AuthContext;
const { QUERY_STATUS } = ACTIONS;
const { useDailySurprise } = DailySurpriseContext;

const MagazineDetail = () => {
  return <></>;
};

const MagazineBody = ({ magazineCode, isDesktop }) => {
  return (
    <Col className="bg-white px-0 text-center lc-magazines-subscription-success-voucher-card">
      <h3 className="confirm-title-text p-0 w-100 text-left mx-auto">
        👋 Prize Awarded!
      </h3>
      <div className="confirm-details-text p-0 w-100 text-left">
        You won (1) Free Magazine Subscription.
      </div>

      <div
        className={`confirm-emazine-daily flex flex-row justify-content-between header-badge rounded my-3 w-100 px-3 ${
          isDesktop ? 'mx-auto' : ''
        }`}
      >
        <div>Subscription Earned</div>
        <div>
          <span className="header-points-text">1</span>
        </div>
      </div>
      <Col className="pt-2 pb-3 lc-magazines-subscription-success-voucher-code-header">
        Here is your unique voucher code:
      </Col>
      <Col className="pt-1 lc-magazines-subscription-success-voucher-code">
        {magazineCode}
      </Col>
      <Col className="text-center lc-magazines-subscription-success-next-step-info">
        Save this code for reference.
      </Col>
      <Col className="text-center pt-5 lc-magazines-subscription-success-next-step-info-try w-75 mx-auto">
        Try again tomorrow for another chance to win!
      </Col>
    </Col>
  );
};

const MagazineFooter = ({ share, isDesktop }) => {
  const { envInfo } = useContext(AuthContext.AuthContext);

  return (
    <div
      className={`row mb-3 text-center mx-auto d-flex flex-row ${
        isDesktop ? 'w-75' : 'w-100'
      }`}
    >
      <div className="text-center ml-auto mr-4">
        <Button
          as="a"
          href={envInfo?.allMagazineListPath}
          target="_blank"
          // className={'emagazine-confirmation-button emagazine-btn-select mt-1 mx-auto-lg'}
        >
          SELECT MAGAZINE
        </Button>
      </div>
      <div className="text-center mr-auto">
        <Button
          onClick={share}
          // className={'emagazine-confirmation-button emagazine-btn-share mt-1 mx-auto-lg btn-green-lg'}
        >
          SHARE
        </Button>
      </div>
    </div>
  );
};

const MagazineSubscriptionConfirm = ({
  show,
  handleSubscribeConfirm,
  handleSubscribeSuccess,
}) => {
  const { source, track } = useContext(AppContext.AppContext);
  const { availablePoints, currencyExchange } = useContext(MagazineContext);
  const queryString = new URL(document.location).searchParams;
  const reservationId = queryString.get('reservationId');
  const { dailySurpriseEvent } = useDailySurprise(
    DailySurpriseContext.DailySurpriseContext
  );

  const { data: user, isFetching: isUserInfoFetching } = useUserInfo();
  const {
    status: moneyTransferStatus,
    error: moneyTransferTxnsError,
    mutate: handleMoneyTransferTxns,
    data: transaction,
  } = useMoneyTransferTxns();
  const { instance } = useMsal();
  const { unAuthToken } = useAuth();
  const { isDesktop } = useResponsive();
  const { eMagazines } = useMagazines();
  const target = useRef(null);

  const [showTxnErrorTooltip, setTxnErrorTooltip] = useState(false);
  const [txnErrorTooltipMessage, setTxnErrorTooltipMessage] = useState(
    ACTIONS.TXN_ERROR.INTERNAL
  );
  const [failSafeToast, setFailSafeToast] = useState();
  const [showSocialConfirmation, setShowSocialConfirmation] = useState(false);
  const [showSocialShare, setShowSocialShare] = useState(false);

  useEffect(() => {
    return () => {
      queryClient.removeQueries('moneyTransferTxnsError');
    };
  }, []);

  useEffect(() => {
    if (moneyTransferTxnsError) {
      setFailSafeToast(moneyTransferTxnsError?.response?.data?.status);
    }
    setTimeout(() => setFailSafeToast(), 5000);
  }, [moneyTransferTxnsError]);

  useEffect(() => {
    if (show) {
      source(PAGES.VIEWED_MAGAZINE_CONFIRMATION_PAGE, {
        ui_version: import.meta.env.APP_VERSION,
      });
    }
  }, [show]);

  useEffect(() => {
    if (
      moneyTransferStatus === QUERY_STATUS.ERROR &&
      !isUserInfoFetching &&
      !failSafeToast
    ) {
      user?.data?.partnerAccounts[0]?.accountBalance < availablePoints &&
        setTxnErrorTooltipMessage(ACTIONS.TXN_ERROR.POINTS);
      setTxnErrorTooltip(true);
    }
  }, [isUserInfoFetching, moneyTransferStatus]);

  useEffect(() => {
    if (moneyTransferStatus === QUERY_STATUS.SUCCESS) {
      handleSubscribeSuccess();
      handleSubscribeConfirm();
    }
  }, [moneyTransferStatus]);

  useEffect(() => {
    if (showTxnErrorTooltip) {
      const timer = setTimeout(() => {
        setTxnErrorTooltip(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showTxnErrorTooltip]);

  const handleCancelSubscription = (e) => {
    track(EVENTS.CLICKED_CANCEL_BUTTON, {
      location: EVENT_LOCATION.MAGAZINE_CONFIRMATION_PAGE,
      ui_version: import.meta.env.APP_VERSION,
      is_daily_surprise: dailySurpriseEvent?.code || false,
    });
    e.preventDefault();
    if (moneyTransferStatus === QUERY_STATUS.LOADING) {
      return;
    }
    handleSubscribeConfirm(moneyTransferStatus === QUERY_STATUS.ERROR);
  };

  const handleConfirmSubscription = () => {
    track(EVENTS.CLICKED_MAGAZINE_CONFIRM_BUTTON, {
      location: EVENT_LOCATION.MAGAZINE_CONFIRMATION_PAGE,
      ui_version: import.meta.env.APP_VERSION,
      is_daily_surprise: dailySurpriseEvent?.code || false,
    });

    const { _id, partnerAccounts } = user?.data;
    // console.log('E Magazines', eMagazines)
    const params = {
      fromAccount: {
        fromUserId: _id,
        fromAccountId: partnerAccounts[0]?.accountId,
        fromAccountValue: currencyExchange?.sourceDebitMax,
        fromCurrencyId: currencyExchange?.sourceCurrencyId,
        fromAccountType: MAGAZINE.PARTNER,
      },
      toAccount: {
        toUserId: _id,
        subAccountId: eMagazines?.[0]?._id,
        toAccountValue: Math.floor(
          availablePoints / currencyExchange?.sourceDebitMax
        ),
        toAccountType: MAGAZINE.EMAGAZINE,
      },
    };
    if (dailySurpriseEvent.code) {
      params.fromAccount = {
        ...params.fromAccount,
        fromAccountId: '',
        fromAccountValue: 1,
        fromCurrencyId: dailySurpriseEvent.code,
        fromAccountType: MAGAZINE.DAILY_SURPRISES,
      };
      params.toAccount = {
        ...params.toAccount,
        toAccountValue: 1,
      };
    }

    handleMoneyTransferTxns(params);
  };

  useEffect(() => {
    if (
      reservationId &&
      dailySurpriseEvent.code &&
      user &&
      eMagazines &&
      !showSocialConfirmation
    ) {
      // console.log('Transfer Status',moneyTransferStatus)
      handleConfirmSubscription();
    }
  }, [
    reservationId,
    dailySurpriseEvent,
    user,
    eMagazines,
    showSocialConfirmation,
  ]);

  useEffect(() => {
    if (transaction && reservationId) {
      setShowSocialConfirmation(true);
    }
  }, [transaction, reservationId]);

  const handleSubscribe = () => {
    track(EVENTS.CLICKED_SUBSCRIBE_BUTTON, {
      location: EVENT_LOCATION.MAGAZINE_CONFIRMATION_PAGE,
      ui_version: import.meta.env.APP_VERSION,
      is_daily_surprise: dailySurpriseEvent?.code || false,
    });
    handleSubscribeConfirm();
  };

  const handleLoginRegister = () => {
    track(EVENTS.LOG_IN_REGISTER_CLICKED, {
      ui_version: import.meta.env.APP_VERSION,
      is_daily_surprise: dailySurpriseEvent?.code || false,
    });

    instance.config.auth.authority = instance.config.auth.loginAuthority;
    instance.loginRedirect({
      ...instance.config.auth.loginRequest,
      extraQueryParameters: {
        anonymousId: window?.analytics?.user?.()?.anonymousId?.(),
      },
    });
  };
  // console.log( transaction?.data?.data?.code?.code)
  return (
    <>
      <div
        className=" bg-white fixed-bottom  fixed   justify-center flex lg:hidden "
        style={{ padding: '1.25rem' }}
      >
        <Button
          // className={`w-100 border-0 btn-button-custom-green capitalized ${isDesktop ? 'py-2 lc-magazines-subscription-subscribe-button btn-rounded' : 'py-2 lc-magazines-subscription-subscribe-button-mobile'}`}
          block
          onClick={
            unAuthToken?.status === ACTIONS.STATUS.RESOLVED
              ? handleLoginRegister
              : handleSubscribe
          }
        >
          {unAuthToken?.status === ACTIONS.STATUS.RESOLVED
            ? 'Log In / Register'
            : 'Subscribe'}
        </Button>
      </div>
      <Button
        className="hidden lg:flex"
        // className={`w-100 border-0 btn-button-custom-green capitalized ${isDesktop ? 'py-2 lc-magazines-subscription-subscribe-button btn-rounded' : 'py-2 lc-magazines-subscription-subscribe-button-mobile'}`}
        block
        onClick={
          unAuthToken?.status === ACTIONS.STATUS.RESOLVED
            ? handleLoginRegister
            : handleSubscribe
        }
      >
        {unAuthToken?.status === ACTIONS.STATUS.RESOLVED
          ? 'Log In / Register'
          : 'Subscribe'}
      </Button>
      <Modal
        show={failSafeToast?.code === 500}
        hide={!failSafeToast}
        className="mt-0"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header
          closeButton
          className="border-0 lc-upsert-toast bg-danger"
        >
          <Col className="p-0">{failSafeToast?.desc}</Col>
        </Modal.Header>
      </Modal>
      <Modal
        show={show && !dailySurpriseEvent.code}
        backdrop="static"
        keyboard={false}
        centered={true}
        className="ms-modal"
        dialogClassName={`rounded' ${
          isDesktop ? '' : 'ms-mobile-dialog mx-auto'
        }`}
        contentClassName="lc-magazines-modal-content"
      >
        <Modal.Header className="border-0 justify-content-center lc-magazines-subscription-confirmation-header pt-5">
          Confirmation
          <ModalCloseBtn handleClose={handleCancelSubscription} />
        </Modal.Header>
        <Modal.Body className="border-0 pt-4">
          <Col className="lc-magazines-subscription-confirmation-info-card bg-white">
            <Col className="lc-magazines-subscription-confirmation-info-card-header px-4 pt-3 text-center">
              Do you want to use {currencyExchange?.sourceDebitMax} Rewards
              points for a magazine subscription?
            </Col>
            <Col className="d-flex px-0 pt-5 lc-magazines-subscription-confirmation-info-card-icon-amount">
              <Icon icon="subscription-star" width="33" height="33" />
              <span className="lc-magazines-subscription-confirmation-info-card-amount pl-2">
                {currencyExchange?.sourceDebitMax}
              </span>
            </Col>
          </Col>
          <Col className="text-center pt-5 pb-4 lc-magazines-subscription-confirmation-next-step-info">
            The next step will provide you with a unique code to select your
            magazine subscription.
          </Col>
        </Modal.Body>
        <Modal.Footer className="border-0 bg-white">
          <ButtonGroup className="flex justify-center w-100">
            <Button
              className="w-1/2"
              // className='lc-magazines-subscription-cancel-button mr-1 rounded w-50 py-3'
              disabled={
                moneyTransferStatus === QUERY_STATUS.LOADING ||
                isUserInfoFetching
              }
              onClick={handleCancelSubscription}
            >
              Cancel
            </Button>

            <Button
              className="w-1/2"
              // className='lc-magazines-subscription-confirm-button w-50 ml-1 py-3 rounded border-0 btn-button-custom-green btn-rounded'
              disabled={
                moneyTransferStatus === QUERY_STATUS.LOADING ||
                isUserInfoFetching ||
                failSafeToast
              }
              onClick={handleConfirmSubscription}
              ref={target}
            >
              Confirm
            </Button>
            <Overlay
              target={target.current}
              show={showTxnErrorTooltip}
              placement="top"
              delay={{ show: 250, hide: 400 }}
            >
              {(props) => (
                <Tooltip
                  className="lc-magazines-subscription-tooltip"
                  {...props}
                >
                  {txnErrorTooltipMessage}
                </Tooltip>
              )}
            </Overlay>
          </ButtonGroup>
        </Modal.Footer>
      </Modal>
      <ConfirmationShareModal
        show={showSocialConfirmation}
        isDesktop={isDesktop}
        handleClose={() => {
          track(EVENTS.DAILY_SURPRISES_CLOSE_BUTTON_CLICKED, {
            location: EVENT_LOCATION.DAILY_SURPRISES_CONFIRM_POPUP,
            ui_version: import.meta.env.APP_VERSION,
          });
          setShowSocialConfirmation(false);
        }}
        detail={<MagazineDetail />}
        body={
          <MagazineBody
            magazineCode={transaction?.data?.data?.code?.code}
            isDesktop={isDesktop}
          />
        }
        footer={
          <MagazineFooter
            isDesktop={isDesktop}
            share={() => {
              track(EVENTS.DAILY_SURPRISES_SHARE_BUTTON_CLICKED, {
                location: EVENT_LOCATION.DAILY_SURPRISES_CONFIRM_POPUP,
                ui_version: import.meta.env.APP_VERSION,
              });
              setShowSocialShare(true);
            }}
          />
        }
        showSocialShare={showSocialShare}
      />
    </>
  );
};

export default MagazineSubscriptionConfirm;
