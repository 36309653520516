import { useQuery } from '@tanstack/react-query';
import getPartnerConfig from '../services/usePartnerConfig';

export default function usePartnerConfig(enabled = false) {
  return useQuery({
    queryKey: ['partnerConfig'],
    queryFn: getPartnerConfig,
    staleTime: 1000,
    enabled,
    retry: false,
  });
}
