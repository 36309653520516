import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useUserInfo } from '@rewards/data-provider';
import { useAsync, ACTIONS, AuthContext } from '@rewards/contexts';

import MagazinesContext from './MagazinesContext';

const { useAuth } = AuthContext;
const MagazinesContextState = (
  { children, fetchEMagazines, fetchEMagazinesUnAuthorized },
  ...props
) => {
  const { checkValidToken, unAuthToken } = useAuth();
  const { data: user } = useUserInfo();

  const asyncEMagazines = useAsync({
    status: ACTIONS.STATUS.IDLE,
    data: { eMagazines: null },
  });

  const {
    data: eMagazinesData,
    run: runAsyncEMagazines,
    status: eMagazinesStatus,
  } = asyncEMagazines;

  useEffect(() => {
    if (user) {
      checkValidToken().then(() => {
        runAsyncEMagazines(
          fetchEMagazines(user?.data?._id).then((data) => ({
            eMagazines: data,
          }))
        );
      });
    }
  }, [user]);

  useEffect(() => {
    if (unAuthToken?.status === ACTIONS.STATUS.RESOLVED) {
      checkValidToken().then(() => {
        runAsyncEMagazines(
          fetchEMagazinesUnAuthorized().then((data) => ({
            eMagazines: data,
          }))
        );
      });
    }
  }, [unAuthToken?.status]);

  if (eMagazinesStatus === ACTIONS.STATUS.IDLE) {
    return (
      <div className="text-center spinner">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }
  return (
    <MagazinesContext.Provider
      value={{
        eMagazinesData,
      }}
      {...props}
    >
      {children}
    </MagazinesContext.Provider>
  );
};
export default MagazinesContextState;
