import { LogLevel } from '@azure/msal-browser';

export const msalConfig = {
  auth: {
    allowRedirectInIframe: true,
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    loginRequest: {
      scopes: ['offline_access', 'openid'],
      forceRefresh: false,
    },
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    secureCookies: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v2.0/me',
  graphMailEndpoint: 'https://graph.microsoft.com/v2.0/me/messages',
};
