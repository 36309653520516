import { useContext } from 'react';

import DailySurpriseContext from './DailySurpriseContext';

const useDailySurprise = () => {
  const dailySuprise = useContext(DailySurpriseContext);

  const {
    dailySurpriseEvent,
    data,
    setData,
    setDailySurpriseEvent,
    refreshBalancePoint,
    setRefreshBalancePoint,
  } = dailySuprise;

  return {
    dailySurpriseEvent,
    data,
    setData,
    setDailySurpriseEvent,
    refreshBalancePoint,
    setRefreshBalancePoint,
  };
};

export default useDailySurprise;
