import { useMutation, useQueryClient } from '@tanstack/react-query';
import { loyaltyAxios } from '@rewards/data-provider';

const useActivateCardNumber = (options) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: ({ cardNumber }) => {
      loyaltyAxios.post('/api/bonus-points/activate', {
        cardNumber,
        activationTime: new Date().getTime(),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['bonus-points-cardNumber']);
    },
    ...options,
  });

  return mutation;
};

export default useActivateCardNumber;
