import { loyaltyAxios } from '@rewards/data-provider';
import { useQuery } from '@tanstack/react-query';

const useBonusPointsByCard = ({ cardNumber, enabled }) => {
  return useQuery({
    queryKey: ['bonus-points-cardNumber', cardNumber],

    queryFn: ({ signal }) => {
      return loyaltyAxios
        .get(`/api/bonus-points/${cardNumber}`, {
          signal,
        })
        .then((res) => {
          return res.data.data;
        });
    },
    enabled,
    staleTime: 24 * 60 * 60 * 1000,
    cacheTime: 24 * 60 * 60 * 1000,
  });
};

export default useBonusPointsByCard;
