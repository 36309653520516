import { useMutation } from '@tanstack/react-query';
import initGenerateToken from '../services/useGenerateToken';

import { UN_AUTH_TOKEN_KEY } from '../utils/constants';

export default function useGenerateToken() {
  return useMutation({
    mutationKey: ['generateToken'],
    mutationFn: initGenerateToken,
    onSuccess: () => {
      sessionStorage.removeItem(UN_AUTH_TOKEN_KEY);
    },
  });
}
