import React, { useState, useContext, useEffect } from 'react';
import { Col, Modal } from 'react-bootstrap';
import { Button } from '@rewards/ui';
import { AppContext } from '@rewards/contexts';

import { EVENTS, PAGES, EVENT_LOCATION } from '../../analytics/events';

import './TermsAndConditons.scss';

const TermsAndConditons = () => {
  const [show, setShow] = useState(false);

  const { track, source } = useContext(AppContext.AppContext);

  useEffect(() => {
    if (show) {
      source(PAGES.LOYALTY_CONNECT_TERMS_AND_CONDITIONS_MODAL, {
        ui_version: import.meta.env.APP_VERSION,
      });
    }
  }, [show]);

  const handleClose = () => {
    track(EVENTS.LOYALTY_CONNECT_CLOSE_CLICKED, {
      location: EVENT_LOCATION.LOYALTY_CONNECT_TERMS_AND_CONDITIONS_MODAL,
      ui_version: import.meta.env.APP_VERSION,
    });
    setShow(false);
  };

  const handleShow = () => {
    track(EVENTS.LOYALTY_CONNECT_TERMS_AND_CONDITIONS_CLICKED, {
      ui_version: import.meta.env.APP_VERSION,
    });
    setShow(true);
  };

  return (
    <>
      <Button intent="link" onClick={handleShow}>
        Terms and Conditions
      </Button>

      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
        scrollable
        className="terms-modal text-justify"
      >
        <Modal.Header closeButton>
          <Col className="terms-title text-center">
            AdvantEdge Rewards Digital Platform – Terms & Conditions
          </Col>
        </Modal.Header>
        <Modal.Body>
          <Col className="terms-sub-title-text p-0 my-2">
            The AdvantEdge Rewards digital platform provides additional ways to
            redeem your AdvantEdge Rewards points for various options aside from
            food, fuel or limited time in-store promotions. These added options
            include paying down student debt, donating to charity or entering
            sweepstakes when available. Redemption options in the platform are
            available in the “Redeem” section and are possible in 100-point
            increments(using the “+” sign to select the amount of points to
            redeem), regardless of redemption option. For student loan payments
            and donations, each 100-point redemption will equate to $1.00 for
            the selected option. Updated points balance will be reflected in the
            platform after each redemption has been processed.
          </Col>
          <Col className="terms-sub-title-text p-0 mt-3">
            Check your printed store receipt or check online at{' '}
            <a
              href="https://www.pricechopper.com/"
              target="_blank"
              rel="noreferrer"
            >
              www.pricechopper.com
            </a>
            , or after logging into
            <a
              href={`${window.location.origin}`}
              target="_blank"
              className="pl-1"
              rel="noreferrer"
            >{`${window.location.origin}`}</a>{' '}
            for your AdvantEdge Rewards points balance. You can earn AdvantEdge
            Rewards points at any Price Chopper / Market 32 or Market Bistro
            location in New York, Connecticut, Vermont, Massachusetts, New
            Hampshire and Pennsylvania. If you do not have an AdvantEdge Card
            you will be required to register for one at{' '}
            <a
              href="https://www.pricechopper.com/rewards/apply/."
              target="_blank"
              rel="noreferrer"
            >
              www.pricechopper.com/rewards/apply/.
            </a>{' '}
            Registering is free.
          </Col>
          <Col className="terms-sub-title-text p-0 mt-3">
            If for any reason you are having difficulty with the AdvantEdge
            Rewards digital platform, you may either visit{' '}
            <a
              href="https://www.pricechopper.com/about-us/customer-service/contact-us/"
              target="_blank"
              rel="noreferrer"
            >
              http://www.pricechopper.com/about-us/customer-service/contact-us/
            </a>{' '}
            or call us at 800-666-7667.
          </Col>
          <Col className="terms-sub-title-text p-0 my-3">
            For more information about AdvantEdge Rewards please visit{' '}
            <a
              href="https://www.pricechopper.com/about-us/customer-service/contact-us/"
              target="_blank"
              rel="noreferrer"
            >
              pricechopper.com/rewards
            </a>
            .
          </Col>

          <Col className="terms-sub-title px-0 mt-5">Student Loan Payment</Col>
          <Col className="terms-sub-title-text p-0 mt-4">
            You may link a loan of yours or champion that of a loved one when
            choosing the “Student Debt” module of the platform. When championing
            a loan, you will be able to send an email to the student loan
            recipient for them to verify and link their loan. For “My Loan”,
            follow the prompts to connect to your loan servicer, enter your loan
            information and hit connect. You only have to do this step once.
            Once connected, each time you visit the Digital Rewards platform
            your connected loan will automatically be available. For “My Loved
            One”, you’ll be prompted to send a link to your loved one. Your
            loved one must visit that link and follow the prompts to connect to
            their loan servicer, enter their loan information and hit connect.
            They only have to do this step once. Once connected, each time you
            visit the Digital Rewards platform their connected loan will
            automatically be available.
          </Col>
          <Col className="terms-sub-title-text p-0 mt-3">
            AdvantEdge Rewards points applied towards student debt can be
            selected in 100-point increments and will be made as a payment
            within 5 business days (100 points = $1.00). In most cases the
            transaction will be processed within 24 hours of your submission.
          </Col>

          <Col className="terms-sub-title px-0 mt-5">Donate to Charity</Col>
          <Col className="terms-sub-title-text p-0 mt-4">
            Customers may donate some of their AdvantEdge Rewards points to one
            or several charities to choose from, with available options rotating
            every few months. Within the “Donate” module in the platform, click
            on the charity option you would like to receive your donation. When
            on the screen for that charity, click on the “+” button to choose
            the number of points that you’d like to donate and then click on the
            “Donate” button to complete your points donation transaction.
            Charities will receive their donation at the end of each donation
            period as a pooled payment to be made from Price Chopper / Market 32
            directly to the organization. When available, you may also donate
            your points to your Tools for Schoolsregistered school by clicking
            on “Donate Points” in the Tools for Schools module and selecting the
            100-point increment level you would like to donate for your school
            to receive school equipment catalogue points. Donations will not be
            processed individually on behalf of each person and are not tax
            deductible.
          </Col>

          <Col className="terms-sub-title px-0 mt-5">Enter Sweepstakes</Col>
          <Col className="terms-sub-title-text p-0 mt-4">
            From time to time there will be an opportunity to enter sweepstakes
            for a chance to win some very cool prizes shown within the “Enter
            Sweepstakes” module. Entries will be redeemed at one entry per
            100-point increment. No purchase necessary to enter for a chance to
            win. Sweepstakes are open to residents of Connecticut,
            Massachusetts, New Hampshire, New York, Pennsylvania and Vermont
            that are at least 18 years of age as of their date of sweepstakes
            entry at{' '}
            <a
              href={`${window.location.origin}`}
              target="_blank"
              rel="noreferrer"
            >{`${window.location.origin}`}</a>{' '}
            with a registered Rewards account, which you can create at no
            charge. You can enter the sweepstakes at the rate of one entry per
            100 AdvantEdge Rewards points redeemed for a chance to win the
            selected prize. Multiple redemptions to enter may be processed on
            the same day. Cumulative entries will be displayed after each
            individual redemption to enter is submitted and also viewable while
            logged in by viewing your cumulative entry count. For complete
            sweepstakes rules, click on the rules link within the sweepstakes
            portion of
            <a
              href={`${window.location.origin}`}
              target="_blank"
              className="pl-1"
              rel="noreferrer"
            >{`${window.location.origin}`}</a>
            .
          </Col>
          <Col className="terms-sub-title-text p-0 mt-3">
            To enter you must be a Price Chopper / Market 32 AdvantEdge Card
            member. If you are not an AdvantEdge Card member you can become one
            for free at{' '}
            <a
              href="http://www.pricechopper.com/about-us/customer-service/contact-us/"
              target="_blank"
              rel="noreferrer"
            >
              https://www.pricechopper.com/rewards/apply/
            </a>
            . Prizes will be awarded by Price Chopper / Market 32 based on
            drawing from all valid entries received during the sweepstakes
            period by random drawing after the sweepstakes end date (except for
            weekly prize winners if applicable, which will be drawn soon after
            each weekly prize period closes). For full sweepstakes details and
            prize info, please view the Sweepstakes Rules at the bottom of the
            “Enter Sweepstakes” page while a sweepstakes is running. Potential
            prize winners will be contacted directly by Price Chopper / Market
            32 or the sweepstakes administrator. Check back often during a
            sweepstakes campaign to see possible prizes and odds of winning
            based on entries to date while each sweepstakes period is open.
          </Col>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TermsAndConditons;
