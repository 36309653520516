import { useQuery } from '@tanstack/react-query';

import getSweepstakeCampaigns from '../services/useSweepstakeCampaigns';

export default function useSweepstakeCampaigns(enabled = false) {
  return useQuery({
    queryKey: ['sweepstakeCampaigns'],
    queryFn: getSweepstakeCampaigns,
    staleTime: 3600 * 1000,
    enabled,
    retry: false,
  });
}
