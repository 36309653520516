import authAxios from './authAxios';

const headerConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const fetchCampaigns = async () => {
  // not used
  return authAxios
    .get('/api/sweepstakeCampaigns', headerConfig)
    .then((response) => {
      if (response.status === 200) {
        const { data } = response.data;
        return data;
      } else {
        return response;
      }
    });
};

export const fetchTransactions = async (params) => {
  return authAxios
    .post('/api/transactions/getUserTransactions', { data: { ...params } })
    .then((response) => {
      if (response.status === 200) {
        const { data } = response.data;
        return data;
      } else {
        return response;
      }
    });
};

export const fetchSurveyResponses = async (userId) => {
  return authAxios
    .get(`/api/surveyEvents/responses/${userId}`)
    .then((response) => {
      if (response.status === 200) {
        const { data } = response.data;
        return data;
      } else {
        return response;
      }
    });
};
