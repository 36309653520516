const styleConst = {
  blue: '0000FF',
  coconuntShade1: '#FEFEFE',
  creamBrulee: '#ffe6a6',
  cobalt: '#005baa',
  electricVioLet: '#9004FE',
  lightGrey: '#F9F9F9',
  moonYellow: '#FFBF1B',
  schooner: '#898785',
  vividOrange: '#ffa41b',
  grayDark: '#C0C0C0',
  schooner: '#898785',
  white: '#FFF',
  whiteShade: '#fefefe',
  green: '#288540',

  'space-20': '1.25rem',
  styleValueToStyleName: (color = '#0000FF') => color,
};

export default styleConst;
