export enum LOYALTY_CONNECT_MODULES {
  SWEEPSTAKES = 'Sweepstakes',
  DONATE = 'Donate',
  STUDENT_DEBT = 'Student Loan',
  PRODUCTS = 'In Store Products',
  MAGAZINES = 'Magazines',
  TOOLS_FOR_SCHOOLS = 'Tools For Schools',
  SHIP_TO_HOME = 'Ship To Home',
  FOOD = 'Food',
  FUEL = 'Fuel',
  GIFT_CARDS = 'Gift Cards',
  SHOP = 'Shop',
  MULTIPLIER_PROMOTION = 'Multiplier Promotion',
  WEEKLY_FLYER = 'Weekly Flyer',
  SHOP_IN_STORE = 'Shop in Store',
  SHOP_ONLINE = 'Shop Online',
  STUDENT_LOAN_REFINANCE = 'Student Loan Refinance',
  DAILY_SURPRISES = 'Daily Surprise',
  UTILITY_OFFERS = 'Utility Offers',
  COMMUNITY_SOLAR = 'Community Solar',
  TRAVEL = 'Travel',
  SURVEY = 'Question of the Month',
  BONUS_POINTS = 'Bonus Points',
}
