import {
  BackNav,
  Button,
  LOYALTY_CONNECT_MODULES,
  LoginButton,
  ModuleLayout,
} from '@rewards/ui';
import { AuthContext } from '@rewards/contexts';
import { Link } from 'react-router-dom';

export const Landing = () => {
  const { token } = AuthContext.useAuth();
  console.log('LANDING');

  return (
    <BackNav module={LOYALTY_CONNECT_MODULES.SURVEY} to="/earn">
      <ModuleLayout>
        <div>
          <div className="flex flex-col items-center justify-center text-white bg-cobalt w-3/4 text- mx-auto mb-5 mt-3">
            <span className="m-4">
              Answer questions and earn Rewards points!
            </span>

            <div className="  w-full  bg-grey-200 ">
              <div className={`flex justify-center h-full `}>
                <img
                  src="https://cdn.spinwheel.io/images/pricechopper/survey-icon.png"
                  width="292"
                  height="152"
                  alt="Question of the Month Icon"
                />
              </div>
            </div>
          </div>
          <div className="w-75 mx-auto flex items-center justify-center mb-5 survey-buttons">
            {!token ? (
              <LoginButton />
            ) : (
              <Button asChild>
                <Link to="question-of-the-month/survey">Take Survey</Link>
              </Button>
            )}
          </div>
        </div>
      </ModuleLayout>
    </BackNav>
  );
};
