import { authAxios } from '@rewards/contexts';

// const headerConfig = {
//   headers: {
//     'Content-Type': 'application/json'
//   }
// }

export const fetchTravels = async (userId) => {
  return authAxios.get(`/api/travels/${userId}`).then((response) => {
    if (response.status === 200) {
      const { data } = response.data;
      return data;
    } else {
      return response;
    }
  });
};

export const fetchTravelsUnAuthorized = async () => {
  return authAxios.get('/api/travels').then((response) => {
    if (response.status === 200) {
      const { data } = response.data;
      return data;
    } else {
      return response;
    }
  });
};
