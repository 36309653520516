/// <reference types="@types/segment-analytics" />

function bufferUntilReady(
  fn: SegmentAnalytics.AnalyticsJS['track'],
  onReadyCallback: SegmentAnalytics.AnalyticsJS['ready']
): SegmentAnalytics.AnalyticsJS['track'] {
  const buffer: Array<Parameters<SegmentAnalytics.AnalyticsJS['track']>> = [];
  let isReady = false;

  onReadyCallback(() => {
    isReady = true;
    buffer.forEach((args: Parameters<SegmentAnalytics.AnalyticsJS['track']>) =>
      fn(...args)
    );
    buffer.length = 0;
  });

  return (...args: Parameters<SegmentAnalytics.AnalyticsJS['track']>) => {
    if (isReady) {
      fn(...args);
    } else {
      buffer.push(args);
    }
  };
}

const CLIENT_ID = 'loyalty_connect';

// buffer calls to the `track` function until segment is ready
export const track = window.analytics
  ? bufferUntilReady((event: string, properties: Record<string, any> = {}) => {
      if (typeof event === 'undefined') {
        throw new Error('Event name is required');
      }

      window.analytics?.track(event, {
        ...properties,
        client_id: CLIENT_ID,
      });
    }, window.analytics?.ready)
  : () => {};
