import useUserInfo from './userInfo';
import useMoneyTransferTxns, {
  useMoneyTransferTxnSLP,
} from './useMoneyTransferTxns';
import usePartnerConfiguration from './partnerConfiguration';
import useExtraImpact from './extraImpact';
import useAuth from './auth';
import useFeatureFlags from './featureFlags';
import useDIM from './useDIM';
import useDisconnectStudentLoan from './useDisconnectStudentLoan';
import usePartner from './usePartner';
import useCampaigns from './useCampaigns';
import useUnAuthToken from './useUnAuthToken';
import useUpsertPartnerAccountSso from './useUpsertPartnerAccountSso';
import useCurrencyExchanges from './useCurrencyExchanges';
import useGenerateTokenPublic from './useGenerateTokenPublic';
import usePartnerConfig from './usePartnerConfig';
import useGenerateToken from './useGenerateToken';
import useUser from './useUser';
import useDigitalDiscounts from './useDigitalDiscounts';
import useSweepstakeCampaigns from './useSweepstakeCampaigns';
import useTransaction from './useTransaction';
import useReservation from './reservation';

export default {
  useUserInfo,
  useMoneyTransferTxns,
  useMoneyTransferTxnSLP,
  usePartnerConfiguration,
  useExtraImpact,
  useAuth,
  useFeatureFlags,
  useDIM,
  useDisconnectStudentLoan,
  usePartner,
  useCampaigns,
  useUnAuthToken,
  useUpsertPartnerAccountSso,
  useCurrencyExchanges,
  useGenerateTokenPublic,
  usePartnerConfig,
  useGenerateToken,
  useUser,
  useDigitalDiscounts,
  useSweepstakeCampaigns,
  useTransaction,
  useReservation,
};
