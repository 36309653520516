import React, { useEffect, useRef } from 'react';
import { useLottie } from 'lottie-react';
import animation from '../../assets/heart-animation.json';
// const animation = React.lazy(() => import('../../assets/heart-animation.json'));

export const PriceAnimation = ({ start }) => {
  const options = {
    autoplay: false,
    loop: false,
    animationData: animation,
    className:
      'w-[100vh] h-full sm:h-full sm:w-full scale-[2] md:scale-1 sm:aspect-video t-0 l-0 z-[1] ',
  };
  const { View, setSpeed, play } = useLottie(options, {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1,
  });
  useEffect(() => {
    setSpeed(1.05);
    if (start) {
      play();
    }
  }, [start, play, setSpeed]);

  return View;
};
