import React from 'react';
import { Col } from 'react-bootstrap';

import '../Menu1/Menu1.scss';

const Menu3 = () => {
  return (
    <Col className="p-0 footer-smallest-text-center" sm={2}>
      <div className="menu-footer-menu-one-container">
        <ul id="menu-footer-menu-one" className="menu-footer-menu-one">
          <li className="menu-item">
            <a href="https://www.pricechopper.com/website-security/">
              Website Security
            </a>
          </li>
          <li className="menu-item">
            <a href="https://www.pricechopper.com/about-us/customer-service/privacy-policy/">
              Privacy Policy
            </a>
          </li>
          <li className="menu-item">
            <a href="https://www.pricechopper.com/about-us/customer-service/coupon-policy/">
              Coupon Policy
            </a>
          </li>
          <li className="menu-item">
            <a href="https://www.pricechopper.com/about-us/customer-service/product-recalls/">
              Product Recalls
            </a>
          </li>
          <li className="menu-item">
            <a href="https://www.pricechopper.com/terms-and-conditions/">
              Terms & Conditions
            </a>
          </li>
          <li className="menu-item">
            <a href="https://www.pricechopper.com/sustainability/">
              Sustainability
            </a>
          </li>
          <li className="menu-item">
            <a href="https://www.anthem.com/machine-readable-file/search/">
              Machine Readable Files
            </a>
          </li>
          <li className="menu-item">
            <a href="https://www.pricechopper.com/our-brands/">Our Brands</a>
          </li>
        </ul>
      </div>
    </Col>
  );
};

export default Menu3;
