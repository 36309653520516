import React from 'react';

import BaseLayout from './BaseLayout';
import { CookiePolicyBanner } from '../CookiesBanner';

const Base = ({ children }) => {
  return (
    <BaseLayout>
      {children}
      <CookiePolicyBanner />
    </BaseLayout>
  );
};

export default Base;
